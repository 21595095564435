import { toPascalKeys } from 'utils/convert.util'
import { fetchAPI } from './apiUtils'

import {
  PRODUCT_URL,
  PRODUCT_UPDATE_URL,
  PRODUCT_ADD_URL,
  PRODUCT_DELETE_URL,
  PRODUCT_GET_URL,
  BOM_URL,
  BOM_UPDATE_URL,
  BOM_ADD_URL,
  BOM_DELETE_URL,
  BOM_GET_URL,
  BOM_CHILD_URL,
  SUGGESTED_PRODUCT,
  SUGGESTED_PART,
  PART_URL,
  PART_ADD_URL,
  PART_DELETE_URL,
  PART_GET_URL,
  PART_UPDATE_URL,
  SCRAPER_URL,
  FILE_UPLOAD_URL,
  DASHBOARD_COMMON_URL,
  SUPPLIER_LIST_URL,
  SUPPLIER_GET_URL,
  SUPPLIER_EDIT_URL,
  SUPPLIER_ADD_URL,
  SUPPLIER_SYNC_URL,
  SUPPLIER_DELETE_URL,
  SUPPLIER_ACTIVE_URL,
  SUPPLIER_LOG_URL,
  SUPPLIER_CSV_URL,
  MERGE_SUPPLIERS,
  PRODUCT_CSV_URL,
  PART_CSV_URL,
  NOTIFICATION_URL,
  SEARCH_PART,
  SEARCH_PRODUCT,
  UOM_GET_URL,
  SYNC_PRODUCT,
  SYNC_PART,
  SYNC_BOM,
  NOTIFICATION_READ_URL,
} from '../consts/url'

export function getCommonData() {
  const formData = {}
  return fetchAPI(DASHBOARD_COMMON_URL, 'POST', formData)
}
export function getProducts(offset, length, filters) {
  const formData = {
    offset,
    length,
    filters,
  }
  console.log(PRODUCT_URL, formData)
  return fetchAPI(PRODUCT_URL, 'POST', formData)
}

export function getBoms(offset, length, filters) {
  const formData = {
    offset,
    length,
    filters,
  }
  console.log(BOM_URL, formData)
  return fetchAPI(BOM_URL, 'POST', formData)
}

export function getSuggestedProducts(productId) {
  const formData = {
    productId: { productId },
  }
  return fetchAPI(SUGGESTED_PRODUCT, 'POST', formData)
}

export function getSuggestedParts(productId) {
  const formData = {
    productId: { productId },
  }
  return fetchAPI(SUGGESTED_PART, 'POST', formData)
}

export function getProductDetail(ProductSlug) {
  return fetchAPI(`${PRODUCT_URL}/${ProductSlug}`)
}

export function searchProducts(queryParams) {
  return fetchAPI(SEARCH_PRODUCT + queryParams)
}

export function updateProduct(orgProduct, newProduct) {
  console.log(PRODUCT_UPDATE_URL, orgProduct, newProduct)
  const formData = {
    old: toPascalKeys(orgProduct),
    new: toPascalKeys(newProduct),
  }
  console.log(PRODUCT_UPDATE_URL, formData)
  return fetchAPI(PRODUCT_UPDATE_URL, 'POST', formData)
}

export function updateBom(orgBom, newBom) {
  const formData = {
    old: toPascalKeys(orgBom),
    new: toPascalKeys(newBom),
  }
  console.log(BOM_UPDATE_URL, formData)
  return fetchAPI(BOM_UPDATE_URL, 'POST', formData)
}

export function addProduct(newProduct) {
  const formData = toPascalKeys(newProduct)
  console.log(PRODUCT_ADD_URL, formData)
  return fetchAPI(PRODUCT_ADD_URL, 'POST', formData)
}

export function addBom(newBom) {
  const formData = toPascalKeys(newBom)
  console.log(BOM_ADD_URL, formData)
  return fetchAPI(BOM_ADD_URL, 'POST', formData)
}

export function getBomChildren(productId) {
  const formData = { productId }
  console.log(BOM_CHILD_URL, formData)
  return fetchAPI(BOM_CHILD_URL, 'POST', formData)
}

export function getProduct(productId) {
  const formData = {
    productId,
  }
  console.log(PRODUCT_GET_URL, formData)
  return fetchAPI(PRODUCT_GET_URL, 'POST', formData)
}

export function getBom(productId) {
  const formData = {
    productId,
  }
  console.log(BOM_GET_URL, formData)
  return fetchAPI(BOM_GET_URL, 'POST', formData)
}

export function getUomList() {
  console.log(UOM_GET_URL)
  return fetchAPI(UOM_GET_URL, 'POST')
}

export function deleteProduct(productId) {
  const formData = {
    productId,
  }
  console.log(PRODUCT_DELETE_URL, formData)
  return fetchAPI(PRODUCT_DELETE_URL, 'POST', formData)
}

export function deleteBom(productId) {
  const formData = {
    productId,
  }
  console.log(BOM_DELETE_URL, formData)
  return fetchAPI(PRODUCT_DELETE_URL, 'POST', formData)
}

export function uploadImage(newFile) {
  const data = new FormData()
  data.append('file', newFile)
  return fetchAPI(FILE_UPLOAD_URL, 'POST', data, true)
}

export function uploadCsvFile(url) {
  const formData = {
    url,
  }
  return fetchAPI(SUPPLIER_CSV_URL, 'POST', formData)
}

export function uploadProductCsv(url, mappings) {
  const formData = {
    url,
    Active: '',
    LegacyProductNumber: '',
    ProductDescription: '',
    VendorPartNumber: '',
    ProductDetails: '',
    UOM: '',
    Price: '',
    Class: '',
    Taxable: '',
    ComboBox: '',
    AllowUOM: '',
    ProductURL: '',
    ProductPictureUrl: '',
    ProductUPC: '',
    ProductSKU: '',
    ProductSOItemType: '',
    IncomeAccount: '',
    Weight: '',
    WeightUOM: '',
    Width: '',
    Height: '',
    Length: '',
    SizeUOM: '',
    DefaultFlag: '',
    AlertNote: '',
    CartonCount: '',
    CartonType: '',
    ExpenseAccountName: '',
    UnitOfMeasureName: '',
    ...mappings,
  }
  return fetchAPI(PRODUCT_CSV_URL, 'POST', formData)
}

export function uploadPartCsv(url, mappings) {
  const formData = {
    url,
    PartPictureUrl: '',
    ItemId: '',
    Vendor: '',
    VendorPartNumber: '',
    DefaultVendor: '',
    ManufacturerId: '',
    ManufacturerName: '',
    ItemDetails: '',
    Description: '',
    LeadTime: '',
    DefaultFlag: '',
    ItemAttributes: '',
    UOM: '',
    Cost: '',
    MinQuantity: '',
    ...mappings,
  }
  return fetchAPI(PART_CSV_URL, 'POST', formData)
}

export function getParts(offset, length, filters) {
  const formData = {
    offset,
    length,
    filters,
  }
  console.log(PART_URL, formData)
  return fetchAPI(PART_URL, 'POST', formData)
}

export function addPart(newPart) {
  const formData = toPascalKeys(newPart)
  return fetchAPI(PART_ADD_URL, 'POST', formData)
}

export function getPart(partId) {
  const formData = { partId }
  return fetchAPI(PART_GET_URL, 'POST', formData)
}

export function updatePart(orgPart, newPart) {
  const formData = {
    old: toPascalKeys(orgPart),
    new: toPascalKeys(newPart),
  }
  return fetchAPI(PART_UPDATE_URL, 'POST', formData)
}

export function deletePart(partId) {
  const formData = {
    partId,
  }
  return fetchAPI(PART_DELETE_URL, 'POST', formData)
}
export function getScrapers(offset, count) {
  const formData = {
    offset,
    count,
  }
  return fetchAPI(SCRAPER_URL, 'POST', formData)
}
export function getSuppliersList(offset, count, filters) {
  const formData = {
    offset,
    length: count,
    filters,
  }
  return fetchAPI(SUPPLIER_LIST_URL, 'POST', formData)
}
export function getSupplier(supplierId) {
  const formData = {
    supplierId,
  }
  return fetchAPI(SUPPLIER_GET_URL, 'POST', formData)
}
export function addSupplier(supplier) {
  const formData = {
    supplier: toPascalKeys(supplier),
  }
  return fetchAPI(SUPPLIER_ADD_URL, 'POST', formData)
}
export function updateSupplier(oldSupplier, newSupplier) {
  const formData = {
    old: toPascalKeys(oldSupplier),
    new: toPascalKeys(newSupplier),
  }
  return fetchAPI(SUPPLIER_EDIT_URL, 'POST', formData)
}
export function deleteSupplier(supplierId) {
  const formData = {
    supplierId,
  }
  return fetchAPI(SUPPLIER_DELETE_URL, 'POST', formData)
}
export function syncService(serviceName) {
  const formData = {
    serviceName,
  }
  return fetchAPI(SUPPLIER_SYNC_URL, 'POST', formData)
}
export function getActiveSuppliers() {
  const formData = {}
  return fetchAPI(SUPPLIER_ACTIVE_URL, 'POST', formData)
}
export function getServiceLog(serviceName) {
  const formData = {
    serviceName,
  }
  return fetchAPI(SUPPLIER_LOG_URL, 'POST', formData)
}
export function getNotifications(offset, length) {
  const formData = {
    offset,
    length,
  }
  return fetchAPI(NOTIFICATION_URL, 'POST', formData)
}

export function setNotificationRead(id) {
  const formData = {
    notificationId: id,
  }
  return fetchAPI(NOTIFICATION_READ_URL, 'POST', formData)
}

export function getPartsByQuery(keyword) {
  const formData = {
    keyword,
  }
  return fetchAPI(SEARCH_PART, 'POST', formData)
}

export function getProductsByQuery(keyword) {
  const formData = {
    keyword,
  }
  return fetchAPI(SEARCH_PRODUCT, 'POST', formData)
}

export function syncProduct() {
  const formData = {}
  return fetchAPI(SYNC_PRODUCT, 'POST', formData)
}

export function syncPart() {
  const formData = {}
  return fetchAPI(SYNC_PART, 'POST', formData)
}

export function syncBom() {
  const formData = {}
  return fetchAPI(SYNC_BOM, 'POST', formData)
}

export function mergeSuppliers(firstSupplierId, secondSupplierId) {
  const formData = {
    firstSupplierId,
    secondSupplierId,
  }
  return fetchAPI(MERGE_SUPPLIERS, 'POST', formData)
}
