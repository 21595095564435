import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { classNames } from 'utils/helper'
import { decimalFormatter } from 'utils/formatter'

import './index.css'

const Header = ({ value, product, manufacturer, scraped, link }) => {
  const history = useHistory()
  const classes = classNames(
    'dashboard-header-button',
    (product && '-product') || '',
    (manufacturer && '-manufacturer') || '',
    (scraped && '-scraped') || ''
  )
  let imageSrc = ''
  let typeText = ''

  if (product) {
    imageSrc = '/assets/img/dashboard/product.svg'
    typeText = 'Total Products'
  }
  if (manufacturer) {
    imageSrc = '/assets/img/dashboard/manufacturer.svg'
    typeText = 'Supplier Items Available'
  }
  if (scraped) {
    imageSrc = '/assets/img/dashboard/scraped.svg'
    typeText = 'Total Scraped Competitor Products'
  }

  return (
    <div
      className={classes}
      onClick={() => {
        if (link) {
          history.push(link)
        }
      }}
    >
      {imageSrc && <img className="dashboard-header-image" src={imageSrc} alt="box" />}
      <div className="dashboard-header-text">
        <span className="dashboard-header-text-value"> {decimalFormatter(value)}</span>
        <span className="dashboard-header-text-caption"> {typeText} </span>
      </div>
    </div>
  )
}

Header.propTypes = {
  value: PropTypes.number,
  product: PropTypes.bool,
  manufacturer: PropTypes.bool,
  scraped: PropTypes.bool,
  link: PropTypes.string,
}

Header.defaultProps = {
  value: 0,
  product: false,
  manufacturer: false,
  scraped: false,
  link: '',
}
export default Header
