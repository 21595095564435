import React from 'react'
import PropTypes from 'prop-types'
import { classNames } from 'utils/helper'
import './index.css'

const HTextInput = ({ children, className, name, placeholder, inputType, error, ...rest }) => {
  let classes = classNames('h-text-input', className || '')

  if (error) {
    classes = classNames('h-input-error', classes)
  }

  return <textarea className={classes} {...rest} {...{ name, placeholder }} id={name} />
}
HTextInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  className: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  inputType: PropTypes.string,
  error: PropTypes.string,
}

HTextInput.defaultProps = {
  children: '',
  className: null,
  id: '',
  placeholder: '',
  name: '',
  inputType: 'normal',
  error: '',
}
export default HTextInput
