import React, { useState } from "react";
import PropTypes from "prop-types";

import { classNames } from "utils/helper";
import "./index.css";
import HButton from "./HButton";

const HImage = ({
  children,
  className,
  onEdit,
  onRemove,
  onPrimary,
  readOnly,
  ...rest
}) => {
  const classes = classNames("h-image-container ", className || "");
  return (
    <div className={classes}>
      <div className="h-image-overlay">
        {onEdit && !readOnly && (
          <HButton className="h-transparent-button" onClick={onEdit}>
            <img
              src="/assets/img/common/pencil.svg"
              alt="edit"
              width="30px"
              height="30px"
            />
          </HButton>
        )}
        {onRemove && !readOnly && (
          <HButton className="h-transparent-button" onClick={onRemove}>
            <img
              src="/assets/img/common/trash.svg"
              alt="remove"
              width="30px"
              height="30px"
            />
          </HButton>
        )}
        {onPrimary && !readOnly && (
          <HButton className="h-transparent-button" onClick={onPrimary}>
            <img
              src="/assets/img/common/top.svg"
              alt="featured"
              width="30px"
              height="30px"
            />
          </HButton>
        )}
      </div>
      <img
        {...rest}
        alt="missing"
        onError={ev => {
          // eslint-disable-next-line no-param-reassign
          // eslint-disable-next-line no-param-reassign
          ev.target.onerror = null;
          ev.target.src = "/assets/img/approval/featured.svg";
        }}
      />
    </div>
  );
};
HImage.propTypes = {
  className: PropTypes.string,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  onPrimary: PropTypes.func,
  readOnly: PropTypes.bool
};

HImage.defaultProps = {
  className: "",
  onEdit: null,
  onRemove: null,
  onPrimary: null,
  readOnly: false
};
export default HImage;
