export const PAGE_ITEMS = [10, 20, 30, 50, 100]
export const PART_MAPPING = [
  'None',
  'Vendor',
  'VendorPartNumber',
  'DefaultVendor',
  'ManufacturerId',
  'ManufacturerName',
  'ItemDetails',
  'Description',
  'LeadTime',
  'DefaultFlag',
  'ItemAttributes',
  'PartPictureUrl',
  'ItemId',
  'UOM',
  'Cost',
  'MinQuantity',
]

export const PRODUCT_MAPPING = [
  'None',
  'LegacyProductNumber',
  'Active',
  'ProductDescription',
  'VendorPartNumber',
  'ProductDetails',
  'UOM',
  'Price',
  'Class',
  'Taxable',
  'ComboBox',
  'AllowUOM',
  'ProductURL',
  'ProductPictureUrl',
  'ProductUPC',
  'ProductSKU',
  'ProductSOItemType',
  'IncomeAccount',
  'Weight',
  'WeightUOM',
  'Width',
  'Height',
  'Length',
  'SizeUOM',
  'DefaultFlag',
  'AlertNote',
  'CartonCount',
  'CartonType',
  'ExpenseAccountName',
  'UnitOfMeasureName',
]
