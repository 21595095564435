import React, { useEffect, useState } from 'react'
import { withRouter, useLocation, useHistory } from 'react-router-dom'
import { MediumLoadingAnimation } from 'components/LoadingAnimation'
import DashboardCard from 'components/Dashboard/card'
import Header from 'components/Dashboard/header'
import { getCommonData } from 'api/productApi'
import { approvePending, declinePending } from 'api/approvalApi'
import { store } from 'react-notifications-component'

import './index.css'

const Dashboard = () => {
  const history = useHistory()
  const { search } = useLocation()
  const [loading, setLoading] = useState(true)
  const [totalProduct, setTotalProduct] = useState(0)
  const [manuItems, setManuItems] = useState(0)
  const [scrapedProducts, setScrapedProducts] = useState(0)

  const [recentProducts, setRecentProducts] = useState({ count: 0, items: [] })
  const [recentManuItems, setRecentManuItems] = useState({
    count: 0,
    items: [],
  })
  const [recentPending, setRecentPending] = useState({ count: 0, items: [] })
  const [recentActivity, setRecentActivity] = useState({ count: 0, items: [] })

  const fetchData = async () => {
    const response = await getCommonData()
    if (response && response.error) {
    } else if (response) {
      setTotalProduct(response?.counts?.totalProducts)
      setManuItems(response?.counts?.totalParts)
      setScrapedProducts(response?.totalScrapers)

      setRecentProducts({
        count: response.counts[3],
        items: response.recentProducts,
      })

      setRecentManuItems({
        count: response.counts[6],
        items: response.parts,
      })

      setRecentPending({
        count: response.counts[4],
        items: response.approvals,
      })

      setRecentActivity({
        count: response.counts[5],
        items: response.activities,
      })
    }
    setLoading(false)
  }

  const onApprovePending = (pending) => {
    setLoading(true)
    const approveAction = async () => {
      try {
        const response = await approvePending(pending)
        if (response && response.error) {
          store.addNotification({
            message: `Error: ${response.error}`,
            type: 'danger',
            insert: 'bottom',
            container: 'bottom-left',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 1000,
            },
          })
        } else if (response && response.message) {
          store.addNotification({
            message: `Successfully approved.`,
            type: 'success',
            insert: 'bottom',
            container: 'bottom-left',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 1000,
            },
          })
          fetchData()
        }
      } catch (err) {
        store.addNotification({
          message: `Error: Approval failed`,
          type: 'danger',
          insert: 'bottom',
          container: 'bottom-left',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 1000,
          },
        })
      }
      setLoading(false)
    }
    approveAction()
  }

  const onDeclinePending = (pending) => {
    setLoading(true)

    const declineAction = async () => {
      try {
        const response = await declinePending(pending)
        if (response && response.error) {
          store.addNotification({
            message: `Error: ${response.error}`,
            type: 'danger',
            insert: 'bottom',
            container: 'bottom-left',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 1000,
            },
          })
        } else if (response && response.message) {
          store.addNotification({
            message: `Decline Success.`,
            type: 'success',
            insert: 'bottom',
            container: 'bottom-left',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 1000,
            },
          })
          fetchData()
        }
      } catch (err) {
        store.addNotification({
          message: 'Decline Error',
          type: 'danger',
          insert: 'bottom',
          container: 'bottom-left',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 1000,
          },
        })
      }
      setLoading(false)
    }
    declineAction()
  }

  useEffect(() => {
    // Loading Data
    setLoading(true)

    fetchData()
  }, [])

  return (
    <div className="dashboard-container">
      {loading && <MediumLoadingAnimation />}
      {!loading && (
        <>
          <div className="dashboard-header">
            <Header value={totalProduct} product link={'./products/view'} />
            <Header value={manuItems} manufacturer link={'./supplier-part-list'} />
            <Header value={scrapedProducts} scraped />
          </div>
          <div className="dashboard-body">
            <DashboardCard
              caption="Recently Added Products"
              itemCount={recentProducts.count}
              items={recentProducts.items}
              itemType="Product"
              viewLink="./products/view"
            />
            <DashboardCard
              caption="Recently Added Supplier Items"
              itemCount={recentManuItems.count}
              items={recentManuItems.items}
              itemType="Part"
              viewLink="./supplier-part-list"
            />
            <DashboardCard
              caption="Pending Approval(s)"
              itemCount={recentPending?.count}
              items={recentPending?.items}
              itemType="Pending"
              viewLink="./approvals/view"
              approveAction={(item) => {
                onApprovePending(item.id)
              }}
              declineAction={(item) => {
                onDeclinePending(item.id)
              }}
            />
            <DashboardCard
              caption="Activity Feed"
              itemCount={recentActivity.count}
              items={recentActivity.items}
              itemType="Activity"
            />
          </div>
        </>
      )}
    </div>
  )
}

export default withRouter(Dashboard)
