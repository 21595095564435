import { combineReducers } from "redux";
import apiCallsInProgress from "./apiStatusReducer";
import modal from "./modalReducer";
import filterReducer, { customFilterReducer } from "./filterReducer";
import importReducer from "./importReducer";
import searchResult from "./searchReducer";
import user from "./userReducer";

const rootReducer = combineReducers({
  apiCallsInProgress,
  modal,
  filter: filterReducer,
  customFilter: customFilterReducer,
  importData: importReducer,
  searchResult,
  user
});

export default rootReducer;
