/* eslint-disable guard-for-in */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react'
import { withRouter, useParams, useHistory } from 'react-router-dom'

import LoadingAnimation from 'components/LoadingAnimation'
import Caption from 'components/Suppliers/Caption'
import HSingleSelect from 'components/common/Basic/HSingleSelect'
import HInput from 'components/common/Basic/HInput'
import HTextInput from 'components/common/Basic/HTextInput'
import HForm from 'components/common/Basic/HForm'
import HButton from 'components/common/Basic/HButton'
import HDropButton from 'components/common/Basic/HDropButton'
import EditViewSettingItem from 'components/Products/EditViewSettingItem'
import { INITIAL_SUPPLIER } from 'consts/suppliers'
import { store } from 'react-notifications-component'
import AddMedia from 'components/common/Filter/AddMedia'
import UploadCSV from 'components/common/Filter/UploadCSV'
import DeleteWarningModal from 'components/common/Modal/DeleteWarningModal'
import { SupplierSalesforceItems } from 'components/common/Filter/SalesforceItems'
import CsvImportModal from 'components/common/Modal/CsvImportModal'
import CustomImportBox from 'components/common/Filter/CustomImport'
import debounce from 'lodash/debounce'

import { toArrayFromJSON, toJSONFromArray, isError, supplierValidationCheck } from 'utils/formatter'
import {
  getSupplier,
  updateSupplier,
  deleteSupplier,
  syncService,
  getServiceLog,
} from 'api/productApi'
import './index.css'

const SupplierDetail = () => {
  const history = useHistory()
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [isSyncProgress, setSyncProgress] = useState(false)
  const [showSupplier, setShowSupplier] = useState({})
  const [orgSupplier, setOrgSupplier] = useState({})
  const [serviceLogs, setServiceLogs] = useState([])
  const [deleteWarning, showDeleteWarning] = useState(false)
  const [undoWarning, showUndoWarning] = useState(false)
  const [changes, setChanges] = useState({})
  const [showImportModal, setImportModal] = useState(false)

  const backToList = () => {
    history.push('/suppliers')
  }

  const onSyncAction = async (service) => {
    setSyncProgress(true)
    try {
      const response = await syncService(service)
      console.log(response)
      setSyncProgress(false)
      store.addNotification({
        message: response.message,
        type: 'success',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 5000,
        },
      })
    } catch {
      store.addNotification({
        message: 'Sorry, we are not able to process your request now, please try again later.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 5000,
        },
      })
    }
  }
  useEffect(() => {
    if (!id) {
      backToList()
    }
    setLoading(true)
    const getData = async () => {
      try {
        const curSupplier = await getSupplier(id)
        if (curSupplier && curSupplier.error) {
          console.log(curSupplier.error)

          store.addNotification({
            message: 'Sorry, we are not able to process your request now, please try again later.',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 5000,
            },
          })
        } else if (curSupplier) {
          curSupplier.serviceName = toArrayFromJSON(curSupplier.serviceName)
          curSupplier.serviceFreq = toArrayFromJSON(curSupplier.serviceFreq)
          const logs = []
          let service
          for (service of curSupplier.serviceName) {
            const log = await getServiceLog(service)
            logs.push(log)
          }

          setServiceLogs(logs)

          setShowSupplier({ ...INITIAL_SUPPLIER, ...curSupplier })
          setOrgSupplier(curSupplier)
          setLoading(false)
        }
        if (!curSupplier) {
          store.addNotification({
            message: 'Sorry, we are not able to process your request. Redirect in 2 seconds...',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 3000,
            },
          })
          setTimeout(() => {
            backToList()
          }, 2000)
        }
      } catch (error) {
        console.log('ViewSupplier error', error)
        store.addNotification({
          message: 'Sorry, we are not able to process your request now. Please try again later.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 4000,
          },
        })
      }
    }
    getData()
  }, [id])

  const onSave = () => {
    // for (const log in serviceLogs) {
    //   if (serviceLogs[log].length === 0) {
    //     store.addNotification({
    //       message: `Service Error. To add a new service, please contact the administrator.`,
    //       type: 'info',
    //       insert: 'top',
    //       container: 'top-right',
    //       animationIn: ['animated', 'bounceIn'],
    //       animationOut: ['animated', 'fadeOut'],
    //       dismiss: {
    //         duration: 5000,
    //       },
    //     })
    //     return
    //   }
    // }

    // if (!supplierValidationCheck(showSupplier)) {
    //   console.log('onSave supplierValidationCheck failed')
    //   store.addNotification({
    //     message: 'Complete Supplier and try again.',
    //     type: 'danger',
    //     insert: 'top',
    //     container: 'top-right',
    //     animationIn: ['animated', 'bounceIn'],
    //     animationOut: ['animated', 'fadeOut'],
    //     dismiss: {
    //       duration: 2000,
    //     },
    //   })
    //   return
    // }
    if (showSupplier === orgSupplier) {
      console.log('Save --> no changes')
      store.addNotification({
        message: 'Sorry, no changes were detected. Please try again after changing the details.',
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      })
    } else {
      console.log('Save', showSupplier, orgSupplier)
      const updatedSupplier = { ...showSupplier }
      updatedSupplier.serviceName = toJSONFromArray(showSupplier.serviceName)

      setLoading(true)
      const editSupplier = async () => {
        const response = await updateSupplier(orgSupplier, updatedSupplier)
        console.log(response)
        if (response && response.error) {
          console.log(response.error.message)
          store.addNotification({
            message: 'Sorry, we are not able to process your request now. please try again later.',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 4000,
            },
          })
        } else if (response) {
          // eslint-disable-next-line no-alert
          console.log(response)
          store.addNotification({
            message: `${response.message}.`,
            type: 'success',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 5000,
            },
          })
        }
        setLoading(false)
      }
      editSupplier()
    }
  }

  const onClear = () => {
    console.log('Clear')
    setShowSupplier({ ...INITIAL_SUPPLIER, id: showSupplier.id })
  }

  const onDelete = () => {
    console.log('Delete')
    setLoading(true)
    const onDeleteAction = async () => {
      const response = await deleteSupplier(showSupplier.id)
      console.log(response)
      if (response && response.error) {
        console.log(response.error.message)
        store.addNotification({
          message: 'Sorry, we are not able to process your request now. Please try again later.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 4000,
          },
        })
      } else if (response) {
        store.addNotification({
          message: `${response.message}. Redirect in 3 seconds. `,
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 5000,
          },
        })
        setTimeout(() => {
          backToList()
        }, 3000)
      }
      setLoading(false)
    }
    onDeleteAction()
  }

  const logChange = (category) => {
    const tempChanges = { ...changes }
    tempChanges[category] = true
    setChanges(tempChanges)
  }

  const onUndo = () => {
    setShowSupplier(orgSupplier)
    setChanges({})
  }

  const onSyncAll = (serviceName) => {
    for (const i in serviceName) {
      onSyncAction(serviceName[i])
    }
  }

  const onSync = (service) => {
    onSyncAction(service)
  }

  const onGetServiceResult = debounce(async (service, index) => {
    const log = await getServiceLog(service)
    const tempLogs = [...serviceLogs]
    tempLogs[index] = log
    setServiceLogs(tempLogs)
  }, 300)

  return (
    <div className="detail-container">
      <div className="supplier-detail">
        {deleteWarning && (
          <DeleteWarningModal
            onHide={() => {
              showDeleteWarning(false)
            }}
            content="Are you sure you want to delete this supplier?"
            okAction={() => {
              onDelete()
              showDeleteWarning(false)
            }}
            cancelAction={() => {
              showDeleteWarning(false)
            }}
          />
        )}
        {undoWarning && (
          <DeleteWarningModal
            onHide={() => {
              showUndoWarning(false)
            }}
            content="Are you sure you want to revert your changes?"
            okAction={() => {
              onUndo()
              showUndoWarning(false)
            }}
            okCaption="Undo"
            cancelAction={() => {
              showUndoWarning(false)
            }}
          />
        )}
        {loading && <LoadingAnimation />}
        <div className="supplier-detail-list">
          <div className="supplier-detail-list-title">
            <Caption
              caption="View / Edit Supplier"
              backAction={() => {
                history.push('/suppliers')
              }}
            />
          </div>
          <div className="horizontal-separator" />
          <div className="supplier-detail-edit-view">
            <HForm className="edit-view-item-form">
              <EditViewSettingItem
                caption="Name:"
                errorMessage={isError((showSupplier && showSupplier.name) || '', 'string')}
              >
                <HInput
                  className="edit-view-input-style"
                  name="name"
                  value={(showSupplier && showSupplier.name) || ''}
                  onChange={(ev) => {
                    const newSupplier = { ...showSupplier }
                    newSupplier.name = ev.currentTarget.value
                    setShowSupplier(newSupplier)
                    logChange('name')
                  }}
                  error={isError((showSupplier && showSupplier.name) || '', 'string')}
                />{' '}
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Vendor:"
                errorMessage={isError((showSupplier && showSupplier.name) || '', 'string')}
                clearAction={() => {
                  onClear()
                }}
              >
                <HSingleSelect
                  name="type"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                  value={
                    showSupplier && {
                      value: showSupplier.isVendor,
                      label: showSupplier.isVendor ? 'TRUE' : 'FALSE',
                    }
                  }
                  onChange={(ev) => {
                    const newSupplier = { ...showSupplier }
                    newSupplier.isVendor = ev.value
                    setShowSupplier(newSupplier)
                    logChange('isVendor')
                  }}
                  isSearchable={false}
                  error={isError((showSupplier && showSupplier.name) || '', 'string')}
                />
              </EditViewSettingItem>
              <EditViewSettingItem
                caption="Manufacturer:"
                errorMessage={isError((showSupplier && showSupplier.name) || '', 'string')}
                clearAction={() => {
                  onClear()
                }}
              >
                <HSingleSelect
                  name="type"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                  value={
                    showSupplier && {
                      value: showSupplier.isManufacturer,
                      label: showSupplier.isManufacturer ? 'TRUE' : 'FALSE',
                    }
                  }
                  onChange={(ev) => {
                    const newSupplier = { ...showSupplier }
                    newSupplier.isManufacturer = ev.value
                    setShowSupplier(newSupplier)
                    logChange('isManufacturer')
                  }}
                  isSearchable={false}
                  error={isError((showSupplier && showSupplier.name) || '', 'string')}
                />
              </EditViewSettingItem>
              <EditViewSettingItem caption="Active:">
                <HSingleSelect
                  name="active"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                  value={
                    showSupplier && {
                      value: showSupplier.active,
                      label: showSupplier.active ? 'TRUE' : 'FALSE',
                    }
                  }
                  onChange={(item) => {
                    const updateSupplier = { ...showSupplier }
                    updateSupplier.active = item.value
                    setShowSupplier(updateSupplier)
                    logChange('active')
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Phone Number:">
                <HInput
                  className="edit-view-input-style"
                  name="phoneNumber"
                  value={(showSupplier && showSupplier.phoneNumber) || ''}
                  onChange={(ev) => {
                    const newSupplier = { ...showSupplier }
                    newSupplier.phoneNumber = ev.currentTarget.value
                    setShowSupplier(newSupplier)
                    logChange('phoneNumber')
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Website:">
                <HInput
                  className="edit-view-input-style"
                  name="website"
                  value={(showSupplier && showSupplier.website) || ''}
                  onChange={(ev) => {
                    const newSupplier = { ...showSupplier }
                    newSupplier.website = ev.currentTarget.value
                    setShowSupplier(newSupplier)
                    logChange('website')
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Contact:">
                <HInput
                  className="edit-view-input-style"
                  name="contact"
                  value={(showSupplier && showSupplier.contact) || ''}
                  onChange={(ev) => {
                    const newSupplier = { ...showSupplier }
                    newSupplier.contact = ev.currentTarget.value
                    setShowSupplier(newSupplier)
                    logChange('contact')
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Description:">
                <HTextInput
                  className="edit-view-text-input-style"
                  name="Description"
                  value={(showSupplier && showSupplier.description) || ''}
                  rows={8}
                  onChange={(ev) => {
                    const newSupplier = { ...showSupplier }
                    newSupplier.description = ev.currentTarget.value
                    setShowSupplier(newSupplier)
                    logChange('description')
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Service Name:"
                addButton
                addAction={() => {
                  const newSupplier = { ...showSupplier }
                  newSupplier.serviceName.push('')
                  newSupplier.serviceFreq.push('')
                  setShowSupplier(newSupplier)
                  const newServiceLogs = [...serviceLogs]
                  newServiceLogs.push([])
                  setServiceLogs(newServiceLogs)
                  logChange('serviceName')
                }}
                errorMessage={isError(
                  (showSupplier && showSupplier.serviceName && showSupplier.serviceName.length) ||
                    '',
                  'string'
                )}
              >
                <div className="h-container">
                  {showSupplier &&
                    showSupplier.serviceName &&
                    showSupplier.serviceName.map((service, index) => {
                      return (
                        <div key={`service-${index}`}>
                          <div className="h-row mb-1">
                            <HInput
                              className="edit-view-input-style mr-2"
                              name="serviceName"
                              value={service || ''}
                              onChange={(ev) => {
                                const newSupplier = { ...showSupplier }
                                newSupplier.serviceName[index] = ev.currentTarget.value
                                setShowSupplier(newSupplier)
                                if (newSupplier.serviceName[index].length > 0) {
                                  onGetServiceResult(newSupplier.serviceName[index], index)
                                }
                              }}
                              error={isError(service, 'string')}
                            />
                            <HDropButton
                              className="mr-2"
                              secondary
                              values={[
                                { value: 'Daily', label: 'Daily' },
                                { value: 'Weekly', label: 'Weekly' },
                                { value: 'Monthly', label: 'Monthly' },
                              ]}
                              value={{
                                value: showSupplier.serviceFreq[index] || 'Daily',
                                label: showSupplier.serviceFreq[index] || 'Daily',
                              }}
                              onChange={(ev) => {
                                const newSupplier = { ...showSupplier }
                                newSupplier.serviceFreq[index] = ev.value
                                setShowSupplier(newSupplier)
                                logChange('serviceName')
                              }}
                            />
                            <HButton
                              className="h-medium-button h-green-button mr-2"
                              onClick={() => {
                                const newSupplier = { ...showSupplier }
                                newSupplier.serviceName.splice(index, 1)
                                setShowSupplier(newSupplier)
                              }}
                            >
                              -
                            </HButton>

                            <HButton
                              className="h-medium-button h-blue-button"
                              onClick={() => {
                                onSync(service)
                              }}
                            >
                              Sync
                            </HButton>
                          </div>
                          <div className="h-row mb-2 pl-2 pr-2 pt-1 pb-1">
                            <div className="h-col">
                              <div className="h-row mb-2">
                                <span className="h-log-header">
                                  &apos;{service}&apos; Log{' '}
                                  {serviceLogs &&
                                    serviceLogs[index] &&
                                    serviceLogs[index].length === 0 &&
                                    'does not exist'}
                                </span>
                              </div>
                              {serviceLogs[index].length > 0 && (
                                <>
                                  <table className="h-log-table">
                                    <tbody>
                                      <tr>
                                        <th>Vendor</th>
                                        <th>VendorPartNumber</th>
                                        <th>ServiceName</th>
                                        <th>Status</th>
                                        <th>Date</th>
                                      </tr>
                                      {serviceLogs[index] &&
                                        serviceLogs[index].map((log, ind) => {
                                          if (ind >= 20) {
                                            return null
                                          }
                                          return (
                                            <tr key={`log-${index}-${ind}`}>
                                              <td>{log.vendor}</td>
                                              <td>{log.vendorPartNumber}</td>
                                              <td>{log.serviceName}</td>
                                              <td>{log.failed ? 'failed' : 'success'}</td>
                                              <td>{log.updatedAt}</td>
                                            </tr>
                                          )
                                        })}
                                    </tbody>
                                  </table>
                                  {serviceLogs[index].length >= 20 && (
                                    <div className="h-row h-big-font mb-4">...</div>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              </EditViewSettingItem>
              <div className="supplier-detail-last-line">
                <HButton
                  className="h-delete-button"
                  onClick={() => {
                    showDeleteWarning(true)
                  }}
                >
                  Delete Supplier
                </HButton>
                <div className="supplier-detail-last-line-right">
                  <HButton
                    className="h-undo-button"
                    onClick={() => {
                      showUndoWarning(true)
                    }}
                    disabled={Object.keys(changes).length === 0}
                  >
                    Undo changes({Object.keys(changes).length})
                  </HButton>
                  <HButton
                    className="h-save-button"
                    onClick={() => {
                      onSave()
                    }}
                  >
                    Save
                  </HButton>
                </div>
              </div>
            </HForm>
          </div>
        </div>
        <div className="supplier-detail-filters">
          <HButton
            className="h-large-button h-blue-button mb-3"
            onClick={() => {
              console.log('Synchronize - ', showSupplier.serviceName)
              if (isSyncProgress) {
                store.addNotification({
                  message: 'Sorry, another instance is running now.',
                  type: 'danger',
                  insert: 'top',
                  container: 'top-right',
                  animationIn: ['animated', 'bounceIn'],
                  animationOut: ['animated', 'fadeOut'],
                  dismiss: {
                    duration: 2000,
                  },
                })
              } else {
                onSyncAll(showSupplier.serviceName)
              }
            }}
          >
            Sync Now
          </HButton>
          <CustomImportBox
            caption={'Upload CSV'}
            onUploadCallback={() => {
              setImportModal(true)
            }}
          />

          <AddMedia
            caption="Set Logo Image"
            pictureUrls={JSON.stringify(showSupplier.logoUrl ? [showSupplier.logoUrl] : [])}
            setPictureUrls={(picUrls) => {
              try {
                const urls = JSON.parse(picUrls)

                const updateSupplier = { ...showSupplier }
                if (urls.length === 0) {
                  updateSupplier.logoUrl = ''
                } else if (urls.length === 1) {
                  updateSupplier.logoUrl = urls && urls[0]
                } else if (urls.length >= 2) {
                  updateSupplier.logoUrl = urls && urls[1]
                }

                setShowSupplier(updateSupplier)
              } catch {
                console.log('setPictureUrl error', picUrls)
              }
            }}
            uniqueMode
          />
          <SupplierSalesforceItems
            salesforceInfo={{
              salesForceId: showSupplier && showSupplier.salesForceId,
              siteId: showSupplier && showSupplier.siteId,
              accountId: showSupplier && showSupplier.accountId,
              catalogueId: showSupplier && showSupplier.catalogueId,
            }}
          />
        </div>
        <CsvImportModal
          show={showImportModal}
          isSupplier
          onHide={() => {
            setImportModal(false)
          }}
        />
      </div>
    </div>
  )
}

export default withRouter(SupplierDetail)
