import { fetchAPI } from "./apiUtils";

const USER_SIGNIN = "auth/login";
const USER_SIGNUP = "auth/createUser";

export function authenticate(signinFormData) {
  return fetchAPI(USER_SIGNIN, "POST", signinFormData);
}

export function signup(signupFormData) {
  return fetchAPI(USER_SIGNUP, "POST", signupFormData);
}
