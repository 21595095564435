import React, { useState } from 'react'
import PropTypes from 'prop-types'
import HCheckBox from 'components/common/Basic/HCheckBox'
import { priceDecimalFormatter } from 'utils/formatter'
import { getProductFeatureImage } from 'utils/product_util'
import './index.css'

const ScraperItem = ({ scraper, importAction }) => {
  const [isChecked, setChecked] = useState(false)
  return (
    <div
      className={isChecked ? 'scrapers-list-item-checked' : 'scrapers-list-item'}
      onClick={() => {
        setChecked(!isChecked)
      }}
      onDoubleClick={() => {
        importAction(scraper)
      }}
    >
      <div className="scrapers-list-item-info">
        <div className="scrapers-list-item-info-checkbox">
          <HCheckBox
            name={scraper.productNumber}
            onChange={() => {
              setChecked(isChecked)
            }}
            checked={isChecked}
          />
        </div>
        <div className="products-list-item-info-pic">
          <img
            className="products-list-item-info-pic"
            src={
              getProductFeatureImage(scraper.productPictureUrl) || '/assets/img/products/sample.png'
            }
            alt="missing"
          />
        </div>
        <div className="products-list-item-info-subinfo">
          <div className="products-list-item-info-title">{scraper.productNumber}</div>
          <div className="products-list-item-info-description">{scraper.productDescription}</div>
          <div className="products-list-item-info-price-type">
            <div className="products-list-item-info-price">
              {priceDecimalFormatter(scraper.price)}
            </div>
            <div className="products-list-item-info-type">{scraper.incomeAccount}</div>
          </div>
        </div>
      </div>
      <div className="products-list-item-action">
        <a
          role="button"
          onClick={() => {
            importAction(scraper)
          }}
          tabIndex={0}
        >
          IMPORT AS PRODUCT
        </a>
      </div>
    </div>
  )
}
ScraperItem.propTypes = {
  scraper: PropTypes.shape({
    id: PropTypes.number.isRequired,
    productNumber: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    incomeAccount: PropTypes.string,
    productPictureUrl: PropTypes.string,
    productDescription: PropTypes.string,
  }),
  importAction: PropTypes.func,
}

ScraperItem.defaultProps = {
  scraper: {
    id: 1,
    productNumber: '230605',
    productDescription: 'Sunblock, SPF 30, 8oz, 1/each',
    price: 24.99,
    incomeAccount: 'Disposable - Sales',
    productPictureUrl: '',
  },
  importAction: () => {},
}
export default ScraperItem
