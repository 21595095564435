const PARTS = {
  MANUFACTURERS: ["Manufacturer 1", "Manufacturer 2", "Manufacturer 3"],
  WEIGHT_UOM: ["Lbs", "Kg", "t"],
  SIZE_UOM: ["In", "cm", "km"],
  PRODUCT_SO_TYPES: ["Sale", "Other"],
  UOM: ["EA", "BOX", "DOZEN"],
};

export const NEW_PART_INFO = {
  MANUFACTURER: ["Manufacturer1", "Manufacturere2", "Manufacturer3"],
  VENDOR: ["Vendor1", "Vendor2", "Vendor3"],
  INCOME_ACCOUNTS: ["Cash1", "Cash2", "Cash3"],
  EXPENSE_ACCOUNTS: ["Cost of Goods Sold", "Cost of Goods Bought"],
};

export const INITIAL_PART = {
  vendor: "",
  vendorPartNumber: 0,
  itemId: "",
  leadTime: 0,
  defaultVendor: false,
  uom: "ea",
  cost: 0.0,
  minQuantity: 0,
  active: true,
  description: "",
  manufacturerId: "",
  manufacturerName: "",
  UOMs: [],
};

export default PARTS;
