import React, { useEffect, useState } from 'react'
import { withRouter, useLocation, useHistory } from 'react-router-dom'
import { MediumLoadingAnimation } from 'components/LoadingAnimation'
import Caption from 'components/Scrapers/Caption'
import Filters from 'components/common/Filter/Filters'
import AddMedia from 'components/common/Filter/AddMedia'
import HSingleSelect from 'components/common/Basic/HSingleSelect'
import HTextInput from 'components/common/Basic/HTextInput'
import HInput from 'components/common/Basic/HInput'
import CustomFilters from 'components/common/Filter/CustomFilters'
import SelectedActions from 'components/common/Filter/SelectedActions'
import ScrapersItemList from 'components/Scrapers/ScrapersItemList'
import { decimalFormatter } from 'utils/formatter'
import HButton from 'components/common/Basic/HButton'
import HPagination from 'components/common/Pagination'
import PARTS from 'consts/parts'
import { PAGE_ITEMS } from 'consts/common'
import ProductPreview from 'components/Products/ProductPreview'
import HForm from 'components/common/Basic/HForm'
import EditViewSettingItem from 'components/Products/EditViewSettingItem'
import SuggestedSupplierParts from 'components/common/Filter/SuggestedSupplierParts'
import SuggestedLinkedProducts from 'components/common/Filter/SuggestedLinkedProducts'
import EditViewActions from 'components/common/Filter/EditViewActions'
import { getScrapers, getProducts } from 'api/productApi'
import './index.css'

const ScraperList = () => {
  const history = useHistory()
  const { search } = useLocation()
  const [loading, setLoading] = useState(true)
  const [scrapers, setParts] = useState([])
  const [results, setResults] = useState(0)
  const [pageTitle, setPageTitle] = useState('All Competitor Scrapers')
  const [pageNumber, setPageNumber] = useState(1)
  const [itemPerPage, setItemPerPage] = useState(100)
  const [totalPageCount, setTotalPageCount] = useState(100)
  const [productImport, setImportProduct] = useState({})

  const fetchData = async () => {
    // const scrapers = await getScrapers((pageNumber - 1) * itemPerPage, itemPerPage);
    const scrapers = await getProducts((pageNumber - 1) * itemPerPage, itemPerPage)
    if (scrapers.error) {
      console.log(scrapers.error.message)
    } else {
      console.log(scrapers)
      setParts(scrapers)
    }
    setLoading(false)
  }

  useEffect(() => {
    // Loading Data
    setLoading(true)
    fetchData()
  }, [pageNumber])

  const onImportAction = (part) => {
    console.log('Import As Product', part)
    setImportProduct(part)
  }

  const onSave = () => {
    console.log('Save')
  }

  const onClear = () => {
    console.log('Clear')
  }

  const onDelete = () => {
    console.log('Delete')
  }

  const onViewProduct = (product) => {
    console.log('OnViewProduct', product)
  }
  if (productImport && productImport.id) {
    return (
      <div className="view-scrapers">
        <div className="view-scrapers-list">
          <div className="view-scrapers-list-title">
            <Caption caption="Scraper Item" primary bContainImg />
          </div>
          <div className="horizontal-separator" />
          <div className="view-scrapers-edit-view">
            <ProductPreview product={productImport} />

            <HForm className="edit-view-item-form">
              <EditViewSettingItem caption="Manufacturer:">
                <HSingleSelect
                  placeholder="Select One..."
                  name="Manufacturer"
                  secondary
                  values={PARTS.MANUFACTURERS.map((manufacturer) => ({
                    value: manufacturer,
                    label: manufacturer,
                  }))}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Manufacturer Item Number:">
                <HInput
                  className="edit-view-input-style"
                  name="manu_item_number"
                  value={productImport.manufacturerItemNumber || ''}
                  onChange={(ev) => {
                    const newProduct = { ...productImport }
                    newProduct.manufacturerItemNumber = ev.currentTarget.value
                    setImportProduct(newProduct)
                  }}
                />{' '}
              </EditViewSettingItem>

              <EditViewSettingItem caption="Product Description:">
                <HTextInput
                  className="edit-view-text-input-style"
                  name="product_description"
                  value={productImport.productDescription}
                  onChange={(ev) => {
                    const newProduct = { ...productImport }
                    newProduct.productDescription = ev.currentTarget.value
                    setImportProduct(newProduct)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Product Details:">
                <HTextInput
                  className="edit-view-text-input-style"
                  name="product_description"
                  value={productImport.productDetails}
                  onChange={(ev) => {
                    const newProduct = { ...productImport }
                    newProduct.productDetails = ev.currentTarget.value
                    setImportProduct(newProduct)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="UOM:">
                <HInput
                  className="edit-view-input-style"
                  name="product_number"
                  value={productImport.uom}
                  onChange={(ev) => {
                    const newProduct = { ...productImport }
                    newProduct.uom = ev.currentTarget.value
                    setImportProduct(newProduct)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Price($):">
                <HInput
                  className="edit-view-input-style"
                  name="product_number"
                  value={productImport.price}
                  onChange={(ev) => {
                    const newProduct = { ...productImport }
                    newProduct.price = ev.currentTarget.value
                    setImportProduct(newProduct)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Active:">
                <HSingleSelect
                  name="isActive"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Taxable:">
                <HSingleSelect
                  name="isTaxable"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="ComboBox:">
                <HSingleSelect
                  name="isComboBox"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="AllowUOM:">
                <HSingleSelect
                  name="isAllowUOM"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Product URL:">
                <HInput
                  className="edit-view-input-style"
                  name="product_url"
                  value={productImport.productURL}
                  onChange={(ev) => {
                    const newProduct = { ...productImport }
                    newProduct.productURL = ev.currentTarget.value
                    setImportProduct(newProduct)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Product Picture URL:">
                <HInput
                  className="edit-view-input-style"
                  name="product_picture_url"
                  value={productImport.productPictureURL}
                  onChange={(ev) => {
                    const newProduct = { ...productImport }
                    newProduct.productPictureURL = ev.currentTarget.value
                    setImportProduct(newProduct)
                  }}
                />
              </EditViewSettingItem>
              <EditViewSettingItem caption="ProductUPC:">
                <HInput
                  className="edit-view-input-style"
                  name="product_upc"
                  value={productImport.productUPC}
                  onChange={(ev) => {
                    const newProduct = { ...productImport }
                    newProduct.productUPC = ev.currentTarget.value
                    setImportProduct(newProduct)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="ProductSKU: (Warehouse Entry Only)">
                <HInput
                  className="edit-view-input-style"
                  name="product_sku"
                  value={productImport.productSKU}
                  onChange={(ev) => {
                    const newProduct = { ...productImport }
                    newProduct.productSKU = ev.currentTarget.value
                    setImportProduct(newProduct)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Product SO Item type:">
                <HSingleSelect
                  name="product_so_item_type"
                  secondary
                  values={PARTS.PRODUCT_SO_TYPES.map((type) => ({
                    value: type,
                    label: type,
                  }))}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Income Account Name:">
                <HInput
                  className="edit-view-input-style"
                  name="income_account"
                  value={productImport.incomeAccount || ''}
                  onChange={(ev) => {
                    const newProduct = { ...productImport }
                    newProduct.incomeAccount = ev.currentTarget.value
                    setImportProduct(newProduct)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Weight:">
                <HInput
                  className="edit-view-input-style"
                  name="weight"
                  value={productImport.weight || ''}
                  onChange={(ev) => {
                    const newProduct = { ...productImport }
                    newProduct.weight = ev.currentTarget.value
                    setImportProduct(newProduct)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="WeightUOM:">
                <HSingleSelect
                  name="weightUOM"
                  secondary
                  values={PARTS.WEIGHT_UOM.map((uom) => ({
                    value: uom,
                    label: uom,
                  }))}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Width:">
                <HInput
                  className="edit-view-input-style"
                  name="width"
                  value={productImport.width || ''}
                  onChange={(ev) => {
                    const newProduct = { ...productImport }
                    newProduct.width = ev.currentTarget.value
                    setImportProduct(newProduct)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Height:">
                <HInput
                  className="edit-view-input-style"
                  name="height"
                  value={productImport.height || ''}
                  onChange={(ev) => {
                    const newProduct = { ...productImport }
                    newProduct.height = ev.currentTarget.value
                    setImportProduct(newProduct)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Length:">
                <HInput
                  className="edit-view-input-style"
                  name="Length"
                  value={productImport.length || ''}
                  onChange={(ev) => {
                    const newProduct = { ...productImport }
                    newProduct.length = ev.currentTarget.value
                    setImportProduct(newProduct)
                  }}
                />
              </EditViewSettingItem>
              <EditViewSettingItem caption="SizeUOM:">
                <HSingleSelect
                  name="sizeUOM"
                  secondary
                  values={PARTS.SIZE_UOM.map((uom) => ({
                    value: uom,
                    label: uom,
                  }))}
                />
              </EditViewSettingItem>
              <EditViewSettingItem caption="DefaultFlag:">
                <HInput
                  className="edit-view-input-style"
                  name="defaultFlag"
                  value={productImport.defaultFlag || ''}
                  onChange={(ev) => {
                    const newProduct = { ...productImport }
                    newProduct.defaultFlag = ev.currentTarget.value
                    setImportProduct(newProduct)
                  }}
                />
              </EditViewSettingItem>
              <EditViewSettingItem caption="Carton Count:">
                <HInput
                  className="edit-view-input-style"
                  name="carton_count"
                  value={productImport.cartonCount || ''}
                  onChange={(ev) => {
                    const newProduct = { ...productImport }
                    newProduct.cartonCount = ev.currentTarget.value
                    setImportProduct(newProduct)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Carton Type:">
                <HInput
                  className="edit-view-input-style"
                  name="carton_type"
                  value={productImport.cartonType || ''}
                  onChange={(ev) => {
                    const newProduct = { ...productImport }
                    newProduct.cartonType = ev.currentTarget.value
                    setImportProduct(newProduct)
                  }}
                />
              </EditViewSettingItem>
            </HForm>
          </div>
        </div>
        <div className="view-scrapers-filters">
          <EditViewActions
            saveCaption="Import As Product"
            clearCaption="Clear All"
            deleteCaption="Delete"
            saveAction={onSave}
            clearAction={onClear}
            deleteAction={onDelete}
          />
          <SuggestedLinkedProducts viewProduct={onViewProduct} />
          <SuggestedSupplierParts viewProduct={onViewProduct} />
          <AddMedia caption="Images Preview" />
        </div>
      </div>
    )
  }
  return (
    <div className="view-scrapers">
      <div className="view-scrapers-list">
        <div className="view-scrapers-list-title">
          <Caption caption="Competitor Scraper List" />
        </div>
        {loading && (
          <div className="products-list-container">
            <MediumLoadingAnimation />
          </div>
        )}
        {!loading && <ScrapersItemList scrapers={scrapers} importAction={onImportAction} />}
        <div className="horizontal-separator" />
        <div className="view-scrapers-list-footer">
          <div className="view-scrapers-list-footer-left">
            <span className="view-scrapers-list-footer-text">
              {`${decimalFormatter(results)} results for  "`}
            </span>
            <span className="view-scrapers-list-footer-text-bold">{`${pageTitle}"`}</span>
            <HButton
              className="view-scrapers-list-footer-button"
              onClick={() => {
                setLoading(true)
                fetchData()
              }}
            >
              <img src="/assets/img/common/refresh.png" alt="refresh" />
              Refresh
            </HButton>
            <div className="view-scrapers-list-footer-select">
              <span>Items Per Page:</span>
              <HSingleSelect
                values={PAGE_ITEMS.map((item) => ({
                  value: item,
                  label: `${item}`,
                }))}
                defaultValue={{ value: 100, label: '100' }}
                name="itemPerPage"
                onChange={(item) => {
                  setItemPerPage(item.value)
                }}
              />
            </div>
          </div>
          <div className="view-scrapers-list-footer-right">
            <HPagination
              pageNumber={pageNumber}
              totalPageCount={totalPageCount}
              setPageNumber={setPageNumber}
            />
          </div>
        </div>
      </div>
      <div className="view-scrapers-filters">
        <Filters />
        <CustomFilters />
        <SelectedActions />
      </div>
    </div>
  )
}

export default withRouter(ScraperList)
