/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import HCheckBox from 'components/common/Basic/HCheckBox'
import { priceDecimalFormatter } from 'utils/formatter'
import { getProductFeatureImage } from 'utils/product_util'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'

import './index.css'

const PartsListItem = ({ part, viewAction, importAction }) => {
  const [isChecked, setChecked] = useState(false)
  const [dateText, setDateText] = useState('')
  useEffect(() => {
    const distanceDays = Math.floor(
      (new Date() - new Date(part && part.updatedAt)) / 1000 / 3600 / 24
    )
    console.log(distanceDays)
    const dateDistance = formatDistanceToNow(new Date(part && part.updatedAt))
    setDateText(`Updated ${dateDistance} ago`)
  }, [])

  return (
    <div
      className={isChecked ? 'parts-list-item-checked' : 'parts-list-item'}
      onClick={() => {
        setChecked(!isChecked)
      }}
      onDoubleClick={() => {
        viewAction(part)
      }}
    >
      <div className="parts-list-item-info">
        <div className="parts-list-item-info-checkbox">
          <HCheckBox
            name={`${part.id}`}
            onChange={() => {
              setChecked(isChecked)
            }}
            checked={isChecked}
          />
        </div>
        <div className="parts-list-item-info-pic">
          <img
            className="parts-list-item-info-pic"
            src={getProductFeatureImage(part.partPictureUrl) || '/assets/img/parts/featured.svg'}
            alt=""
            onError={(ev) => {
              // eslint-disable-next-line no-param-reassign
              ev.target.onerror = null
              // eslint-disable-next-line no-param-reassign
              ev.target.src = '/assets/img/parts/featured.svg'
            }}
          />
        </div>
        <div className="parts-list-item-info-subinfo">
          <div className="parts-list-item-info-title">{part && part.description}</div>
          <div className="parts-list-item-info-description">{part && part.manufacturerId}</div>
          <div className="parts-list-item-info-price-type">
            {part.UOMs && part.UOMs.length > 0 && (
              <div className="parts-list-item-info-price">
                {priceDecimalFormatter(part.UOMs[0].cost)}-{part.UOMs[0].uom}
              </div>
            )}
            <div className="parts-list-item-info-type">{part.vendor}</div>
          </div>
        </div>
      </div>
      <div className="parts-list-item-action">
        <div className="parts-list-item-date">{}</div>

        <a
          role="button"
          onClick={() => {
            importAction(part)
          }}
          tabIndex={0}
        >
          IMPORT AS PRODUCT
        </a>
      </div>
    </div>
  )
}
PartsListItem.propTypes = {
  part: PropTypes.shape(),
  viewAction: PropTypes.func,
  importAction: PropTypes.func,
}

PartsListItem.defaultProps = {
  part: {},
  viewAction: () => {},
  importAction: () => {},
}
export default PartsListItem
