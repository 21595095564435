/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import HCheckBox from 'components/common/Basic/HCheckBox'
import { priceDecimalFormatter, getShortString } from 'utils/formatter'
import { getProductFeatureImage } from 'utils/product_util'
import HButton from 'components/common/Basic/HButton'

import './index.css'

const ApprovalsListItem = ({
  item,
  viewEditAction,
  approveAction,
  declineAction,
  checked,
  setChecked,
}) => {
  const [user, setUser] = useState({})
  const [createdAt, setCreatedAt] = useState('')
  const [showState, setShowState] = useState({})

  useEffect(() => {
    setUser(item.user)
    setCreatedAt(item.createdAt)

    if (item.action === 'add' || item.action === 'added') {
      if (item.objectType === 'Part') {
        setShowState({
          pictureUrl: (item.newState && item.newState.partPictureUrl) || ' ',
          number: (item.newState && item.newState.manufacturerId) || ' ',
          description: (item.newState && item.newState.description) || ' ',
          price: (item.newState && item.newState.cost) || 0,
          income: (item.newState && item.newState.vendor) || ' ',
        })
      } else if (item.objectType === 'BOMProduct') {
        setShowState({
          pictureUrl: (item.newState && item.newState.product.productPictureUrl) || ' ',
          number: (item.newState && getShortString(item.newState.product.productDetails)) || ' ',
          description:
            (item.newState && getShortString(item.newState.product.productDescription)) || ' ',
          price: (item.newState && item.newState.product.price) || 0,
          income: (item.newState && getShortString(item.newState.product.incomeAccount)) || ' ',
        })
      } else {
        setShowState({
          pictureUrl: (item.newState && item.newState.productPictureUrl) || ' ',
          number: (item.newState && item.newState.productNumber) || ' ',
          description: (item.newState && item.newState.productDescription) || ' ',
          price: (item.newState && item.newState.price) || 0,
          income: (item.newState && item.newState.incomeAccount) || ' ',
        })
      }
    } else if (item.action === 'edit') {
      if (item.objectType === 'Part') {
        setShowState({
          pictureUrl: (item.newState && item.newState.partPictureUrl) || ' ',
          number: (item.newState && item.newState.manufacturerId) || ' ',
          description: (item.newState && item.newState.description) || ' ',
          price: (item.newState && item.newState.cost) || 0,
          income: (item.newState && item.newState.vendor) || ' ',
        })
      } else {
        setShowState({
          pictureUrl: (item.newState && item.newState.productPictureUrl) || ' ',
          number: (item.newState && item.newState.productNumber) || ' ',
          description: (item.newState && item.newState.productDescription) || ' ',
          price: (item.newState && item.newState.price) || 0,
          income: (item.newState && item.newState.incomeAccount) || ' ',
        })
      }
    } else if (item.action === 'delete') {
      if (item.objectType === 'Part') {
        setShowState({
          pictureUrl: (item.newState && item.newState.partPictureUrl) || ' ',
          number: (item.newState && item.newState.manufacturerId) || ' ',
          description: (item.newState && item.newState.description) || ' ',
          price: (item.newState && item.newState.cost) || 0,
          income: (item.newState && item.newState.vendor) || ' ',
        })
      } else {
        setShowState({
          pictureUrl: (item.oldState && item.oldState.productPictureUrl) || ' ',
          number: (item.oldState && item.oldState.productNumber) || ' ',
          description: (item.oldState && item.oldState.productDescription) || ' ',
          price: (item.oldState && item.oldState.price) || 0,
          income: (item.oldState && item.oldState.incomeAccount) || ' ',
        })
      }
    }
  }, [item])
  return (
    <div
      className={checked ? 'approvals-list-item-checked' : 'approvals-list-item'}
      onClick={() => {
        setChecked(!checked)
      }}
      onDoubleClick={() => {
        viewEditAction(item)
      }}
    >
      <div className="approvals-list-item-info">
        <div className="approvals-list-item-info-checkbox">
          <HCheckBox
            name={`${item.id}`}
            onChange={() => {
              setChecked(!checked)
            }}
            checked={checked}
          />
        </div>
        <div className="approvals-list-item-info-pic">
          <img
            src={
              getProductFeatureImage((showState && showState.pictureUrl) || '') ||
              '/assets/img/approval/featured.svg'
            }
            alt="featured"
            onError={(ev) => {
              // eslint-disable-next-line no-param-reassign
              ev.target.onerror = null
              // eslint-disable-next-line no-param-reassign
              ev.target.src = '/assets/img/approval/featured.svg'
            }}
          />
        </div>
        <div className="approvals-list-item-info-pic-tag">
          <span className={`h-icon -${item.action}-mark -sz-30`} />
        </div>
        <div className="approvals-list-item-info-subinfo">
          <div className="approvals-list-item-info-title">
            {(showState && showState.number) || 'No Product Number'}
          </div>
          <div className="approvals-list-item-info-description">
            {(showState && showState.description) || 'No Product Description'}
          </div>
          <div className="approvals-list-item-info-price-type">
            <div className="approvals-list-item-info-price">
              {item.objectType !== 'BOMProduct' &&
                priceDecimalFormatter(showState && showState.price)}
            </div>
            <div className="approvals-list-item-info-type">
              {(showState && showState.income) || 'No Income'}
            </div>
          </div>
        </div>
        <div className="approvals-list-item-info-user">
          <div className="approvals-list-item-info-user-pic">
            <img
              src={(user && user.userImage) || '/assets/img/users/user-blue.svg'}
              alt="missing"
              onError={(ev) => {
                // eslint-disable-next-line no-param-reassign
                ev.target.onerror = null
                // eslint-disable-next-line no-param-reassign
                ev.target.src = '/assets/img/users/user-blue.svg'
              }}
            />
          </div>
          <div className="approvals-list-item-info-user-name">
            {user && user.username ? (
              <Link to={`/users/view/${user.id}`}>
                <span> {user && user.username}</span>
              </Link>
            ) : (
              <span> Invalid User</span>
            )}
          </div>
        </div>
        <div className="approvals-list-item-info-time">
          <span>{createdAt || 'Invalid Date'}</span>
        </div>
      </div>
      <div className="approvals-list-item-action">
        <HButton
          className="dashboard-item-action-decline"
          onClick={(ev) => {
            ev.stopPropagation()
            declineAction(item.id)
          }}
        >
          Deny
        </HButton>

        <HButton
          className="dashboard-item-action-approve"
          onClick={(ev) => {
            ev.stopPropagation()
            approveAction(item.id)
          }}
        >
          Approve
        </HButton>
      </div>
    </div>
  )
}
ApprovalsListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    oldState: PropTypes.shape({}),
    newState: PropTypes.shape({}),
    user: PropTypes.shape({}),
    createdAt: PropTypes.string,
    action: PropTypes.string,
    objectType: PropTypes.string,
  }),
  viewEditAction: PropTypes.func,
  approveAction: PropTypes.func,
  declineAction: PropTypes.func,
  checked: PropTypes.bool,
  setChecked: PropTypes.func,
}

ApprovalsListItem.defaultProps = {
  item: {
    id: 1,
    oldState: {},
    newState: {},
    user: {},
    createdAt: '',
    action: 'add',
    objectType: 'Product',
  },
  viewEditAction: () => {},
  approveAction: () => {},
  declineAction: () => {},
  checked: false,
  setChecked: () => {},
}
export default ApprovalsListItem
