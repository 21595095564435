import React, { useEffect, useState } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { store } from 'react-notifications-component'
import { PAGE_ITEMS } from 'consts/common'

import { MediumLoadingAnimation } from 'components/LoadingAnimation'
import HSingleSelect from 'components/common/Basic/HSingleSelect'
import HButton from 'components/common/Basic/HButton'
import HPagination from 'components/common/Pagination'

import Filters from 'components/common/Filter/Filters'
import CustomFilters from 'components/common/Filter/CustomFilters'
import SelectedActions from 'components/common/Filter/SelectedActions'
import CustomImportBox from 'components/common/Filter/CustomImport'

import Caption from 'components/Products/Caption'
import ProductsList from 'components/Products/ProductsList'

import { getProducts, uploadProductCsv } from 'api/productApi'
import CsvImportModal from 'components/common/Modal/CsvImportModal'

import { decimalFormatter } from 'utils/formatter'

import {
  setNormalFilter,
  setCustomFilter,
  removeNormalFilter,
  removeCustomFilter,
} from 'redux/actions/filterAction'

import './index.css'

const ViewProducts = ({
  filter,
  customFilter,
  setNormalFilter,
  setCustomFilter,
  removeNormalFilter,
  removeCustomFilter,
}) => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [products, setProducts] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])

  const [results, setResults] = useState(0)
  const [pageTitle, setPageTitle] = useState('All Products')
  const [pageNumber, setPageNumber] = useState(1)
  const [itemPerPage, setItemPerPage] = useState(100)
  const [totalPageCount, setTotalPageCount] = useState(100)
  const [isSelectAll, setSelectAll] = useState(false)
  const [isSelectShown, setSelectShown] = useState(false)

  const [showImportModal, setImportModal] = useState(false)

  const fetchData = async () => {
    try {
      const normal = (filter && filter.product) || {}
      const custom = (customFilter && customFilter.product) || []

      const productFilters = {
        normal,
        custom,
      }

      const products = await getProducts(
        (pageNumber - 1) * itemPerPage,
        itemPerPage,
        productFilters
      )
      if (products && products.error) {
        store.addNotification({
          message: 'Sorry, we are not able to process your request now. Please try again later.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 4000,
          },
        })
      } else {
        setProducts(products.products)
        const selectedArray = []
        products.products.map(() => {
          selectedArray.push(isSelectAll || isSelectShown)
        })
        setSelectedProducts(selectedArray)
        setResults(products.totalCount)
        setTotalPageCount(Math.ceil(products.totalCount / itemPerPage))
      }
    } catch (error) {
      store.addNotification({
        message: 'Sorry, we are not able to process your request now. Please try again later.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      })
    }
    setLoading(false)
  }

  const batchSave = (record, value) => {}

  useEffect(() => {
    // Loading Data
    setLoading(true)
    fetchData()

    if (filter && filter.product && filter.product.category) {
      setPageTitle('Filtered Products')
    }
    if (customFilter && customFilter.product && customFilter.product.length > 0) {
      setPageTitle('Filtered Products')
    }
  }, [pageNumber, itemPerPage, filter, customFilter])

  useEffect(() => {
    const productStatus = []
    selectedProducts.map(() => {
      productStatus.push(isSelectAll || isSelectShown)
    })
    setSelectedProducts(productStatus)
  }, [isSelectAll, isSelectShown])

  const onViewEditProduct = (product) => {
    if (product.isBom) {
      history.push(`/boms/view/${product.id}`)
    } else {
      history.push(`/products/view/${product.id}`)
    }
  }

  const onFilterApply = (applyFilter) => {
    if (applyFilter.category === 'Select One' || applyFilter.sortBy === 'Select One') {
      store.addNotification({
        message: "Sorry, you didn't select category or sort options.",
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      })
    } else {
      const updateFilter = { ...filter }
      updateFilter.product = applyFilter
      setNormalFilter(updateFilter)
    }
  }

  const onCustomFilterApply = (applyFilter) => {
    if (applyFilter && applyFilter.length > 0) {
      const updateFilter = { ...customFilter }
      updateFilter.product = applyFilter
      setCustomFilter(updateFilter)
    } else {
      store.addNotification({
        message: "Sorry, you didn't select category or value options...",
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      })
    }
  }

  const onFilterCancel = () => {
    removeNormalFilter()
  }

  const onCustomFilterCancel = () => {
    // removeCustomFilter()
    const updateFilter = { ...customFilter }
    updateFilter.product = []
    setCustomFilter(updateFilter)
  }

  return (
    <div className="view-products">
      <div className="view-products-list">
        <div className="view-products-list-title">
          <Caption
            caption={pageTitle}
            addAction={() => {
              history.push('/products/add')
            }}
          />
        </div>
        {loading && (
          <div className="products-list-container">
            <MediumLoadingAnimation />
          </div>
        )}
        {!loading && (
          <ProductsList
            products={products}
            viewEditAction={onViewEditProduct}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
          />
        )}
        <div className="horizontal-separator" />
        <div className="view-products-list-footer">
          <div className="view-products-list-footer-left">
            <span className="view-products-list-footer-text">
              {`${decimalFormatter(results)} results for  "`}
            </span>
            <span className="view-products-list-footer-text-bold">{`${pageTitle}"`}</span>
            <HButton
              className="view-products-list-footer-button"
              onClick={() => {
                setLoading(true)
                fetchData()
              }}
            >
              <img src="/assets/img/common/refresh.png" alt="refresh" />
              Refresh
            </HButton>
            <div className="view-products-list-footer-select">
              <span>Items Per Page:</span>
              <HSingleSelect
                values={PAGE_ITEMS.map((item) => ({
                  value: item,
                  label: `${item}`,
                }))}
                defaultValue={{ value: 100, label: '100' }}
                name="itemPerPage"
                onChange={(item) => {
                  setItemPerPage(item.value)
                }}
                isSearchable={false}
              />
            </div>
          </div>
          <div className="view-products-list-footer-right">
            <HPagination
              pageNumber={pageNumber}
              totalPageCount={totalPageCount}
              setPageNumber={setPageNumber}
            />
          </div>
        </div>
      </div>
      <div className="view-products-filters">
        <Filters
          filter={filter && filter.product}
          onApply={onFilterApply}
          onCancel={onFilterCancel}
        />
        <CustomFilters
          filter={customFilter && customFilter.product}
          onApply={onCustomFilterApply}
          onCancel={onCustomFilterCancel}
          isProduct
        />
        <SelectedActions
          totalItems={results}
          pageItems={products.length}
          selectTotalItems={(mode) => {
            setSelectAll(mode)
          }}
          selectPageShownItems={(mode) => {
            setSelectShown(mode)
          }}
          onSave={batchSave}
        />
        <CustomImportBox
          caption={'Import Products'}
          onUploadCallback={() => {
            setImportModal(true)
          }}
        />{' '}
      </div>
      <CsvImportModal
        show={showImportModal}
        isProduct
        onHide={() => {
          setImportModal(false)
        }}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  filter: state.filter,
  customFilter: state.customFilter,
})

const mapDispatchToProps = {
  setNormalFilter,
  setCustomFilter,
  removeNormalFilter,
  removeCustomFilter,
}

ViewProducts.propTypes = {
  setNormalFilter: PropTypes.func.isRequired,
  setCustomFilter: PropTypes.func.isRequired,
  removeNormalFilter: PropTypes.func.isRequired,
  removeCustomFilter: PropTypes.func.isRequired,
  filter: PropTypes.shape({}).isRequired,
  customFilter: PropTypes.shape({}).isRequired,
}

const connectedViewProducts = connect(mapStateToProps, mapDispatchToProps)(ViewProducts)

export default withRouter(connectedViewProducts)
