import React, { useEffect, useState } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { MediumLoadingAnimation } from 'components/LoadingAnimation'
import Caption from 'components/ApproveDecline/Caption'
import Filters from 'components/common/Filter/Filters'
import HSingleSelect from 'components/common/Basic/HSingleSelect'
import { PAGE_ITEMS } from 'consts/common'
import CustomFilters from 'components/common/Filter/CustomFilters'
import SelectedActions from 'components/common/Filter/SelectedActions'
import ApprovalsList from 'components/ApproveDecline/ApprovalsList'
import { decimalFormatter } from 'utils/formatter'
import HButton from 'components/common/Basic/HButton'
import { store } from 'react-notifications-component'
import { getApprovalList, approvePending, declinePending } from 'api/approvalApi'
import HPagination from 'components/common/Pagination'
import {
  setNormalFilter,
  setCustomFilter,
  removeNormalFilter,
  removeCustomFilter,
} from 'redux/actions/filterAction'

import './index.css'

const ApproveDeclinePage = ({
  user,
  filter,
  customFilter,
  setNormalFilter,
  setCustomFilter,
  removeNormalFilter,
  removeCustomFilter,
}) => {
  const history = useHistory()
  const isAdminUser = user && user.confirmed && user.isAdmin
  const [loading, setLoading] = useState(true)
  const [approvals, setApprovals] = useState([])
  const [selectedApprovals, setSelectedApprovals] = useState([])

  const [results, setResults] = useState(0)
  const [pageTitle, setPageTitle] = useState('Approvals Pending')
  const [pageNumber, setPageNumber] = useState(1)
  const [itemPerPage, setItemPerPage] = useState(100)
  const [totalPageCount, setTotalPageCount] = useState(1)
  const [isSelectAll, setSelectAll] = useState(false)
  const [isSelectShown, setSelectShown] = useState(false)

  const fetchData = async () => {
    setLoading(true)
    try {
      const normal = filter || {}
      const custom = customFilter || []

      const filtersParam = {
        normal,
        custom,
      }

      const response = await getApprovalList(
        (pageNumber - 1) * itemPerPage,
        itemPerPage,
        filtersParam
      )

      if (response && response.error) {
        console.log(response.error.message)
        store.addNotification({
          message: `Sorry, we are not able to process your request now. Please try again later. ${response.error.message}`,
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
          },
        })
      } else if (response.approvals && response.approvals.length > 0) {
        setApprovals(response.approvals)
        const selectedArray = []
        response.approvals.map(() => {
          selectedArray.push(isSelectAll || isSelectShown)
        })
        setSelectedApprovals(selectedArray)
        setResults(response.totalCount)
        setTotalPageCount(Math.ceil(response.totalCount / itemPerPage))
      } else {
        console.log('No Approvals')
        setApprovals([])
        setResults(0)
        setTotalPageCount(1)
      }
    } catch (error) {
      console.log('Approvals error', error)
      store.addNotification({
        message: 'Sorry, we are not able to process your request now. Please try again later.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
        },
      })
    }
    setLoading(false)
  }

  const batchSave = (record, value) => {
    console.log('batchSave', record, value)
  }

  useEffect(() => {
    if (!isAdminUser) {
      history.goBack()
    }
  }, [])

  useEffect(() => {
    // Loading Data
    setLoading(true)
    fetchData()
  }, [pageNumber, itemPerPage, filter, customFilter])

  useEffect(() => {
    const productStatus = []
    selectedApprovals.map(() => {
      productStatus.push(isSelectAll || isSelectShown)
    })
    setSelectedApprovals(productStatus)
  }, [isSelectAll, isSelectShown])

  const onViewEditApproval = (approval) => {
    history.push(`/approvals/view/${approval.id}`)
  }

  const onApprovePending = (pending) => {
    setLoading(true)
    const approveAction = async () => {
      try {
        const response = await approvePending(pending)
        if (response && response.error) {
          store.addNotification({
            message: 'Sorry, we are not able to process your request now. Please try again later.',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 3000,
            },
          })
        } else if (response && response.message) {
          store.addNotification({
            message: 'Successfully approved. Thank you!',
            type: 'success',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 3000,
            },
          })
          fetchData()
        }
      } catch (err) {
        console.log('Approval Pending: ', err)
        store.addNotification({
          message: 'Sorry, we are not able to process your request now. Please try again later.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
          },
        })
      }
      setLoading(false)
    }
    approveAction()
  }

  const onDeclinePending = (pending) => {
    setLoading(true)

    const declineAction = async () => {
      try {
        const response = await declinePending(pending)
        if (response && response.error) {
          console.log(response.error)
          store.addNotification({
            message: 'Sorry, we are not able to process your request now. Please try again later.',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 3000,
            },
          })
        } else if (response && response.message) {
          store.addNotification({
            message: 'Successfully declined. Thank you!',
            type: 'success',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 3000,
            },
          })
          fetchData()
        }
      } catch (err) {
        console.log('Approval Pending: ', err)
        store.addNotification({
          message: 'Sorry, we are not able to process your request now. Please try again later.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
          },
        })
      }
      setLoading(false)
    }
    declineAction()
  }

  const onFilterApply = (applyFilter) => {
    if (applyFilter.category === 'Select One' || applyFilter.sortBy === 'Select One') {
      store.addNotification({
        message: 'Sorry, please select Category and SortBy option.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
        },
      })
    } else {
      setNormalFilter(applyFilter)
    }
  }

  const onCustomFilterApply = (applyFilter) => {
    if (applyFilter && applyFilter.length > 0) {
      setCustomFilter(applyFilter)
    } else {
      store.addNotification({
        message: 'Sorry, please select Category and Value option.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
        },
      })
    }
  }

  const onFilterCancel = () => {
    removeNormalFilter()
  }

  const onCustomFilterCancel = () => {
    removeCustomFilter()
  }

  return (
    <div className="view-approvals">
      <div className="view-approvals-list">
        <div className="view-approvals-list-title">
          <Caption caption={pageTitle} tertiary />
        </div>
        {loading && (
          <div className="approvals-list-container">
            <MediumLoadingAnimation />
          </div>
        )}
        {!loading && (
          <ApprovalsList
            approvals={approvals}
            viewEditAction={onViewEditApproval}
            approveAction={onApprovePending}
            declineAction={onDeclinePending}
            selectedItems={selectedApprovals}
            setSelectedItems={setSelectedApprovals}
          />
        )}
        <div className="horizontal-separator" />
        <div className="view-approvals-list-footer">
          <div className="view-approvals-list-footer-left">
            <span className="view-approvals-list-footer-text">{decimalFormatter(results)}</span>
            &nbsp;
            <span className="view-approvals-list-footer-text-bold">{` ${pageTitle}`}</span>
            <HButton
              className="view-approvals-list-footer-button"
              onClick={() => {
                setLoading(true)
                fetchData()
              }}
            >
              <img src="/assets/img/common/refresh.png" alt="refresh" />
              Refresh
            </HButton>
            <div className="view-approvals-list-footer-select">
              <span>Items Per Page:</span>
              <HSingleSelect
                values={PAGE_ITEMS.map((item) => ({
                  value: item,
                  label: `${item}`,
                }))}
                defaultValue={{ value: 100, label: '100' }}
                name="itemPerPage"
                onChange={(item) => {
                  setItemPerPage(item.value)
                }}
                isSearchable={false}
              />
            </div>
            {totalPageCount > 1 && (
              <HPagination
                pageNumber={pageNumber}
                totalPageCount={totalPageCount}
                setPageNumber={setPageNumber}
              />
            )}
          </div>
          <div className="view-approvals-list-footer-right">
            <div className="view-approvals-list-action-container">
              <HButton
                className="view-approvals-action-decline-all"
                onClick={(ev) => {
                  ev.stopPropagation()
                  if (approvals && approvals.length) {
                    approvals.forEach((element) => {
                      onDeclinePending(element && element.id)
                    })
                  }
                }}
              >
                Deny All
              </HButton>

              <HButton
                className="view-approvals-action-approve-all"
                onClick={(ev) => {
                  ev.stopPropagation()
                  if (approvals && approvals.length) {
                    approvals.forEach((element) => {
                      onApprovePending(element && element.id)
                    })
                  }
                }}
              >
                Approve All
              </HButton>
            </div>
          </div>
        </div>
      </div>
      <div className="view-approvals-filters">
        <Filters filter={filter} onApply={onFilterApply} onCancel={onFilterCancel} />
        <CustomFilters
          filter={customFilter}
          onApply={onCustomFilterApply}
          onCancel={onCustomFilterCancel}
        />
        <SelectedActions
          totalItems={results}
          pageItems={approvals.length}
          selectTotalItems={(mode) => {
            setSelectAll(mode)
          }}
          selectPageShownItems={(mode) => {
            setSelectShown(mode)
          }}
          onSave={batchSave}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.user && state.user.user,
  filter: state.filter,
  customFilter: state.customFilter,
})

const mapDispatchToProps = {
  setNormalFilter,
  setCustomFilter,
  removeNormalFilter,
  removeCustomFilter,
}

ApproveDeclinePage.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    isAdmin: PropTypes.bool,
    confirmed: PropTypes.bool,
  }).isRequired,
  setNormalFilter: PropTypes.func.isRequired,
  setCustomFilter: PropTypes.func.isRequired,
  removeNormalFilter: PropTypes.func.isRequired,
  removeCustomFilter: PropTypes.func.isRequired,
  filter: PropTypes.shape({}).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  customFilter: PropTypes.any.isRequired,
}

const connectedViewProducts = connect(mapStateToProps, mapDispatchToProps)(ApproveDeclinePage)

export default withRouter(connectedViewProducts)
