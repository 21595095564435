/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import { Field, Form, Formik } from 'formik'
import * as yup from 'yup'

import { closeSignInSignUpModal, showSignUpModal } from 'redux/actions/modalActions'
import { signinRequest } from 'redux/actions/authActions'
import { authenticate } from 'api/authApi'
import { REQUIRED_VALIDATION, INVALID_EMAIL } from 'consts/form'
import FormError from 'components/common/Basic/FormError'
import LoadingAnimation from 'components/LoadingAnimation'
import './index.css'

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email(INVALID_EMAIL)
    .required(REQUIRED_VALIDATION),
  password: yup.string().required(REQUIRED_VALIDATION),
})

const initialValues = {
  email: '',
  password: '',
}

const SignInModal = ({
  signInModalShown,
  showSignUpModal,
  closeSignInSignUpModal,
  signinRequest,
  modalHeader,
  errorMessage,
}) => {
  const [loading, setLoading] = useState(false)
  const [errorMessages, setErrorMessages] = useState(errorMessage ? [errorMessage] : null)
  console.log('errorMessage', errorMessage)
  async function onSubmit(formData) {
    setLoading(true)
    try {
      const auth = await authenticate(formData)
      console.log(auth)

      if (auth && auth.user && auth.user.confirmed) {
        signinRequest(auth)
        closeSignInSignUpModal()
      } else {
        setErrorMessages(['Please confirm your email address.'])
      }
    } catch (e) {
      console.log('error', e)
      setErrorMessages(['Invalid Information. Please try again.'])
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal show={signInModalShown} onHide={() => {}}>
      {loading && <LoadingAnimation />}
      <Modal.Header className="h-modal-header">
        <Link to="/">
          <img src="/assets/img/common/main-logo.png" alt="logo" />
          {/* <span>{modalHeader}</span> */}
        </Link>
      </Modal.Header>

      <Modal.Body className="modal--sign-in pb-5">
        {errorMessages && (
          <div className="alert-error-message-container">
            <div className="alert-invalid-input">
              <svg className="icon icon-warning">
                <use xlinkHref="#spriteIcon-warning" />
              </svg>
              <span>{errorMessages.join(', ')}</span>
            </div>
          </div>
        )}
        <Formik
          {...{ initialValues, validationSchema }}
          validateOnChange
          validateOnBlur
          onSubmit={(form) => {
            onSubmit(form)
          }}
        >
          {({ values }) => (
            <Form>
              <h3 className="h-modal-header-text">Sign In</h3>
              <div className="labeled-group floating">
                <Field type="email" className="form-control" name="email" />
                <FormError name="email" />
                <label className="label-focused">Email</label>
              </div>
              <div className="input-with-icons mb-2 ii-right">
                <div className="labeled-group floating">
                  <Field type="password" className="form-control" name="password" />
                  <FormError name="password" />
                  <label className="label-focused">Password</label>
                </div>
                <div className="input-append">
                  <a href="/forgot">Forgot ?</a>
                </div>
              </div>
              <button type="submit" className="btn btn-primary btn-block mt-2">
                Sign In
              </button>
              <div className="form-footer">
                <span>
                  Don't have an account? &nbsp; Contact site&nbsp;
                  <a href="mailto:alex@fullbarsmedia.com">administrator.</a>
                </span>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  signInModalShown: state.modal.signInModalShown,
  modalHeader: state.modal.modalHeader,
})

const mapDispatchToProps = {
  closeSignInSignUpModal,
  showSignUpModal,
  signinRequest,
}

SignInModal.propTypes = {
  signInModalShown: PropTypes.bool.isRequired,
  closeSignInSignUpModal: PropTypes.func.isRequired,
  showSignUpModal: PropTypes.func.isRequired,
  signinRequest: PropTypes.func.isRequired,
  modalHeader: PropTypes.string,
}

SignInModal.defaultProps = {
  modalHeader: 'Please Sign In to Hercules.',
}

const connectedSignInModal = connect(mapStateToProps, mapDispatchToProps)(SignInModal)
export default connectedSignInModal
