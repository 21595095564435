import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { getShortString } from 'utils/formatter'
import HButton from '../Basic/HButton'
import Collapsible from '../Basic/Collapsible'
import { syncProduct, syncBom, syncPart } from 'api/productApi.js'
import { MediumLoadingAnimation } from 'components/LoadingAnimation'

import './index.css'

const SalesforceSubItem = ({ info, header, item, link }) => {
  const history = useHistory()
  return (
    <tr>
      <td width="40%" className="salesforce-subitem-header">
        {header}
      </td>
      <td width="60%" className="salesforce-subitem-item">
        {getShortString(info[`${item}`], 20)}
        {link && (
          <button
            className="salesforce-subitem-link-button h-transparent-button"
            onClick={() => {
              if (info[`${item}`]) {
                window.open(info[`${item}`])
              }
            }}
          >
            <img src="/assets/img/products/link.svg" alt="link" />
          </button>
        )}
      </td>
    </tr>
  )
}

const ProductSalesforceItems = ({ salesforceInfo }) => {
  const [loading, setLoading] = useState(false)
  const [syncMessage, setSyncMessage] = useState('')
  const [expanded, setExpanded] = useState(false)

  const onSync = async () => {
    setLoading(true)
    console.log('onSync')
    try {
      const data = await syncProduct()
      console.log('data', data)
      if (data && data.message) {
        setSyncMessage(data.message)
      }
    } catch (ev) {
      console.log('onSync Error', ev)
    }
    setLoading(false)
  }

  return (
    <div className="filters-container">
      <div className="filters-container-caption">
        <span>Salesforce Settings</span>
        <HButton
          className="h-mini-button h-green-button"
          name="sync"
          onClick={() => {
            onSync()
          }}
          disabled={!!syncMessage}
        >
          Sync Now
        </HButton>
        <button
          className="filters-container-expand"
          onClick={() => {
            setExpanded(!expanded)
          }}
          type="button"
        >
          {expanded ? (
            <img width="30px" src="/assets/img/common/dropdown-close.svg" alt="close" />
          ) : (
            <img width="30px" src="/assets/img/common/dropdown-down.svg" alt="expand" />
          )}
        </button>
      </div>
      <Collapsible expanded={expanded}>
        <div className="filters-container-seperator" />
        <div className="filters-container-body-competitor">
          {loading && (
            <div className="suggested-linked-products-empty-list">
              <MediumLoadingAnimation />
            </div>
          )}
          {!loading && salesforceInfo && !syncMessage && (
            <table className="salesforce-items-table">
              <tbody>
                <SalesforceSubItem
                  info={salesforceInfo}
                  header="MasterItemID"
                  item="masterItemId"
                />
                <SalesforceSubItem
                  info={salesforceInfo}
                  header="InventoryAccount"
                  item="inventoryAccount"
                />
                <SalesforceSubItem info={salesforceInfo} header="PriceID" item="priceId" />
                <SalesforceSubItem info={salesforceInfo} header="PriceListID" item="priceListId" />
                <SalesforceSubItem
                  info={salesforceInfo}
                  header="ItemMasterUrl"
                  item="salesforceItemMasterUrl"
                  link
                />
                <SalesforceSubItem info={salesforceInfo} header="Kit" item="salesforceKit" />
                <SalesforceSubItem info={salesforceInfo} header="Lock" item="salesforceLock" />
                <SalesforceSubItem
                  info={salesforceInfo}
                  header="PriceListUrl"
                  item="salesforcePriceListUrl"
                  link
                />
                <SalesforceSubItem
                  info={salesforceInfo}
                  header="PriceUrl"
                  item="salesforcePriceUrl"
                />
                <SalesforceSubItem
                  info={salesforceInfo}
                  header="ProductFamily"
                  item="salesforceProductFamily"
                />
                <SalesforceSubItem
                  info={salesforceInfo}
                  header="ProductGroupID"
                  item="salesforceProductGroupId"
                />
                <SalesforceSubItem
                  info={salesforceInfo}
                  header="ProductGroupURL"
                  item="salesforceProductGroupUrl"
                  link
                />
                <SalesforceSubItem
                  info={salesforceInfo}
                  header="ProductID"
                  item="salesforceProductId"
                />
                <SalesforceSubItem
                  info={salesforceInfo}
                  header="ProductURL"
                  item="salesforceProductUrl"
                  link
                />
              </tbody>
            </table>
          )}
          {!loading && !salesforceInfo && (
            <div className="suggested-linked-products-empty-list">
              {!loading && (
                <p style={{ textAlign: 'center' }}>
                  {syncMessage
                    ? `${syncMessage} ...`
                    : 'No Salesforce information available for this object.'}
                </p>
              )}
            </div>
          )}
          {!loading && salesforceInfo && syncMessage && (
            <div className="suggested-linked-products-empty-list">
              <p style={{ textAlign: 'center' }}>{syncMessage}...</p>
            </div>
          )}
        </div>
      </Collapsible>
    </div>
  )
}
ProductSalesforceItems.propTypes = {
  salesforceInfo: PropTypes.shape({}),
}

ProductSalesforceItems.defaultProps = {
  salesforceInfo: null,
}

const BomSalesforceItems = ({ salesforceInfo }) => {
  const [loading, setLoading] = useState(false)
  const [syncMessage, setSyncMessage] = useState('')
  const [expanded, setExpanded] = useState(false)

  const onSync = async () => {
    console.log('onSync')
    setLoading(true)
    try {
      const data = await syncBom()
      console.log('data', data)
      if (data && data.message) {
        setSyncMessage(data.message)
      }
    } catch (ev) {
      console.log('onSync Error', ev)
    }
    setLoading(false)
  }

  return (
    <div className="filters-container">
      <div className="filters-container-caption">
        <span>Salesforce Settings</span>
        <HButton
          className="h-mini-button h-green-button"
          name="sync"
          onClick={() => {
            onSync()
          }}
          disabled={!!syncMessage}
        >
          Sync Now
        </HButton>
        <button
          className="filters-container-expand"
          onClick={() => {
            setExpanded(!expanded)
          }}
          type="button"
        >
          {expanded ? (
            <img width="30px" src="/assets/img/common/dropdown-close.svg" alt="close" />
          ) : (
            <img width="30px" src="/assets/img/common/dropdown-down.svg" alt="expand" />
          )}
        </button>
      </div>
      <Collapsible expanded={expanded}>
        <div className="filters-container-seperator" />
        <div className="filters-container-body-competitor">
          {loading && (
            <div className="suggested-linked-products-empty-list">
              <MediumLoadingAnimation />
            </div>
          )}
          {!loading && salesforceInfo && !syncMessage && (
            <table className="salesforce-items-table">
              <tbody>
                <SalesforceSubItem
                  info={salesforceInfo}
                  header="MasterItemID"
                  item="masterItemId"
                />
                <SalesforceSubItem
                  info={salesforceInfo}
                  header="InventoryAccount"
                  item="inventoryAccount"
                />
                <SalesforceSubItem info={salesforceInfo} header="PriceID" item="priceId" />
                <SalesforceSubItem info={salesforceInfo} header="PriceListID" item="priceListId" />
                <SalesforceSubItem
                  info={salesforceInfo}
                  header="ItemMasterUrl"
                  item="salesforceItemMasterUrl"
                  link
                />
                <SalesforceSubItem info={salesforceInfo} header="Kit" item="salesforceKit" />
                <SalesforceSubItem info={salesforceInfo} header="Lock" item="salesforceLock" />
                <SalesforceSubItem
                  info={salesforceInfo}
                  header="PriceListUrl"
                  item="salesforcePriceListUrl"
                  link
                />
                <SalesforceSubItem
                  info={salesforceInfo}
                  header="PriceUrl"
                  item="salesforcePriceUrl"
                />
                <SalesforceSubItem
                  info={salesforceInfo}
                  header="ProductFamily"
                  item="salesforceProductFamily"
                />
                <SalesforceSubItem
                  info={salesforceInfo}
                  header="ProductGroupID"
                  item="salesforceProductGroupId"
                />
                <SalesforceSubItem
                  info={salesforceInfo}
                  header="ProductGroupURL"
                  item="salesforceProductGroupUrl"
                  link
                />
                <SalesforceSubItem
                  info={salesforceInfo}
                  header="ProductID"
                  item="salesforceProductId"
                />
                <SalesforceSubItem
                  info={salesforceInfo}
                  header="ProductURL"
                  item="salesforceProductUrl"
                  link
                />
                <SalesforceSubItem
                  info={salesforceInfo}
                  header="*BomSalesforceParentId"
                  item="bomSalesforceParentId"
                />
                <SalesforceSubItem
                  info={salesforceInfo}
                  header="*BomSalesforceUrl"
                  item="bomSalesforceUrl"
                  link
                />
              </tbody>
            </table>
          )}
          {!loading && !salesforceInfo && (
            <div className="suggested-linked-products-empty-list">
              {!loading && (
                <p style={{ textAlign: 'center' }}>
                  {syncMessage
                    ? `${syncMessage} ...`
                    : 'No Salesforce information available for this object.'}
                </p>
              )}
            </div>
          )}
          {!loading && salesforceInfo && syncMessage && (
            <div className="suggested-linked-products-empty-list">
              <p style={{ textAlign: 'center' }}>{syncMessage}...</p>
            </div>
          )}
        </div>
      </Collapsible>
    </div>
  )
}
BomSalesforceItems.propTypes = {
  salesforceInfo: PropTypes.shape({}),
}

BomSalesforceItems.defaultProps = {
  salesforceInfo: null,
}

const PartSalesforceItems = ({ salesforceInfo }) => {
  const [loading, setLoading] = useState(false)
  const [syncMessage, setSyncMessage] = useState('')
  const [expanded, setExpanded] = useState(false)

  const onSync = async () => {
    setLoading(true)
    console.log('onSyncPart')
    try {
      const data = await syncPart()
      console.log('data', data)
      if (data && data.message) {
        setSyncMessage(data.message)
      }
    } catch (ev) {
      console.log('onSync Error', ev)
    }
    setLoading(false)
  }

  return (
    <div className="filters-container">
      <div className="filters-container-caption">
        <span>Salesforce Settings</span>
        <HButton
          className="h-mini-button h-green-button"
          name="sync"
          onClick={() => {
            onSync()
          }}
          disabled={!!syncMessage}
        >
          Sync Now
        </HButton>
        <button
          className="filters-container-expand"
          onClick={() => {
            setExpanded(!expanded)
          }}
          type="button"
        >
          {expanded ? (
            <img width="30px" src="/assets/img/common/dropdown-close.svg" alt="close" />
          ) : (
            <img width="30px" src="/assets/img/common/dropdown-down.svg" alt="expand" />
          )}
        </button>
      </div>
      <Collapsible expanded={expanded}>
        <div className="filters-container-seperator" />
        <div className="filters-container-body-competitor">
          {loading && (
            <div className="suggested-linked-products-empty-list">
              <MediumLoadingAnimation />
            </div>
          )}
          {!loading && salesforceInfo && !syncMessage && (
            <table className="salesforce-items-table">
              <tbody>
                <SalesforceSubItem info={salesforceInfo} header="UOMId" item="salesforceUomId" />
                <SalesforceSubItem
                  info={salesforceInfo}
                  header="SupplierNameCatId"
                  item="supplierNameCatId"
                />
                <SalesforceSubItem
                  info={salesforceInfo}
                  header="SupplierItemId"
                  item="salesforceSupplierItemId"
                />
                <SalesforceSubItem
                  info={salesforceInfo}
                  header="SupplierPriceBreakId"
                  item="salesforceSupplierPriceBreakId"
                />
                <SalesforceSubItem
                  info={salesforceInfo}
                  header="OriginalCost"
                  item="originalCost"
                />
                <SalesforceSubItem
                  info={salesforceInfo}
                  header="IsUnMatchedUOMPart"
                  item="isUnMatchedUomPart"
                />
              </tbody>
            </table>
          )}
          {!loading && !salesforceInfo && (
            <div className="suggested-linked-products-empty-list">
              {!loading && (
                <p style={{ textAlign: 'center' }}>
                  {syncMessage
                    ? `${syncMessage} ...`
                    : 'No Salesforce information available for this object.'}
                </p>
              )}
            </div>
          )}
          {!loading && salesforceInfo && syncMessage && (
            <div className="suggested-linked-products-empty-list">
              <p style={{ textAlign: 'center' }}>{syncMessage}...</p>
            </div>
          )}
        </div>
      </Collapsible>
    </div>
  )
}
PartSalesforceItems.propTypes = {
  salesforceInfo: PropTypes.shape({}),
}

PartSalesforceItems.defaultProps = {
  salesforceInfo: null,
}

const SupplierSalesforceItems = ({ salesforceInfo }) => {
  const [loading, setLoading] = useState(false)
  const [syncMessage, setSyncMessage] = useState('')
  const [expanded, setExpanded] = useState(false)

  const onSync = async () => {
    setLoading(true)
    console.log('onSyncSupplier')
    try {
      const data = await syncPart()
      console.log('data', data)
      if (data && data.message) {
        setSyncMessage(data.message)
      }
    } catch (ev) {
      console.log('onSync Error', ev)
    }
    setLoading(false)
  }
  return (
    <div className="filters-container">
      <div className="filters-container-caption">
        <span>Salesforce Settings</span>
        <HButton
          className="h-mini-button h-green-button"
          name="sync"
          onClick={() => {
            onSync()
          }}
          disabled={!!syncMessage}
        >
          Sync Now
        </HButton>
        <button
          className="filters-container-expand"
          onClick={() => {
            setExpanded(!expanded)
          }}
          type="button"
        >
          {expanded ? (
            <img width="30px" src="/assets/img/common/dropdown-close.svg" alt="close" />
          ) : (
            <img width="30px" src="/assets/img/common/dropdown-down.svg" alt="expand" />
          )}
        </button>
      </div>
      <Collapsible expanded={expanded}>
        <div className="filters-container-seperator" />
        <div className="filters-container-body-competitor">
          {loading && (
            <div className="suggested-linked-products-empty-list">
              <MediumLoadingAnimation />
            </div>
          )}
          {!loading && salesforceInfo && !syncMessage && (
            <table className="salesforce-items-table">
              <tbody>
                <SalesforceSubItem
                  info={salesforceInfo}
                  header="Supplier URL"
                  item="supplierLink"
                  link
                />
                <SalesforceSubItem
                  info={salesforceInfo}
                  header="SalesForceID"
                  item="salesForceId"
                />
                <SalesforceSubItem info={salesforceInfo} header="SiteID" item="siteId" />
                <SalesforceSubItem info={salesforceInfo} header="AccountID" item="accountId" />
                <SalesforceSubItem info={salesforceInfo} header="CatalogueID" item="catalogueId" />
              </tbody>
            </table>
          )}
          {!loading && !salesforceInfo && (
            <div className="suggested-linked-products-empty-list">
              {!loading && (
                <p style={{ textAlign: 'center' }}>
                  {syncMessage
                    ? `${syncMessage} ...`
                    : 'No Salesforce information available for this object.'}
                </p>
              )}
            </div>
          )}
          {!loading && salesforceInfo && syncMessage && (
            <div className="suggested-linked-products-empty-list">
              <p style={{ textAlign: 'center' }}>{syncMessage}...</p>
            </div>
          )}
        </div>
      </Collapsible>
    </div>
  )
}
SupplierSalesforceItems.propTypes = {
  salesforceInfo: PropTypes.shape({}),
}

SupplierSalesforceItems.defaultProps = {
  salesforceInfo: null,
}

export { PartSalesforceItems, SupplierSalesforceItems, BomSalesforceItems }
export default ProductSalesforceItems
