export default {
  apiCallsInProgress: 0,
  modal: {
    signInModalShown: false,
    signUpModalShown: false,
    modalHeader: ""
  },
  user: {
    user: {}
  },
  filter: {},
  customFilter: {},
  importData: {},
  searchResult: {
    q: "",
    Products: []
  }
};
