import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import HSingleSelect from 'components/common/Basic/HSingleSelect'
import { PRODUCT_FILTERS_CATERGORIES } from 'consts/filters'
import HCheckBox from '../Basic/HCheckBox'
import Collapsible from '../Basic/Collapsible'
import HInput from '../Basic/HInput'
import HButton from '../Basic/HButton'
import { store } from 'react-notifications-component'

import './index.css'

const SelectedActions = ({
  totalItems = 0,
  pageItems = 0,
  selectTotalItems,
  selectPageShownItems,
  saveAction,
  isSupplier,
  suppliers,
  mergeAction,
}) => {
  const [isExpanded, setExpand] = useState(false)
  const [isSelectAll, setSelectAll] = useState(false)
  const [isSelectPageShown, setSelectPageShown] = useState(false)
  const [batchUpdateSelected, setBatchUpdateSelected] = useState('')
  const [batchUpdateValue, setBatchUpdateValue] = useState('')
  const [mergeToSupplier, setMergeToSupplier] = useState({
    value: '-1',
    label: 'Select Supplier',
  })

  useEffect(() => {
    selectTotalItems(isSelectAll)
  }, [isSelectAll])

  useEffect(() => {
    selectPageShownItems(isSelectPageShown)
  }, [isSelectPageShown])

  return (
    <div className="filters-container">
      <div className="filters-container-caption">
        <span>Selected Actions</span>
        <button
          className="filters-container-expand"
          onClick={() => {
            setExpand(!isExpanded)
          }}
          type="button"
        >
          {isExpanded ? (
            <img src="/assets/img/common/close.svg" alt="close" />
          ) : (
            <img src="/assets/img/common/expand.svg" alt="expand" />
          )}
        </button>
      </div>
      <Collapsible expanded={isExpanded}>
        <div className="filters-container-seperator" />
        <div className="selected-actions-container-body">
          <div className="filters-container-row">
            <HCheckBox
              caption={`Select All (${totalItems} Items) `}
              name="select-all"
              onChange={() => {
                setSelectAll(!isSelectAll)
              }}
              checked={isSelectAll}
            />
          </div>
          <div className="filters-container-row">
            <HCheckBox
              caption={`Select Page Shown (${pageItems} Items) `}
              name="select-page-shown"
              onChange={() => {
                setSelectPageShown(!isSelectPageShown)
              }}
              checked={isSelectPageShown}
            />
          </div>
          <div className="filters-container-row">
            <span>Batch Update Selected:</span>
            <HSingleSelect
              values={PRODUCT_FILTERS_CATERGORIES.map((category) => ({
                value: category,
                label: category,
              }))}
              placeholder="Select One..."
              name="batch-update-selected"
              onChange={(category) => {
                setBatchUpdateSelected(category.value)
              }}
              value={{
                value: batchUpdateSelected,
                label: batchUpdateSelected,
              }}
            />
          </div>

          <div className="filters-container-row">
            <HInput
              placeholder="Enter Value here..."
              name="batch-update-value"
              onChange={(ev) => {
                setBatchUpdateValue(ev.currentTarget.value)
              }}
              value={batchUpdateValue}
              inputType="mini"
            />
          </div>
          {isSupplier && (
            <div className="filters-container-row">
              <HButton
                className="h-mini-button h-green-button filters-container-button"
                name="merge"
                onClick={() => {
                  if (mergeToSupplier.value === '-1') {
                    store.addNotification({
                      message: 'Select Target Supplier',
                      type: 'danger',
                      insert: 'top',
                      container: 'top-right',
                      animationIn: ['animated', 'bounceIn'],
                      animationOut: ['animated', 'fadeOut'],
                      dismiss: {
                        duration: 2000,
                      },
                    })
                  } else {
                    mergeAction(mergeToSupplier.value)
                  }
                }}
              >
                Merge To
              </HButton>
              <HSingleSelect
                values={[
                  {
                    value: 'Selected',
                    label: 'Selected Only',
                  },
                  ...suppliers,
                ]}
                placeholder="Select One..."
                name="merge-selected"
                onChange={(category) => {
                  setMergeToSupplier(category)
                }}
                value={mergeToSupplier}
              />
            </div>
          )}
          <div className="filters-container-row">
            <div className="filters-container-row-custom-left" />
            <div className="filters-container-row-custom-right">
              <HButton
                className="h-mini-button h-green-button"
                name="save"
                onClick={() => {
                  saveAction(batchUpdateSelected, batchUpdateValue)
                }}
              >
                Save
              </HButton>

              <HButton
                className="h-mini-button h-pink-button"
                name="clear"
                onClick={() => {
                  setBatchUpdateValue('')
                  setSelectAll(false)
                  setSelectPageShown(false)
                  setBatchUpdateSelected('')
                }}
              >
                Clear Values
              </HButton>
            </div>
          </div>
        </div>
      </Collapsible>
    </div>
  )
}
SelectedActions.propTypes = {
  totalItems: PropTypes.number,
  pageItems: PropTypes.number,
  selectTotalItems: PropTypes.func,
  selectPageShownItems: PropTypes.func,
  saveAction: PropTypes.func,
  clearAction: PropTypes.func,
  mergeAction: PropTypes.func,
  isSupplier: PropTypes.bool,
}

SelectedActions.defaultProps = {
  totalItems: 0,
  pageItems: 0,
  selectTotalItems: () => {},
  selectPageShownItems: () => {},
  saveAction: () => {},
  clearAction: () => {},
  mergeAction: () => {},
  isSupplier: false,
}
export default SelectedActions
