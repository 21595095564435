import React from 'react'
import PropTypes from 'prop-types'
import SuppliersListItem from './SuppliersListItem'
import './index.css'

const SuppliersList = ({
  suppliers,
  viewEditAction,
  selectedSuppliers,
  setSelectedSuppliers,
  syncAction,
}) => {
  console.log(suppliers)
  return (
    <div className="suppliers-list-container">
      {suppliers &&
        suppliers.length > 0 &&
        suppliers.map((supplier, index) => (
          <SuppliersListItem
            key={supplier.id}
            name={supplier.name}
            supplier={supplier}
            viewEditAction={viewEditAction}
            checked={selectedSuppliers[index]}
            setChecked={(value) => {
              const suppliersStatus = [...selectedSuppliers]
              suppliersStatus[index] = value
              setSelectedSuppliers(suppliersStatus)
            }}
            syncAction={syncAction}
          />
        ))}
      {suppliers && suppliers.length === 0 && (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            textAlign: 'center',
            fontSize: '22px',
          }}
        >
          No Suppliers available.
        </span>
      )}
    </div>
  )
}
SuppliersList.propTypes = {
  suppliers: PropTypes.arrayOf(PropTypes.shape({})),
  viewEditAction: PropTypes.func,
  selectedSuppliers: PropTypes.arrayOf(PropTypes.bool),
  setSelectedSuppliers: PropTypes.func,
  syncAction: PropTypes.func,
}

SuppliersList.defaultProps = {
  suppliers: [],
  viewEditAction: () => {},
  selectedSuppliers: [],
  setSelectedSuppliers: () => {},
  syncAction: () => {},
}
export default SuppliersList
