/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import HCheckBox from 'components/common/Basic/HCheckBox'
import { capitalizeText } from 'utils/formatter'
import './index.css'

const UserListItem = ({ user, viewEditAction }) => {
  const [isChecked, setChecked] = useState(false)
  const [dateText, setDateText] = useState('')

  useEffect(() => {
    const updatedDate = new Date(user && user.updatedAt)
    setDateText(`Last login: ${updatedDate.toLocaleString()}`)
  }, [])

  return (
    <div
      className={isChecked ? 'users-list-item-checked' : 'users-list-item'}
      onClick={() => {
        setChecked(!isChecked)
      }}
      onDoubleClick={() => {
        viewEditAction(user)
      }}
    >
      <div className="users-list-item-left">
        <div className="users-list-item-info">
          <div className="users-list-item-info-checkbox">
            <HCheckBox
              name={user.username}
              onChange={() => {
                setChecked(isChecked)
              }}
              checked={isChecked}
            />
          </div>
          <div className="users-list-item-info-pic">
            <img src={user.userImage || '/assets/img/users/user-blue.svg'} alt="missing" />
          </div>
          <div className="users-list-item-info-subinfo">
            <div className="users-list-item-info-title">
              {capitalizeText(user.username)}
              {user.isAdmin && (
                <div className="users-list-item-info-title-badge">
                  <img src="/assets/img/users/admin_badge.svg" alt="admin" width="25px" />
                </div>
              )}
            </div>
            <div className="users-list-item-info-description">{user.email}</div>
          </div>
        </div>
      </div>
      <div className="users-list-item-right">
        <div className="users-list-item-date">{dateText}</div>
        <div className="users-list-item-action">
          <a
            role="button"
            onClick={() => {
              viewEditAction(user)
            }}
            tabIndex={0}
          >
            EDIT / VIEW
          </a>
        </div>
      </div>
    </div>
  )
}
UserListItem.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool,
    userImage: PropTypes.string,
  }),
  viewEditAction: PropTypes.func,
}

UserListItem.defaultProps = {
  user: {
    id: 1,
    username: 'Andres',
    email: 'Andres@admin.com',
    isAdmin: true,
    userImage: '',
  },
  viewEditAction: () => {},
}
export default UserListItem
