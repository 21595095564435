import React from "react";
import PropTypes from "prop-types";
import Pagination from "react-bootstrap/Pagination";
import "./index.css";

const showPaginationItems = (page, totalPageCount, setPageNumber) => {
  const items = [];
  if (totalPageCount === 0) {
    return null;
  }
  items.push(
    <Pagination.Item
      key={1}
      active={page === 1}
      onClick={() => {
        setPageNumber(1);
      }}
    >
      {1}
    </Pagination.Item>
  );
  if (totalPageCount === 1) {
    return items;
  }
  if (totalPageCount === 2) {
    items.push(
      <Pagination.Item
        key={2}
        active={page === 2}
        onClick={() => {
          setPageNumber(2);
        }}
      >
        {2}
      </Pagination.Item>
    );
    return items;
  }

  if (totalPageCount === 3) {
    items.push(
      <Pagination.Item
        key={2}
        active={page === 2}
        onClick={() => {
          setPageNumber(2);
        }}
      >
        {2}
      </Pagination.Item>
    );
    items.push(
      <Pagination.Item
        key={3}
        active={page === 3}
        onClick={() => {
          setPageNumber(3);
        }}
      >
        {3}
      </Pagination.Item>
    );
    return items;
  }

  if (totalPageCount === 4) {
    items.push(
      <Pagination.Item
        key={2}
        active={page === 2}
        onClick={() => {
          setPageNumber(2);
        }}
      >
        {2}
      </Pagination.Item>
    );
    items.push(
      <Pagination.Item
        key={3}
        active={page === 3}
        onClick={() => {
          setPageNumber(3);
        }}
      >
        {3}
      </Pagination.Item>
    );
    items.push(
      <Pagination.Item
        key={4}
        active={page === 4}
        onClick={() => {
          setPageNumber(4);
        }}
      >
        {4}
      </Pagination.Item>
    );
    return items;
  }

  if (page <= 4) {
    for (let i = 2; i <= page; i += 1) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === page}
          onClick={() => {
            setPageNumber(i);
          }}
        >
          {i}
        </Pagination.Item>
      );
    }
    items.push(
      <Pagination.Item
        key={page + 1}
        onClick={() => {
          setPageNumber(page + 1);
        }}
      >
        {page + 1}
      </Pagination.Item>
    );
  } else {
    items.push(<Pagination.Ellipsis key={`${page}-ellipsis-1`} disabled />);
    items.push(
      <Pagination.Item
        key={page - 1}
        onClick={() => {
          setPageNumber(page - 1);
        }}
      >
        {page - 1}
      </Pagination.Item>
    );
    items.push(
      <Pagination.Item
        key={page}
        active
        onClick={() => {
          setPageNumber(page);
        }}
      >
        {" "}
        {page}{" "}
      </Pagination.Item>
    );
  }

  if (page === totalPageCount) {
    return items;
  }
  if (page + 1 === totalPageCount) {
    items.push(
      <Pagination.Item
        key={page + 1}
        onClick={() => {
          setPageNumber(page + 1);
        }}
      >
        {" "}
        {page + 1}{" "}
      </Pagination.Item>
    );
    return items;
  }
  if (page > 4) {
    items.push(
      <Pagination.Item
        key={page + 1}
        onClick={() => {
          setPageNumber(page + 1);
        }}
      >
        {" "}
        {page + 1}{" "}
      </Pagination.Item>
    );
  }
  items.push(<Pagination.Ellipsis key={`${page}-ellipsis-2`} disabled />);
  items.push(
    <Pagination.Item
      key={totalPageCount}
      onClick={() => {
        setPageNumber(totalPageCount);
      }}
    >
      {totalPageCount}
    </Pagination.Item>
  );
  return items;
};

const HPagination = ({ pageNumber, totalPageCount, setPageNumber }) => {
  return (
    <>
      {totalPageCount > 0 && (
        <>
          <Pagination>
            <Pagination.Prev
              onClick={() => {
                if (pageNumber > 1) {
                  setPageNumber(pageNumber - 1);
                }
              }}
            />
            {showPaginationItems(pageNumber, totalPageCount, setPageNumber)}
            <Pagination.Next
              onClick={() => {
                if (pageNumber < totalPageCount) {
                  setPageNumber(pageNumber + 1);
                }
              }}
            />
          </Pagination>
        </>
      )}
    </>
  );
};
HPagination.propTypes = {
  pageNumber: PropTypes.number,
  totalPageCount: PropTypes.number,
  setPageNumber: PropTypes.func
};

HPagination.defaultProps = {
  pageNumber: 0,
  totalPageCount: 0,
  setPageNumber: () => {}
};
export default HPagination;
