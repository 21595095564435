import { fetchAPI } from "./apiUtils";
import {
  USERS_URL,
  USER_UPDATE_URL,
  USER_ADD_URL,
  USER_GET_URL,
  USER_DEL_URL
} from "../consts/url";

export function getUsers(offset, length) {
  const formData = {
    offset,
    length
  };
  return fetchAPI(USERS_URL, "POST", formData);
}

export function updateUser(oldUser, newUser) {
  const formData = { old: { ...oldUser }, new: { ...newUser } };
  return fetchAPI(USER_UPDATE_URL, "POST", formData);
}

export function addUser(newUser) {
  const formData = { newUser: { ...newUser } };
  return fetchAPI(USER_ADD_URL, "POST", formData);
}

export function getUser(userId) {
  const formData = { id: userId };
  return fetchAPI(USER_GET_URL, "POST", formData);
}

export function deleteUser(userId) {
  const formData = { id: userId };
  return fetchAPI(USER_DEL_URL, "POST", formData);
}
