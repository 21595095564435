import React, { useEffect, useState } from 'react'
import { withRouter, useHistory, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import LoadingAnimation from 'components/LoadingAnimation'
import Caption from 'components/Users/Caption'
import HSingleSelect from 'components/common/Basic/HSingleSelect'
import HInput from 'components/common/Basic/HInput'
import HButton from 'components/common/Basic/HButton'
import { INITIAL_USER } from 'consts/users'
import HForm from 'components/common/Basic/HForm'
import EditViewSettingItem from 'components/Products/EditViewSettingItem'
import DeleteWarningModal from 'components/common/Modal/DeleteWarningModal'
import UserPreview from 'components/Users/UserPreview'
import { getUser, updateUser, deleteUser } from 'api/userApi'
import AddMedia from 'components/common/Filter/AddMedia'
import { isError, userValidationCheck, capitalizeText } from 'utils/formatter'
import { store } from 'react-notifications-component'

import './index.css'

const UserDetail = ({ user }) => {
  const history = useHistory()
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [showUser, setShowUser] = useState({})
  const [orgUser, setOrgUser] = useState({})
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [deleteWarning, showDeleteWarning] = useState(false)

  const backToList = () => {
    history.push('/users/view')
  }

  const fetchData = async () => {
    try {
      const curUser = await getUser(id)
      console.log('GetUser Result', curUser)
      if (curUser && curUser.error) {
        store.addNotification({
          message: `Sorry, we are not able to process your request now,${curUser.error} please try again later.`,
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 5000,
          },
        })
      } else {
        setShowUser(curUser)
        setOrgUser(curUser)
      }
    } catch (ev) {
      console.log('fetchData Error', ev)
      store.addNotification({
        message: 'Sorry, we are not able to process your request now, please try again later.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 5000,
        },
      })
    }
    setLoading(false)
  }

  const UpdateUserData = async () => {
    try {
      const response = await updateUser(orgUser, showUser)
      if (response && response.error) {
        console.log(response.error)
        store.addNotification({
          message: `${response.error}.\n please try again.`,
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 5000,
          },
        })
      } else {
        store.addNotification({
          message: 'Successfully Updated.',
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 5000,
          },
        })
      }
    } catch (ev) {
      console.log('UpdateUser Error', ev)
      store.addNotification({
        message: 'Sorry, we are not able to process your request now, please try again later.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 5000,
        },
      })
    }
    setLoading(false)
  }

  const DeleteUserData = async () => {
    try {
      const response = await deleteUser(showUser.id)
      if (response && response.error) {
        console.log(response.error)
        store.addNotification({
          message: `${response.error}.\n please try again.`,
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 5000,
          },
        })
      } else {
        store.addNotification({
          message: response.message || 'Successfully Deleted.',
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 5000,
          },
        })
        backToList()
      }
    } catch (ev) {
      console.log('DeleteUser Error', ev)
      store.addNotification({
        message: 'Sorry, we are not able to process your request now, please try again later.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 5000,
        },
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    if (user && user.confirmed && user.isAdmin) {
      setLoading(true)
      fetchData()
      setErrorMessage('')
    } else {
      store.addNotification({
        message: 'Sorry, you are not allowed to access this page.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 5000,
        },
      })
    }
  }, [id])

  const onSave = () => {
    // if (!userValidationCheck(showUser)) {
    //   console.log('onSave userValidationCheck failed')
    //   store.addNotification({
    //     message: 'Complete User Profile and try again.',
    //     type: 'danger',
    //     insert: 'top',
    //     container: 'top-center',
    //     animationIn: ['animated', 'bounceIn'],
    //     animationOut: ['animated', 'fadeOut'],
    //     dismiss: {
    //       duration: 2000,
    //     },
    //   })
    //   return
    // }
    if (showUser === orgUser) {
      store.addNotification({
        message: 'Sorry, no changes were detected. Please try again later',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 5000,
        },
      })
    } else {
      console.log('Save', showUser, orgUser)
      setLoading(true)
      UpdateUserData()
    }
  }

  const onClear = () => {
    console.log('Clear')
    setShowUser({ ...INITIAL_USER })
  }
  const onDelete = () => {
    console.log('Delete')
    setLoading(true)
    DeleteUserData()
  }

  return (
    <div className="user-detail">
      {loading && <LoadingAnimation />}
      {deleteWarning && (
        <DeleteWarningModal
          onHide={() => {
            showDeleteWarning(false)
          }}
          content="Are you sure you want to delete this user?"
          okAction={() => {
            onDelete()
            showDeleteWarning(false)
          }}
          cancelAction={() => {
            showDeleteWarning(false)
          }}
        />
      )}
      {errorMessage && <div className="medium-size-text">{errorMessage}</div>}
      {errorMessage === '' && (
        <>
          <div className="user-detail-list">
            <div className="user-detail-list-title">
              <Caption caption="View / Edit User" primary backAction={backToList} />
            </div>
            <div className="horizontal-separator" />
            <div className="user-detail-edit-view">
              <UserPreview user={showUser} />
              <HForm className="edit-view-item-form" autocomplete="off">
                <input autocomplete="off" name="hidden" type="text" style={{ display: 'none' }} />

                <EditViewSettingItem
                  caption="User Name:"
                  errorMessage={isError(showUser && showUser.username, 'string')}
                >
                  <HInput
                    className="edit-view-input-style"
                    value={showUser.username}
                    onChange={(ev) => {
                      const newUser = { ...showUser }
                      newUser.username = capitalizeText(ev.currentTarget.value)
                      setShowUser(newUser)
                    }}
                    autocomplete="off"
                    error={isError(showUser && showUser.username, 'string')}
                    capitalize
                  />{' '}
                </EditViewSettingItem>

                <EditViewSettingItem
                  caption="User Role:"
                  errorMessage={isError(showUser && showUser.isAdmin, 'string')}
                  clearAction={() => {
                    onClear()
                  }}
                >
                  <HSingleSelect
                    name="userRole"
                    secondary
                    values={[
                      { value: true, label: 'Admin' },
                      { value: false, label: 'User' },
                    ]}
                    value={
                      showUser.isAdmin
                        ? { value: true, label: 'Admin' }
                        : { value: false, label: 'User' }
                    }
                    onChange={(ev) => {
                      const newUser = { ...showUser }
                      newUser.isAdmin = ev.value
                      setShowUser(newUser)
                    }}
                    isSearchable={false}
                    error={isError(showUser && showUser.isAdmin, 'string')}
                  />
                </EditViewSettingItem>

                <EditViewSettingItem
                  caption="Email:"
                  errorMessage={isError(showUser && showUser.email, 'email')}
                >
                  <HInput
                    className="edit-view-input-style"
                    value={showUser.email || ''}
                    onChange={(ev) => {
                      const newUser = { ...showUser }
                      newUser.email = ev.currentTarget.value
                      setShowUser(newUser)
                    }}
                    autocomplete="off"
                    error={isError(showUser && showUser.email, 'email')}
                  />
                </EditViewSettingItem>

                <EditViewSettingItem
                  caption="Password:"
                  // errorMessage={isError(showUser && showUser.password, 'string')}
                >
                  <HInput
                    className="edit-view-input-style"
                    value={showUser.password || ''}
                    onChange={(ev) => {
                      const newUser = { ...showUser }
                      newUser.password = ev.currentTarget.value
                      setShowUser(newUser)
                    }}
                    inputType="password"
                    autocomplete="off"
                    // error={isError(showUser && showUser.password, 'string')}
                  />
                </EditViewSettingItem>

                {showUser.password !== orgUser.password && showUser.password !== '123456' && (
                  <EditViewSettingItem
                    caption="Confirm Password:"
                    errorMessage={
                      confirmPassword === showUser.password ? '' : 'Password not matched.'
                    }
                  >
                    <HInput
                      className="edit-view-input-style"
                      name="password"
                      value={confirmPassword}
                      onChange={(ev) => {
                        setConfirmPassword(ev.currentTarget.value)
                      }}
                      inputType="password"
                      // error={confirmPassword === showUser.password ? '' : 'Password not matched.'}
                    />
                  </EditViewSettingItem>
                )}
                <EditViewSettingItem
                  caption="Confirmed:"
                  errorMessage={isError(showUser && showUser.confirmed, 'string')}
                >
                  <HSingleSelect
                    name="confirmed"
                    secondary
                    values={[
                      { value: true, label: 'True' },
                      { value: false, label: 'False' },
                    ]}
                    value={
                      showUser.confirmed
                        ? { value: true, label: 'True' }
                        : { value: false, label: 'False' }
                    }
                    onChange={(ev) => {
                      const newUser = { ...showUser }
                      newUser.confirmed = ev.value
                      setShowUser(newUser)
                    }}
                    isSearchable={false}
                    error={isError(showUser && showUser.confirmed, 'string')}
                  />
                </EditViewSettingItem>
                <div className="user-detail-last-line">
                  <HButton
                    className="h-save-button"
                    onClick={() => {
                      onSave()
                    }}
                  >
                    Save
                  </HButton>

                  <HButton
                    className="h-delete-button"
                    onClick={() => {
                      showDeleteWarning(true)
                    }}
                  >
                    Delete
                  </HButton>
                </div>
              </HForm>
            </div>
          </div>
          <div className="user-detail-filters">
            <AddMedia
              caption="Upload a Profile Picture"
              pictureUrls={JSON.stringify(showUser.userImage ? [showUser.userImage] : [])}
              setPictureUrls={(picUrls) => {
                const urls = JSON.parse(picUrls)
                console.log(urls)

                const updateUser = { ...showUser }
                if (urls.length === 0) {
                  updateUser.userImage = ''
                  console.log('0')
                } else if (urls.length === 1) {
                  updateUser.userImage = urls && urls[0]
                  console.log('1')
                } else if (urls.length >= 2) {
                  updateUser.userImage = urls && urls[1]
                  console.log('2')
                }

                setShowUser(updateUser)
              }}
              uniqueMode
            />
          </div>
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.user && state.user.user,
})

UserDetail.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    isAdmin: PropTypes.bool,
    confirmed: PropTypes.bool,
  }).isRequired,
}

const connectedHeader = connect(mapStateToProps, null)(UserDetail)
export default withRouter(connectedHeader)
