/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { Link, useHistory, useLocation } from 'react-router-dom'
import throttle from 'lodash/throttle'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { showSignInModal } from 'redux/actions/modalActions'
import Dropdown from 'react-bootstrap/Dropdown'
import { signoutRequest } from 'redux/actions/authActions'
import { getProductFeatureImage } from 'utils/product_util'
import Spinner from 'react-bootstrap/Spinner'

import { priceDecimalFormatter } from 'utils/formatter'

import { getTopBarSearchResult } from 'api/searchApi'

import NotiBell from './NotiBell'
import './header.css'

const Header = ({ showSignInModal, signoutRequest, user }) => {
  const { pathname } = useLocation()
  const [query, setQuery] = useState('')
  const [products, setProducts] = useState([])
  const [parts, setParts] = useState([])
  const [searchFocused, setSearchFocused] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const searchInputRef = useRef(null)
  const searchButtonRef = useRef(null)
  const userSignedIn = user && user.confirmed
  const history = useHistory()

  useEffect(() => {
    console.log('searchFocused', searchFocused)
  }, [searchFocused])

  const querySearch = throttle(async () => {
    if (query && query.length >= 3) {
      try {
        const productFilters = {
          normal: {},
          custom: {},
        }
        setLoading(true)
        const data = await getTopBarSearchResult(query)
        console.log('QuerySearch', data)
        setProducts(data.items.products)
        setParts(data.items.parts)
        setLoading(false)
      } catch (error) {
        console.log(' ---> products loading failed', error)
        setProducts([])
      }
    } else {
      setProducts([])
    }
  }, 1000)

  useEffect(() => {
    setQuery('')
  }, [pathname])

  function searchQueryChange(newQuery) {
    console.log('query change', newQuery)
    setQuery(newQuery)
  }

  useEffect(() => {
    if (query.length === 0) {
      console.log('query is none')
      setProducts([])
    } else {
      querySearch()
    }
  }, [query])

  if (!userSignedIn && pathname !== '/forgot') {
    showSignInModal('Sign In')
    return (
      <>
        <div className="main_body" />
      </>
    )
  }

  const viewProduct = (index) => {
    history.push(`/products/view/${index}`)
  }
  const viewPart = (index) => {
    history.push(`/supplier-part-list/${index}`)
  }

  return (
    <>
      <div className="top-nav">
        <div className="top-nav-left">
          <div className="top-nav-line" />
          <div className="top-nav-title-box">
            <span className="top-nav-title-bold ">HERCULES&nbsp;</span>MANAGEMENT
          </div>
        </div>
        <div className="top-nav-right">
          <div className="top-nav-search-container">
            <div className="top-nav-search">
              <input
                className="top-nav-form-control"
                type="text"
                placeholder="Search Product Database..."
                value={query}
                onChange={(e) => searchQueryChange(e.currentTarget.value)}
                onFocus={() => {
                  setSearchFocused(true)
                }}
                onBlur={() => {
                  setTimeout(() => {
                    if (document.activeElement.className !== 'top-nav-form-control') {
                      setSearchFocused(false)
                    }
                  }, 300)
                }}
                ref={searchInputRef}
              />
              <button
                className="top-nav-search-button"
                onClick={(e) => {
                  e.preventDefault()
                  querySearch()
                  searchInputRef.current.focus()
                  setSearchFocused(true)
                }}
                type="button"
                ref={searchButtonRef}
              >
                <div className="top-nav-magnifier">
                  <img
                    src="/assets/img/header/magnifier.svg"
                    width="22px"
                    height="22px"
                    alt="maginifier"
                  />
                </div>
              </button>
            </div>
            {searchFocused && ((products && products.length > 0) || (parts && parts.length > 0)) && (
              <div className="suggestions-container">
                {isLoading && (
                  <div className="pt-3 pb-3 row align-items-center justify-content-center text-align-center">
                    <Spinner className="mr-3" animation="border" variant="primary" /> Loading...
                  </div>
                )}
                {!isLoading && (
                  <ul>
                    {products &&
                      products.map((product, index) => {
                        return (
                          <li
                            className="suggestion-item"
                            key={product.id}
                            onClick={() => {
                              viewProduct(product.id)
                            }}
                          >
                            <div className="suggestions-item-div">
                              <div className="suggestion-item-img">
                                <img
                                  className="suggestion-item-img-pic"
                                  src={
                                    getProductFeatureImage(product && product.productPictureUrl) ||
                                    '/assets/img/products/featured.svg'
                                  }
                                  alt=""
                                  onError={(ev) => {
                                    // eslint-disable-next-line no-param-reassign
                                    ev.target.onerror = null
                                    // eslint-disable-next-line no-param-reassign
                                    ev.target.src = '/assets/img/products/featured.svg'
                                  }}
                                />
                              </div>
                              <div className="suggestion-item-details">
                                <div className="suggestion-item-number">
                                  <span>{product.productDescription}</span>
                                </div>
                                <div className="suggestion-item-description">
                                  <span>{product.productNumber}</span>
                                </div>
                                <div className="suggestion-item-sub-details">
                                  <div className="suggestion-item-price">
                                    <span>{priceDecimalFormatter(product && product.price)}</span>
                                  </div>
                                  <div className="suggestion-item-uom">
                                    <span>{product.uom}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        )
                      })}
                    {parts &&
                      parts.map((part, index) => {
                        return (
                          <li
                            className="suggestion-item"
                            key={part.id}
                            onClick={() => {
                              viewPart(part.id)
                            }}
                          >
                            <div className="suggestions-item-div">
                              <div className="suggestion-item-img">
                                <img
                                  className="suggestion-item-img-pic"
                                  src={
                                    getProductFeatureImage(part && part.partPictureUrl) ||
                                    '/assets/img/parts/featured.svg'
                                  }
                                  alt=""
                                  onError={(ev) => {
                                    // eslint-disable-next-line no-param-reassign
                                    ev.target.onerror = null
                                    // eslint-disable-next-line no-param-reassign
                                    ev.target.src = '/assets/img/parts/featured.svg'
                                  }}
                                />
                              </div>
                              <div className="suggestion-item-details">
                                <div className="suggestion-item-number">
                                  <span>{part.description}</span>
                                </div>
                                <div className="suggestion-item-description">
                                  <span>{part.vendorPartNumber}</span>
                                </div>
                                <div className="suggestion-item-sub-details">
                                  <div className="suggestion-item-price">
                                    <span>{part && part.manufacturerId}</span>
                                  </div>
                                  <div className="suggestion-item-uom">
                                    <span>{part.manufacturerName}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        )
                      })}
                  </ul>
                )}
              </div>
            )}
            {searchFocused && products && products.length === 0 && parts && parts.length === 0 && (
              <div className="suggestions-container">
                <div className="pt-3 pb-3 row align-items-center justify-content-center text-align-center">
                  {isLoading && (
                    <>
                      <Spinner className="mr-3" animation="border" variant="primary" /> Loading...
                    </>
                  )}
                  {!isLoading && query && `No Results for "${query}"`}
                  {!isLoading &&
                    !query &&
                    `Try searching for a product like "vinyl gloves" or "syringe."`}
                </div>
              </div>
            )}
          </div>
          <NotiBell />
          <div className="top-nav-user-pic">
            {userSignedIn && (
              <ul className="nav">
                <Dropdown>
                  <Dropdown.Toggle variant="outline-light">
                    <div className="top-nav-user-pic-container">
                      <img
                        className="top-nav-user-pic-img"
                        src={user.userImage || '/assets/img/users/user-blue.svg'}
                        alt=""
                        onError={(ev) => {
                          // eslint-disable-next-line no-param-reassign
                          ev.target.onerror = null
                          // eslint-disable-next-line no-param-reassign
                          ev.target.src = '/assets/img/users/user-blue.svg'
                        }}
                      />
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu alignRight>
                    <Dropdown.Item disabled>
                      <div className="row ml-2 mr-2 align-items-center">
                        <img
                          className="top-nav-user-pic-img"
                          src={user.userImage || '/assets/img/users/user-blue.svg'}
                          alt=""
                          onError={(ev) => {
                            // eslint-disable-next-line no-param-reassign
                            ev.target.onerror = null
                            // eslint-disable-next-line no-param-reassign
                            ev.target.src = '/assets/img/users/user-blue.svg'
                          }}
                        />
                        <div className="col ml-1">
                          <span className="-blue">{user.username}</span>
                          <span>{user.isAdmin ? 'Admin' : 'User'}</span>
                        </div>
                      </div>
                    </Dropdown.Item>

                    <Dropdown.Item
                      onClick={() => {
                        console.log('user', user)
                        history.push(`/users/view/${user.id}`)
                      }}
                    >
                      <div className="row ml-2 mr-2 align-items-center">
                        <div className="size-50">
                          <img
                            className="pos-12"
                            src="/assets/img/header/setting.png"
                            alt=""
                            width="25px"
                            height="25px"
                          />
                        </div>
                        <span>Account Settings</span>
                      </div>
                    </Dropdown.Item>

                    <Dropdown.Divider />

                    <Dropdown.Item
                      onClick={() => {
                        signoutRequest()
                      }}
                    >
                      <div className="row ml-2 mr-2 align-items-center">
                        <div className="size-50">
                          <img
                            className="pos-12"
                            src="/assets/img/header/logout.png"
                            alt=""
                            width="25px"
                            height="25px"
                          />
                        </div>
                        <span>Log Out</span>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  user: state.user && state.user.user,
})

const mapDispatchToProps = {
  showSignInModal,
  signoutRequest,
}

Header.propTypes = {
  showSignInModal: PropTypes.func.isRequired,
  signoutRequest: PropTypes.func.isRequired,
  user: PropTypes.shape({
    userImage: PropTypes.string,
    username: PropTypes.string,
    confirmed: PropTypes.bool,
  }).isRequired,
}

const connectedHeader = connect(mapStateToProps, mapDispatchToProps)(Header)
export default connectedHeader
