import React, { useState } from 'react'
import PropTypes from 'prop-types'
import HButton from 'components/common/Basic/HButton'
import './index.css'

const CustomImportBox = ({ caption, onUploadCallback }) => {
  return (
    <div className="upload-csv-files">
      <div className="header-part">
        <span>{caption}</span>
        <HButton className="upload-csv-button" onClick={onUploadCallback}>
          <img
            src="/assets/img/common/file-upload-solid.svg"
            alt="missing"
            width="25px"
            height="25px"
          />
        </HButton>
      </div>
    </div>
  )
}
CustomImportBox.propTypes = {
  caption: PropTypes.string,
  onUploadCallback: PropTypes.func,
}

CustomImportBox.defaultProps = {
  caption: 'Upload CSV',
  onUploadCallback: () => {},
}
export default CustomImportBox
