/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { getProductFeatureImage, filterText } from 'utils/product_util'
import './index.css'
import { map } from 'jquery'

const MediumCompetitor = ({ competitor, viewCompetitor, secondary }) => {
  const [uoms, setUoms] = useState([])

  useEffect(() => {
    if (secondary) {
      console.log('competitor uoms', competitor)
      setUoms(
        competitor.UOMs.map((uom) => {
          if (uom.cost == 'Call for price') {
            return { uom: uom.uom, price: uom.cost }
          }
          return { uom: uom.uom, price: `$${uom.cost}` }
        })
      )
      return
    }

    if (competitor && competitor.price) {
      try {
        const tempUoms = JSON.parse(competitor && competitor.price)
        if (tempUoms && tempUoms.length > 0) {
          setUoms(
            tempUoms.map((uom) => {
              return { uom: uom.uom, price: uom.price }
            })
          )
        }
      } catch {
        setUoms([{ uom: 'each', price: 'Call for price' }])
        console.log('MediumCompetitor error', competitor.price)
      }
    }
  }, [competitor])

  if (secondary) {
    return (
      <div className="medium-competitor-show">
        <div className="medium-competitor-image">
          <img
            src={
              getProductFeatureImage(competitor && competitor.partPictureUrl) ||
              '/assets/img/products/featured.svg'
            }
            alt="Missing"
            width="100%"
            height="100%"
          />
          <button
            className="h-transparent-button"
            onClick={() => {
              viewCompetitor(competitor)
            }}
          >
            <img src="/assets/img/products/link.svg" alt="link" />
          </button>
        </div>
        <div className="medium-competitor-details">
          <div className="medium-competitor-description">
            {` ${filterText(competitor && competitor.description, 20)} `}{' '}
          </div>
          <div>
            <span className="medium-competitor-blue">
              {filterText(competitor && competitor.itemDetails, 30)}
            </span>
          </div>
          <div className="medium-competitor-price">
            <span className="medium-competitor-price-value">{competitor && competitor.vendor}</span>
          </div>
          <div className="medium-competitor-uoms">
            {uoms.map((uom) => (
              <div className="medium-competitor-type" key={uom.uom}>
                <span className="medium-competitor-type-uom">{uom.uom}</span>
                <span className="medium-competitor-type-price">{uom.price}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="medium-competitor-show">
      <div className="medium-competitor-image">
        <img
          src={
            getProductFeatureImage(competitor && competitor.imageUrl) ||
            '/assets/img/products/featured.svg'
          }
          alt="Missing"
          width="100%"
          height="100%"
        />
        <button
          className="h-transparent-button"
          onClick={() => {
            viewCompetitor(competitor)
          }}
        >
          <img src="/assets/img/products/link.svg" alt="link" />
        </button>
      </div>
      <div className="medium-competitor-details">
        <div className="medium-competitor-description">
          {` ${filterText(competitor && competitor.description, 20)} `}{' '}
        </div>
        <div>
          <span className="medium-competitor-blue">
            {filterText(competitor && competitor.details, 30)}
          </span>
        </div>
        <div className="medium-competitor-price">
          <span className="medium-competitor-price-value">{competitor && competitor.sku}</span>
        </div>
        <div className="medium-competitor-uoms">
          {uoms.map((uom) => (
            <div className="medium-competitor-type" key={uom.uom}>
              <span className="medium-competitor-type-uom">{uom.uom}</span>
              <span className="medium-competitor-type-price">{uom.price}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

MediumCompetitor.propTypes = {
  competitor: PropTypes.shape({}),
  viewCompetitor: PropTypes.func,
  secondary: PropTypes.bool,
}

MediumCompetitor.defaultProps = {
  competitor: {},
  viewCompetitor: () => {},
  secondary: false,
}
export default MediumCompetitor
