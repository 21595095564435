// deprecated - as we don't need to save Products data inside redux
import * as types from "./actionTypes";

export function importSettingSuccess(importData) {
  return { type: types.IMPORT_SETTING_SUCCESS, importData };
}

export function importRemoveSuccess() {
  return { type: types.IMPORT_REMOVE_SUCCESS };
}

export function setImportData(importData) {
  console.log("SetImportData", importData);
  return async dispatch => {
    dispatch(importSettingSuccess(importData));
  };
}

export function removeImportData() {
  return async dispatch => {
    dispatch(importRemoveSuccess());
  };
}
