import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import HSingleSelect from 'components/common/Basic/HSingleSelect'
import FILTERS, {
  PRODUCT_FILTERS_CATERGORIES,
  PART_FILTERS_CATEGORIES,
  BOM_FILTERS_CATEGORIES,
  SUPPLIER_FILTERS_CATERGORIES,
} from 'consts/filters'
// import HCheckBox from "../Basic/HCheckBox";
// import HMultiSelect from "../Basic/HMultiSelect";
import Collapsible from '../Basic/Collapsible'
import HInput from '../Basic/HInput'
import HButton from '../Basic/HButton'
import './index.css'

const Filters = ({
  isProduct,
  isPart,
  isBom,
  // isScraper,
  isSupplier,
  onApply,
  onCancel,
  filter,
}) => {
  const [isExpanded, setExpand] = useState(false)
  const [filterCategory, setFilterCategory] = useState('Select One')
  const [filterSortBy, setFilterSortBy] = useState('Select One')
  const [isStock, setFilterStock] = useState(false)
  const [isDiscontinued, setFilterDiscontinued] = useState(false)
  const [filterTags, setFilterTags] = useState([])
  const [filterMinPrice, setFilterMinPrice] = useState(0.0)
  const [filterMaxPrice, setFilterMaxPrice] = useState(100000.0)

  useEffect(() => {
    setFilterCategory((filter && filter.category) || 'Select One')
    setFilterSortBy((filter && filter.sortBy) || 'Select One')
    setFilterStock((filter && filter.outOfStock) || false)
    setFilterDiscontinued((filter && filter.discontinued) || false)
    setFilterMinPrice((filter && filter.minPrice) || 0)
    setFilterMaxPrice((filter && filter.maxPrice) || 100000)
    if (filter && filter.category && filter.sortBy) {
      setExpand(true)
    }
  }, [filter])

  return (
    <div className="filters-container">
      <div className="filters-container-caption">
        <span>Sort</span>
        <button
          className="filters-container-expand"
          onClick={() => {
            setExpand(!isExpanded)
          }}
          type="button"
        >
          {isExpanded ? (
            <img src="/assets/img/common/close.svg" alt="close" />
          ) : (
            <img src="/assets/img/common/expand.svg" alt="expand" />
          )}
        </button>
      </div>
      <Collapsible expanded={isExpanded}>
        <div className="filters-container-seperator" />
        <div className="filters-container-body">
          <div className="filters-container-row">
            <span> Category: </span>
            <div className="filters-container-row-common">
              <HSingleSelect
                values={
                  (isProduct &&
                    PRODUCT_FILTERS_CATERGORIES.map((category) => ({
                      value: category,
                      label: category,
                    }))) ||
                  (isPart &&
                    PART_FILTERS_CATEGORIES.map((category) => ({
                      value: category,
                      label: category,
                    }))) ||
                  (isSupplier &&
                    SUPPLIER_FILTERS_CATERGORIES.map((category) => ({
                      value: category,
                      label: category,
                    }))) ||
                  (isBom &&
                    BOM_FILTERS_CATEGORIES.map((category) => ({
                      value: category,
                      label: category,
                    }))) ||
                  FILTERS.CATEGORIES.map((category) => ({
                    value: category,
                    label: category,
                  }))
                }
                placeholder="Select One"
                name="Categories"
                value={{ value: filterCategory, label: filterCategory }}
                onChange={(category) => {
                  setFilterCategory(category.value)
                }}
              />
            </div>
          </div>
          <div className="filters-container-row">
            <span> Sort By: </span>
            <div className="filters-container-row-common">
              <HSingleSelect
                values={FILTERS.SORTBY}
                placeholder="Select One"
                name="sortBy"
                value={{
                  value: filterSortBy,
                  label:
                    (filterSortBy === 'DESC' && 'Descending') ||
                    (filterSortBy === 'ASC' && 'Ascending') ||
                    filterSortBy,
                }}
                defaultValue={filterSortBy}
                onChange={(sortby) => {
                  setFilterSortBy(sortby.value)
                }}
              />
            </div>
          </div>
          {!isSupplier && (
            <div className="filters-container-row">
              <span> Price($): </span>
              <div className="filters-container-row-common">
                <div className="filters-container-row-subpart">
                  <span> Min: </span>
                  <HInput
                    name="Min"
                    placeholder="$00.00"
                    inputType="mini"
                    type="number"
                    value={filterMinPrice}
                    onChange={(ev) => {
                      const value = parseFloat(ev.currentTarget.value)
                      if (value === 'NaN') {
                        setFilterMinPrice(0.0)
                      }
                      setFilterMinPrice(value)
                    }}
                  />
                </div>

                <div className="filters-container-row-subpart">
                  <span> Max: </span>
                  <HInput
                    name="Max"
                    placeholder="$100,000.00"
                    inputType="mini"
                    type="number"
                    value={filterMaxPrice}
                    onChange={(ev) => {
                      const value = parseFloat(ev.currentTarget.value)
                      if (value === 'NaN') {
                        setFilterMaxPrice(0.0)
                      }
                      setFilterMaxPrice(value)
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="filters-container-row">
            <div className="filters-container-row-left" />

            <div className="filters-container-row-right">
              <HButton
                className="h-medium-button h-green-button mr-2"
                onClick={() => {
                  const filter = {
                    category: filterCategory,
                    sortBy: filterSortBy,
                    minPrice: filterMinPrice,
                    maxPrice: filterMaxPrice,
                    outOfStock: isStock,
                    discontinued: isDiscontinued,
                  }
                  onApply(filter)
                }}
              >
                Apply
              </HButton>
              <HButton
                className="h-medium-button h-pink-button"
                onClick={() => {
                  setFilterMinPrice(0)
                  setFilterMaxPrice(100000.0)
                  setFilterCategory('Select One')
                  setFilterSortBy('Select One')
                  setFilterStock(false)
                  setFilterDiscontinued(false)
                  setFilterTags({})
                  onCancel()
                }}
              >
                Cancel
              </HButton>
            </div>
          </div>
        </div>
      </Collapsible>
    </div>
  )
}

Filters.propTypes = {
  isProduct: PropTypes.bool,
  isPart: PropTypes.bool,
  isBom: PropTypes.bool,
  isScraper: PropTypes.bool,
  isSupplier: PropTypes.bool,
  onApply: PropTypes.func,
  onCancel: PropTypes.func,
  filter: PropTypes.shape({
    category: PropTypes.string,
    sortBy: PropTypes.string,
    minPrice: PropTypes.number,
    maxPrice: PropTypes.number,
    outOfStock: PropTypes.bool,
    discontinued: PropTypes.bool,
  }),
}

Filters.defaultProps = {
  isProduct: true,
  isPart: false,
  isScraper: false,
  isSupplier: false,
  isBom: false,
  onApply: () => {},
  onCancel: () => {},
  filter: null,
}
export default Filters
