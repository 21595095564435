import React from "react";
import PropTypes from "prop-types";
import { classNames } from "utils/helper";
import "./index.css";

const Caption = ({ caption, bContainImg, primary, secondary, initial }) => {
  const classes = classNames(
    "scrapers-caption-container",
    (primary && "-primary") || "",
    (secondary && "-secondary") || "",
    (initial && "-initial") || ""
  );
  return (
    <div className={classes}>
      <div className="scrapers-caption-container-left">
        {bContainImg && (
          <img src="/assets/img/scrapers/caption_box.svg" alt="box" />
        )}
        <span> {caption}</span>
      </div>
      <div className="scrapers-caption-container-right">
        <img src="/assets/img/common/back.svg" alt="back" width="50px" />
      </div>
    </div>
  );
};

Caption.propTypes = {
  caption: PropTypes.string,
  bContainImg: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  initial: PropTypes.bool
};

Caption.defaultProps = {
  caption: "",
  bContainImg: true,
  primary: true,
  secondary: false,
  initial: false
};
export default Caption;
