import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function searchReducer(
  state = initialState.searchResult,
  action
) {
  switch (action.type) {
    case types.TOP_BAR_SEARCH_RESULT_SUCCESS:
      return { ...state, Products: action.Products, q: action.q };
    default:
      return state;
  }
}
