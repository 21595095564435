/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-danger */
import React from 'react'
import PropTypes from 'prop-types'
import HButton from './HButton'
import HSingleSelect from './HSingleSelect'
import './index.css'

const HUOMTable = ({ columns, globalUoms, data, setData, selectMode, onSelect }) => {
  return (
    <div className="table-responsive">
      <table className={selectMode ? 'table' : 'table table-hover table-bordered'}>
        <thead>
          <tr className="h-table-header">
            {columns &&
              columns.map((item) => {
                return <th>{item}</th>
              })}
            <th align="center"></th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => {
              return (
                <tr
                  className="table-row-hover-blue cursor-pointer"
                  onClick={() => {
                    if (selectMode) {
                      onSelect(item, index)
                    }
                  }}
                >
                  {selectMode && (
                    <>
                      <td>{item?.UOM || item?.uom}</td>
                      <td>{item?.Per || item?.per}</td>
                      <td>${item?.Cost || item?.cost}</td>
                      <td>{item?.Weight || item?.weight}</td>
                      <td>{item?.WeightUOM || item?.weightUom}</td>
                      <td>{item?.Length || item?.length}</td>
                      <td>{item?.Width || item?.width}</td>
                      <td>{item?.Height || item?.height}</td>
                      <td>{item?.SizeUOM || item?.sizeUom}</td>
                      {/* <td>{item.cartonType}</td>
                      <td>{item.cartonCount}</td> */}
                    </>
                  )}

                  {!selectMode && (
                    <>
                      <td>
                        <HSingleSelect
                          name="uom"
                          values={globalUoms.map((type) => ({
                            value: type,
                            label: type,
                          }))}
                          value={{
                            value: item.uom,
                            label: item.uom,
                          }}
                          onChange={(ev) => {
                            const tempData = [...data]
                            tempData[index].uom = ev.value
                            setData(tempData)
                          }}
                          isSearchable
                          miniMode={true}
                        />
                      </td>
                      <td>
                        <input
                          className="h-uom-table-input"
                          value={item.per}
                          onChange={(ev) => {
                            const tempData = [...data]
                            tempData[index].per = ev.target.value
                            setData(tempData)
                          }}
                        />
                      </td>
                      <td>
                        $
                        <input
                          className="h-uom-table-cost-input"
                          value={item.cost}
                          onChange={(ev) => {
                            const tempData = [...data]
                            tempData[index].cost = ev.target.value
                            setData(tempData)
                          }}
                        />
                      </td>
                      <td>
                        <input
                          className="h-uom-table-input"
                          value={item.weight}
                          onChange={(ev) => {
                            const tempData = [...data]
                            tempData[index].weight = ev.target.value
                            setData(tempData)
                          }}
                        />
                      </td>
                      <td>
                        <input
                          className="h-uom-table-input"
                          value={item.weightUom}
                          onChange={(ev) => {
                            const tempData = [...data]
                            tempData[index].weightUom = ev.target.value
                            setData(tempData)
                          }}
                        />
                      </td>
                      <td>
                        <input
                          className="h-uom-table-input"
                          value={item.width}
                          onChange={(ev) => {
                            const tempData = [...data]
                            tempData[index].width = ev.target.value
                            setData(tempData)
                          }}
                        />
                      </td>
                      <td>
                        <input
                          className="h-uom-table-input"
                          value={item.height}
                          onChange={(ev) => {
                            const tempData = [...data]
                            tempData[index].height = ev.target.value
                            setData(tempData)
                          }}
                        />
                      </td>
                      <td>
                        <input
                          className="h-uom-table-input"
                          value={item.length}
                          onChange={(ev) => {
                            const tempData = [...data]
                            tempData[index].length = ev.target.value
                            setData(tempData)
                          }}
                        />
                      </td>
                      <td>
                        <input
                          className="h-uom-table-input"
                          value={item.sizeUom}
                          onChange={(ev) => {
                            const tempData = [...data]
                            tempData[index].sizeUom = ev.target.value
                            setData(tempData)
                          }}
                        />
                      </td>
                      <td align="center">
                        <HButton
                          className="h-blue-button rounded-button"
                          onClick={() => {
                            const tempData = [...data]
                            tempData.splice(index, 1)
                            setData(tempData)
                          }}
                        >
                          -
                        </HButton>
                      </td>
                    </>
                  )}
                </tr>
              )
            })}
          {data && data.length === 0 && (
            <tr>
              <td colSpan={10} style={{ textAlign: 'center', fontSize: '18px' }}>
                No UOMs - click the + icon to add a Unit of Measure for this item.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {!selectMode && (
        <div className="h-child-table-add-row">
          <HButton
            className="rounded-button"
            onClick={() => {
              const tempData = [
                ...data,
                {
                  cartonCount: null,
                  cartonType: null,
                  cost: 0,
                  height: 0,
                  itemAvailability: false,
                  itemsAvailable: 0,
                  length: null,
                  minQuantity: 0,
                  partId: 34307,
                  per: 1,
                  sizeUom: 'in',
                  status: 'active',
                  uom: 'EA',
                  weight: 0,
                  weightUom: 'Lbs',
                  width: 0,
                },
              ]
              setData(tempData)
            }}
          >
            +
          </HButton>
        </div>
      )}
    </div>
  )
}
HUOMTable.propTypes = {
  columns: PropTypes.any,
  data: PropTypes.any,
  setData: PropTypes.func,
  selectMode: PropTypes.bool,
  globalUoms: PropTypes.any,
}

HUOMTable.defaultProps = {
  columns: [],
  data: [],
  setData: () => {},
  selectMode: false,
  globalUoms: [],
}
export default HUOMTable
