/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { classNames } from 'utils/helper'

import { getProductFeatureImage } from 'utils/product_util'
import { priceDecimalFormatter } from 'utils/formatter'

import throttle from 'lodash/throttle'
import Spinner from 'react-bootstrap/Spinner'
import { store } from 'react-notifications-component'
import { getPartsByQuery } from 'api/productApi'
import onClickOutside from 'react-onclickoutside'
import HInput from './HInput'

import './index.css'

function HSearchPartInput({ setSelectedPart }) {
  const [searchPartQuery, setSearchPartQuery] = useState('')
  const [searchPartResult, setSearchPartResult] = useState([])
  const [searchFocused, setSearchFocused] = useState(false)
  const [searchLoading, setSearchLoading] = useState(false)

  const searchPart = throttle(async (searchPartQuery) => {
    if (searchPartQuery && searchPartQuery.length > 0) {
      try {
        const results = await getPartsByQuery(searchPartQuery)
        console.log('GetPartsByQuery --> ', results)
        if (results && results.error) {
          console.log('error', results.error)
          store.addNotification({
            message: 'Sorry, we are not able to process your request now. Please try again later.',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 4000,
            },
          })
        }
        if (results && results.parts) {
          const tempParts = []

          results.parts.forEach((part) => {
            if (part && part.UOMs) {
              part.UOMs.forEach((uom) => {
                const newPart = { ...part }
                newPart.uom = uom
                tempParts.push(newPart)
              })
            }
          })

          console.log('tempParts ---> ', tempParts)
          setSearchPartResult(tempParts)
        }
      } catch (error) {
        console.log('Search error', error)
        store.addNotification({
          message: 'Sorry, we are not able to process your request now. Please try again later.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 4000,
          },
        })
      }
    } else {
      setSearchPartResult([])
    }
    setSearchLoading(false)
  }, 400)

  useEffect(() => {
    setSearchLoading(true)
    searchPart(searchPartQuery)
  }, [searchPartQuery])

  HSearchPartInput.handleClickOutside = () => setSearchFocused(false)

  return (
    <div className="search-part-input-div">
      <HInput
        className="search-part-input"
        searchBox
        value={searchPartQuery}
        onChange={(ev) => {
          setSearchPartQuery(ev.currentTarget.value)
        }}
        onFocus={() => {
          setSearchFocused(true)
        }}
      />
      {searchPartResult && searchPartResult.length > 0 && (
        <div
          className={
            !searchFocused ? 'search-result-container hide' : 'search-result-container show'
          }
        >
          {searchLoading && (
            <div className="pt-3 pb-3 row align-items-center justify-content-center">
              <Spinner className="mr-3" animation="border" variant="primary" /> Loading...
            </div>
          )}
          {!searchLoading && (
            <ul>
              {searchPartResult &&
                searchPartResult.map((part, index) => {
                  return (
                    <li
                      className="suggestion-item"
                      key={index}
                      onClick={() => {
                        console.log('Part is connected', part._id)
                        setSelectedPart(part)
                        setSearchFocused(false)
                      }}
                    >
                      <div className="suggestions-item-div">
                        <div className="suggestion-item-img">
                          <img
                            className="suggestion-item-img-pic"
                            src={
                              getProductFeatureImage(part && part.partPictureUrl) ||
                              '/assets/img/parts/featured.svg'
                            }
                            alt=""
                            onError={(ev) => {
                              // eslint-disable-next-line no-param-reassign
                              ev.target.onerror = null
                              // eslint-disable-next-line no-param-reassign
                              ev.target.src = '/assets/img/parts/featured.svg'
                            }}
                          />
                        </div>
                        <div className="suggestion-item-details">
                          <div className="suggestion-item-number">
                            <span>{part.partNumber}</span>
                          </div>
                          <div className="suggestion-item-description">
                            <span>{part.description}</span>
                          </div>
                          <div className="suggestion-item-sub-details">
                            <div className="suggestion-item-price">
                              <span>{priceDecimalFormatter(part && part.uom.cost)}</span>
                            </div>
                            <div className="suggestion-item-uom">
                              <span>{part.uom.uom}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                })}
            </ul>
          )}
        </div>
      )}
      {searchPartResult && searchPartResult.length === 0 && (
        <div
          className={
            !searchFocused ? 'search-result-container hide' : 'search-result-container show'
          }
          tabIndex={0}
        >
          <div className="pt-3 pb-3 row align-items-center justify-content-center">
            {searchLoading && (
              <>
                <Spinner className="mr-3" animation="border" variant="primary" /> Loading...
              </>
            )}
            {!searchLoading && `No Results for "${searchPartQuery}"`}
          </div>
        </div>
      )}
    </div>
  )
}

HSearchPartInput.propTypes = {
  setSelectedPart: PropTypes.func,
}

HSearchPartInput.defaultProps = {
  setSelectedPart: () => {},
}

const clickOutsideConfig = {
  handleClickOutside: () => HSearchPartInput.handleClickOutside,
}

export default onClickOutside(HSearchPartInput, clickOutsideConfig)
