/* eslint-disable no-restricted-syntax */
import format from 'date-fns/format'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { string } from 'prop-types'

export function priceFormatter(priceCents) {
  return `$${priceCents / 100} `
}

export function priceDecimalFormatter(price) {
  const priceStyle = {
    style: 'currency',
    currency: 'USD',
  }
  if (price) {
    return price.toLocaleString('us-US', priceStyle)
  }
  return '$0.00'
}

export function decimalFormatter(number) {
  if (typeof number !== 'number') {
    return 0
  }
  return number.toLocaleString('us-US')
}

export function toDecimal(string) {
  if (string) {
    return parseFloat(string)
  }
  return 0
}

export function toArrayFromJSON(string) {
  if (!string) {
    return []
  }
  try {
    return JSON.parse(string)
  } catch {
    return []
  }
}

export function toJSONFromArray(array) {
  return JSON.stringify(array)
}

export function isError(value, role) {
  const numberRGEX = /^[0-9]+\.?[0-9]*$/
  const numericRGEX = /^[a-zA-Z0-9-_]*$/
  const urlRGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  const upcRGEX = /^[0-9]*$/
  const phoneRGEX = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/
  const emailRGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  const statusText = `${value}`
  if (statusText === 'undefined' || statusText.length === 0) return 'Field is Empty.'
  if (statusText === 'null') return 'Field is Empty.'
  if (role === 'decimal') {
    if (statusText !== '-1') {
      if (numberRGEX.test(statusText) === false) return 'Invalid Value'
    }
  } else if (role === 'number') {
    if (numericRGEX.test(statusText) === false) return 'Invalid Value'
  } else if (role === 'url') {
    if (urlRGEX.test(statusText) === false) return 'Invalid URL'
  } else if (role === 'upc') {
    if (upcRGEX.test(statusText) === false) return 'Invalid UPC'
  } else if (role === 'phone') {
    if (phoneRGEX.test(statusText) === false) return 'Invalid Phone'
  } else if (role === 'email') {
    if (emailRGEX.test(statusText) === false) return 'Invalid Email'
  }
  return ''
}

export const productValidationCheck = (product) => {
  // if (isError(product.partNumber, 'string')) {
  //   console.log('PartNumber error')
  //   return false
  // }
  // if (isError(product.partUom, 'string')) {
  //   console.log('PartUom error')
  //   return false
  // }

  // if (isError(product.productDescription, 'string')) {
  //   console.log('ProductDescription error')
  //   return false
  // }
  // if (isError(product.uom, 'string')) {
  //   console.log('UOM error')
  //   return false
  // }
  if (isError(`${product.price}`, 'decimal')) {
    console.log('price error')
    return false
  }

  return true
}

export const bomValidationCheck = (product) => {
  if (isError(product.productDescription, 'string')) {
    console.log('ProductDescription error')
    return false
  }
  if (isError(`${product.price}`, 'decimal')) {
    console.log('price error')
    return false
  }

  return true
}

export const partValidationCheck = (part) => {
  if (isError(part.manufacturerName, 'string')) {
    console.log('manufacturer error')
    return false
  }
  if (isError(part.manufacturerId, 'number')) {
    console.log('manufacturerPartNumber error')
    return false
  }
  if (isError(part.vendor, 'string')) {
    console.log('vendor error')
    return false
  }
  if (isError(part.vendorPartNumber, 'number')) {
    console.log('vendorPartNumber error')
    return false
  }

  if (isError(part.description, 'string')) {
    console.log('Part Description error')
    return false
  }
  if (isError(part.uom, 'string')) {
    console.log('UOM error')
    return false
  }
  if (isError(part.defaultVendor, 'string')) {
    console.log('UOM error')
    return false
  }
  if (isError(part.cost, 'decimal')) {
    console.log('price error')
    return false
  }

  return true
}

export const supplierValidationCheck = (supplier) => {
  if (isError(supplier.active, 'string')) {
    console.log('active error')
    return false
  }
  if (isError(supplier.name, 'string')) {
    console.log('name error')
    return false
  }
  if (isError(supplier.IsVendor, 'string')) {
    console.log('vendor error')
    return false
  }
  if (isError(supplier.IsManufacturer, 'string')) {
    console.log('Manufacturer error')
    return false
  }

  return true
}

export const userValidationCheck = (user) => {
  if (isError(user.username, 'string')) {
    console.log('username error')
    return false
  }
  if (isError(user.email, 'string')) {
    console.log('email error')
    return false
  }
  if (isError(user.password, 'string')) {
    console.log('password error')
    return false
  }

  if (isError(user.confirmed, 'string')) {
    console.log('confirmed error')
    return false
  }

  if (isError(user.isAdmin, 'string')) {
    console.log('isAdmin error')
    return false
  }

  return true
}

export const capitalizeText = (text) => {
  if (text && text.length >= 1) {
    return text.charAt(0).toUpperCase() + text.slice(1)
  }
  return ''
}

export const getFormattedTime = (item) => {
  if (!item || !item.updatedAt) {
    return ''
  }
  const updatedTime = new Date(item && item.updatedAt)
  const currentTime = new Date()
  console.log(updatedTime, currentTime, currentTime - updatedTime)

  if (currentTime - updatedTime < 60 * 60 * 1000 * 24) {
    const dateDistance = formatDistanceToNow(updatedTime)
    return `${dateDistance} ago`
  }
  return format(new Date(item && item.updatedAt), 'yyyy-MM-dd HH:mm:ss')
}

export const getShortString = (item, maxLength = 50) => {
  if (!item) {
    return ''
  }
  if (typeof item !== 'string') {
    return item
  }

  if (item.length <= maxLength) {
    return item
  }
  return `${item.substring(0, maxLength)} ...`
}
