import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import HButton from "../Basic/HButton";
import "./index.css";

const EditViewActions = ({
  saveCaption,
  clearCaption,
  deleteCaption,
  saveAction,
  clearAction,
  deleteAction,
  hideSave,
  hideClear,
  hideDelete,
  saveButtonSize,
  clearButtonSize,
  deleteButtonSize
}) => {
  const [saveButtonClassName, setSaveButtonClassName] = useState("");
  const [clearButtonClassName, setClearButtonClassName] = useState("");
  const [deleteButtonClassName, setDeleteButtonClassName] = useState("");

  useEffect(() => {
    if (saveButtonSize === "medium") {
      setSaveButtonClassName("h-medium-button h-green-button");
    } else if (saveButtonSize === "large") {
      setSaveButtonClassName("h-large-button h-green-button");
    }
    if (clearButtonSize === "medium") {
      setClearButtonClassName("h-medium-button h-grey-button");
    } else if (clearButtonSize === "large") {
      setClearButtonClassName("h-large-button h-grey-button");
    }
    if (deleteButtonSize === "medium") {
      setDeleteButtonClassName("h-medium-button h-pink-button");
    } else if (deleteButtonSize === "large") {
      setDeleteButtonClassName("h-large-button h-pink-button");
    }
  }, []);

  return (
    <div className="filters-container-actions-bar">
      {!hideSave && (
        <HButton
          className={saveButtonClassName}
          onClick={() => {
            saveAction();
          }}
        >
          {saveCaption}
        </HButton>
      )}
      {!hideClear && (
        <HButton
          className={clearButtonClassName}
          onClick={() => {
            clearAction();
          }}
        >
          {clearCaption}
        </HButton>
      )}
      {!hideDelete && (
        <HButton
          className={deleteButtonClassName}
          onClick={() => {
            deleteAction();
          }}
        >
          {deleteCaption}
        </HButton>
      )}
    </div>
  );
};
EditViewActions.propTypes = {
  saveAction: PropTypes.func,
  clearAction: PropTypes.func,
  deleteAction: PropTypes.func,
  saveCaption: PropTypes.string,
  clearCaption: PropTypes.string,
  deleteCaption: PropTypes.string,
  hideSave: PropTypes.bool,
  hideClear: PropTypes.bool,
  hideDelete: PropTypes.bool,
  saveButtonSize: PropTypes.string,
  clearButtonSize: PropTypes.string,
  deleteButtonSize: PropTypes.string
};

EditViewActions.defaultProps = {
  saveAction: () => {},
  clearAction: () => {},
  deleteAction: () => {},
  saveCaption: "Save Changes",
  clearCaption: "Clear All",
  deleteCaption: "Delete",
  hideSave: false,
  hideClear: false,
  hideDelete: false,
  saveButtonSize: "medium",
  clearButtonSize: "medium",
  deleteButtonSize: "medium"
};
export default EditViewActions;
