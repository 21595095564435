/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import PropTypes from 'prop-types'
import { classNames } from 'utils/helper'
import { getProductFeatureImage } from 'utils/product_util'
import { getFormattedTime, getShortString } from 'utils/formatter'
import { Link, useHistory } from 'react-router-dom'
import HButton from '../common/Basic/HButton'
import './index.css'

const DashboardCard = ({
  caption,
  itemCount,
  items,
  itemType,
  viewLink,
  approveAction,
  declineAction,
}) => {
  const classes = classNames('dashboard-main-card')
  const history = useHistory()
  return (
    <div className={classes}>
      <div className="dashboard-main-card-header">{caption}</div>
      <div className="horizontal-separator" />
      <div className="dashboard-main-card-body">
        {items.map((item) => {
          if (itemType === 'Product') {
            return (
              <div
                key={`recent-product-${item.id}`}
                className="dashboard-item"
                onClick={() => {
                  if (item.isBom) {
                    history.push(`boms/view/${item.id}`)
                  } else {
                    history.push(`products/view/${item.id}`)
                  }
                }}
              >
                <img
                  className="dashboard-item-image"
                  src={
                    getProductFeatureImage(item && item.productPictureUrl) ||
                    '/assets/img/products/featured.svg'
                  }
                  alt="pic"
                  onError={(ev) => {
                    // eslint-disable-next-line no-param-reassign
                    ev.target.onerror = null
                    // eslint-disable-next-line no-param-reassign
                    ev.target.src = '/assets/img/products/featured.svg'
                  }}
                />
                <div className="dashboard-item-info">
                  <div className="dashboard-item-info-title">
                    <Link
                      to={!item.isBom ? `products/view/${item.id}` : `boms/view/${item.id}`}
                      onClick={(ev) => {
                        ev.stopPropagation()
                      }}
                    >
                      {item.productNumber || 'No Product Number'}{' '}
                    </Link>
                  </div>
                  <div className="dashboard-item-info-description">
                    {item.productDescription || 'No Product Description'}
                  </div>
                </div>
                <div className="dashboard-item-action">
                  <Link
                    to={!item.isBom ? `products/view/${item.id}` : `boms/view/${item.id}`}
                    onClick={(ev) => {
                      ev.stopPropagation()
                    }}
                  >
                    {' '}
                    VIEW{' '}
                  </Link>
                </div>
              </div>
            )
          }
          if (itemType === 'Part') {
            return (
              <div
                key={`recent-manufacturer-${item.id}`}
                className="dashboard-item"
                onClick={() => {
                  history.push(`${viewLink}/${item.id}`)
                }}
              >
                <img
                  className="dashboard-item-image"
                  src={
                    getProductFeatureImage(item.partPictureUrl) || '/assets/img/parts/featured.svg'
                  }
                  alt="pic"
                  onError={(ev) => {
                    // eslint-disable-next-line no-param-reassign
                    ev.target.onerror = null
                    // eslint-disable-next-line no-param-reassign
                    ev.target.src = '/assets/img/parts/featured.svg'
                  }}
                />
                <div className="dashboard-item-info">
                  <div className="dashboard-item-info-title">{item.manufacturerId}</div>
                  <div className="dashboard-item-info-description">{item.description}</div>
                </div>
                <div className="dashboard-item-action">
                  <Link
                    to={`${viewLink}/${item.id}`}
                    onClick={(ev) => {
                      ev.stopPropagation()
                    }}
                  >
                    {' '}
                    VIEW{' '}
                  </Link>
                </div>
              </div>
            )
          }
          if (itemType === 'Pending') {
            let pendingItem = null
            if (item.action === 'add' || item.action === 'added') {
              if (item.objectType === 'Part') {
                item.newState = JSON.parse(item?.newState)
                pendingItem = {
                  pictureUrl: (item.newState && item.newState?.PartPictureUrl) || ' ',
                  number: (item.newState && item.newState?.ManufacturerId) || ' ',
                  description:
                    (item.newState && getShortString(item.newState?.Descriptionescription)) || ' ',
                  price: (item.newState && item.newState?.Cost) || 0,
                  income: (item.newState && item.newState?.Vendor) || ' ',
                }
              } else if (item.objectType === 'BOMProduct') {
                item.newState = JSON.parse(item?.newState)
                pendingItem = {
                  pictureUrl: (item.newState && item.newState?.product.ProductPictureUrl) || ' ',
                  number:
                    (item.newState && getShortString(item.newState?.product.ProductDetails)) || ' ',
                  description:
                    (item.newState && getShortString(item.newState?.product.ProductDescription)) ||
                    ' ',
                  price: (item.newState && item.newState?.product.price) || 0,
                  income:
                    (item.newState && getShortString(item.newState?.product.incomeAccount)) || ' ',
                }
              } else {
                item.newState = JSON.parse(item?.newState)
                console.log(item, 'item')
                pendingItem = {
                  pictureUrl: (item.newState && item.newState?.ProductPictureUrl) || ' ',
                  number: (item.newState && item.newState?.ProductNumber) || ' ',
                  description: (item.newState && item.newState?.ProductDescription) || ' ',
                  price: (item.newState && item.newState?.Price) || 0,
                  income: (item.newState && item.newState?.IncomeAccount) || ' ',
                }
              }
            } else if (item.action === 'edit') {
              if (item.objectType === 'Part') {
                item.newState = JSON.parse(item?.newState)
                pendingItem = {
                  pictureUrl: (item.newState && item.newState?.PartPictureUrl) || ' ',
                  number: (item.newState && item.newState?.ManufacturerId) || ' ',
                  description: (item.newState && item.newState?.Descriptionescription) || ' ',
                  price: (item.newState && item.newState?.cost) || 0,
                  income: (item.newState && item.newState?.vendor) || ' ',
                }
              } else {
                item.newState = JSON.parse(item.newState)
                pendingItem = {
                  pictureUrl: (item.newState && item.newState?.ProductPictureUrl) || ' ',
                  number: (item.newState && item.newState?.ProductNumber) || ' ',
                  description: (item.newState && item.newState?.ProductDescription) || ' ',
                  price: (item.newState && item.newState?.Price) || 0,
                  income: (item.newState && item.newState?.IncomeAccount) || ' ',
                }
              }
            } else if (item.action === 'delete') {
              if (item.objectType === 'Part') {
                item.newState = JSON.parse(item?.newState)
                pendingItem = {
                  pictureUrl: (item.newState && item.newState?.PartPictureUrl) || ' ',
                  number: (item.newState && item.newState?.ManufacturerId) || ' ',
                  description: (item.newState && item.newState?.Descriptionescription) || ' ',
                  price: (item.newState && item.newState?.cost) || 0,
                  income: (item.newState && item.newState?.vendor) || ' ',
                }
              } else {
                item.oldState = JSON.parse(item?.oldState)
                pendingItem = {
                  pictureUrl: (item.oldState && item.oldState?.ProductPictureUrl) || ' ',
                  number: (item.oldState && item.oldState?.ProductNumber) || ' ',
                  description: (item.oldState && item.oldState?.ProductDescription) || ' ',
                  price: (item.oldState && item.oldState?.price) || 0,
                  income: (item.oldState && item.oldState?.incomeAccount) || ' ',
                }
              }
            }
            return (
              <div
                key={`recent-pending-${item.id}`}
                className="dashboard-item"
                onClick={() => {
                  history.push(`${viewLink}/${item.id}`)
                }}
              >
                <img
                  className="dashboard-item-image"
                  src={
                    getProductFeatureImage(pendingItem && pendingItem.pictureUrl) ||
                    '/assets/img/products/featured.svg'
                  }
                  alt="pic"
                />
                <div className="dashboard-item-info">
                  <div className="dashboard-item-info-title">
                    {pendingItem && pendingItem.description}
                  </div>
                  <div className="dashboard-item-info-description">
                    {pendingItem && pendingItem.number}
                  </div>
                </div>
                <div className="dashboard-item-action">
                  <HButton
                    className="dashboard-item-action-decline"
                    onClick={(ev) => {
                      ev.stopPropagation()
                      declineAction(item)
                    }}
                  >
                    Deny
                  </HButton>

                  <HButton
                    className="dashboard-item-action-approve"
                    onClick={(ev) => {
                      ev.stopPropagation()
                      approveAction(item)
                    }}
                  >
                    Approve
                  </HButton>
                </div>
              </div>
            )
          }
          if (itemType === 'Activity') {
            return (
              <div
                key={`recent-activity-${item.id}`}
                className="dashboard-item"
                onClick={() => {
                  if (item.objectType === 'Product') {
                    history.push(`products/view/${item.objectId}`)
                  } else if (item.objectType === 'BOMProduct') {
                    history.push(`boms/view/${item.objectId}`)
                  } else if (item.objectType === 'Part') {
                    history.push(`supplier-part-list/${item.objectId}`)
                  } else {
                    history.push(`${viewLink}/${item.id}`)
                  }
                }}
              >
                <img
                  className="dashboard-item-image dashboard-user-image"
                  src={item?.user?.userImage || '/assets/img/users/user-blue.svg'}
                  alt="pic"
                  onError={(ev) => {
                    // eslint-disable-next-line no-param-reassign
                    ev.target.onerror = null
                    // eslint-disable-next-line no-param-reassign
                    ev.target.src = '/assets/img/users/user-blue.svg'
                  }}
                />
                <div className="dashboard-item-info">
                  <div className="dashboard-item-info-title">
                    <span className="dashboard-user-name">
                      <Link
                        to={`users/view/${item?.user?.id}`}
                        onClick={(ev) => {
                          ev.stopPropagation()
                        }}
                      >{`${item?.user?.username} `}</Link>
                    </span>
                    <span className="dashboard-user-action">
                      {item.action === 'edit' && `modified a ${item.objectType}`}
                      {item.action === 'add' && `added new ${item.objectType}`}
                      {item.action === 'delete' && `deleted ${item.objectType}`}
                    </span>
                  </div>
                  <div className="dashboard-item-info-description">
                    <span className="dashboard-action-time">{getFormattedTime(item)}</span>
                  </div>
                </div>
              </div>
            )
          }
          return null
        })}
      </div>
      {viewLink && (
        <div className="dashboard-main-card-footer">
          <a href={viewLink}>VIEW ALL&nbsp;({itemCount})</a>
        </div>
      )}
    </div>
  )
}

DashboardCard.propTypes = {
  caption: PropTypes.string,
  itemCount: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  itemType: PropTypes.string,
  viewLink: PropTypes.string,
  approveAction: PropTypes.func,
  declineAction: PropTypes.func,
}

DashboardCard.defaultProps = {
  caption: '',
  itemCount: 0,
  items: [],
  itemType: 'Product',
  viewLink: '',
  approveAction: () => {},
  declineAction: () => {},
}
export default DashboardCard
