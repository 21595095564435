/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react'
import { Link, useLocation, useHistory, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { SIDEBAR_INITIALS } from 'consts/sidebar'
import { signoutRequest } from 'redux/actions/authActions'
import SignOutButton from './SignOutButton'
import HButton from '../Basic/HButton'
import './Sidebar.css'

const Sidebar = ({ user, lastUpdatedTime, signoutRequest }) => {
  const history = useHistory()
  const isAdmin = user.confirmed && user.isAdmin
  const { pathname } = useLocation()
  const [Buttons, setButtons] = useState([])
  const [pageLabel, setPageLabel] = useState('')
  const [minimized, setMinimized] = useState(false)

  useEffect(() => {
    setButtons(SIDEBAR_INITIALS)
  }, [])

  useEffect(() => {
    if (Buttons === SIDEBAR_INITIALS) {
      if (pathname === '/') {
        setPageLabel(SIDEBAR_INITIALS[0].label)
      }
      SIDEBAR_INITIALS.forEach((item) => {
        if (item.link !== '' && pathname.indexOf(item.link) !== -1) {
          if (item.type === 3 && Buttons.length > 0) {
            console.log('item', item, Buttons)
            collapseSubitems(item.pid)
          }
          setPageLabel(item.label)
        }
      })
    }
  }, [Buttons])

  useEffect(() => {
    if (pathname === '/') {
      setPageLabel(SIDEBAR_INITIALS[0].label)
    }
    SIDEBAR_INITIALS.forEach((item) => {
      if (item.link !== '' && pathname.indexOf(item.link) !== -1) {
        if (item.type === 3 && Buttons.length > 0) {
          console.log('item', item, Buttons)
          collapseSubitems(item.pid)
        }
        setPageLabel(item.label)
      }
    })
  }, [pathname])

  const getIndex = (link) => {
    let index = -1
    let lastCollapse = -1
    let selectFlag = false

    const temporaryButtons = Buttons.map((item, ind) => {
      if (item.type === 2 && selectFlag === false) {
        lastCollapse = ind
      }

      if (item.link !== '' && link.indexOf(item.link) >= 0) {
        index = ind
        if (item.type === 3) {
          selectFlag = true
        }
      }
      const newItem = item
      newItem.selected = false
      return newItem
    })

    if (selectFlag === true) {
      temporaryButtons[lastCollapse].selected = true
    }
    return index
  }

  const curLink = pathname
  getIndex(curLink)
  const signoutAction = () => {
    console.log('SignOut')
    signoutRequest()
  }

  const collapseSubitems = (index) => {
    let temporaryButtons = [...Buttons]
    let flag = false
    if (temporaryButtons.length === 0) {
      return
    }
    temporaryButtons = temporaryButtons.map((item) => {
      const newItem = { ...item }
      if (newItem.type === 2) {
        newItem.dropped = false
      }
      if (newItem.type === 3) {
        newItem.show = false
      }
      return newItem
    })
    temporaryButtons = temporaryButtons.map((item, itemIndex) => {
      const newItem = { ...item }
      if (itemIndex <= index) {
        return newItem
      }
      if (flag === true) {
        return newItem
      }
      if (item.type !== 3) {
        flag = true
        return newItem
      }
      newItem.show = true
      return newItem
    })
    temporaryButtons[index].dropped = true
    setButtons(temporaryButtons)
  }

  const minimizeSubitems = (index) => {
    let temporaryButtons = [...Buttons]
    let flag = false
    temporaryButtons = temporaryButtons.map((item, itemIndex) => {
      if (itemIndex <= index) {
        return item
      }
      if (flag === true) {
        return item
      }
      if (item.type !== 3) {
        flag = true
        return item
      }
      const newItem = item
      newItem.show = false
      return newItem
    })
    temporaryButtons[index].dropped = false
    setButtons(temporaryButtons)
  }

  const eraseDroppedMark = (index) => {
    console.log('droppedMark', index)
    if (index === -1) {
      const updatedButtons = Buttons.map((item) => {
        const newItem = { ...item }
        newItem.dropped = false
        return newItem
      })
      setButtons(updatedButtons)
    } else {
      setButtons(
        Buttons.map((item, i) => {
          if (i !== index) return item
          const newItem = item
          newItem.dropped = true
          return newItem
        })
      )
    }
  }

  return (
    <div
      className={
        !minimized ? `left-side-bar-main-box` : 'left-side-bar-main-box left-side-minimized'
      }
    >
      <div className="left-side-bar-main">
        <Link className="left-side-bar-logo-image" to="/">
          <img src="/assets/img/header/logo.png" alt="logo" width="220px" />
        </Link>
        <div className="left-side-bar-guide">
          <img src="/assets/img/sidebar/news.svg" alt="news" width="30px" height="20px" />
          <span>{pageLabel}</span>
        </div>
        <div className="left-side-bar-items">
          {Buttons &&
            Buttons.map((value, index) => {
              if (
                value.show &&
                (value.adminOnly === false || (value.adminOnly === true && isAdmin === true))
              ) {
                return (
                  <div
                    key={`sidebar-item-${index}`}
                    className={
                      value.link !== '' && curLink.indexOf(value.link) >= 0 && value.type !== 3
                        ? 'nav-button-box-selected'
                        : value.link !== '' && curLink.indexOf(value.link) >= 0 && value.type === 3
                        ? 'nav-button-inner-box-selected'
                        : value.type === 3
                        ? 'nav-button-inner-box'
                        : value.type === 2 && value.selected
                        ? 'nav-button-dropped'
                        : 'nav-button-box'
                    }
                    onClick={() => {
                      if (value.type === 1) {
                        eraseDroppedMark(-1)
                        history.push(value.link)
                      } else if (value.type === 3) {
                        eraseDroppedMark(index)
                        history.push(value.link)
                      } else if (value.type === 2) {
                        if (value.dropped) {
                          minimizeSubitems(index)
                        } else {
                          collapseSubitems(index)
                        }
                      }
                    }}
                  >
                    <span className="nav-button-caption-container">
                      <span>{value.caption}</span>{' '}
                    </span>
                    {value.addLink && (
                      <div className="nav-button-box-minimize">
                        {value.link && curLink.indexOf(value.link) >= 0 ? (
                          <button
                            className="nav-button-box-plus-selected"
                            onClick={(ev) => {
                              ev.stopPropagation()
                              history.push(value.addLink)
                            }}
                          >
                            +
                          </button>
                        ) : (
                          <button
                            className="nav-button-box-plus"
                            onClick={(ev) => {
                              ev.stopPropagation()
                              history.push(value.addLink)
                            }}
                          >
                            +
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                )
              }
            })}
        </div>
      </div>
      <div className="left-side-bar-bottom">
        <div className="horizontal-separator" />
        <SignOutButton signoutAction={signoutAction} />
      </div>
      <div
        className="left-side-bar-minimize-button"
        onClick={() => {
          setMinimized(!minimized)
        }}
      >
        {!minimized ? (
          <img src="/assets/img/sidebar/left.svg" alt="logo" width="20px" />
        ) : (
          <img src="/assets/img/sidebar/right.svg" alt="logo" width="20px" />
        )}
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  user: state.user && state.user.user,
})

const mapDispatchToProps = {
  signoutRequest,
}

Sidebar.propTypes = {
  signoutRequest: PropTypes.func.isRequired,
  user: PropTypes.shape({
    username: PropTypes.string,
    confirmed: PropTypes.bool,
    isAdmin: PropTypes.bool,
  }).isRequired,
  lastUpdatedTime: PropTypes.string,
}

Sidebar.defaultProps = {
  lastUpdatedTime: 'March 5th 2019 - 2:35PM CST',
}

const connectedHeader = connect(mapStateToProps, mapDispatchToProps)(Sidebar)
export default withRouter(connectedHeader)
