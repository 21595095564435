import React, { useEffect, useState } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { store } from 'react-notifications-component'
import LoadingAnimation from 'components/LoadingAnimation'
import Caption from 'components/Users/Caption'
import HSingleSelect from 'components/common/Basic/HSingleSelect'
import HInput from 'components/common/Basic/HInput'
import HForm from 'components/common/Basic/HForm'
import HButton from 'components/common/Basic/HButton'
import EditViewActions from 'components/common/Filter/EditViewActions'
import EditViewSettingItem from 'components/Products/EditViewSettingItem'
import { addUser } from 'api/userApi'
import AddMedia from 'components/common/Filter/AddMedia'
import { isError, userValidationCheck } from 'utils/formatter'

import './index.css'

const AddUser = ({ user }) => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [newUser, setNewUser] = useState({
    confirmed: true,
    password: '',
    username: '',
    password: '',
    isAdmin: false,
  })
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [showError, setShowError] = useState(false)

  const backToList = () => {
    history.push('/users/view')
  }
  const backToDashboard = () => {
    history.push('/')
  }

  const SaveUser = async () => {
    setLoading(true)
    try {
      const response = await addUser(newUser)
      console.log(response)
      if (response && response.error) {
        store.addNotification({
          message: `${response.error}.\n Sorry, we are not able to process your request.`,
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 5000,
          },
        })
      } else if (response) {
        store.addNotification({
          message: 'Successfully Added.',
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 5000,
          },
        })
        setTimeout(() => {
          backToList()
        }, 3000)
      }
    } catch (ev) {
      store.addNotification({
        message: 'Sorry, we are not able to process your request now, please try again later.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 5000,
        },
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    // Loading Data
    setLoading(true)
    if (user && user.confirmed && user.isAdmin) {
      setErrorMessage('')
    } else {
      store.addNotification({
        message: 'Successfully Added. Redirect in 3 seconds.',
        type: 'success',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 5000,
        },
      })
      backToDashboard()
    }
    setLoading(false)
  }, [])

  const onSave = () => {
    setShowError(true)
    if (newUser.password !== confirmPassword) {
      store.addNotification({
        message: 'Sorry, Complete User profile and try again later.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 5000,
        },
      })
      return
    }
    if (!userValidationCheck(newUser)) {
      store.addNotification({
        message: 'Complete User Profile and try again.',
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 2000,
        },
      })
      return
    }
    SaveUser()
  }

  const onClear = () => {}
  const onDelete = () => {}

  return (
    <div className="add-user">
      {loading && <LoadingAnimation />}
      {errorMessage && <div className="medium-size-text">{errorMessage}</div>}
      {errorMessage === '' && (
        <>
          <div className="add-user-list">
            <div className="add-user-list-title">
              <Caption caption="New User Entry" primary />
            </div>
            <div className="horizontal-separator" />
            <div className="add-user-edit-view">
              <HForm className="add-user-item-form" autocomplete="off">
                <EditViewSettingItem
                  caption="User Name:"
                  errorMessage={showError && isError(newUser && newUser.username, 'string')}
                >
                  <HInput
                    className="edit-view-input-style"
                    name="add_user_name"
                    value={newUser.username}
                    onChange={(ev) => {
                      const tempUser = { ...newUser }
                      tempUser.username = ev.currentTarget.value
                      setNewUser(tempUser)
                    }}
                    autocomplete="new-username"
                    error={showError && isError(newUser && newUser.username, 'string')}
                  />{' '}
                </EditViewSettingItem>

                <EditViewSettingItem
                  caption="User Role:"
                  errorMessage={showError && isError(newUser && newUser.isAdmin, 'string')}
                  clearAction={() => {
                    onClear()
                  }}
                >
                  <HSingleSelect
                    name="userRole"
                    secondary
                    values={[
                      { value: true, label: 'Admin' },
                      { value: false, label: 'User' },
                    ]}
                    defaultValue={
                      newUser.isAdmin
                        ? { value: true, label: 'Admin' }
                        : { value: false, label: 'User' }
                    }
                    onChange={(ev) => {
                      const tempUser = { ...newUser }
                      tempUser.isAdmin = ev.value
                      setNewUser(tempUser)
                    }}
                    isSearchable={false}
                    error={showError && isError(newUser && newUser.isAdmin, 'string')}
                  />
                </EditViewSettingItem>

                <EditViewSettingItem
                  caption="Email:"
                  errorMessage={showError && isError(newUser && newUser.email, 'email')}
                >
                  <HInput
                    className="edit-view-input-style"
                    name="email"
                    value={newUser.email}
                    onChange={(ev) => {
                      const tempUser = { ...newUser }
                      tempUser.email = ev.currentTarget.value
                      setNewUser(tempUser)
                    }}
                    error={showError && isError(newUser && newUser.email, 'string')}
                  />
                </EditViewSettingItem>

                <EditViewSettingItem
                  caption="Password:"
                  errorMessage={showError && isError(newUser && newUser.password, 'string')}
                >
                  <HInput
                    className="edit-view-input-style"
                    name="add_user_password"
                    value={newUser.password}
                    onChange={(ev) => {
                      const tempUser = { ...newUser }
                      tempUser.password = ev.currentTarget.value
                      setNewUser(tempUser)
                    }}
                    autocomplete="new-password"
                    inputType="password"
                    error={showError && isError(newUser && newUser.password, 'string')}
                  />
                </EditViewSettingItem>

                <EditViewSettingItem
                  caption="Confirm Password:"
                  errorMessage={
                    newUser.password === confirmPassword ? '' : 'Password does not match.'
                  }
                >
                  <HInput
                    className="edit-view-input-style"
                    name="add_confirm_password"
                    value={confirmPassword}
                    onChange={(ev) => {
                      setConfirmPassword(ev.currentTarget.value)
                    }}
                    inputType="password"
                    error={newUser.password === confirmPassword ? '' : 'Password does not match.'}
                  />
                </EditViewSettingItem>

                <EditViewSettingItem
                  caption="Confirmed:"
                  errorMessage={showError && isError(newUser && newUser.confirmed, 'string')}
                >
                  <HSingleSelect
                    name="confirmed"
                    secondary
                    values={[
                      { value: true, label: 'True' },
                      { value: false, label: 'False' },
                    ]}
                    defaultValue={
                      newUser.confirmed
                        ? { value: true, label: 'True' }
                        : { value: false, label: 'False' }
                    }
                    onChange={(ev) => {
                      const tempUser = { ...newUser }
                      tempUser.confirmed = ev.value
                      setNewUser(tempUser)
                    }}
                    isSearchable={false}
                    error={showError && isError(newUser && newUser.confirmed, 'string')}
                  />
                </EditViewSettingItem>
                <div className="user-detail-last-line">
                  <HButton
                    className="h-save-button"
                    onClick={() => {
                      onSave()
                    }}
                  >
                    Save
                  </HButton>

                  <HButton
                    className="h-delete-button"
                    onClick={() => {
                      onDelete()
                    }}
                  >
                    Cancel
                  </HButton>
                </div>
              </HForm>
            </div>
          </div>
          <div className="add-user-filters">
            <AddMedia
              caption="Set User Profile"
              pictureUrls={JSON.stringify(newUser.userImage ? [newUser.userImage] : [])}
              setPictureUrls={(picUrls) => {
                const urls = JSON.parse(picUrls)

                const updateUser = { ...newUser }
                if (urls.length === 0) {
                  updateUser.userImage = ''
                } else if (urls.length === 1) {
                  updateUser.userImage = urls && urls[0]
                } else if (urls.length >= 2) {
                  updateUser.userImage = urls && urls[1]
                }

                setNewUser(updateUser)
              }}
            />
          </div>
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.user && state.user.user,
})
AddUser.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    isAdmin: PropTypes.bool,
    confirmed: PropTypes.bool,
  }).isRequired,
}

const connectedHeader = connect(mapStateToProps, null)(AddUser)
export default withRouter(connectedHeader)
