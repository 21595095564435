export const SIDEBAR_INITIALS = [
  {
    type: 1,
    caption: 'Dashboard',
    link: '/dashboard',
    show: true,
    selected: false,
    adminOnly: false,
    label:
      'Have you taken a demo? Our support team is available to help! Email support@getstorecat.com for help.',
  },
  {
    type: 1,
    caption: 'My Products',
    link: '/products/view',
    show: true,
    selected: false,
    adminOnly: false,
    label:
      'Products are the customer-facing records which can be exported to your website or CRM. Make sure it looks good, and don’t forget to add an appropriate markup to the product.',
    addLink: '/products/add',
  },
  {
    type: 1,
    caption: 'My BOMs',
    link: '/boms/view',
    show: true,
    selected: false,
    adminOnly: false,
    label:
      'A Bill of Materials is a group of products sold as one. A product must first be created from a supplier-item, and assigned a price, before it can be used as a BOM. ',
    addLink: '/boms/new-bom',
  },
  {
    type: 1,
    caption: 'Supplier Items',
    link: '/supplier-part-list',
    show: true,
    selected: false,
    adminOnly: false,
    label:
      'Supplier-Items are the supplier raw materials which you sell as products. Add a Supplier and contact support to set up automated data feeds. Once your supplier-items are created, you can sell them to customers as products!',
    addLink: '/new-part',
  },
  {
    type: 1,
    caption: 'Suppliers',
    link: '/suppliers',
    show: true,
    selected: false,
    adminOnly: true,
    label:
      ' Configure your suppliers here. These will automatically be synced to your CDN and website, and can be used to calculate shipping costs in your ERP.',
    addLink: '/new-supplier',
  },
  {
    type: 1,
    caption: 'Approve / Decline',
    link: '/approvals/view',
    show: true,
    selected: false,
    adminOnly: true,
    label: 'When non-admin users make changes, they will appear here for approval.',
  },
  {
    type: 1,
    caption: 'Users',
    link: '/users/view',
    show: true,
    selected: false,
    adminOnly: true,
    label: 'Invite your team to participate in the process.',
    addLink: '/users/new',
  },
]
