/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import LoadingAnimation from 'components/LoadingAnimation'
import Caption from 'components/Products/Caption'
import AddMedia from 'components/common/Filter/AddMedia'
import HSingleSelect from 'components/common/Basic/HSingleSelect'
import HInput from 'components/common/Basic/HInput'
import HTextInput from 'components/common/Basic/HTextInput'
import HSearchPartInput from 'components/common/Basic/HSearchPartInput'
import HButton from 'components/common/Basic/HButton'
import PRODUCTS, { INITIAL_PRODUCT } from 'consts/products'
import HForm from 'components/common/Basic/HForm'
import EditViewSettingItem from 'components/Products/EditViewSettingItem'
import { addProduct, getUomList } from 'api/productApi'
import { removeImportData } from 'redux/actions/importAction'
import { store } from 'react-notifications-component'
import { isError, productValidationCheck } from 'utils/formatter'
import { PartPreviewSmall } from 'components/Parts/PartPreview'
import PartUomView from 'components/Parts/PartUom'

import './index.css'

const AddProduct = ({ importData, removeImportData }) => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [newProduct, setNewProduct] = useState(INITIAL_PRODUCT)
  const [showError, setShowError] = useState(false)
  const [linkedPart, setLinkedPart] = useState(null)
  const [globalUoms, setGlobalUoms] = useState([])
  const [productUoms, setProductUoms] = useState([])

  const backToList = () => {
    history.push('/products/view')
  }
  const fetchDatafromServer = async () => {
    setLoading(true)
    try {
      const uoms = await getUomList()
      if (uoms) {
        setGlobalUoms(
          uoms.map((item) => {
            return item && item.name
          })
        )
      } else {
        setGlobalUoms([])
      }
    } catch (error) {
      console.log('GetUOMs error', error)
      store.addNotification({
        message: 'Sorry, we are not able to process your request now. Please try again later.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      })
    }
    setLoading(false)
  }
  useEffect(() => {
    fetchDatafromServer()
    if (importData && importData.type === 'Part') {
      const updateProduct = { ...INITIAL_PRODUCT }
      updateProduct.price = importData.price
      updateProduct.uom = importData.uom
      updateProduct.weight = importData.weight
      updateProduct.weightUom = importData.weightUom
      updateProduct.width = importData.width
      updateProduct.height = importData.height
      updateProduct.length = importData.length
      updateProduct.sizeUom = importData.sizeUom
      updateProduct.cartonType = importData.cartonType
      updateProduct.cartonCount = importData.cartonCount

      updateProduct.productDescription = importData.productDescription
      updateProduct.productDetails = importData.productDetails
      updateProduct.productPictureUrl = importData.productPictureUrl

      updateProduct.partNumber = importData.partNumber
      updateProduct.partUom = importData.partUom

      setLinkedPart({ ...importData.linked, uom: { ...importData.selectedUom } })
      setNewProduct(updateProduct)
      removeImportData()
    }
  }, [])

  const onSave = () => {
    if (!productValidationCheck(newProduct)) {
      setShowError(true)
      store.addNotification({
        message: 'Sorry, please complete the product and try again later',
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 2000,
        },
      })
      return
    }
    setLoading(true)
    const fetchData = async () => {
      try {
        const response = await addProduct(newProduct)
        if (response && response.error) {
          console.log(response.error.message)
          store.addNotification({
            message: 'Sorry, we are not able to process your request now. Please try again later.',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 5000,
            },
          })
        } else if (response) {
          store.addNotification({
            message: `${response.message}. Redirect in 3 seconds. `,
            type: 'success',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 5000,
            },
          })
          setTimeout(() => {
            backToList()
          }, 3000)
        }
      } catch (ev) {
        console.log('AddProduct error', ev)
        store.addNotification({
          message: 'Sorry, we are not able to process your request now. Please try again later.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 5000,
          },
        })
      }
      setLoading(false)
    }
    fetchData()
  }

  const onClear = () => {
    setNewProduct(INITIAL_PRODUCT)
  }
  const onDelete = () => {
    backToList()
  }
  return (
    <div className="detail-container">
      <div className="add-product">
        {loading && <LoadingAnimation />}
        <div className="add-product-list">
          <div className="add-product-list-title">
            <Caption caption="New Product Entry" primary />
          </div>
          <div className="horizontal-separator" />
          <div className="add-product-edit-view">
            <HForm className="add-product-item-form">
              {linkedPart === null && (
                <EditViewSettingItem caption="Select Supplier-Item:">
                  <HSearchPartInput
                    setSelectedPart={(part) => {
                      const updateProduct = { ...newProduct }
                      updateProduct.partNumber = part.id
                      updateProduct.partUom = part.id
                      updateProduct.price = (part.uom.cost * 5) / 4
                      updateProduct.productPictureUrl = part.partPictureUrl
                      updateProduct.productDescription = part.description
                      updateProduct.productDetails = part.itemDetails
                      updateProduct.uom = part.uom.uom
                      setNewProduct(updateProduct)
                      setLinkedPart(part)
                      setProductUoms(
                        part.UOMs.map((uom) => {
                          return uom && uom.uom
                        })
                      )
                    }}
                  />
                </EditViewSettingItem>
              )}
              <EditViewSettingItem
                caption="Linked Supplier Item"
                bordered
                errorMessage={
                  showError && isError((newProduct && newProduct.partNumber) || '', 'number')
                }
                deleteAction={
                  linkedPart
                    ? () => {
                        setLinkedPart(null)
                        setProductUoms([])
                      }
                    : null
                }
              >
                {linkedPart && (
                  <PartPreviewSmall
                    part={linkedPart}
                    link={`../supplier-part-list/${linkedPart.id}`}
                  />
                )}
                {linkedPart && <PartUomView uom={linkedPart.uom} />}
                {!linkedPart && (
                  <div
                    style={{
                      display: 'flex',
                      height: '125px',
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: '#000000',
                      fontSize: '16px',
                    }}
                  >
                    No Linked Item
                  </div>
                )}
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Product Description:"
                errorMessage={
                  showError &&
                  isError((newProduct && newProduct.productDescription) || '', 'string')
                }
                clearAction={() => {
                  onClear()
                }}
              >
                <HTextInput
                  className="add-product-text-input-style"
                  name="product_description"
                  value={newProduct.productDescription}
                  onChange={(ev) => {
                    const updateProduct = { ...newProduct }
                    updateProduct.productDescription = ev.currentTarget.value
                    setNewProduct(updateProduct)
                  }}
                  error={
                    showError &&
                    isError((newProduct && newProduct.productDescription) || '', 'string')
                  }
                  rows={3}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Product Details:">
                <HTextInput
                  className="add-product-text-input-style"
                  name="product_details"
                  value={newProduct.productDetails}
                  onChange={(ev) => {
                    const updateProduct = { ...newProduct }
                    updateProduct.productDetails = ev.currentTarget.value
                    setNewProduct(updateProduct)
                  }}
                  rows={5}
                />
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Size:"
                errorMessage={showError && isError((newProduct && newProduct.uom) || '', 'string')}
              >
                <HSingleSelect
                  name="uom"
                  secondary
                  values={
                    productUoms.length
                      ? productUoms.map((type) => ({
                          value: type,
                          label: type,
                        }))
                      : globalUoms.map((type) => ({
                          value: type,
                          label: type,
                        }))
                  }
                  value={
                    newProduct && {
                      value: newProduct.uom,
                      label: newProduct.uom,
                    }
                  }
                  onChange={(ev) => {
                    const updateProduct = { ...newProduct }
                    updateProduct.uom = ev.value
                    setNewProduct(updateProduct)
                  }}
                  isSearchable={true}
                  error={showError && isError((newProduct && newProduct.uom) || '', 'string')}
                />
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Price($):"
                errorMessage={showError && isError(newProduct && newProduct.price, 'decimal')}
              >
                <HInput
                  className="add-product-input-style"
                  name="price"
                  inputType="number"
                  value={newProduct.price}
                  onChange={(ev) => {
                    const updateProduct = { ...newProduct }
                    updateProduct.price = ev.currentTarget.value
                    setNewProduct(updateProduct)
                  }}
                  error={showError && isError(newProduct && newProduct.price, 'decimal')}
                />
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Default Vendor:"
                errorMessage={
                  showError && isError(newProduct && newProduct.defaultVendor, 'string')
                }
              >
                <HSingleSelect
                  name="isDefaultVendor"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                  value={
                    newProduct && {
                      value: newProduct.defaultVendor,
                      label: newProduct.defaultVendor ? 'TRUE' : 'FALSE',
                    }
                  }
                  onChange={(item) => {
                    const updateProduct = { ...newProduct }
                    updateProduct.defaultVendor = item.value
                    setNewProduct(updateProduct)
                  }}
                  error={showError && isError(newProduct && newProduct.defaultVendor, 'string')}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Active:">
                <HSingleSelect
                  name="isActive"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                  defaultValue={
                    newProduct && {
                      value: newProduct.active,
                      label: newProduct.active ? 'TRUE' : 'FALSE',
                    }
                  }
                  onChange={(item) => {
                    const updateProduct = { ...newProduct }
                    updateProduct.active = item.value
                    setNewProduct(updateProduct)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Taxable:">
                <HSingleSelect
                  name="isTaxable"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                  defaultValue={
                    newProduct && {
                      value: newProduct.taxable,
                      label: newProduct.taxable ? 'TRUE' : 'FALSE',
                    }
                  }
                  onChange={(item) => {
                    const updateProduct = { ...newProduct }
                    updateProduct.taxable = item.value
                    setNewProduct(updateProduct)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="ComboBox:">
                <HSingleSelect
                  name="isComboBox"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                  defaultValue={
                    newProduct && {
                      value: newProduct.comboBox,
                      label: newProduct.comboBox ? 'TRUE' : 'FALSE',
                    }
                  }
                  onChange={(item) => {
                    const updateProduct = { ...newProduct }
                    updateProduct.comboBox = item.value
                    setNewProduct(updateProduct)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="AllowSize:">
                <HSingleSelect
                  name="isAllowUOM"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                  defaultValue={
                    newProduct && {
                      value: newProduct.allowUom,
                      label: newProduct.allowUom ? 'TRUE' : 'FALSE',
                    }
                  }
                  onChange={(item) => {
                    const updateProduct = { ...newProduct }
                    updateProduct.allowUom = item.value
                    setNewProduct(updateProduct)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Product URL:">
                <HInput
                  className="edit-view-input-style"
                  name="product_url"
                  value={newProduct.productUrl}
                  onChange={(ev) => {
                    const updateProduct = { ...newProduct }
                    updateProduct.productUrl = ev.currentTarget.value
                    setNewProduct(updateProduct)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="ProductUPC:">
                <HInput
                  className="add-product-input-style"
                  name="product_upc"
                  value={newProduct.productUpc}
                  onChange={(ev) => {
                    const updateProduct = { ...newProduct }
                    updateProduct.productUpc = ev.currentTarget.value
                    setNewProduct(updateProduct)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="ProductSKU: (Warehouse Entry Only)">
                <HInput
                  className="add-product-input-style"
                  name="product_sku"
                  value={newProduct.productSku}
                  onChange={(ev) => {
                    const updateProduct = { ...newProduct }
                    updateProduct.productSku = ev.currentTarget.value
                    setNewProduct(updateProduct)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Income Account Name:">
                <HInput
                  className="edit-view-input-style"
                  name="income_account"
                  value={newProduct.incomeAccount || ''}
                  onChange={(ev) => {
                    const updateProduct = { ...newProduct }
                    updateProduct.incomeAccount = ev.currentTarget.value
                    setNewProduct(updateProduct)
                  }}
                  // error={
                  //   showError && isError((newProduct && newProduct.incomeAccount) || '', 'string')
                  // }
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Expense Account Name:">
                <HInput
                  className="edit-view-input-style"
                  name="expense_account"
                  value={newProduct.expenseAccountName || ''}
                  onChange={(ev) => {
                    const updateProduct = { ...newProduct }
                    updateProduct.expenseAccountName = ev.currentTarget.value
                    setNewProduct(updateProduct)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Weight:">
                <HInput
                  className="add-product-input-style"
                  name="weight"
                  inputType="number"
                  value={newProduct.weight}
                  onChange={(ev) => {
                    const updateProduct = { ...newProduct }
                    updateProduct.weight = ev.currentTarget.value
                    setNewProduct(updateProduct)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="WeightSize:">
                <HSingleSelect
                  name="weightUOM"
                  secondary
                  values={PRODUCTS.WEIGHT_UOM.map((uom) => ({
                    value: uom,
                    label: uom,
                  }))}
                  defaultValue={
                    newProduct && {
                      value: newProduct.weightUom,
                      label: newProduct.weightUom,
                    }
                  }
                  onChange={(item) => {
                    const updateProduct = { ...newProduct }
                    updateProduct.weightUom = item.value
                    setNewProduct(updateProduct)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Width:"
                clearAction={() => {
                  onClear()
                }}
              >
                <HInput
                  className="add-product-input-style"
                  name="width"
                  inputType="number"
                  value={newProduct.width}
                  onChange={(ev) => {
                    const updateProduct = { ...newProduct }
                    updateProduct.width = ev.currentTarget.value
                    setNewProduct(updateProduct)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Height:">
                <HInput
                  className="add-product-input-style"
                  name="height"
                  inputType="number"
                  value={newProduct.height}
                  onChange={(ev) => {
                    const updateProduct = { ...newProduct }
                    updateProduct.height = ev.currentTarget.value
                    setNewProduct(updateProduct)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Length:">
                <HInput
                  className="add-product-input-style"
                  name="Length"
                  value={newProduct.length}
                  onChange={(ev) => {
                    const updateProduct = { ...newProduct }
                    updateProduct.length = ev.currentTarget.value
                    setNewProduct(updateProduct)
                  }}
                />
              </EditViewSettingItem>
              <EditViewSettingItem caption="SizeUnit:">
                <HSingleSelect
                  name="sizeUOM"
                  secondary
                  values={PRODUCTS.SIZE_UOM.map((uom) => ({
                    value: uom,
                    label: uom,
                  }))}
                  defaultValue={
                    newProduct && {
                      value: newProduct.sizeUom,
                      label: newProduct.sizeUom,
                    }
                  }
                  onChange={(item) => {
                    const updateProduct = { ...newProduct }
                    updateProduct.sizeUom = item.value
                    setNewProduct(updateProduct)
                  }}
                />
              </EditViewSettingItem>
              <EditViewSettingItem caption="DefaultFlag:">
                <HInput
                  className="add-product-input-style"
                  name="defaultFlag"
                  value={newProduct.defaultFlag}
                  onChange={(ev) => {
                    const updateProduct = { ...newProduct }
                    updateProduct.defaultFlag = ev.currentTarget.value
                    setNewProduct(updateProduct)
                  }}
                />
              </EditViewSettingItem>
              <EditViewSettingItem
                caption="Carton Count:"
                error={showError && isError(newProduct && newProduct.cartonCount, 'string')}
              >
                <HInput
                  className="add-product-input-style"
                  name="carton_count"
                  inputType="number"
                  value={newProduct.cartonCount}
                  onChange={(ev) => {
                    const updateProduct = { ...newProduct }
                    updateProduct.cartonCount = ev.currentTarget.value
                    setNewProduct(updateProduct)
                  }}
                  error={showError && isError(newProduct && newProduct.cartonCount, 'string')}
                />
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Carton Type:"
                error={showError && isError(newProduct && newProduct.cartonType, 'string')}
              >
                <HInput
                  className="add-product-input-style"
                  name="carton_type"
                  value={newProduct.cartonType}
                  onChange={(ev) => {
                    const updateProduct = { ...newProduct }
                    updateProduct.cartonType = ev.currentTarget.value
                    setNewProduct(updateProduct)
                  }}
                  error={showError && isError(newProduct && newProduct.cartonType, 'string')}
                />
              </EditViewSettingItem>
              <div className="add-product-last-line">
                <HButton
                  className="h-save-button"
                  onClick={() => {
                    onSave()
                  }}
                >
                  Save
                </HButton>

                <HButton
                  className="h-delete-button"
                  onClick={() => {
                    onDelete()
                  }}
                >
                  Cancel
                </HButton>
              </div>
            </HForm>
          </div>
        </div>
        <div className="add-product-filters">
          <AddMedia
            pictureUrls={newProduct.productPictureUrl}
            setPictureUrls={(urls) => {
              const updateProduct = { ...newProduct }
              updateProduct.productPictureUrl = urls
              setNewProduct(updateProduct)
            }}
          />
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  importData: state.importData,
})

const mapDispatchToProps = {
  removeImportData,
}

AddProduct.propTypes = {
  importData: PropTypes.shape().isRequired,
  removeImportData: PropTypes.func.isRequired,
}

const connectedAddProduct = connect(mapStateToProps, mapDispatchToProps)(AddProduct)

export default withRouter(connectedAddProduct)
