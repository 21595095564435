import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'
import HSearchProductInput from 'components/common/Basic/HSearchProductInput'
import { ProductPreviewSmall } from 'components/Products/ProductPreview'
import './index.css'

const BomChildModal = ({ onHide, bomChild, okAction, cancelAction }) => {
  const [linkedProduct, setLinkedProduct] = useState((bomChild && bomChild.product) || '')
  const [linkedProductCost, setLinkedProductCost] = useState((bomChild && bomChild.price) || '0')
  const [linkedProductQuantity, setLinkedProductQuantity] = useState(
    (bomChild && bomChild.quantity) || '1'
  )
  return (
    <Modal size="lg" show onHide={onHide}>
      <Modal.Header>
        <div className="px-2 mb-2">
          <h2>Please Select Child</h2>
        </div>
      </Modal.Header>
      <Modal.Body className="modal--quick-view">
        <div className="modal--body">
          <div className="modal-search-section">
            <HSearchProductInput
              initialProductCost={(bomChild && bomChild.price) || '0'}
              initialProductQuantity={(bomChild && bomChild.quantity) || '1'}
              setSelectedProduct={(product) => {
                setLinkedProduct(product)
              }}
              setSelectedProductQuantity={(quantity) => {
                setLinkedProductQuantity(quantity)
              }}
              setSelectedProductCost={(cost) => {
                setLinkedProductCost(cost)
              }}
            />
          </div>
          <div className="modal-product-section">
            <ProductPreviewSmall product={linkedProduct} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary lg"
          type="button"
          onClick={() => {
            okAction(linkedProduct, linkedProductCost, linkedProductQuantity)
          }}
        >
          OK
        </Button>
        <Button variant="primary lg" type="button" onClick={cancelAction}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

BomChildModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  bomChild: PropTypes.shape(),
  okAction: PropTypes.func,
  cancelAction: PropTypes.func,
}
BomChildModal.defaultProps = {
  bomChild: {},
  okAction: () => {},
  cancelAction: () => {},
}
export default BomChildModal
