import React, { useEffect, useState } from 'react'
import { withRouter, useParams, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import LoadingAnimation from 'components/LoadingAnimation'
import Caption from 'components/Parts/Caption'
import AddMedia from 'components/common/Filter/AddMedia'
import HSingleSelect from 'components/common/Basic/HSingleSelect'
import HInput from 'components/common/Basic/HInput'
import HTextInput from 'components/common/Basic/HTextInput'
import HForm from 'components/common/Basic/HForm'
import HButton from 'components/common/Basic/HButton'
import HUOMTable from 'components/common/Basic/HUOMTable'
import { PartSalesforceItems } from 'components/common/Filter/SalesforceItems'
import EditViewActions from 'components/common/Filter/EditViewActions'
import EditViewSettingItem from 'components/Products/EditViewSettingItem'
import PartPreview from 'components/Parts/PartPreview'
import UomSelectionModal from 'components/common/Modal/UomSelectionModal'
import DeleteWarningModal from 'components/common/Modal/DeleteWarningModal'
import { getPart, updatePart, deletePart, getActiveSuppliers, getUomList } from 'api/productApi'
import { INITIAL_PART } from 'consts/parts'
import { toDecimal, isError, partValidationCheck } from 'utils/formatter'

import { setImportData } from 'redux/actions/importAction'
import { store } from 'react-notifications-component'

import './index.css'

const PartDetail = ({ setImportData }) => {
  const { id } = useParams()
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [showPart, setShowPart] = useState({})
  const [orgPart, setOrgPart] = useState({})
  const [isModalShown, showModal] = useState(false)
  const [captionText, setCaptionText] = useState('View / Import Manufacturer Item')
  const [globalManufacturers, setGlobalManufacturers] = useState([])
  const [globalVendors, setGlobalVendors] = useState([])
  const [globalUoms, setGlobalUoms] = useState([])
  const [deleteWarning, showDeleteWarning] = useState(false)
  const [undoWarning, showUndoWarning] = useState(false)
  const [changes, setChanges] = useState({})

  const backToList = () => {
    history.push('/supplier-part-list')
  }

  useEffect(() => {
    if (!id) {
      backToList()
    }
    setLoading(true)
    const getData = async () => {
      try {
        const { manufacturers, vendors } = await getActiveSuppliers()
        const curPart = await getPart(id)
        console.log(curPart)
        setGlobalManufacturers(manufacturers)
        setGlobalVendors(vendors)

        if (curPart && curPart.error) {
          console.log(curPart.error)
          store.addNotification({
            message: 'Sorry, we are not able to process your request now. Please try again later.',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 4000,
            },
          })
        } else {
          setShowPart({ ...INITIAL_PART, ...curPart })
          const orgUoms = curPart.UOMs.map((uom) => {
            return { ...uom }
          })
          setOrgPart({ ...curPart, UOMs: [...orgUoms] })
          setLoading(false)
        }
      } catch (error) {
        console.log('ViewPart error', error)
        store.addNotification({
          message: 'Sorry, we are not able to process your request now. Please try again later.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 4000,
          },
        })
      }

      try {
        const uoms = await getUomList()
        if (uoms) {
          setGlobalUoms(
            uoms.map((item) => {
              return item && item.name
            })
          )
        } else {
          setGlobalUoms([])
        }
      } catch (error) {
        console.log('GetUOMs error', error)
        store.addNotification({
          message: 'Sorry, we are not able to process your request now. Please try again later.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 4000,
          },
        })
      }
    }
    getData()
  }, [id])

  useEffect(() => {
    console.log('--> org changed', orgPart)
  }, [orgPart])

  useEffect(() => {
    if (showPart && showPart.description) {
      if (showPart.description.length <= 20) {
        setCaptionText(showPart.description)
      } else {
        setCaptionText(`${showPart.description.slice(0, 20)} ...`)
      }
    }
  }, [showPart])

  const onSave = () => {
    if (!partValidationCheck(showPart)) {
      console.log('onSave partValidationCheck failed')
      store.addNotification({
        message: 'Complete Item and try again.',
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 2000,
        },
      })
      return
    }

    if (showPart === orgPart) {
      store.addNotification({
        message: 'Sorry, no changes were detected. Please try again after changing the details.',
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      })
    } else {
      console.log('Save', showPart, orgPart)
      setLoading(true)
      const editProduct = async () => {
        const response = await updatePart(orgPart, showPart)
        console.log(response)
        if (response && response.error) {
          console.log(response.error.message)
          store.addNotification({
            message: 'Sorry, we are not able to process your request now. please try again later.',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 4000,
            },
          })
        } else if (response) {
          store.addNotification({
            message: `${response.message}`,
            type: 'success',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 5000,
            },
          })
        }
        setLoading(false)
      }
      editProduct()
    }
  }

  const onClear = () => {
    console.log('Clear')
    setShowPart({ ...INITIAL_PART, id: showPart.id })
  }

  const onDelete = () => {
    console.log('Delete')
    setLoading(true)
    const onDeleteAction = async () => {
      const response = await deletePart(showPart.id)
      console.log(response)
      if (response && response.error) {
        console.log(response.error.message)
        store.addNotification({
          message: 'Sorry, we are not able to process your request now. Please try again later.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 4000,
          },
        })
      } else if (response) {
        console.log(response)
        store.addNotification({
          message: `${response.message}. Redirect in 3 seconds. `,
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 5000,
          },
        })
        setTimeout(() => {
          backToList()
        }, 3000)
      }
      setLoading(false)
    }
    onDeleteAction()
  }

  const logChange = (category) => {
    const tempChanges = { ...changes }
    tempChanges[category] = true
    setChanges(tempChanges)
  }

  const onUndo = () => {
    console.log('orgPart', orgPart)
    const orgUoms = orgPart.UOMs.map((uom) => {
      return { ...uom }
    })
    setShowPart({ ...orgPart, UOMs: [...orgUoms] })
    setChanges({})
  }

  const onImportAction = (selectedUom) => {
    console.log('Import Action', selectedUom)
    setImportData({
      type: 'Part',
      vendor: showPart.vendor,
      price: selectedUom.cost,
      uom: selectedUom.uom,
      weight: selectedUom.weight,
      weightUom: selectedUom.weightUom,
      width: selectedUom.width,
      height: selectedUom.height,
      length: selectedUom.length,
      sizeUom: selectedUom.sizeUom,
      cartonType: selectedUom.cartonType,
      cartonCount: selectedUom.cartonCount,
      productDescription: showPart.description,
      productDetails: showPart.itemDetails,
      manufacturerId: showPart.manufacturerId,
      vendorNumber: showPart.vendorPartNumber,
      manufacturerName: showPart.manufacturerName,
      productPictureUrl: showPart.partPictureUrl,
      partNumber: showPart.id,
      partUom: selectedUom.id,
      linked: { ...showPart },
      selectedUom,
    })
    history.push('/products/add')
  }

  const onExportAction = () => {
    console.log('export action')
  }

  return (
    <div className="detail-container">
      {isModalShown && (
        <UomSelectionModal
          onHide={() => {
            showModal(false)
          }}
          headerText="Select UOM Part"
          partUoms={showPart.UOMs}
          onSelect={(item) => {
            onImportAction(item)
          }}
        />
      )}
      {deleteWarning && (
        <DeleteWarningModal
          onHide={() => {
            showDeleteWarning(false)
          }}
          content="Are you sure you want to delete this part?"
          okAction={() => {
            onDelete()
            showDeleteWarning(false)
          }}
          cancelAction={() => {
            showDeleteWarning(false)
          }}
        />
      )}
      {undoWarning && (
        <DeleteWarningModal
          onHide={() => {
            showUndoWarning(false)
          }}
          content="Are you sure you want to revert your changes?"
          okAction={() => {
            onUndo()
            showUndoWarning(false)
          }}
          okCaption="Undo"
          cancelAction={() => {
            showUndoWarning(false)
          }}
        />
      )}
      <div className="part-detail">
        {loading && <LoadingAnimation />}
        <div className="part-detail-list">
          <div className="part-detail-list-title">
            <Caption
              caption={captionText}
              primary
              bContainImg
              backAction={() => {
                backToList()
              }}
            />
          </div>
          <div className="horizontal-separator" />
          <div className="part-detail-edit-view">
            <PartPreview part={showPart} />

            <HForm className="edit-view-item-form">
              <EditViewSettingItem
                caption="Manufacturer:"
                errorMessage={isError((showPart && showPart.manufacturerName) || '', 'string')}
                clearAction={() => {
                  onClear()
                }}
              >
                <HSingleSelect
                  name="manufacturer"
                  secondary
                  values={globalManufacturers.map((item) => {
                    return { value: item, label: item }
                  })}
                  value={{
                    value: showPart.manufacturerName,
                    label: showPart.manufacturerName,
                  }}
                  onChange={(ev) => {
                    const newPart = { ...showPart }
                    newPart.manufacturerName = ev.value
                    setShowPart(newPart)
                    logChange('manufacturerName')
                  }}
                  isSearchable={false}
                  error={isError((showPart && showPart.manufacturerName) || '', 'string')}
                />
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Manufacturer Item Number:"
                errorMessage={isError((showPart && showPart.manufacturerId) || '', 'number')}
              >
                <HInput
                  className="edit-view-input-style"
                  name="manu_item_number"
                  value={showPart.manufacturerId || ''}
                  onChange={(ev) => {
                    const newPart = { ...showPart }
                    newPart.manufacturerId = ev.currentTarget.value
                    setShowPart(newPart)
                    logChange('manufacturerId')
                  }}
                  error={isError((showPart && showPart.manufacturerId) || '', 'number')}
                />
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Vendor:"
                errorMessage={isError((showPart && showPart.vendor) || '', 'string')}
              >
                <HSingleSelect
                  name="vendor"
                  secondary
                  values={globalVendors.map((item) => {
                    return { value: item, label: item }
                  })}
                  value={{
                    value: showPart.vendor,
                    label: showPart.vendor,
                  }}
                  onChange={(ev) => {
                    const newPart = { ...showPart }
                    newPart.vendor = ev.value
                    setShowPart(newPart)
                    logChange('vendor')
                  }}
                  error={isError((showPart && showPart.vendor) || '', 'string')}
                  isEditable
                />
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Vendor Item Number:"
                errorMessage={isError((showPart && showPart.vendorPartNumber) || '', 'number')}
              >
                <HInput
                  className="edit-view-input-style"
                  name="vendor_part_number"
                  value={showPart.vendorPartNumber || ''}
                  onChange={(ev) => {
                    const newPart = { ...showPart }
                    newPart.vendorPartNumber = ev.currentTarget.value
                    setShowPart(newPart)
                    logChange('vendorPartNumber')
                  }}
                  error={isError((showPart && showPart.vendorPartNumber) || '', 'string')}
                />
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Item Description:"
                errorMessage={isError((showPart && showPart.description) || '', 'string')}
              >
                <HTextInput
                  className="edit-view-text-input-style"
                  name="part_description"
                  value={showPart.description || ''}
                  onChange={(ev) => {
                    const newPart = { ...showPart }
                    newPart.description = ev.currentTarget.value
                    setShowPart(newPart)
                    logChange('description')
                  }}
                  error={isError((showPart && showPart.description) || '', 'string')}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Item Details:">
                <HTextInput
                  className="edit-view-text-input-style"
                  name="product_description"
                  value={showPart.itemDetails || ''}
                  onChange={(ev) => {
                    const newPart = { ...showPart }
                    newPart.itemDetails = ev.currentTarget.value
                    setShowPart(newPart)
                    logChange('itemDetails')
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Default Vendor:"
                errorMessage={isError(showPart && showPart.defaultVendor, 'string')}
              >
                <HSingleSelect
                  name="defaultVendor"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                  defaultValue={{ value: true, label: 'TRUE' }}
                  onChange={(ev) => {
                    console.log([ev.target.id])
                    const newPart = { ...showPart }
                    newPart.defaultVendor = ev.value
                    setShowPart(newPart)
                    logChange('defaultVendor')
                  }}
                  isSearchable={false}
                  errorMessage={isError(showPart && showPart.defaultVendor, 'string')}
                />
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Status:"
                errorMessage={isError(showPart && showPart.status, 'string')}
              >
                <HSingleSelect
                  name="itemAvailability"
                  secondary
                  values={[
                    { value: 'active', label: 'Active' },
                    { value: 'Not active', label: 'Not Active' },
                  ]}
                  value={
                    showPart && {
                      value: showPart.status,
                      label: showPart.status === 'active' ? 'Active' : 'Not Active',
                    }
                  }
                  onChange={(ev) => {
                    const newPart = { ...showPart }
                    newPart.status = ev.value
                    setShowPart(newPart)
                    logChange('status')
                  }}
                  isSearchable={false}
                  error={isError(showPart && showPart.status, 'string')}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Lead Time:">
                <HInput
                  className="edit-view-input-style"
                  name="lead_time"
                  value={showPart.leadTime || ''}
                  onChange={(ev) => {
                    const newPart = { ...showPart }
                    newPart.leadTime = toDecimal(ev.currentTarget.value)
                    setShowPart(newPart)
                    logChange('leadTime')
                  }}
                  type="number"
                />
              </EditViewSettingItem>
              <EditViewSettingItem caption="DefaultFlag:">
                <HInput
                  className="edit-view-input-style"
                  name="defaultFlag"
                  value={showPart.defaultFlag || ''}
                  onChange={(ev) => {
                    const newPart = { ...showPart }
                    newPart.defaultFlag = ev.currentTarget.value
                    setShowPart(newPart)
                    logChange('defaultFlag')
                  }}
                />
              </EditViewSettingItem>
              <HUOMTable
                columns={[
                  'Size',
                  'Per',
                  'Cost',
                  'Weight',
                  'Unit',
                  'Length',
                  'Width',
                  'Height',
                  'Unit',
                ]}
                data={showPart.UOMs}
                setData={(data) => {
                  const tempPart = { ...showPart, UOMs: [...data] }
                  setShowPart(tempPart)
                  logChange('UOMs')
                }}
                globalUoms={globalUoms}
              />
              <div className="parts-detail-last-line">
                <HButton
                  className="h-delete-button"
                  onClick={() => {
                    showDeleteWarning(true)
                  }}
                >
                  Delete
                </HButton>
                <div className="parts-detail-last-line-right">
                  <HButton
                    className="h-undo-button"
                    onClick={() => {
                      showUndoWarning(true)
                    }}
                    disabled={Object.keys(changes).length === 0}
                  >
                    Undo changes({Object.keys(changes).length})
                  </HButton>
                  <HButton
                    className="h-save-button"
                    onClick={() => {
                      onSave()
                    }}
                  >
                    Save
                  </HButton>
                </div>
              </div>
            </HForm>
          </div>
        </div>
        <div className="part-detail-filters">
          <EditViewActions
            saveCaption="Import as a product"
            saveAction={() => {
              if (showPart.UOMs && showPart.UOMs.length > 0) {
                if (showPart.UOMs.length === 1) {
                  onImportAction(showPart.UOMs[0])
                } else {
                  showModal(true)
                }
              } else {
                store.addNotification({
                  message: 'Sorry, UOM list is empty. Try again after adding uoms to the list.',
                  type: 'danger',
                  insert: 'top',
                  container: 'top-right',
                  animationIn: ['animated', 'bounceIn'],
                  animationOut: ['animated', 'fadeOut'],
                  dismiss: {
                    duration: 3000,
                  },
                })
              }
            }}
            clearCaption="Export as a PDF file"
            clearAction={onExportAction}
            hideDelete
          />
          <AddMedia
            caption="Images"
            pictureUrls={showPart.partPictureUrl}
            setPictureUrls={(urls) => {
              const updateProduct = { ...showPart }
              updateProduct.partPictureUrl = urls
              setShowPart(updateProduct)
            }}
          />
          <PartSalesforceItems salesforceInfo={showPart && showPart.salesforceInfo} />
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  setImportData,
}
PartDetail.propTypes = {
  setImportData: PropTypes.func.isRequired,
}

const connectedHeader = connect(null, mapDispatchToProps)(PartDetail)

export default withRouter(connectedHeader)
