/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react'
import PropTypes from 'prop-types'
import { priceDecimalFormatter } from 'utils/formatter'
import './index.css'

const PartUomView = ({ uom }) => {
  return (
    <div className="part-uom-show">
      <table className="table table-bordered table-hover">
        <tbody>
          <tr>
            <td width="100px">ID:</td>
            <td width="100px">{uom?.id || uom?.partId}</td>
          </tr>
          <tr>
            <td>Size:</td>
            <td>{uom.uom || 'Each'}</td>
          </tr>
          <tr>
            <td>Per:</td>
            <td>{uom.per || '1'}</td>
          </tr>
          <tr>
            <td>Cost:</td>
            <td>{priceDecimalFormatter(uom.cost)}</td>
          </tr>
          <tr>
            <td>L*W*H:</td>
            <td>
              {uom.length || '0'}*{uom.width || '0'}*{uom.height || '0'} ({uom.sizeUom || 'in'})
            </td>
          </tr>
          <tr>
            <td>Weight:</td>
            <td>
              {uom.weight || '0'} ({uom.weightUom || 'kg'})
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

PartUomView.propTypes = {
  uom: PropTypes.shape(),
}

PartUomView.defaultProps = {
  uom: {
    id: 0,
    uom: 'Each',
    per: 10,
    cost: 0,
    length: 0,
    width: 0,
    height: 0,
    sizeUom: 'in',
    weight: 0,
    weightUom: 'kg',
  },
}

export default PartUomView
