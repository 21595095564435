import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'
import './index.css'

const AlertModal = ({ onHide, headerText, bodyText, actionLabels, actions }) => {
  return (
    <Modal size="lg" show onHide={onHide}>
      <Modal.Header>
        <div className="px-2 mb-2">
          <h2>{headerText}</h2>
        </div>
      </Modal.Header>
      <Modal.Body className="modal--quick-view">
        <div className="modal--body">
          <div className="px-2 mb-2">
            <h4>{bodyText}</h4>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {actionLabels &&
          actionLabels.map((action, index) => (
            <Button
              key={`alert-action-${action}`}
              variant="primary lg"
              type="button"
              onClick={actions[index]}
            >
              {action}
            </Button>
          ))}
      </Modal.Footer>
    </Modal>
  )
}

AlertModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  headerText: PropTypes.string,
  bodyText: PropTypes.string,
  actionLabels: PropTypes.arrayOf(PropTypes.string),
  actions: PropTypes.arrayOf(PropTypes.func),
}
AlertModal.defaultProps = {
  headerText: 'Success',
  bodyText: 'Action Success',
  actionLabels: [],
  actions: [() => {}, () => {}],
}
export default AlertModal
