export const PRODUCT_FILTERS_CATERGORIES = [
  'PartNumber',
  'ProductNumber',
  'ManufacturerId',
  'UOM',
  'Price',
  'Taxable',
  'Weight',
  'Height',
  'Length',
  'Status',
]

export const PRODUCT_SELECT_CATEGORIES = {
  Taxable: [
    { value: true, label: 'TRUE' },
    { value: false, label: 'FALSE' },
  ],
  Status: [
    { value: 'active', label: 'Active' },
    { value: 'Not active', label: 'Not active' },
  ],
}

export const PART_FILTERS_CATEGORIES = [
  'ManufacturerId',
  'ManufacturerName',
  'Vendor',
  'VendorPartNumber',
  'Description',
  'Cost',
  'UOM',
  'LeadTime',
  'MinQuantity',
  'Status',
]

export const PART_SELECT_CATEGORIES = {
  Status: [
    { value: 'active', label: 'Active' },
    { value: 'Not active', label: 'Not active' },
  ],
}

export const SUPPLIER_FILTERS_CATERGORIES = [
  'AccountId',
  'Address',
  'CatalogueId',
  'Contact',
  'Description',
  'Frequency',
  'Name',
  'PhoneNumber',
  'ServiceName',
  'SalesForceId',
  'Status',
]

export const SUPPLIER_SELECT_CATEGORIES = {
  Status: [
    { value: 'active', label: 'Active' },
    { value: 'Not active', label: 'Not active' },
  ],
}

export const BOM_FILTERS_CATEGORIES = [
  'ProductNumber',
  'Price',
  'Taxable',
  'Description',
  'Detail',
  'Status',
]

export const BOM_SELECT_CATEGORIES = {
  Taxable: [
    { value: true, label: 'True' },
    { value: false, label: 'False' },
  ],
  Status: [
    { value: 'active', label: 'Active' },
    { value: 'Not active', label: 'Not active' },
  ],
}

const FILTERS = {
  CATEGORIES: ['Category1', 'Category2', 'Category3', 'Category4'],
  SORTBY: [
    { value: 'DESC', label: 'Descending' },
    { value: 'ASC', label: 'Ascending' },
  ],
  TAGS: ['Blue Tag', 'Red Tag', 'Green Tag'],
  OPERATORS: [
    { value: 'eq', label: 'Equals' },
    { value: 'gt', label: 'Greater' },
    { value: 'lt', label: 'Less' },
  ],
  BATCH_UPDATE_CATEGORY: ['123', '567', '789'],
}

export default FILTERS
