import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import MediumCompetitor from '../../Scrapers/MediumCompetitor'
import './index.css'

const SimilarProducts = ({ caption, products, viewProduct, manufacturerNum }) => {
  return (
    <div className="filters-container">
      <div className="filters-container-caption">
        {manufacturerNum && <span>{caption}</span>}
        {!manufacturerNum && <span>No other suppliers for this part.</span>}
      </div>
      <div className="filters-container-seperator" />
      <div className="filters-container-body-competitor">
        {manufacturerNum &&
          products &&
          products.slice(0, 10).map((product) => {
            return (
              <div key={product.id} className="suggested-linked-products-item">
                <MediumCompetitor competitor={product} viewCompetitor={viewProduct} secondary />
              </div>
            )
          })}
        {!manufacturerNum && (
          <div className="suggested-linked-products-empty-list">
            <span>To import more supplier items, visit the suppliers tab.</span>
          </div>
        )}
        {manufacturerNum && (!products || products.length === 0) && (
          <div className="suggested-linked-products-empty-list">
            <span>To import more supplier items, visit the suppliers tab.</span>
          </div>
        )}
      </div>
    </div>
  )
}
SimilarProducts.propTypes = {
  caption: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.shape({})),
  viewProduct: PropTypes.func,
  manufacturerNum: PropTypes.string,
}

SimilarProducts.defaultProps = {
  caption: 'Suggested Competitor Products',
  product: [],
  viewProduct: () => {},
  manufacturerNum: null,
}
export default SimilarProducts
