import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function filterReducer(state = initialState.filter, action) {
  switch (action.type) {
    case types.FILTER_SETTING_SUCCESS:
      return { ...action.filter };
    case types.FILTER_REMOVE_SUCCESS:
      return initialState.filter;
    default:
      return state;
  }
}

export function customFilterReducer(state = initialState.customFilter, action) {
  switch (action.type) {
    case types.CUSTOM_FILTER_SETTING_SUCCESS:
      return { ...action.filter };
    case types.CUSTOM_FILTER_REMOVE_SUCCESS:
      return initialState.customFilter;
    default:
      return state;
  }
}
