import React, { useState, useEffect } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import LoadingAnimation from 'components/LoadingAnimation'
import Caption from 'components/Parts/Caption'
import AddMedia from 'components/common/Filter/AddMedia'
import HSingleSelect from 'components/common/Basic/HSingleSelect'
import HInput from 'components/common/Basic/HInput'
import HButton from 'components/common/Basic/HButton'
import HTextInput from 'components/common/Basic/HTextInput'
import HForm from 'components/common/Basic/HForm'
import HUOMTable from 'components/common/Basic/HUOMTable'
import EditViewSettingItem from 'components/Products/EditViewSettingItem'
import { INITIAL_PART } from 'consts/parts'
import { toDecimal, isError, partValidationCheck } from 'utils/formatter'
import { addPart, getActiveSuppliers, getUomList } from 'api/productApi'
import { store } from 'react-notifications-component'

import './index.css'

const NewPart = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [newPart, setNewPart] = useState({})
  const [globalManufacturers, setGlobalManufacturers] = useState([])
  const [globalVendors, setGlobalVendors] = useState([])
  const [globalUoms, setGlobalUoms] = useState([])
  const [showError, setShowError] = useState(false)

  const backToList = () => {
    history.push('/supplier-part-list')
  }

  const fetchDatafromServer = async () => {
    setLoading(true)
    try {
      const { manufacturers, vendors } = await getActiveSuppliers()
      setGlobalManufacturers(manufacturers)
      setGlobalVendors(vendors)
    } catch (error) {
      console.log('GetSupplier error', error)
      store.addNotification({
        message: 'Sorry, we are not able to process your request now. Please try again later.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      })
    }

    try {
      const uoms = await getUomList()
      if (uoms) {
        setGlobalUoms(
          uoms.map((item) => {
            return item && item.name
          })
        )
      } else {
        setGlobalUoms([])
      }
    } catch (error) {
      console.log('GetUOMs error', error)
      store.addNotification({
        message: 'Sorry, we are not able to process your request now. Please try again later.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchDatafromServer()
    setNewPart({ ...INITIAL_PART })
  }, [])

  const onSave = () => {
    console.log('Save', newPart)

    if (newPart.UOMs && newPart.UOMs.length === 0) {
      store.addNotification({
        message: 'No UOMs - click the + icon to add a Unit of Measure for this Part.',
        type: 'default',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 2000,
        },
      })
      return
    }
    if (!partValidationCheck(newPart)) {
      setShowError(true)
      store.addNotification({
        message: 'Sorry, please complete the item and try again later',
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 2000,
        },
      })
      return
    }

    setLoading(true)
    const fetchData = async () => {
      const response = await addPart(newPart)
      console.log(response)
      if (response && response.error) {
        console.log(response.error.message)
        store.addNotification({
          message: 'Sorry, we are not able to process your request now. Please try again later.',
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 4000,
          },
        })
      } else if (response) {
        store.addNotification({
          message: `${response.message}. Redirect in 3 seconds. `,
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 5000,
          },
        })
        setTimeout(() => {
          backToList()
        }, 3000)
      }
      setLoading(false)
    }
    fetchData()
  }

  const onClear = () => {
    console.log('Clear')
    setNewPart({ ...INITIAL_PART })
  }
  const onDelete = () => {
    console.log('Delete')
    backToList()
  }

  return (
    <div className="detail-container">
      <div className="add-part">
        {loading && <LoadingAnimation />}
        <div className="add-part-list">
          <div className="add-part-list-title">
            <Caption caption="New Manufacturer Item Entry" />
          </div>
          <div className="horizontal-separator" />
          <div className="add-part-edit-view">
            <HForm className="add-part-item-form">
              <EditViewSettingItem
                caption="Manufacturer:"
                errorMessage={
                  showError && isError((newPart && newPart.manufacturerName) || '', 'string')
                }
              >
                <HSingleSelect
                  name="manufacturer"
                  secondary
                  values={globalManufacturers.map((item) => {
                    return { value: item, label: item }
                  })}
                  defaultValue={{
                    value: newPart.manufacturerName,
                    label: newPart.manufacturerName,
                  }}
                  onChange={(ev) => {
                    const updatePart = { ...newPart }
                    updatePart.manufacturerName = ev.value
                    setNewPart(updatePart)
                  }}
                  isSearchable={false}
                  error={
                    showError && isError((newPart && newPart.manufacturerName) || '', 'string')
                  }
                />
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Manufacturer Item Number:"
                errorMessage={
                  showError && isError((newPart && newPart.manufacturerId) || '', 'number')
                }
                clearAction={() => {
                  onClear()
                }}
              >
                <HInput
                  className="edit-view-input-style"
                  name="manufacturerPartNumber"
                  value={newPart.manufacturerId}
                  onChange={(ev) => {
                    const updatePart = { ...newPart }
                    updatePart.manufacturerId = ev.currentTarget.value.toUpperCase()
                    setNewPart(updatePart)
                  }}
                  error={showError && isError((newPart && newPart.manufacturerId) || '', 'number')}
                />
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Vendor"
                errorMessage={showError && isError((newPart && newPart.vendor) || '', 'string')}
              >
                <HSingleSelect
                  name="vendor"
                  secondary
                  values={globalVendors.map((item) => {
                    return { value: item, label: item }
                  })}
                  defaultValue={{
                    value: newPart.vendor,
                    label: newPart.vendor,
                  }}
                  onChange={(ev) => {
                    const updatePart = { ...newPart }
                    updatePart.vendor = ev.value
                    setNewPart(updatePart)
                  }}
                  isSearchable={false}
                  error={showError && isError((newPart && newPart.vendor) || '', 'string')}
                />
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Vendor Item Number"
                errorMessage={
                  showError && isError((newPart && newPart.vendorPartNumber) || '', 'number')
                }
              >
                <HInput
                  className="edit-view-input-style"
                  name="vendor_part_number"
                  value={newPart.vendorPartNumber || ''}
                  onChange={(ev) => {
                    const updatePart = { ...newPart }
                    updatePart.vendorPartNumber = ev.currentTarget.value.toUpperCase()
                    setNewPart(updatePart)
                  }}
                  error={
                    showError && isError((newPart && newPart.vendorPartNumber) || '', 'number')
                  }
                />
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Item Description:"
                errorMessage={
                  showError && isError((newPart && newPart.description) || '', 'string')
                }
              >
                <HTextInput
                  className="edit-view-text-input-style"
                  name="part_description"
                  value={newPart.description || ''}
                  onChange={(ev) => {
                    const updatePart = { ...newPart }
                    updatePart.description = ev.currentTarget.value
                    setNewPart(updatePart)
                  }}
                  rows={3}
                  error={showError && isError((newPart && newPart.description) || '', 'string')}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Item Details:">
                <HTextInput
                  className="edit-view-text-input-style"
                  name="part_details"
                  value={newPart.itemDetails || ''}
                  onChange={(ev) => {
                    const updatePart = { ...newPart }
                    updatePart.itemDetails = ev.currentTarget.value
                    setNewPart(updatePart)
                  }}
                  rows={8}
                />
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Default Vendor:"
                errorMessage={showError && isError(newPart && newPart.defaultVendor, 'string')}
              >
                <HSingleSelect
                  name="defaultVendor"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                  value={{
                    value: newPart && newPart.defaultVendor,
                    label: newPart && newPart.defaultVendor ? 'TRUE' : 'FALSE',
                  }}
                  onChange={(ev) => {
                    const updatePart = { ...newPart }
                    updatePart.defaultVendor = ev.value
                    setNewPart(updatePart)
                  }}
                  isSearchable={false}
                  error={showError && isError(newPart && newPart.defaultVendor, 'string')}
                />
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Item Availability:"
                errorMessage={showError && isError(newPart && newPart.status, 'string')}
              >
                <HSingleSelect
                  name="itemAvailability"
                  secondary
                  values={[
                    { value: 'active', label: 'Active' },
                    { value: 'Not active', label: 'Not active' },
                  ]}
                  value={{
                    value: newPart && newPart.status,
                    label: newPart && newPart.status === 'active' ? 'Active' : 'Not active',
                  }}
                  onChange={(ev) => {
                    const updatePart = { ...newPart }
                    updatePart.status = ev.value
                    setNewPart(updatePart)
                  }}
                  isSearchable={false}
                  error={showError && isError(newPart && newPart.status, 'string')}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Lead Time:">
                <HInput
                  className="edit-view-input-style"
                  name="lead_time"
                  value={newPart.leadTime || ''}
                  onChange={(ev) => {
                    const updatePart = { ...newPart }
                    updatePart.leadTime = toDecimal(ev.currentTarget.value)
                    setNewPart(updatePart)
                  }}
                  type="number"
                />
              </EditViewSettingItem>
              <EditViewSettingItem caption="DefaultFlag:">
                <HInput
                  className="edit-view-input-style"
                  name="defaultFlag"
                  value={newPart.defaultFlag || ''}
                  onChange={(ev) => {
                    const updatePart = { ...newPart }
                    newPart.defaultFlag = ev.currentTarget.value
                    setNewPart(updatePart)
                  }}
                />
              </EditViewSettingItem>
              <HUOMTable
                columns={[
                  'Size',
                  'Per',
                  'Cost',
                  'Weight',
                  'Unit',
                  'Length',
                  'Width',
                  'Height',
                  'Unit',
                ]}
                data={newPart.UOMs}
                setData={(data) => {
                  const tempPart = { ...newPart }
                  tempPart.UOMs = data
                  setNewPart(tempPart)
                }}
                globalUoms={globalUoms}
              />
              <div className="add-part-last-line">
                <HButton
                  className="h-save-button"
                  onClick={() => {
                    onSave()
                  }}
                >
                  Save
                </HButton>
                <HButton
                  className="h-delete-button"
                  onClick={() => {
                    onDelete()
                  }}
                >
                  Cancel
                </HButton>
              </div>
            </HForm>
          </div>
        </div>
        <div className="add-part-filters">
          <AddMedia
            caption="Supplier Item Images Preview"
            pictureUrls={newPart.partPictureUrl}
            setPictureUrls={(urls) => {
              const updatePart = { ...newPart }
              updatePart.partPictureUrl = urls
              setNewPart(updatePart)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default withRouter(NewPart)
