import * as types from '../actions/actionTypes'
import initialState from './initialState'

export default function userReducer(state = initialState.user, action) {
  switch (action.type) {
    case types.USER_SIGNIN_SUCCESS:
      return action.userData
    case types.USER_SIGNOUT_SUCCESS:
      console.log('signout action', action)
      const newUser = { ...initialState.user, message: action.message }
      return newUser
    default:
      return state
  }
}
