import { fetchAPI } from "./apiUtils";
import {
  APPROVALS_PENDING_URL,
  APPROVALS_APPROVE_URL,
  APPROVALS_DECLINE_URL,
  APPROVALS_PENDING_RECENT,
  APPROVALS_GET_PENDING_ALL,
  APPROVALS_GET_PENDING
} from "../consts/url";

export function getApprovalList(offset, length) {
  const formData = {
    offset,
    length
  };
  return fetchAPI(APPROVALS_PENDING_URL, "POST", formData);
}

export function approvePending(id) {
  const formData = { logId: id };
  return fetchAPI(APPROVALS_APPROVE_URL, "POST", formData);
}

export function declinePending(id) {
  const formData = { logId: id };
  return fetchAPI(APPROVALS_DECLINE_URL, "POST", formData);
}

export function getRecentPending() {
  const formData = {};
  return fetchAPI(APPROVALS_PENDING_RECENT, "POST", formData);
}

export function getPendings() {
  const formData = {};
  return fetchAPI(APPROVALS_GET_PENDING_ALL, "POST", formData);
}

export function getPending(id) {
  const formData = {
    logId: id
  };
  return fetchAPI(APPROVALS_GET_PENDING, "POST", formData);
}
