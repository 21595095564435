/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react'
import { withRouter, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import { INITIAL_BOM } from 'consts/bom'
import LoadingAnimation from 'components/LoadingAnimation'
import Caption from 'components/Products/Caption'
import AddMedia from 'components/common/Filter/AddMedia'
import HSingleSelect from 'components/common/Basic/HSingleSelect'
import HInput from 'components/common/Basic/HInput'
import HTextInput from 'components/common/Basic/HTextInput'
import HForm from 'components/common/Basic/HForm'
import HBomChildsTable from 'components/common/Basic/HBomChildsTable'
import { isError, bomValidationCheck } from 'utils/formatter'
import { BomSalesforceItems } from 'components/common/Filter/SalesforceItems'
import EditViewSettingItem from 'components/Products/EditViewSettingItem'
import ProductPreview from 'components/Products/ProductPreview'

import { getProduct, updateBom, deleteProduct, getBomChildren } from 'api/productApi'
import { store } from 'react-notifications-component'
import SuggestedCompetitorsProducts from 'components/common/Filter/SuggestedCompetitorsProducts'
import DeleteWarningModal from 'components/common/Modal/DeleteWarningModal'
import HButton from 'components/common/Basic/HButton'

const BomDetail = ({ history }) => {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [showBom, setShowBom] = useState({})
  const [competitors, setCompetitors] = useState([])
  const [bomChildren, setBomChildren] = useState([])
  const [deleteWarning, showDeleteWarning] = useState(false)
  const [undoWarning, showUndoWarning] = useState(false)
  const [changes, setChanges] = useState({})

  const [orgBom, setOrgBom] = useState({})
  const [orgBomChildren, setOrgBomChildren] = useState([])

  const backToList = () => {
    history.push('/boms/view')
  }

  useEffect(() => {
    if (!id) {
      backToList()
    }
    setLoading(true)
    const getData = async () => {
      try {
        const {
          product: curProduct,
          relatedCompetitorProducts: competitorProducts,
        } = await getProduct(id)
        if (curProduct && curProduct.error) {
          store.addNotification({
            message: 'Sorry, we are not able to process your request now, please try again later.',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 5000,
            },
          })
        } else {
          if (curProduct.isBom) {
            const bomChilds = await getBomChildren(curProduct.id)
            setBomChildren(bomChilds)
            setOrgBomChildren(bomChilds)
          }
          setShowBom({ ...INITIAL_BOM, ...curProduct })
          setCompetitors(competitorProducts)
          setOrgBom(curProduct)
          setLoading(false)
        }
      } catch (error) {
        store.addNotification({
          message: 'Sorry, we are not able to process your request now. Please try again later.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 4000,
          },
        })
      }
    }
    getData()
  }, [id])

  const onSave = () => {
    if (!bomValidationCheck(showBom)) {
      store.addNotification({
        message: 'Complete Product and try again.',
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 2000,
        },
      })
      return
    }
    if (showBom === orgBom) {
      store.addNotification({
        message: 'Sorry, no changes were detected. Please try again after changing the details.',
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      })
    } else {
      const updateBomChildren = bomChildren.map((bom, index) => {
        return {
          price: bom.price || '',
          quantity: bom.quantity || '',
          bomProductId: id,
          notes: null,
          soItemTypeId: null,
          kitItemTypeId: null,
          description: null,
          taxRateName: null,
          bomParentId: bom.bomParentId || bom.product.id || '',
          salesForceFailed: null,
          errors: null,
          bomSalesforceChildId: null,
          bomSalesforceURL: null,
        }
      })

      setLoading(true)
      const editProduct = async () => {
        const response = await updateBom(
          { ...orgBom, bomChildren: orgBomChildren },
          { ...showBom, bomChildren: updateBomChildren }
        )
        if (response && response.error) {
          store.addNotification({
            message: 'Sorry, we are not able to process your request now. please try again later.',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 4000,
            },
          })
        } else if (response) {
          // eslint-disable-next-line no-alert
          store.addNotification({
            message: `${response.message}`,
            type: 'success',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 5000,
            },
          })
        }
        setLoading(false)
      }
      try {
        editProduct()
      } catch (ev) {
        store.addNotification({
          message: 'Sorry, we are not able to process your request now. please try again later.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 4000,
          },
        })
        setLoading(false)
      }
    }
  }

  const onClear = () => {
    setShowBom({
      ...INITIAL_BOM,
      id: showBom.id,
      productNumber: showBom.productNumber,
    })
  }
  const onDelete = () => {
    setLoading(true)
    const onDeleteAction = async () => {
      const response = await deleteProduct(showBom.id)
      if (response && response.error) {
        store.addNotification({
          message: 'Sorry, we are not able to process your request now. Please try again later.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 4000,
          },
        })
      } else if (response) {
        store.addNotification({
          message: `${response.message}. Redirect in 3 seconds. `,
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 5000,
          },
        })
        setTimeout(() => {
          backToList()
        }, 3000)
      }
      setLoading(false)
    }
    onDeleteAction()
  }
  const logChange = (category) => {
    const tempChanges = { ...changes }
    tempChanges[category] = true
    setChanges(tempChanges)
  }

  const onUndo = () => {
    setShowBom(orgBom)
    setBomChildren(orgBomChildren)
    setChanges({})
  }

  const onViewEditProduct = (product) => {
    history.push(`/products/view/${product.id}`)
  }

  const onViewCompetitor = (competitor) => {
    if (competitor && competitor.productUrl) {
      window.open(competitor.productUrl)
    }
  }

  return (
    <div className="detail-container">
      <div className="product-detail">
        {loading && <LoadingAnimation />}
        {deleteWarning && (
          <DeleteWarningModal
            onHide={() => {
              showDeleteWarning(false)
            }}
            content="Are you sure you want to delete this BOM?"
            okAction={() => {
              onDelete()
              showDeleteWarning(false)
            }}
            cancelAction={() => {
              showDeleteWarning(false)
            }}
          />
        )}
        {undoWarning && (
          <DeleteWarningModal
            onHide={() => {
              showUndoWarning(false)
            }}
            content="Are you sure you want to revert your changes?"
            okAction={() => {
              onUndo()
              showUndoWarning(false)
            }}
            okCaption="Undo"
            cancelAction={() => {
              showUndoWarning(false)
            }}
          />
        )}
        <div className="product-detail-list">
          <div className="product-detail-list-title">
            <Caption
              caption="View / Edit BOM"
              backAction={() => {
                history.push('/boms/view')
              }}
            />
          </div>
          <div className="horizontal-separator" />
          <div className="product-detail-edit-view">
            <ProductPreview product={showBom} />
            <HForm className="edit-view-item-form">
              <EditViewSettingItem
                caption="Product Number:"
                errorMessage={isError((showBom && showBom.productNumber) || '', 'number')}
              >
                <HInput
                  className="edit-view-input-style"
                  name="product_number"
                  value={showBom.productNumber}
                  onChange={(ev) => {
                    const newProduct = { ...showBom }
                    newProduct.productNumber = ev.currentTarget.value
                    setShowBom(newProduct)
                    logChange('productNumber')
                  }}
                  error={isError((showBom && showBom.productNumber) || '', 'string')}
                  uppercase
                  readOnly
                />
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Product Description:"
                errorMessage={isError((showBom && showBom.productDescription) || '', 'string')}
                clearAction={() => {
                  onClear()
                }}
              >
                <HTextInput
                  className="edit-view-text-input-style"
                  name="product_description"
                  value={showBom.productDescription}
                  onChange={(ev) => {
                    const newProduct = { ...showBom }
                    newProduct.productDescription = ev.currentTarget.value
                    setShowBom(newProduct)
                    logChange('productDescription')
                  }}
                  error={isError((showBom && showBom.productDescription) || '', 'string')}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Product Details:(Optional)">
                <HTextInput
                  className="edit-view-text-input-style"
                  name="product_details"
                  value={showBom.productDetails}
                  onChange={(ev) => {
                    const newProduct = { ...showBom }
                    newProduct.productDetails = ev.currentTarget.value
                    setShowBom(newProduct)
                    logChange('productDetails')
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Cost of Goods:">
                <HInput
                  className="edit-view-input-style"
                  name="product_cogs"
                  value={showBom.cogs}
                  onChange={(ev) => {
                    const newProduct = { ...showBom }
                    newProduct.cogs = ev.currentTarget.value
                    setShowBom(newProduct)
                    logChange('cogs')
                  }}
                  readOnly
                />
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Price($):"
                errorMessage={isError(showBom && showBom.price, 'decimal')}
              >
                <HInput
                  className="edit-view-input-style"
                  name="price"
                  value={showBom.price}
                  onChange={(ev) => {
                    const newProduct = { ...showBom }
                    newProduct.price = ev.currentTarget.value
                    setShowBom(newProduct)
                    logChange('price')
                  }}
                  error={isError(showBom && showBom.price, 'decimal')}
                />
              </EditViewSettingItem>
              <EditViewSettingItem caption="Default Vendor:">
                <HSingleSelect
                  name="isDefaultVendor"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                  defaultValue={
                    showBom && {
                      value: showBom.defaultVendor,
                      label: showBom.defaultVendor ? 'TRUE' : 'FALSE',
                    }
                  }
                  onChange={(item) => {
                    const updateProduct = { ...showBom }
                    updateProduct.defaultVendor = item.value
                    setShowBom(updateProduct)
                    logChange('defaultVendor')
                  }}
                />
              </EditViewSettingItem>
              <EditViewSettingItem
                caption="Active:"
                errorMessage={isError(showBom && showBom.active, 'string')}
              >
                <HSingleSelect
                  name="isActive"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                  value={
                    showBom && {
                      value: showBom && showBom.active,
                      label: showBom && showBom.active ? 'TRUE' : 'FALSE',
                    }
                  }
                  onChange={(ev) => {
                    const newProduct = { ...showBom }
                    newProduct.active = ev.value
                    setShowBom(newProduct)
                    logChange('active')
                  }}
                  isSearchable={false}
                  error={isError(showBom && showBom.active, 'string')}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Taxable:">
                <HSingleSelect
                  name="isTaxable"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                  value={
                    showBom && {
                      value: showBom.taxable,
                      label: showBom.taxable ? 'TRUE' : 'FALSE',
                    }
                  }
                  onChange={(ev) => {
                    const newProduct = { ...showBom }
                    newProduct.taxable = ev.value
                    setShowBom(newProduct)
                    logChange('isTaxable')
                  }}
                  isSearchable={false}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="ComboBox:">
                <HSingleSelect
                  name="isComboBox"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                  value={
                    showBom && {
                      value: showBom.comboBox,
                      label: showBom.comboBox ? 'TRUE' : 'FALSE',
                    }
                  }
                  onChange={(ev) => {
                    const newProduct = { ...showBom }
                    newProduct.comboBox = ev.value
                    setShowBom(newProduct)
                    logChange('comboBox')
                  }}
                  isSearchable={false}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="AllowSize:">
                <HSingleSelect
                  name="isAllowUOM"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                  value={
                    showBom && {
                      value: showBom.allowUom,
                      label: showBom.allowUom ? 'TRUE' : 'FALSE',
                    }
                  }
                  onChange={(ev) => {
                    const newProduct = { ...showBom }
                    newProduct.allowUom = ev.value
                    setShowBom(newProduct)
                    logChange('allowUom')
                  }}
                  isSearchable={false}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Product URL:">
                <HInput
                  className="edit-view-input-style"
                  name="product_url"
                  value={showBom.productUrl}
                  onChange={(ev) => {
                    const newProduct = { ...showBom }
                    newProduct.productUrl = ev.currentTarget.value
                    setShowBom(newProduct)
                    logChange('productUrl')
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="ProductUPC:">
                <HInput
                  className="edit-view-input-style"
                  name="product_upc"
                  value={showBom.productUpc}
                  onChange={(ev) => {
                    const newProduct = { ...showBom }
                    newProduct.productUpc = ev.currentTarget.value
                    setShowBom(newProduct)
                    logChange('productUpc')
                  }}
                  inputType="number"
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="ProductSKU: (Warehouse Entry Only)">
                <HInput
                  className="edit-view-input-style"
                  name="product_sku"
                  value={showBom.productSku}
                  onChange={(ev) => {
                    const newProduct = { ...showBom }
                    newProduct.productSku = ev.currentTarget.value
                    setShowBom(newProduct)
                    logChange('productSku')
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Income Account Name:">
                <HInput
                  className="edit-view-input-style"
                  name="income_account"
                  value={showBom.incomeAccount || ''}
                  onChange={(ev) => {
                    const newProduct = { ...showBom }
                    newProduct.incomeAccount = ev.currentTarget.value
                    setShowBom(newProduct)
                    logChange('incomeAccount')
                  }}
                />
              </EditViewSettingItem>
              <EditViewSettingItem caption="Expense Account Name:">
                <HInput
                  className="edit-view-input-style"
                  name="expense_account"
                  value={showBom.expenseAccountName || ''}
                  onChange={(ev) => {
                    const updateProduct = { ...showBom }
                    updateProduct.expenseAccountName = ev.currentTarget.value
                    setShowBom(updateProduct)
                    logChange('expenseAccount')
                  }}
                />
              </EditViewSettingItem>
              <EditViewSettingItem caption="Weight:">
                <HInput
                  className="edit-view-input-style"
                  name="weight"
                  value={showBom.weight}
                  onChange={(ev) => {
                    const newProduct = { ...showBom }
                    newProduct.weight = ev.currentTarget.value
                    setShowBom(newProduct)
                    logChange('weight')
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="DefaultFlag:(Optional)">
                <HInput
                  className="edit-view-input-style"
                  name="defaultFlag"
                  value={showBom.defaultFlag}
                  onChange={(ev) => {
                    const newProduct = { ...showBom }
                    newProduct.defaultFlag = ev.currentTarget.value
                    setShowBom(newProduct)
                    logChange('defaultFlag')
                  }}
                />
              </EditViewSettingItem>
              <EditViewSettingItem caption="Carton Count:">
                <HInput
                  className="edit-view-input-style"
                  name="carton_count"
                  type="number"
                  value={showBom.cartonCount || ''}
                  onChange={(ev) => {
                    const newProduct = { ...showBom }
                    newProduct.cartonCount = ev.currentTarget.value
                    setShowBom(newProduct)
                    logChange('cartonCount')
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Carton Type:">
                <HInput
                  className="edit-view-input-style"
                  name="carton_type"
                  value={showBom.cartonType || ''}
                  onChange={(ev) => {
                    const newProduct = { ...showBom }
                    newProduct.cartonType = ev.currentTarget.value
                    setShowBom(newProduct)
                    logChange('cartonType')
                  }}
                />
              </EditViewSettingItem>
              <EditViewSettingItem caption="BOM Children">
                <HBomChildsTable
                  childs={bomChildren}
                  setChilds={(childsValue) => {
                    setBomChildren(childsValue)
                    logChange('bomChildren')
                  }}
                />
              </EditViewSettingItem>
              <div className="product-detail-last-line">
                <HButton
                  className="h-delete-button"
                  onClick={() => {
                    showDeleteWarning(true)
                  }}
                >
                  Delete BOM
                </HButton>
                <div className="product-detail-last-line-right">
                  <HButton
                    className="h-undo-button"
                    onClick={() => {
                      showUndoWarning(true)
                    }}
                    disabled={Object.keys(changes).length === 0}
                  >
                    Undo changes({Object.keys(changes).length})
                  </HButton>
                  <HButton
                    className="h-save-button"
                    onClick={() => {
                      onSave()
                    }}
                  >
                    Save
                  </HButton>
                </div>
              </div>
            </HForm>
          </div>
        </div>
        <div className="product-detail-filters">
          <AddMedia
            pictureUrls={showBom.productPictureUrl}
            setPictureUrls={(urls) => {
              const updateProduct = { ...showBom }
              updateProduct.productPictureUrl = urls
              setShowBom(updateProduct)
            }}
          />
          <SuggestedCompetitorsProducts
            competitors={competitors}
            viewCompetitor={onViewCompetitor}
          />
          <BomSalesforceItems salesforceInfo={showBom && showBom.productSalesforce} />
        </div>
      </div>
    </div>
  )
}

BomDetail.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default withRouter(BomDetail)
