export const REQUIRED_VALIDATION = "Please fill in the required field.";
export const REQUIRED_MIN_DATE = "Please select valid date.";
export const INVALID_EMAIL = "Invalid email address";
export const INVALID_OPTION = "Please select one of the options below";
export const INVALID_INTEGER = "Please select non negative integer.";
export const INVALID_PHONE_NUMBER = "Invalid phone number";
// Book Demo page
export const NUMBER_OF_PEOPLE = [
  "0-10",
  "10-25",
  "25-50",
  "50-100",
  "100-150",
  "150-300",
  "300+"
];

export const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";
export const DEFAULT_TIME_FORMAT = "hh:mm a";
