/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react'
import PropTypes from 'prop-types'
import HButton from '../common/Basic/HButton'
import { classNames } from 'utils/helper'
import { useHistory } from 'react-router-dom'

import './index.css'

const EditViewSettingItem = ({
  children,
  caption,
  addButton,
  addAction,
  deleteAction,
  errorMessage,
  bordered,
  clearAction,
  link,
}) => {
  const controlClassName = classNames(
    'edit-view-setting-item-control',
    (bordered && 'bordered pl-4') || '',
    (bordered && !!errorMessage && 'border-error') || '',
    (link && '-linked') || ''
  )
  const history = useHistory()

  return (
    <div className="edit-view-setting-item">
      <div className="edit-view-setting-item-text">
        <span>{caption}</span>
        {addButton && (
          <HButton className="h-medium-button h-green-button" onClick={addAction}>
            +
          </HButton>
        )}
        {clearAction && (
          <div className="edit-view-setting-item-clear">
            <HButton className="h-clear-button" onClick={clearAction}>
              clear all X
            </HButton>
          </div>
        )}
      </div>
      <div
        className={controlClassName}
        onClick={() => {
          if (link) {
            history.push(link)
          }
        }}
      >
        {children}
        {deleteAction && (
          <div className="edit-view-setting-item-delete">
            <HButton className="h-transparent-reverse-button" onClick={deleteAction}>
              <img
                src="/assets/img/header/times-solid.svg"
                alt="delete"
                width="20px"
                height="20px"
              />
            </HButton>
          </div>
        )}
      </div>
      <div className="edit-view-setting-item-error">{errorMessage}</div>
    </div>
  )
}

EditViewSettingItem.propTypes = {
  children: PropTypes.any,
  caption: PropTypes.string,
  addButton: PropTypes.bool,
  addAction: PropTypes.func,
  deleteAction: PropTypes.func,
  clearAction: PropTypes.func,
  errorMessage: PropTypes.string,
  bordered: PropTypes.bool,
  link: PropTypes.string,
}

EditViewSettingItem.defaultProps = {
  children: '',
  caption: '',
  addButton: false,
  addAction: () => {},
  deleteAction: null,
  clearAction: null,
  errorMessage: '',
  bordered: false,
  link: '',
}
export default EditViewSettingItem
