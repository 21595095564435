import React from "react";
import PropTypes from "prop-types";
import ScraperItem from "./ScraperItem";
import "./index.css";

const ScrapersItemList = ({ scrapers, importAction }) => {
  return (
    <div className="parts-list-container">
      {scrapers &&
        scrapers.length > 0 &&
        scrapers.map(scraper => (
          <ScraperItem
            key={scraper.id}
            name={scraper.name}
            part={scraper}
            importAction={importAction}
          />
        ))}
    </div>
  );
};
ScrapersItemList.propTypes = {
  scrapers: PropTypes.arrayOf(PropTypes.shape({})),
  importAction: PropTypes.func
};

ScrapersItemList.defaultProps = {
  scrapers: [],
  importAction: () => {}
};
export default ScrapersItemList;
