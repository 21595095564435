import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function modalReducer(state = initialState.modal, action) {
  switch (action.type) {
    case types.SHOW_SIGNIN_MODAL_SUCCESS:
      return {
        ...state,
        signUpModalShown: false,
        signInModalShown: true,
        modalHeader: action.text,
      };
    case types.SHOW_SIGNUP_MODAL_SUCCESS:
      return { ...state, signUpModalShown: true, signInModalShown: false };
    case types.CLOSE_SIGNIN_SIGNUP_MODAL_SUCCESS: // close all modals - maybe after signin/sign up success
      return { signUpModalShown: false, signInModalShown: false };
    default:
      return state;
  }
}
