/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { MediumLoadingAnimation } from 'components/LoadingAnimation'
import Caption from 'components/Suppliers/Caption'
import Filters from 'components/common/Filter/Filters'
import CustomFilters from 'components/common/Filter/CustomFilters'
import SelectedActions from 'components/common/Filter/SelectedActions'
import SuppliersList from 'components/Suppliers/SuppliersList'
import { decimalFormatter } from 'utils/formatter'
import HButton from 'components/common/Basic/HButton'
import { getSuppliersList, syncService, mergeSuppliers } from 'api/productApi'
import HSingleSelect from 'components/common/Basic/HSingleSelect'
import { PAGE_ITEMS } from 'consts/common'
import HPagination from 'components/common/Pagination'
import { toArrayFromJSON, toJSONFromArray } from 'utils/formatter'
import { store } from 'react-notifications-component'
import SupplierMergeModal from 'components/common/Modal/SupplierMergeModal'

import {
  setNormalFilter,
  setCustomFilter,
  removeNormalFilter,
  removeCustomFilter,
} from 'redux/actions/filterAction'

import './index.css'

const ViewSuppliers = ({
  filter,
  customFilter,
  setNormalFilter,
  setCustomFilter,
  removeNormalFilter,
  removeCustomFilter,
}) => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [suppliers, setSuppliers] = useState([])
  const [selectedSuppliers, setSelectedSuppliers] = useState([])

  const [results, setResults] = useState(0)
  const [pageTitle, setPageTitle] = useState('All Suppliers')
  const [pageNumber, setPageNumber] = useState(1)
  const [itemPerPage, setItemPerPage] = useState(100)
  const [totalPageCount, setTotalPageCount] = useState(100)
  const [isSelectAll, setSelectAll] = useState(false)
  const [isSelectShown, setSelectShown] = useState(false)
  const [mergeWarning, showMergeWarning] = useState(false)

  const [mergeTo, setMergeTo] = useState(null)

  const fetchData = async () => {
    try {
      const normal = (filter && filter.suppliers) || {}
      const custom = (customFilter && customFilter.suppliers) || []

      const supplierFilters = {
        normal,
        custom,
      }
      const suppliers = await getSuppliersList(
        (pageNumber - 1) * itemPerPage,
        itemPerPage,
        supplierFilters
      )

      if (suppliers && suppliers.error) {
        console.log(suppliers.error.message)
        store.addNotification({
          message: 'Sorry, we are not able to process your request now. Please try again later.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 4000,
          },
        })
      } else {
        setSuppliers(suppliers.suppliers)
        const selectedArray = []
        suppliers.suppliers.map(() => {
          selectedArray.push(isSelectAll || isSelectShown)
        })
        setSelectedSuppliers(selectedArray)
        setResults(suppliers.totalCount)
        setTotalPageCount(Math.ceil(suppliers.totalCount / itemPerPage))
      }
    } catch (error) {
      console.log('ViewSuppliers fetchData error', error)
      store.addNotification({
        message: 'Sorry, we are not able to process your request now. Please try again later.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      })
    }
    setLoading(false)
  }

  const batchSave = (record, value) => {
    console.log('batchSave', record, value)
  }

  useEffect(() => {
    // Loading Data
    setLoading(true)
    fetchData()

    if (filter && filter.suppliers && filter.suppliers.category) {
      setPageTitle('Filtered Suppliers')
    }
    if (customFilter && customFilter.suppliers && customFilter.suppliers.length > 0) {
      setPageTitle('Filtered Suppliers')
    }
  }, [pageNumber, itemPerPage, filter, customFilter])

  useEffect(() => {
    const supplierStatus = []
    selectedSuppliers.map(() => {
      supplierStatus.push(isSelectAll || isSelectShown)
    })
    setSelectedSuppliers(supplierStatus)
  }, [isSelectAll, isSelectShown])

  const onViewEditSupplier = (supplier) => {
    history.push(`/suppliers/${supplier.id}`)
  }

  const onSynchronizeSupplier = (serviceName) => {
    const onSyncAction = async (service) => {
      const response = await syncService(service)
      if (response && response.error) {
        store.addNotification({
          message: response.error,
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 4000,
          },
        })
      } else {
        store.addNotification({
          message: response.message,
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 5000,
          },
        })
      }
    }
    toArrayFromJSON(serviceName).map((service) => {
      onSyncAction(service)
    })
  }

  const onFilterApply = (applyFilter) => {
    if (applyFilter.category === 'Select One' || applyFilter.sortBy === 'Select One') {
      store.addNotification({
        message: "Sorry, you didn't select category or sort options.",
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      })
    } else {
      const updateFilter = { ...filter }
      updateFilter.suppliers = applyFilter
      setNormalFilter(updateFilter)
    }
  }

  const onCustomFilterApply = (applyFilter) => {
    if (applyFilter && applyFilter.length > 0) {
      const updateFilter = { ...customFilter }
      updateFilter.suppliers = applyFilter
      setCustomFilter(updateFilter)
    } else {
      store.addNotification({
        message: "Sorry, you didn't select category or value options...",
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      })
    }
  }

  const onFilterCancel = () => {
    removeNormalFilter()
  }

  const onCustomFilterCancel = () => {
    const updateFilter = { ...customFilter }
    updateFilter.suppliers = []
    setCustomFilter(updateFilter)
  }

  const onMerge = async () => {
    console.log('onMerge', mergeTo)
    showMergeWarning(false)
    const filteredSuppliers = suppliers.filter((item, index) => {
      return selectedSuppliers[index]
    })

    const selectedArray = []
    suppliers.map(() => {
      selectedArray.push(isSelectAll || isSelectShown)
    })
    setSelectedSuppliers(selectedArray)

    if (mergeTo === filteredSuppliers[0].id) {
      store.addNotification({
        message: `Sorry, please select different suppliers.`,
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 2000,
        },
      })
      return
    }

    store.addNotification({
      message: 'Merge Started...',
      type: 'info',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animated', 'bounceIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: 2000,
      },
    })
    await mergeSuppliers(mergeTo, filteredSuppliers[0].id)
    store.addNotification({
      message: 'Merge Ended...',
      type: 'info',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animated', 'bounceIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: 2000,
      },
    })
    fetchData()
  }

  return (
    <div className="view-suppliers">
      {mergeWarning && (
        <SupplierMergeModal
          onHide={() => {
            showMergeWarning(false)
          }}
          content="Are you sure you want to merge these suppliers?"
          okAction={() => {
            onMerge()
          }}
          cancelAction={() => {
            showMergeWarning(false)
          }}
        />
      )}
      <div className="view-suppliers-list">
        <div className="view-suppliers-list-title">
          <Caption
            caption={pageTitle}
            addAction={() => {
              history.push('/new-supplier')
            }}
          />
        </div>
        {loading && (
          <div className="suppliers-list-container">
            <MediumLoadingAnimation />
          </div>
        )}
        {!loading && (
          <SuppliersList
            suppliers={suppliers}
            viewEditAction={onViewEditSupplier}
            selectedSuppliers={selectedSuppliers}
            setSelectedSuppliers={setSelectedSuppliers}
            syncAction={onSynchronizeSupplier}
          />
        )}
        <div className="horizontal-separator" />
        <div className="view-suppliers-list-footer">
          <div className="view-suppliers-list-footer-left">
            <span className="view-suppliers-list-footer-text">
              {`${decimalFormatter(results)} results for  "`}
            </span>
            <span className="view-suppliers-list-footer-text-bold">{`${pageTitle}"`}</span>
            <HButton
              className="view-suppliers-list-footer-button"
              onClick={() => {
                setLoading(true)
                fetchData()
              }}
            >
              <img src="/assets/img/common/refresh.png" alt="refresh" />
              Refresh
            </HButton>
            <div className="view-products-list-footer-select">
              <span>Item Per Page:</span>
              <HSingleSelect
                values={PAGE_ITEMS.map((item) => ({
                  value: item,
                  label: `${item}`,
                }))}
                defaultValue={{ value: 100, label: '100' }}
                name="itemPerPage"
                onChange={(item) => {
                  setItemPerPage(item.value)
                }}
                isSearchable={false}
              />
            </div>
          </div>
          <div className="view-products-list-footer-right">
            <HPagination
              pageNumber={pageNumber}
              totalPageCount={totalPageCount}
              setPageNumber={setPageNumber}
            />
          </div>
        </div>
      </div>
      <div className="view-suppliers-filters">
        <Filters
          filter={filter && filter.suppliers}
          onApply={onFilterApply}
          onCancel={onFilterCancel}
          isProduct={false}
          isSupplier
        />
        <CustomFilters
          filter={customFilter && customFilter.suppliers}
          onApply={onCustomFilterApply}
          onCancel={onCustomFilterCancel}
          isProduct={false}
          isSupplier
        />
        <SelectedActions
          totalItems={results}
          pageItems={suppliers.length}
          selectTotalItems={(mode) => {
            setSelectAll(mode)
          }}
          selectPageShownItems={(mode) => {
            setSelectShown(mode)
          }}
          onSave={batchSave}
          isSupplier
          suppliers={suppliers.map((supplier) => {
            return { value: supplier.id, label: supplier.name }
          })}
          mergeAction={(merge) => {
            setMergeTo(merge)
            const filteredSuppliers = suppliers.filter((item, index) => {
              return selectedSuppliers[index]
            })
            console.log('selectedSuppliers', filteredSuppliers)
            if (filteredSuppliers && filteredSuppliers.length === 1) {
              showMergeWarning(true)
            } else {
              store.addNotification({
                message: 'Please select Source Supplier',
                type: 'danger',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'bounceIn'],
                animationOut: ['animated', 'fadeOut'],
                dismiss: {
                  duration: 2000,
                },
              })
            }
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  filter: state.filter,
  customFilter: state.customFilter,
})

const mapDispatchToProps = {
  setNormalFilter,
  setCustomFilter,
  removeNormalFilter,
  removeCustomFilter,
}

ViewSuppliers.propTypes = {
  setNormalFilter: PropTypes.func.isRequired,
  setCustomFilter: PropTypes.func.isRequired,
  removeNormalFilter: PropTypes.func.isRequired,
  removeCustomFilter: PropTypes.func.isRequired,
  filter: PropTypes.any.isRequired,
  customFilter: PropTypes.any.isRequired,
}

const connectedViewSuppliers = connect(mapStateToProps, mapDispatchToProps)(ViewSuppliers)

export default withRouter(connectedViewSuppliers)
