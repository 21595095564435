/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import { withRouter, useHistory } from 'react-router-dom'

import LoadingAnimation from 'components/LoadingAnimation'
import Caption from 'components/Suppliers/Caption'
import HSingleSelect from 'components/common/Basic/HSingleSelect'
import HInput from 'components/common/Basic/HInput'
import HTextInput from 'components/common/Basic/HTextInput'
import HButton from 'components/common/Basic/HButton'
import HForm from 'components/common/Basic/HForm'
import HDropButton from 'components/common/Basic/HDropButton'
import EditViewActions from 'components/common/Filter/EditViewActions'
import EditViewSettingItem from 'components/Products/EditViewSettingItem'
import { INITIAL_SUPPLIER } from 'consts/suppliers'
import { store } from 'react-notifications-component'
import AddMedia from 'components/common/Filter/AddMedia'
import { addSupplier, getServiceLog } from 'api/productApi'
import { toJSONFromArray, isError, supplierValidationCheck } from 'utils/formatter'
import debounce from 'lodash/debounce'

import './index.css'

const AddSupplier = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [newSupplier, setNewSupplier] = useState(INITIAL_SUPPLIER)
  const [showError, setShowError] = useState(false)
  const [serviceLogs, setServiceLogs] = useState([])

  const backToList = () => {
    history.push('/suppliers')
  }
  const onSave = () => {
    if (!supplierValidationCheck(newSupplier)) {
      setShowError(true)
      store.addNotification({
        message: 'Sorry, please complete the supplier and try again later',
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 2000,
        },
      })
      return
    }
    setLoading(true)
    const fetchData = async () => {
      try {
        const updatedSupplier = { ...newSupplier }
        updatedSupplier.serviceName = toJSONFromArray(newSupplier.serviceName)
        updatedSupplier.serviceFreq = toJSONFromArray(newSupplier.serviceFreq)
        const response = await addSupplier(updatedSupplier)
        console.log(response)
        if (response && response.error) {
          console.log(response.error.message)
          store.addNotification({
            message: 'Sorry, we are not able to process your request now. Please try again later.',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 5000,
            },
          })
        } else if (response) {
          store.addNotification({
            message: `${response.message}. Redirect in 3 seconds. `,
            type: 'success',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 5000,
            },
          })
          setTimeout(() => {
            backToList()
          }, 3000)
        }
      } catch (ev) {
        console.log('AddSupplier error', ev)
        store.addNotification({
          message: 'Sorry, we are not able to process your request now. Please try again later.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 5000,
          },
        })
      }
      setLoading(false)
    }
    fetchData()
  }

  const onClear = () => {
    console.log('Clear')
    setNewSupplier({ ...INITIAL_SUPPLIER })
  }
  const onDelete = () => {
    console.log('Delete')
    backToList()
  }
  const onGetServiceResult = debounce(async (service, index) => {
    const log = await getServiceLog(service)
    const tempLogs = [...serviceLogs]
    tempLogs[index] = log
    setServiceLogs(tempLogs)
  }, 300)

  return (
    <div className="detail-container">
      <div className="add-supplier">
        {loading && <LoadingAnimation />}
        <div className="add-supplier-list">
          <div className="add-supplier-list-title">
            <Caption
              caption="New Supplier"
              backAction={() => {
                history.push('/suppliers')
              }}
            />
          </div>
          <div className="horizontal-separator" />
          <div className="add-supplier-edit-view">
            <HForm className="edit-view-item-form">
              <EditViewSettingItem
                caption="Name:"
                errorMessage={
                  showError && isError((newSupplier && newSupplier.name) || '', 'string')
                }
              >
                <HInput
                  className="edit-view-input-style"
                  name="name"
                  value={(newSupplier && newSupplier.name) || ''}
                  onChange={(ev) => {
                    const updateSupplier = { ...newSupplier }
                    updateSupplier.name = ev.currentTarget.value
                    setNewSupplier(updateSupplier)
                  }}
                  error={showError && isError((newSupplier && newSupplier.name) || '', 'string')}
                />{' '}
              </EditViewSettingItem>
              <EditViewSettingItem
                caption="Vendor:"
                errorMessage={
                  showError && isError((newSupplier && newSupplier.IsVendor) || '', 'string')
                }
              >
                <HSingleSelect
                  name="type"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                  defaultValue={
                    newSupplier && {
                      value: newSupplier.IsVendor,
                      label: newSupplier.IsVendor ? 'TRUE' : 'FALSE',
                    }
                  }
                  onChange={(item) => {
                    const updateSupplier = { ...newSupplier }
                    updateSupplier.IsVendor = item.value
                    setNewSupplier(updateSupplier)
                  }}
                  errorMessage={
                    showError && isError((newSupplier && newSupplier.IsVendor) || '', 'string')
                  }
                />
              </EditViewSettingItem>
              <EditViewSettingItem
                caption="Manufacturer:"
                errorMessage={
                  showError && isError((newSupplier && newSupplier.IsManufacturer) || '', 'string')
                }
              >
                <HSingleSelect
                  name="type"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                  defaultValue={
                    newSupplier && {
                      value: newSupplier.IsManufacturer,
                      label: newSupplier.IsManufacturer ? 'TRUE' : 'FALSE',
                    }
                  }
                  onChange={(item) => {
                    const updateSupplier = { ...newSupplier }
                    updateSupplier.IsManufacturer = item.value
                    setNewSupplier(updateSupplier)
                  }}
                  errorMessage={
                    showError && isError((newSupplier && newSupplier.IsManufacturer) || '', 'string')
                  }
                />
              </EditViewSettingItem>
              <EditViewSettingItem
                caption="Active:"
                errorMessage={
                  showError && isError((newSupplier && newSupplier.active) || '', 'string')
                }
              >
                <HSingleSelect
                  name="active"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                  defaultValue={
                    newSupplier && {
                      value: newSupplier.active,
                      label: newSupplier.active ? 'TRUE' : 'FALSE',
                    }
                  }
                  onChange={(item) => {
                    const updateSupplier = { ...newSupplier }
                    updateSupplier.active = item.value
                    setNewSupplier(updateSupplier)
                  }}
                  errorMessage={
                    showError && isError((newSupplier && newSupplier.active) || '', 'string')
                  }
                />
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Address:"
                errorMessage={
                  showError && isError((newSupplier && newSupplier.address) || '', 'string')
                }
              >
                <HInput
                  className="edit-view-input-style"
                  name="address"
                  value={(newSupplier && newSupplier.address) || ''}
                  onChange={(ev) => {
                    const updateSupplier = { ...newSupplier }
                    updateSupplier.address = ev.currentTarget.value
                    setNewSupplier(updateSupplier)
                  }}
                  error={showError && isError((newSupplier && newSupplier.address) || '', 'string')}
                />
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Phone Number:"
                errorMessage={
                  showError && isError((newSupplier && newSupplier.phoneNumber) || '', 'string')
                }
              >
                <HInput
                  className="edit-view-input-style"
                  name="phoneNumber"
                  value={(newSupplier && newSupplier.phoneNumber) || ''}
                  onChange={(ev) => {
                    const updateSupplier = { ...newSupplier }
                    updateSupplier.phoneNumber = ev.currentTarget.value
                    setNewSupplier(updateSupplier)
                  }}
                  error={
                    showError && isError((newSupplier && newSupplier.phoneNumber) || '', 'string')
                  }
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Contact Name:">
                <HInput
                  className="edit-view-input-style"
                  name="contact"
                  value={(newSupplier && newSupplier.contact) || ''}
                  onChange={(ev) => {
                    const updateSupplier = { ...newSupplier }
                    updateSupplier.contact = ev.currentTarget.value
                    setNewSupplier(updateSupplier)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Description:"
                errorMessage={
                  showError && isError((newSupplier && newSupplier.description) || '', 'string')
                }
              >
                <HTextInput
                  className="edit-view-text-input-style"
                  name="Description"
                  value={(newSupplier && newSupplier.description) || ''}
                  rows={8}
                  onChange={(ev) => {
                    const updateSupplier = { ...newSupplier }
                    updateSupplier.description = ev.currentTarget.value
                    setNewSupplier(updateSupplier)
                  }}
                  error={
                    showError && isError((newSupplier && newSupplier.description) || '', 'string')
                  }
                />
              </EditViewSettingItem>
              <EditViewSettingItem
                caption="Service Name:"
                addButton
                addAction={() => {
                  const updateSupplier = { ...newSupplier }
                  updateSupplier.serviceName.push('')
                  updateSupplier.serviceFreq.push('Daily')
                  setNewSupplier(updateSupplier)
                  const newServiceLogs = [...serviceLogs]
                  newServiceLogs.push([])
                  setServiceLogs(newServiceLogs)
                }}
              >
                <div className="h-container">
                  {newSupplier &&
                    newSupplier.serviceName &&
                    newSupplier.serviceName.map((service, index) => {
                      return (
                        <div key={index}>
                          <div className="h-row mb-1">
                            <HInput
                              className="edit-view-input-style mr-2"
                              name="serviceName"
                              value={service || ''}
                              onChange={(ev) => {
                                const updateSupplier = { ...newSupplier }
                                updateSupplier.serviceName[index] = ev.currentTarget.value
                                setNewSupplier(updateSupplier)
                                if (updateSupplier.serviceName[index].length > 0) {
                                  onGetServiceResult(updateSupplier.serviceName[index], index)
                                }
                              }}
                              error={showError && isError(service || '', 'string')}
                            />
                            <HDropButton
                              className="mr-2"
                              secondary
                              values={[
                                { value: 'Daily', label: 'Daily' },
                                { value: 'Weekly', label: 'Weekly' },
                                { value: 'Monthly', label: 'Monthly' },
                              ]}
                              value={{
                                value: newSupplier.serviceFreq[index] || 'Daily',
                                label: newSupplier.serviceFreq[index] || 'Daily',
                              }}
                              onChange={(ev) => {
                                const updateSupplier = { ...newSupplier }
                                updateSupplier.serviceFreq[index] = ev.value
                                setNewSupplier(updateSupplier)
                              }}
                            />
                            <HButton
                              className="h-medium-button h-blue-button h-round-button"
                              onClick={() => {
                                const updateSupplier = { ...newSupplier }
                                updateSupplier.serviceName.splice(index, 1)
                                setNewSupplier(updateSupplier)
                              }}
                            >
                              -
                            </HButton>
                          </div>
                          <div className="h-row mb-2 pl-2 pr-2 pt-1 pb-1">
                            <div className="h-col">
                              <div className="h-row mb-2">
                                <span className="h-log-header">
                                  &apos;{service}&apos; Log{' '}
                                  {serviceLogs &&
                                    serviceLogs[index] &&
                                    serviceLogs[index].length === 0 &&
                                    'does not exist'}
                                </span>
                              </div>
                              {serviceLogs[index].length > 0 && (
                                <>
                                  <table className="h-log-table">
                                    <tbody>
                                      <tr>
                                        <th>Vendor</th>
                                        <th>VendorPartNumber</th>
                                        <th>ServiceName</th>
                                        <th>Status</th>
                                        <th>Date</th>
                                      </tr>
                                      {serviceLogs[index] &&
                                        serviceLogs[index].map((log, index) => {
                                          if (index >= 20) {
                                            return null
                                          }
                                          return (
                                            <tr key={log.id}>
                                              <td>{log.vendor}</td>
                                              <td>{log.vendorPartNumber}</td>
                                              <td>{log.serviceName}</td>
                                              <td>{log.failed ? 'failed' : 'success'}</td>
                                              <td>{log.updatedAt}</td>
                                            </tr>
                                          )
                                        })}
                                    </tbody>
                                  </table>
                                  {serviceLogs[index].length >= 20 && (
                                    <div className="h-row h-big-font mb-4">...</div>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              </EditViewSettingItem>
              <div className="add-supplier-last-line">
                <HButton
                  className="h-save-button"
                  onClick={() => {
                    onSave()
                  }}
                >
                  Save
                </HButton>
                <HButton
                  className="h-delete-button"
                  onClick={() => {
                    onDelete()
                  }}
                >
                  Cancel
                </HButton>
              </div>
            </HForm>
          </div>
        </div>
        <div className="add-supplier-filters">
          <AddMedia
            caption="Set Logo Image"
            pictureUrls={JSON.stringify(newSupplier.logoUrl ? [newSupplier.logoUrl] : [])}
            setPictureUrls={(picUrls) => {
              const urls = JSON.parse(picUrls)
              const updateSupplier = { ...newSupplier }
              if (urls.length === 0) {
                updateSupplier.logoUrl = ''
              } else if (urls.length === 1) {
                updateSupplier.logoUrl = urls && urls[0]
              } else if (urls.length >= 2) {
                updateSupplier.logoUrl = urls && urls[1]
              }

              setNewSupplier(updateSupplier)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default withRouter(AddSupplier)
