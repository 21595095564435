import * as types from './actionTypes'

export function signupSuccess() {
  return { type: types.USER_SIGNUP_SUCCESS }
}

export function signinSuccess(userData) {
  return { type: types.USER_SIGNIN_SUCCESS, userData }
}

export function signoutSuccess(errMsg) {
  return { type: types.USER_SIGNOUT_SUCCESS, message: errMsg }
}

export function signinRequest(userData) {
  return async (dispatch) => {
    dispatch(signinSuccess(userData))
  }
}

export function signoutRequest(errMsg) {
  return async (dispatch) => {
    dispatch(signoutSuccess(errMsg))
  }
}
