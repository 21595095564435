import { REQUIRED_VALIDATION } from "consts/form";
import * as types from "./actionTypes";

export function showSignInModal(headerText = REQUIRED_VALIDATION) {
  return async dispatch => {
    const action = { type: types.SHOW_SIGNIN_MODAL_SUCCESS, text: headerText };
    dispatch(action);
  };
}

export function showSignUpModal() {
  return async dispatch => {
    const action = { type: types.SHOW_SIGNUP_MODAL_SUCCESS };
    dispatch(action);
  };
}

export function closeSignInSignUpModal() {
  return async dispatch => {
    const action = { type: types.CLOSE_SIGNIN_SIGNUP_MODAL_SUCCESS };
    dispatch(action);
  };
}
