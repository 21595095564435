import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import LoadingAnimation from '../../components/LoadingAnimation'
import HButton from '../../components/common/Basic/HButton'
import HInput from '../../components/common/Basic/HInput'
import './index.css'

const ForgotPage = () => {
  const [loading, setLoading] = useState(true)
  const [emailSent, setEmailSent] = useState(false)
  const [email, setEmail] = useState('')

  useEffect(() => {
    // Loading Data
    const fetchData = async () => {
      setLoading(false)
    }
    fetchData()
  }, [])

  if (loading) {
    return <LoadingAnimation />
  }

  return (
    <div className="forgot_page">
      <div className="forgot_page-container">
        <div className="forgot_page-header">
          <p>Hercules System</p>
        </div>
        <div className="forgot_page-body">
          {!emailSent && (
            <>
              {' '}
              <p>Enter the email address.</p>
              <div className="forgot_page-line">
                <HInput
                  className="forgot_page-email mr-2"
                  onChange={(ev) => {
                    setEmail(ev.currentTarget.value)
                  }}
                />
                <HButton
                  className="h-green-button h-large-button ml-2"
                  onClick={() => {
                    if (email) {
                      setEmailSent(true)
                    }
                  }}
                >
                  Send
                </HButton>
              </div>
            </>
          )}
          {emailSent && (
            <h3 style={{ textAlign: 'center' }}>
              Please check your email for your reset password instructions. Thank you!
            </h3>
          )}
        </div>
      </div>
    </div>
  )
}

export default withRouter(ForgotPage)
