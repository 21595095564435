import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function importReducer(state = initialState.importData, action) {
  console.log(action);
  switch (action.type) {
    case types.IMPORT_SETTING_SUCCESS:
      return { ...action.importData };
    case types.IMPORT_REMOVE_SUCCESS:
      return initialState.importData;
    default:
      return state;
  }
}
