import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import "./index.css";

const PageNotFound = ({ history, user }) => {
  const userSignedIn = user && user.confirmed;
  if (!userSignedIn) {
    history.push("/");
    return null;
  }

  return (
    <div className="blank-page">
      <p className="big-size-text">Oops! Page Not found.</p>
      <span className="small-size-text">
        Go to{" "}
        <a className="medium-size-text" href="/dashboard">
          Dashboard
        </a>{" "}
        page
      </span>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user && state.user.user
});
PageNotFound.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    confirmed: PropTypes.bool
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

const connectedHeader = connect(mapStateToProps, null)(PageNotFound);
export default withRouter(connectedHeader);
