import React from "react";
import PropTypes from "prop-types";
import { classNames } from "utils/helper";
import "./index.css";

const HCheckBox = ({ children, className, name, caption, ...rest }) => {
  const classes = classNames("h-checkbox-container ", className || "");

  return (
    <label className={classes} htmlFor={name}>
      <span className="h-checkbox-caption">{caption}</span>
      <input type="checkbox" id={name} {...rest} />
      <span className="h-checkbox-checkmark" />
      {children}
    </label>
  );
};
HCheckBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),

  className: PropTypes.string,
  name: PropTypes.string,
  caption: PropTypes.string
};

HCheckBox.defaultProps = {
  children: "",
  className: "",
  caption: "",
  name: ""
};
export default HCheckBox;
