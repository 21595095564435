import React from 'react'
import PropTypes from 'prop-types'
import { classNames } from 'utils/helper'
import './index.css'

const HInput = ({
  children,
  className,
  name,
  placeholder,
  inputType,
  error,
  uppercase,
  capitalize,
  searchBox,
  ...rest
}) => {
  let classes = classNames('h-input', className || '')
  if (error) {
    classes = classNames('h-input-error', classes)
  }
  if (uppercase) {
    classes = classNames('h-input-uppercase', classes)
  }
  if (capitalize) {
    classes = classNames('h-input-capitalize', classes)
  }
  if (searchBox) {
    classes = classNames('h-input-search-box', classes)
  }

  switch (inputType) {
    case 'mini':
      classes = classNames('h-input-mini', classes)
      return <input className={classes} {...rest} {...{ name, placeholder }} id={name} />
    case 'password':
      return (
        <input className={classes} {...rest} {...{ name, placeholder }} id={name} type="password" />
      )
    case 'number':
      return (
        <input className={classes} {...rest} {...{ name, placeholder }} id={name} type="number" />
      )
    case 'normal':
    default:
      return (
        <input className={classes} {...rest} {...{ name, placeholder }} id={name} type="input" />
      )
  }
}
HInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  className: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  inputType: PropTypes.string,
  error: PropTypes.string,
  uppercase: PropTypes.bool,
  capitalize: PropTypes.bool,
  searchBox: PropTypes.bool,
}

HInput.defaultProps = {
  children: '',
  className: null,
  id: '',
  placeholder: '',
  name: '',
  inputType: 'normal',
  error: '',
  uppercase: false,
  capitalize: false,
  searchBox: false,
}
export default HInput
