import React from 'react'
import PropTypes from 'prop-types'
import { classNames } from 'utils/helper'
import Select from 'react-select'
import './index.css'

const HDropButton = ({ children, className, name, placeholder, values, secondary, ...rest }) => {
  let classes = classNames('h-drop-button', className || '')
  if (secondary) {
    classes = classNames(classes, '-secondary')
  }
  const customStyle = {
    control: (styles) => ({
      ...styles,
      boxSizing: 'border-box',
      borderRadius: '5px',
      borderColor: '#5371f7',
      backgroundColor: '#ffffff',
      minHeight: secondary ? '30px' : '20px',
      height: secondary ? '30px' : '20px',
      color: '#000000',
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: '0px 0px 0px 0px',
    }),
    singleValue: (styles) => ({
      ...styles,
      marginLeft: '10px',
      marginRight: '10px',
    }),
    input: (styles) => ({
      ...styles,
      minHeight: secondary ? '30px' : '18px',
      height: secondary ? '30px' : '18px',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 10,
      margin: 0,
    }),
    menu: (styles) => ({
      ...styles,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: () => ({
      display: 'none',
    }),
    dropdownIndicator: () => ({
      display: 'none',
      height: secondary ? '30px' : '20px',
      minHeight: secondary ? '30px' : '20px',
    }),
    clearIndicator: () => ({
      display: 'none',
    }),
  }

  return (
    <Select
      className={classes}
      {...rest}
      {...{ name, placeholder }}
      styles={customStyle}
      options={values}
      instanceId={name}
    />
  )
}
HDropButton.propTypes = {
  className: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  secondary: PropTypes.bool,
}

HDropButton.defaultProps = {
  className: null,
  name: '',
  id: '',
  values: [],
  placeholder: '',
  secondary: false,
}
export default HDropButton
