export const PRODUCT_URL = 'product/getlist'
export const PRODUCT_UPDATE_URL = 'product/edit'
export const PRODUCT_ADD_URL = 'product/add'
export const PRODUCT_DELETE_URL = 'product/delete'
export const PRODUCT_GET_URL = 'product/get'

export const BOM_URL = 'bom/getProducts'
export const BOM_UPDATE_URL = 'bom/editProduct'
export const BOM_ADD_URL = 'bom/addProduct'
export const BOM_DELETE_URL = 'bom/delete'
export const BOM_GET_URL = 'bom/get'
export const BOM_CHILD_URL = 'bom/getchildren'

export const SUGGESTED_PRODUCT = 'suggested/product'
export const SUGGESTED_PART = 'suggested/part'
export const ACTION_URL = 'action'

export const PART_URL = 'part/getlist'
export const PART_ADD_URL = 'part/add'
export const PART_GET_URL = 'part/get'
export const PART_DELETE_URL = 'part/delete'
export const PART_UPDATE_URL = 'part/edit'

export const SCRAPER_URL = 'scraper/getscrapers'
export const USERS_URL = 'auth/getlist'
export const USER_UPDATE_URL = 'auth/edit'
export const USER_ADD_URL = 'auth/signup'
export const USER_GET_URL = 'auth/get'
export const USER_DEL_URL = 'auth/delete'

export const FILE_UPLOAD_URL = 'files/upload'

export const APPROVALS_PENDING_URL = 'log/getpendinglist'
export const APPROVALS_APPROVE_URL = 'log/approve'
export const APPROVALS_DECLINE_URL = 'log/decline'
export const APPROVALS_PENDING_RECENT = 'log/getrecent'
export const APPROVALS_GET_PENDING_ALL = 'log/getall'
export const APPROVALS_GET_PENDING = 'log/getpending'
// We don't have dashboard module
// export const DASHBOARD_COMMON_URL = 'dashboard/common'
export const DASHBOARD_COMMON_URL = 'part/common'

export const SUPPLIER_LIST_URL = 'supplier/getlist'
export const SUPPLIER_GET_URL = 'supplier/get'
export const SUPPLIER_ADD_URL = 'supplier/add'
export const SUPPLIER_SYNC_URL = 'supplier/sync'
export const SUPPLIER_EDIT_URL = 'supplier/edit'
export const SUPPLIER_DELETE_URL = 'supplier/delete'
export const SUPPLIER_ACTIVE_URL = 'supplier/getsuppliers'
export const MERGE_SUPPLIERS = 'supplier/mergeSupplier'
export const SUPPLIER_LOG_URL = 'supplier/getlogs'

export const SUPPLIER_CSV_URL = 'import/spreadsheet'
export const PRODUCT_CSV_URL = 'spreadsheet/uploadProductCSV'
export const PART_CSV_URL = 'spreadsheet/uploadPartCSV'

export const SEARCH_TOP_BAR = 'search/global'
export const SEARCH_PRODUCT = 'search/products'
export const SEARCH_PART = 'search/parts'
export const SEARCH_ITEMS = 'search/both'

// export const NOTIFICATION_URL = 'notifications/get'
export const NOTIFICATION_URL = 'notifications/getUnseenNotificationList'
export const NOTIFICATION_READ_URL = 'notifications/setNotificationSeen'
export const UOM_GET_URL = 'uom/getUomList'
export const SYNC_PRODUCT = 'salesforce/ProductSync'
export const SYNC_PART = 'salesforce/PartSync'
export const SYNC_BOM = 'salesforce/BOMProductSync'
