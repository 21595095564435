import React from 'react'
import PropTypes from 'prop-types'
import UserListItem from './UserListItem'
import './index.css'

const UsersList = ({ users, viewEditAction }) => {
  return (
    <div className="users-list-container">
      {users &&
        users.length > 0 &&
        users.map((user) => (
          <UserListItem
            key={user.id}
            name={user.name}
            user={user}
            viewEditAction={viewEditAction}
          />
        ))}
      {users && users.length === 0 && (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            textAlign: 'center',
            fontSize: '22px',
          }}
        >
          No users available.
        </span>
      )}
    </div>
  )
}
UsersList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({})),
  viewEditAction: PropTypes.func,
}

UsersList.defaultProps = {
  users: [],
  viewEditAction: () => {},
}
export default UsersList
