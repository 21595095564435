import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import PropTypes from "prop-types";
import { getSuggestedParts } from "../../../api/productApi";
import MediumProduct from "../../Products/MediumProduct";
import "./index.css";

const SuggestedSupplierParts = ({ product, viewProduct }) => {
  const [linkedProducts, setLinkedProducts] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const products = await getSuggestedParts(product.id);
        if (products && products.error) {
          console.log("No Suggested Products");
          return;
        }
        setLinkedProducts(products);
        setLoading(false);
      } catch (ev) {
        console.log("SuggestedSupplierParts error", ev);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="filters-container">
      <div className="filters-container-caption">
        <span>Suggested Supplier Parts</span>
      </div>
      <div className="filters-container-seperator" />
      <div className="filters-container-body">
        {loading && (
          <div
            className="row align-items-center justify-content-center
          "
          >
            <Spinner animation="border" variant="primary" />
          </div>
        )}{" "}
        {!loading &&
          linkedProducts &&
          linkedProducts.map(product => {
            return (
              <div className="suggested-linked-products-item">
                <MediumProduct product={product} viewProduct={viewProduct} />
              </div>
            );
          })}
      </div>
    </div>
  );
};
SuggestedSupplierParts.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    productNumber: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    incomeAccount: PropTypes.string.isRequired,
    productPictureUrl: PropTypes.string,
    productDescription: PropTypes.string
  }),
  viewProduct: PropTypes.func
};

SuggestedSupplierParts.defaultProps = {
  product: {
    id: 1,
    productNumber: "230605",
    productDescription: "Sunblock, SPF 30, 8oz, 1/each",
    price: 24.99,
    incomeAccount: "Disposable - Sales",
    productPictureUrl: "[]"
  },
  viewProduct: () => {}
};
export default SuggestedSupplierParts;
