import React from "react";
import PropTypes from "prop-types";
import ApprovalsListItem from "./ApprovalsListItem";
import "./index.css";

const ApprovalsList = ({
  approvals,
  viewEditAction,
  approveAction,
  declineAction,
  selectedItems,
  setSelectedItems
}) => {
  return (
    <div className="approvals-list-container">
      {approvals &&
        approvals.length > 0 &&
        approvals.map((approvalItem, index) => (
          <ApprovalsListItem
            key={approvalItem.id}
            name={approvalItem.name}
            item={approvalItem}
            viewEditAction={viewEditAction}
            approveAction={approveAction}
            declineAction={declineAction}
            checked={selectedItems[index]}
            setChecked={value => {
              const itemsStatus = [...selectedItems];
              itemsStatus[index] = value;
              setSelectedItems(itemsStatus);
            }}
          />
        ))}
      {approvals && approvals.length === 0 && (
        <span>
          <img src="/assets/img/approval/caption_box.svg" alt="" width="50px" />
          &nbsp;&nbsp;No Pending Changes. Please try again later.
        </span>
      )}
    </div>
  );
};
ApprovalsList.propTypes = {
  approvals: PropTypes.arrayOf(PropTypes.shape({})),
  viewEditAction: PropTypes.func,
  approveAction: PropTypes.func,
  declineAction: PropTypes.func,
  selectedItems: PropTypes.arrayOf(PropTypes.bool),
  setSelectedItems: PropTypes.func
};

ApprovalsList.defaultProps = {
  approvals: [],
  viewEditAction: () => {},
  approveAction: () => {},
  declineAction: () => {},
  selectedItems: [],
  setSelectedItems: () => {}
};
export default ApprovalsList;
