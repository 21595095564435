/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import {
  closeSignInSignUpModal,
  showSignInModal
} from "redux/actions/modalActions";
import { REQUIRED_VALIDATION, INVALID_EMAIL } from "consts/form";
import { signup } from "api/authApi";
import FormError from "components/common/Basic/FormError";
import LoadingAnimation from "components/LoadingAnimation";

const validationSchema = yup.object().shape({
  firstName: yup.string().required(REQUIRED_VALIDATION),
  lastName: yup.string().required(REQUIRED_VALIDATION),
  email: yup
    .string()
    .email(INVALID_EMAIL)
    .required(REQUIRED_VALIDATION),
  phone: yup.string().required(REQUIRED_VALIDATION),
  companyName: yup.string(),
  address: yup.string().required(REQUIRED_VALIDATION),
  city: yup.string().required(REQUIRED_VALIDATION),
  zip: yup.string().required(REQUIRED_VALIDATION),
  password: yup.string().required(REQUIRED_VALIDATION),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
});

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  companyName: "",
  address: "",
  city: "",
  zip: "",
  password: "",
  passwordConfirmation: ""
};

const SignUpModal = ({
  signUpModalShown,
  showSignInModal,
  closeSignInSignUpModal
}) => {
  const [loading, setLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState(null);
  const [confirmStatus, setConfirmStatus] = useState(false);

  async function onSubmit(formData) {
    console.log("--> formdata", formData);
    setLoading(true);
    try {
      const body = { newUser: formData };
      console.log(body);
      await signup({ newUser: formData });
      setConfirmStatus(true);
    } catch (e) {
      setConfirmStatus(false);
      setErrorMessages(["Oops, something went wrong, Please try again."]);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      show={signUpModalShown}
      size="lg"
      onHide={() => {
        closeSignInSignUpModal();
      }}
    >
      {loading && <LoadingAnimation />}
      <button
        className="close"
        type="button"
        data-dismiss="modal"
        aria-label="Close"
        onClick={() => {
          closeSignInSignUpModal();
        }}
      />
      <Modal.Header className="h-modal-header">
        <h3>
          {" "}
          <img
            src="assets/img/common/icon.png"
            alt="icon"
            width="30px"
            height="30px"
          />
          Create your Hercules account
        </h3>
      </Modal.Header>

      <Modal.Body className="modal--sign-up">
        {!confirmStatus && (
          <>
            {errorMessages && (
              <div className="alert-error-message-container">
                <div className="alert-invalid-input">
                  <svg className="icon icon-warning">
                    <use xlinkHref="#spriteIcon-warning" />
                  </svg>
                  <span>{errorMessages.join(", ")}</span>
                </div>
              </div>
            )}
            <Formik
              {...{ initialValues, validationSchema }}
              validateOnChange
              validateOnBlur
              onSubmit={form => {
                onSubmit(form);
              }}
            >
              {({ values }) => (
                <Form autoComplete="off">
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <Field
                          type="text"
                          className="form-control"
                          name="firstName"
                        />
                        <FormError name="firstName" />
                        <label className="label-focused">First Name</label>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <Field
                          type="text"
                          className="form-control"
                          name="lastName"
                        />
                        <FormError name="lastName" />
                        <label className="label-focused">Last Name</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <Field
                          type="email"
                          className="form-control"
                          name="email"
                        />
                        <FormError name="email" />
                        <label className="label-focused">Email</label>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <Field
                          type="text"
                          className="form-control"
                          name="phone"
                        />
                        <FormError name="phone" />
                        <label className="label-focused">Phone Number</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <Field
                          type="text"
                          className="form-control"
                          name="companyName"
                        />
                        <FormError name="companyName" />
                        <label className="label-focused">
                          Company Name (Optional)
                        </label>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <Field
                          type="text"
                          className="form-control"
                          name="address"
                        />
                        <FormError name="address" />
                        <label className="label-focused">Address</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <Field
                          type="text"
                          className="form-control"
                          name="city"
                        />
                        <FormError name="city" />
                        <label
                          className={classnames({
                            "label-focused": values.city
                          })}
                        >
                          City
                        </label>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <Field
                          type="text"
                          className="form-control"
                          name="zip"
                        />
                        <FormError name="zip" />

                        <label className="label-focused">Postal Code</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <Field
                          type="password"
                          className="form-control"
                          name="password"
                        />
                        <FormError name="password" />
                        <label className="label-focused">Password</label>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <Field
                          type="password"
                          className="form-control"
                          name="passwordConfirmation"
                        />
                        <FormError name="passwordConfirmation" />
                        <label className="label-focused">
                          Confirm Password
                        </label>
                      </div>
                    </div>
                  </div>
                  <button className="btn btn-block btn-primary" type="submit">
                    Sign Up
                  </button>
                </Form>
              )}
            </Formik>
          </>
        )}

        {confirmStatus && (
          <h3 style={{ textAlign: "center" }}>
            Please confirm your email address
          </h3>
        )}
        <div className="text-center mt-4">Already have an account? &nbsp;</div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = state => ({
  signUpModalShown: state.modal.signUpModalShown
});

const mapDispatchToProps = {
  closeSignInSignUpModal,
  showSignInModal
};

SignUpModal.propTypes = {
  signUpModalShown: PropTypes.bool,
  closeSignInSignUpModal: PropTypes.func.isRequired,
  showSignInModal: PropTypes.func.isRequired
};

SignUpModal.defaultProps = {
  signUpModalShown: false
};

const connectedSignUpModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpModal);

export default connectedSignUpModal;
