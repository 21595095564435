import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import { useDropzone } from 'react-dropzone'
import { MediumLoadingAnimation } from 'components/LoadingAnimation'
import XLSX from 'xlsx'
import Table from 'react-bootstrap/Table'
import { getShortString } from 'utils/formatter'
import HButton from 'components/common/Basic/HButton'
import HSingleSelect from 'components/common/Basic/HSingleSelect'
import { PART_MAPPING, PRODUCT_MAPPING } from 'consts/common'
import { uploadImage, uploadPartCsv, uploadProductCsv } from 'api/productApi'
import { store } from 'react-notifications-component'
import './index.css'

const CsvImportModal = ({ show, onHide, isProduct, isPart, isSupplier }) => {
  const [headerText, setHeaderText] = useState('')
  const { acceptedFiles, getRootProps, open, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: '.csv, .xlsx',
  })
  const [tableData, setTableData] = useState([])
  const [columnNames, setColumnNames] = useState([])
  const [mappingNames, setMappingNames] = useState([])
  const [loading, setLoading] = useState(false)
  const [fileInfo, setFileInfo] = useState({})
  const [rowCount, setRowCount] = useState(0)
  const [importedFile, setImportedFile] = useState(null)
  const [mappings, setMappings] = useState([])

  useEffect(() => {
    if (isProduct) {
      console.log('ProductCsvImportModal')
      setHeaderText('Import Products')
      setMappings(PRODUCT_MAPPING)
    } else if (isPart) {
      console.log('PartCsvImportModal')
      setHeaderText('Import Supplier Items')
      setMappings(PART_MAPPING)
    } else if (isSupplier) {
      console.log('SupplierCsvImportModal')
      setHeaderText('Import Suppliers')
      setMappings(PART_MAPPING)
    }
  }, [])

  useEffect(() => {
    console.log('acceptedFiles', acceptedFiles)
    if (acceptedFiles.length > 0) {
      const loadedFile = acceptedFiles[0]
      const size = loadedFile.size / 1024 / 1024

      setImportedFile(loadedFile)
      setFileInfo({
        fileName: loadedFile.name,
        size: `${size.toFixed(2)}MB`,
        type: loadedFile.type,
      })
      const reader = new FileReader()
      const rABS = !!reader.readAsBinaryString
      reader.onload = ({ target: { result } }) => {
        const wb = XLSX.read(result, { type: rABS ? 'binary' : 'array' })
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
        console.log('data', data)
        if (!data || !data.length) {
          setLoading(false)
        } else {
          setColumnNames(data[0])
          const defaultMappings = []
          data[0].forEach((data, index) => {
            defaultMappings.push(
              mappings.find((item) => {
                return item === data
              }) || 'None'
            )
          })
          setMappingNames(defaultMappings)
          setRowCount(data.length - 1)
          if (data.length <= 10) {
            setTableData(data.slice(1))
          } else {
            setTableData(data.slice(1, 11))
          }
        }
        setLoading(false)
      }
      setLoading(true)
      if (rABS) reader.readAsBinaryString(loadedFile)
      else reader.readAsArrayBuffer(loadedFile)
    }
  }, [acceptedFiles])

  const importProduct = async () => {
    console.log('uploadProductCsv file', importedFile, mappingNames)
    let mappings = {}
    let requiredFields = 0
    mappingNames.forEach((value, index) => {
      if (value) {
        const newMappingItem = {}
        newMappingItem[value] = columnNames[index]
        mappings = { ...mappings, ...newMappingItem }
      }
    })
    console.log('uploadProductCsv mapping', mappings)
    if (!Object.keys(mappings).length) {
      console.log('uploadProductCsv mappings null')
      store.addNotification({
        message: 'Sorry, no mappings are selected. Please try again.',
        type: 'warning',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
        },
      })
      setLoading(false)
      return
    }

    setLoading(true)
    const filePath = importedFile.name
    const fileSize = importedFile.size
    if (fileSize >= 10 * 1024 * 1024) {
      store.addNotification({
        message: 'Sorry, limit of CSV file is 10MB. Please select another file.',
        type: 'warning',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
        },
      })
      setLoading(false)
      return
    }
    const uploadedFile = await uploadImage(importedFile)
    if (!uploadedFile || (uploadedFile && uploadedFile.error) || !uploadedFile.url) {
      console.log('Uploading Error', uploadedFile)
      store.addNotification({
        message: 'Sorry, we are not able to process your request now, please try again later.',
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
        },
      })
      setLoading(false)
      return
    }

    const uploadingResult = await uploadProductCsv(uploadedFile.url, mappings)
    if (!uploadingResult || (uploadingResult && uploadingResult.error)) {
      console.log('Uploading error', uploadingResult)
      store.addNotification({
        message: 'Sorry, we are not able to process your request now, please try again later.',
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
        },
      })
      setLoading(false)
      return
    }
    store.addNotification({
      message: 'File Uploaded Successfully, Updating to DB.',
      type: 'success',
      insert: 'top',
      container: 'top-center',
      animationIn: ['animated', 'bounceIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: 3000,
      },
    })
    setLoading(false)
  }

  const importPart = async () => {
    console.log('uploadPartCsv file', importedFile, mappingNames)
    let mappings = {}
    let requiredFields = 0
    mappingNames.forEach((value, index) => {
      if (value) {
        const newMappingItem = {}
        newMappingItem[value] = columnNames[index]
        mappings = { ...mappings, ...newMappingItem }
        if (value === 'UOM') {
          requiredFields++
        } else if (value === 'VendorPartNumber') {
          requiredFields++
        } else if (value === 'Vendor') {
          requiredFields++
        }
      }
    })
    if (requiredFields !== 3) {
      console.log('uploadPartCsv mapping', mappings)
      store.addNotification({
        message: 'Sorry, please select required fields. - Vendor, VendorPartNumber and UOM',
        type: 'warning',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
        },
      })
      setLoading(false)
      return
    }
    console.log('uploadPartCsv mapping', mappings)
    if (!Object.keys(mappings).length) {
      console.log('uploadPartCsv mappings null')
      store.addNotification({
        message: 'Sorry, no mappings are selected. Please try again.',
        type: 'warning',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
        },
      })
      setLoading(false)
      return
    }

    setLoading(true)
    const filePath = importedFile.name
    const fileSize = importedFile.size
    if (fileSize >= 10 * 1024 * 1024) {
      store.addNotification({
        message: 'Sorry, limit of CSV file is 10MB. Please select another file.',
        type: 'warning',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
        },
      })
      setLoading(false)
      return
    }
    const uploadedFile = await uploadImage(importedFile)
    if (!uploadedFile || (uploadedFile && uploadedFile.error) || !uploadedFile.url) {
      console.log('Uploading Error', uploadedFile)
      store.addNotification({
        message: 'Sorry, we are not able to process your request now, please try again later.',
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
        },
      })
      setLoading(false)
      return
    }

    const uploadingResult = await uploadPartCsv(uploadedFile.url, mappings)
    if (!uploadingResult || (uploadingResult && uploadingResult.error)) {
      console.log('Uploading error', uploadingResult)
      store.addNotification({
        message: 'Sorry, we are not able to process your request now, please try again later.',
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
        },
      })
      setLoading(false)
      return
    }
    store.addNotification({
      message: 'File Uploaded Successfully, Updating to DB.',
      type: 'success',
      insert: 'top',
      container: 'top-center',
      animationIn: ['animated', 'bounceIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: 3000,
      },
    })
    setLoading(false)
  }
  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => {
        setFileInfo({})
        setRowCount(0)
        setColumnNames([])
        setTableData([])
        setLoading(false)
        onHide()
      }}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header bsPrefix="csv-header" closeButton>
        <div className="csv-modal-header">
          <h2>{headerText}</h2>
        </div>
      </Modal.Header>
      <Modal.Body className="modal--quick-view">
        {loading && <MediumLoadingAnimation />}
        {!loading && (
          <>
            {(!tableData || !tableData.length || !columnNames || !columnNames.length) && (
              <div {...getRootProps({ className: 'csv-modal-dropzone' })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop csv (xlsx) file here, or click to select csv (xlsx) file</p>
                <button type="button" onClick={open}>
                  Open File Dialog
                </button>
              </div>
            )}
            {tableData && tableData.length > 0 && columnNames && columnNames.length > 0 && (
              <div className="csv-modal-body">
                <div className="csv-modal-file-info">
                  <span className="heading">
                    Name: <span className="info-text">{getShortString(fileInfo.fileName, 20)}</span>
                  </span>
                  <span className="heading">
                    Size: <span className="info-text">{fileInfo.size}</span>
                  </span>
                  <span className="heading">
                    Type: <span className="info-text">{getShortString(fileInfo.type, 10)}</span>
                  </span>
                  <span className="heading">
                    Count: <span className="info-text">{rowCount}</span>
                  </span>
                  <button
                    type="button"
                    className="close csv-modal-close-button"
                    onClick={() => {
                      setFileInfo({})
                      setRowCount(0)
                      setColumnNames([])
                      setTableData([])
                      setLoading(false)
                    }}
                  >
                    <span aria-hidden="true">x</span>
                    <span className="sr-only">Close</span>
                  </button>
                </div>
                <div className="csv-modal-items-table-container">
                  <Table striped bordered hover size="sm" className="css-modal-items-table">
                    <tr>
                      <th className="csv-modal-items-table-mapping-item-index"></th>
                      {columnNames &&
                        columnNames.map((column, index) => {
                          return (
                            <th
                              className="csv-modal-items-table-mapping-item"
                              style={{ width: '200px' }}
                            >
                              <HSingleSelect
                                values={mappings.map((item) => ({
                                  value: item,
                                  label: `${item}`,
                                }))}
                                defaultValue={{
                                  value: `${mappings.find((item) => {
                                    return item === column
                                  }) || 'None'}`,
                                  label: `${mappings.find((item) => {
                                    return item === column
                                  }) || 'None'}`,
                                }}
                                name="MAPPING"
                                onChange={(mappingValue) => {
                                  const mappings = [...mappingNames]
                                  if (mappingValue.value !== 'None') {
                                    mappings[index] = mappingValue.value
                                  } else {
                                    mappings[index] = ''
                                  }
                                  setMappingNames(mappings)
                                }}
                              />
                            </th>
                          )
                        })}
                    </tr>
                    <tr>
                      <td>#</td>
                      {columnNames &&
                        columnNames.map((column) => {
                          return <th>{column}</th>
                        })}
                    </tr>
                    {tableData &&
                      tableData.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            {columnNames.map((column, colIndex) => {
                              if (item && item[colIndex]) {
                                return (
                                  <td key={colIndex}>{getShortString(`${item[colIndex]}`, 50)}</td>
                                )
                              } else {
                                return <td key={colIndex} />
                              }
                            })}
                          </tr>
                        )
                      })}
                    {rowCount && rowCount > 10 && (
                      <tr>
                        <td colSpan={columnNames.length + 1} align="center">
                          <span className="etc-mark">...</span>
                        </td>
                      </tr>
                    )}
                  </Table>
                </div>
                <div className="csv-modal-actions">
                  <HButton
                    className="h-green-button csv-modal-action-button"
                    onClick={() => {
                      console.log('onUpload')
                      if (isPart || isSupplier) {
                        importPart()
                      }
                      if (isProduct) {
                        importProduct()
                      }
                    }}
                  >
                    Upload
                  </HButton>
                  <HButton
                    className="h-pink-button csv-modal-action-button"
                    onClick={() => {
                      setFileInfo({})
                      setRowCount(0)
                      setColumnNames([])
                      setTableData([])
                      setLoading(false)
                    }}
                  >
                    Cancel
                  </HButton>
                </div>
              </div>
            )}
          </>
        )}
      </Modal.Body>
      {/* <Modal.Footer></Modal.Footer> */}
    </Modal>
  )
}

CsvImportModal.propTypes = {
  show: PropTypes.bool,
  isProduct: PropTypes.bool,
  isPart: PropTypes.bool,
  isSupplier: PropTypes.bool,
}
CsvImportModal.defaultProps = {
  show: false,
  isProduct: false,
  isPart: false,
  isSupplier: false,
}
export default CsvImportModal
