import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import MediumCompetitor from '../../Scrapers/MediumCompetitor'
import './index.css'

const SuggestedCompetitorsProducts = ({ caption, secondary, competitors, viewCompetitor }) => {
  return (
    <div className="filters-container">
      <div className="filters-container-caption">
        <span>{caption}</span>
      </div>
      <div className="filters-container-seperator" />
      <div className="filters-container-body-competitor">
        {competitors &&
          competitors.map((competitor) => {
            return (
              <div key={competitor.id} className="suggested-linked-products-item">
                <MediumCompetitor competitor={competitor} viewCompetitor={viewCompetitor} />
              </div>
            )
          })}
        {(!competitors || competitors.length === 0) && (
          <div className="suggested-linked-products-empty-list">
            {!secondary && (
              <span>
                No matching competitor products found. Please{' '}
                <a href="mailto:support@atlastechnologies.xyz">Contact Support.</a> if you'd like to
                begin tracking more competitors.
              </span>
            )}
            {secondary && (
              <span>
                No matching products found. Please{' '}
                <a href="mailto:support@atlastechnologies.xyz">Contact Support.</a> if you'd like to
                begin tracking more similar products.
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
SuggestedCompetitorsProducts.propTypes = {
  caption: PropTypes.string,
  competitors: PropTypes.arrayOf(PropTypes.shape({})),
  viewCompetitor: PropTypes.func,
  secondary: PropTypes.bool,
}

SuggestedCompetitorsProducts.defaultProps = {
  caption: 'Suggested Competitor Products',
  product: [],
  viewCompetitor: () => {},
  secondary: false,
}
export default SuggestedCompetitorsProducts
