/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import './Sidebar.css'

const SignOutButton = ({ signoutAction }) => {
  return (
    <div
      className="nav-signout-button"
      onClick={() => {
        signoutAction()
      }}
    >
      <span className="nav-signout-button-caption">Sign Out</span>
      <img src="/assets/img/sidebar/logout.svg" alt="SignOut" width="20px" height="20px" />
    </div>
  )
}

export default SignOutButton
