import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import './index.css'

const SupplierMergeModal = ({ onHide, content, okCaption, okAction, cancelAction }) => {
  return (
    <Modal size="sm" show onHide={onHide}>
      <Modal.Body>
        <div className="delete-warning-content">{content}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-danger"
          type="button"
          onClick={() => {
            okAction()
          }}
        >
          {okCaption}
        </Button>
        <Button variant="primary sm" type="button" onClick={cancelAction}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

SupplierMergeModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  content: PropTypes.string,
  okCaption: PropTypes.string,
  okAction: PropTypes.func,
  cancelAction: PropTypes.func,
}
SupplierMergeModal.defaultProps = {
  content: '',
  okCaption: 'Merge',
  okAction: () => {},
  cancelAction: () => {},
}
export default SupplierMergeModal
