export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";

// JWT Authentication
export const BEGIN_API_AUTH = "BEGIN_API_AUTH";
export const API_AUTH_ERROR = "API_AUTH_ERROR";
export const API_AUTH_SUCCESS = "API_AUTH_SUCCESS";

// Filter
export const FILTER_SETTING_SUCCESS = "FILTER_SETTING_SUCCESS";
export const CUSTOM_FILTER_SETTING_SUCCESS = "CUSTOM_FILTER_SETTING_SUCCESS";
export const FILTER_REMOVE_SUCCESS = "FILTER_REMOVE_SUCCESS";
export const CUSTOM_FILTER_REMOVE_SUCCESS = "CUSTOM_FILTER_REMOVE_SUCCESS";

// Fetching Product detail
export const FETCH_PRODUCT_DETAIL_SUCCESS = "FETCH_PRODUCT_DETAIL_SUCCESS";
export const FETCH_PRODUCT_DETAIL_FAILED = "FETCH_PRODUCT_DETAIL_SUCCESS";

// Modals
export const SHOW_SIGNIN_MODAL_SUCCESS = "SHOW_SIGNIN_MODAL_SUCCESS";
export const SHOW_SIGNUP_MODAL_SUCCESS = "SHOW_SIGNUP_MODAL_SUCCESS";

export const CLOSE_SIGNIN_SIGNUP_MODAL_SUCCESS = "CLOSE_SIGNIN_MODAL_SUCCESS";

// Auth
export const USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS";
export const USER_SINGUP_FAILED = "USER_SINGUP_FAILED";

export const USER_SIGNIN_SUCCESS = "USER_SIGNIN_SUCCESS";
export const USER_SIGNIN_FAILED = "USER_SIGNIN_FAILED";

export const USER_SIGNOUT_SUCCESS = "USER_SIGNOUT_SUCCESS";

// TopBar Search
export const TOP_BAR_SEARCH_RESULT_SUCCESS = "TOP_BAR_SEARCH_RESULT_SUCCESS";
export const TOP_BAR_SEARCH_RESULT_FAILED = "TOP_BAR_SEARCH_RESULT_FAILED";

// Import Part as a Product
export const IMPORT_SETTING_SUCCESS = "IMPORT_SETTING_SUCCESS";
export const IMPORT_REMOVE_SUCCESS = "IMPORT_REMOVE_SUCCESS";
