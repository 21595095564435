/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { withRouter, useParams, Link, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import LoadingAnimation from 'components/LoadingAnimation'
import Caption from 'components/ApproveDecline/Caption'
import { toDecimal } from 'utils/formatter'
import HForm from 'components/common/Basic/HForm'
import HUOMTable from 'components/common/Basic/HUOMTable'
import EditViewActions from 'components/common/Filter/EditViewActions'
import ProductPreview from 'components/Products/ProductPreview'
import PartPreview from 'components/Parts/PartPreview'
import { getPending, approvePending, declinePending } from 'api/approvalApi'
import AddMedia from 'components/common/Filter/AddMedia'
import { store } from 'react-notifications-component'
import HBomChildsTable from 'components/common/Basic/HBomChildsTable'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'

import './index.css'

const ApprovalDetail = ({ signedInUser }) => {
  const { id } = useParams()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState({})
  const [action, setAction] = useState('')
  const [status, setStatus] = useState('pending')
  const [objectType, setObjectType] = useState('Product')
  const [orgState, setOrgState] = useState({})
  const [newState, setNewState] = useState({})
  const [objectId, setObjectId] = useState(null)
  const [objectLink, setObjectLink] = useState('')

  const [headerText, setHeaderText] = useState('')
  const [createdAt, setCreatedAt] = useState('')

  const [showProduct, setShowProduct] = useState({})
  const [orgProduct, setOrgProduct] = useState({})
  const [diff, setDiff] = useState({})
  const [bomChilds, setBomChilds] = useState([])
  const [objectName, setObjectName] = useState('')

  const backToList = () => {
    if (!(signedInUser && signedInUser.confirmed && signedInUser.isAdmin)) {
      history.goBack()
    } else {
      history.push('/approvals/view')
    }
  }

  const getTimeBy = (time) => {
    try {
      const dateDistance = formatDistanceToNow(new Date(time))
      return `${dateDistance} ago`
    } catch (error) {
      console.log('Error', error)
    }
    return ''
  }

  useEffect(() => {
    if (!(signedInUser && signedInUser.confirmed && signedInUser.isAdmin)) {
      store.addNotification({
        message: "Sorry, you don't have administrative right.",
        type: 'info',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      })

      setTimeout(() => {
        history.goBack()
      }, 3000)
    } else {
      if (!id) {
        backToList()
      }
      setLoading(true)
      const getData = async () => {
        try {
          const curApproval = await getPending(id)
          if (curApproval && curApproval.error) {
            console.log(curApproval.error)
            store.addNotification({
              message: `${curApproval.error}. Sorry, we are not able to process your request now. `,
              type: 'danger',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animated', 'bounceIn'],
              animationOut: ['animated', 'fadeOut'],
              dismiss: {
                duration: 4000,
              },
            })
            setTimeout(() => {
              history.goBack()
            }, 3000)
          } else {
            setUser(curApproval.log.user)
            setStatus(curApproval.log.status)
            setObjectType(curApproval.log.objectType)
            setAction(curApproval.log.action)
            setObjectId(curApproval.log.objectId)
            setCreatedAt(curApproval.log.createdAt)
            setOrgState(curApproval.log.oldState)
            setNewState(curApproval.log.newState)
          }
        } catch (error) {
          console.log('Pending error', error)
          store.addNotification({
            message: 'Sorry, we are not able to process your request now. Please try again later.',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 4000,
            },
          })
        }
        setLoading(false)
      }
      getData()
    }
  }, [id])

  useEffect(() => {
    if (action === 'add' || action === 'added') {
      setShowProduct(newState)
      setObjectName(
        newState.description || newState.productDescription || newState?.product?.productDescription
      )
      if (objectType === 'Product') {
        setHeaderText(`New product was added by `)
      } else if (objectType === 'Part') {
        setHeaderText(`New part was added by `)
      } else if (objectType === 'BOMProduct') {
        setShowProduct(newState.product)
        setHeaderText(`New BOM was added by `)
      }
      setObjectLink('')
    } else if (action === 'edit') {
      setOrgProduct(orgState)
      setShowProduct(newState)
      setObjectName(newState.description || newState.productDescription)
      if (objectType === 'Product') {
        setHeaderText(` was changed by `)
        setObjectLink(`/products/view/${objectId}`)
      } else if (objectType === 'Part') {
        setHeaderText(` was changed by `)
        setObjectLink(`/supplier-part-list/${objectId}`)
      } else if (objectType === 'BOMProduct') {
        setHeaderText(` was changed by `)
        setObjectLink(`/boms/view/${objectId}`)
      }

      const keys = Object.keys(orgState)
      let key = null

      const diffs = {}
      // eslint-disable-next-line no-restricted-syntax
      for (key of keys) {
        if (orgState[key] !== newState[key]) {
          diffs[key] = true
        } else {
          diffs[key] = false
        }
      }
      setDiff(diffs)
    } else if (action === 'delete') {
      setShowProduct(orgState)
      setObjectName(orgState.description || orgState.productDescription)
      if (objectType === 'Product') {
        setHeaderText(` was deleted by `)
        setObjectLink(`/products/view/${objectId}`)
      } else if (objectType === 'Part') {
        setHeaderText(` was deleted by `)
        setObjectLink(`/products/view/${objectId}`)
      } else if (objectType === 'BOMProduct') {
        setHeaderText(` was deleted by `)
        setObjectLink(`/boms/view/${objectId}`)
      }
    }
  }, [orgState, newState])

  const DetailRow = ({ diff, title, value }) => (
    <div className={`view-item-row ${diff && 'diff'}`}>
      <div className="view-item-row-header">{title}</div>
      <span> {value} </span>
    </div>
  )

  const onSave = () => {
    setLoading(true)
    const approveAction = async () => {
      try {
        const response = await approvePending(id)
        setLoading(false)

        if (response && response.error) {
          store.addNotification({
            message: 'Sorry, we are not able to process your request now. Please try again later.',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 4000,
            },
          })
        } else if (response && response.message) {
          store.addNotification({
            message: 'Successfully approved. Thank you!',
            type: 'success',
            insert: 'right',
            container: 'top-center',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 4000,
            },
          })
          setTimeout(() => {
            backToList()
          }, 3000)
        }
      } catch (err) {
        store.addNotification({
          message: 'Sorry, we are not able to process your request now. Please try again later.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 4000,
          },
        })
      }
    }
    approveAction()
  }

  const onDecline = () => {
    setLoading(true)

    const declineAction = async () => {
      try {
        const response = await declinePending(id)
        if (response && response.error) {
          console.log(response.error)
          store.addNotification({
            message: 'Sorry, we are not able to process your request now. Please try again later.',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 4000,
            },
          })
        } else if (response && response.message) {
          store.addNotification({
            message: 'Successfully declined.',
            type: 'success',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 4000,
            },
          })
          setTimeout(() => {
            backToList()
          }, 3000)
        }
      } catch (err) {
        console.log('Decline Error: ', err)
        store.addNotification({
          message: 'Sorry, we are not able to process your request now. Please try again later.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 4000,
          },
        })
      }
      setLoading(false)
    }
    declineAction()
  }
  return (
    <div className="detail-container">
      <div className="approval-detail">
        {loading && <LoadingAnimation />}
        <div className="view-approvals-list">
          <div className="view-approvals-list-title">
            <Caption
              caption={`${objectName}`}
              backAction={() => {
                history.push('/approvals/view')
              }}
              tertiary
            />
          </div>
          <div className="horizontal-separator" />
          <div className="view-approvals-edit-view">
            <div className="view-approvals-list-header">
              {action !== 'add' && action !== 'added' ? (
                <span>
                  <Link to={objectLink}>{`${objectType}(ID: ${objectId})`}</Link>
                  {headerText}
                  <Link to={`/users/view/${user.id}`}>{user.username} </Link>
                </span>
              ) : (
                <span>
                  {headerText}
                  <Link to={`/users/view/${user.id}`}>{user.username} </Link>
                </span>
              )}
            </div>
            {objectType === 'Product' && <ProductPreview product={showProduct} />}
            {objectType === 'BOMProduct' && <ProductPreview product={showProduct} />}
            {objectType === 'Part' && <PartPreview part={showProduct} />}
            <HForm className="edit-view-item-form">
              {objectType === 'Part' && (
                <>
                  <DetailRow
                    diff={diff.manufacturerId}
                    title="Part Number:"
                    value={showProduct && showProduct?.manufacturerId}
                  />
                  <DetailRow
                    diff={diff.manufacturerName}
                    title="Manufacturer:"
                    value={showProduct && showProduct?.manufacturerName}
                  />
                  <DetailRow
                    diff={diff.vendor}
                    title="Vendor:"
                    value={showProduct && showProduct?.vendor}
                  />
                  <DetailRow
                    diff={diff.vendorPartNumber}
                    title="Vendor Number:"
                    value={showProduct && showProduct?.vendorPartNumber}
                  />
                  <DetailRow
                    diff={diff.description}
                    title="Part Description:"
                    value={showProduct && showProduct?.description}
                  />
                  <DetailRow
                    diff={diff.details}
                    title="Part Details:"
                    value={showProduct && showProduct?.details}
                  />
                  <DetailRow
                    diff={diff.defaultVendor}
                    title="Default Vendor:"
                    value={showProduct && showProduct?.defaultVendor ? 'True' : 'False'}
                  />
                  <DetailRow
                    diff={diff.leadTime}
                    title="Lead Time:"
                    value={showProduct && showProduct?.leadTime}
                  />
                  <DetailRow
                    diff={diff.partPictureUrl}
                    title="Picture URL:"
                    value={showProduct && showProduct?.partPictureUrl}
                  />
                  <DetailRow
                    diff={diff.defaultFlag}
                    title="Default Flag:"
                    value={showProduct && showProduct?.defaultFlag}
                  />
                  <div className="view-item-row">
                    <HUOMTable
                      columns={[
                        'UOM',
                        'Per',
                        'Cost',
                        'Weight',
                        'WeightUom',
                        'Length',
                        'Width',
                        'Height',
                        'SizeUom',
                      ]}
                      data={showProduct?.UOMs}
                      setData={() => {}}
                      selectMode
                      onSelect={() => {}}
                    />
                  </div>
                </>
              )}
              {objectType === 'Product' && (
                <>
                  <DetailRow
                    diff={diff.partNumber}
                    title="Part Number:"
                    value={showProduct && showProduct?.partNumber?.id || showProduct?.partNumber}
                  />
                  <DetailRow
                    diff={diff.productNumber}
                    title="Product Number:"
                    value={showProduct && showProduct?.productNumber}
                  />
                  <DetailRow
                    diff={diff.productDescription}
                    title="Product Description:"
                    value={showProduct && showProduct?.productDescription}
                  />
                  <DetailRow
                    diff={diff.productDetails}
                    title="Product Details:"
                    value={showProduct && showProduct?.productDetails}
                  />
                  <DetailRow diff={diff.uom} title="UOM:" value={showProduct && showProduct?.uom} />
                  <DetailRow
                    diff={diff.price}
                    title="Price:"
                    value={`$${toDecimal(showProduct && showProduct?.price)}`}
                  />
                  <DetailRow
                    diff={diff.active}
                    title="Active:"
                    value={showProduct && showProduct?.active ? 'True' : 'False'}
                  />
                  <DetailRow
                    diff={diff.taxable}
                    title="Taxable:"
                    value={showProduct && showProduct?.taxable ? 'True' : 'False'}
                  />
                  <DetailRow
                    diff={diff.comboBox}
                    title="ComboBox:"
                    value={showProduct && showProduct?.comboBox ? 'True' : 'False'}
                  />
                  <DetailRow
                    diff={diff.allowUom}
                    title="AllowUOM:"
                    value={showProduct && showProduct?.allowUom ? 'True' : 'False'}
                  />
                  <DetailRow
                    diff={diff.productUrl}
                    title="Product URL:"
                    value={showProduct && showProduct?.productUrl}
                  />
                  <DetailRow
                    diff={diff.productPictureUrl}
                    title="Product Picture URL:"
                    value={showProduct && showProduct?.productPictureUrl}
                  />
                  <DetailRow
                    diff={diff.productUpc}
                    title="Product UPC:"
                    value={showProduct && showProduct?.productUpc}
                  />
                  <DetailRow
                    diff={diff.productSku}
                    title="Product SKU:"
                    value={showProduct && showProduct?.productSku}
                  />
                  <DetailRow
                    diff={diff.productSoItemType}
                    title="Product SO Item Type:"
                    value={showProduct && showProduct?.productSoItemType}
                  />
                  <DetailRow
                    diff={diff.incomeAccount}
                    title="Income Account:"
                    value={showProduct && showProduct?.incomeAccount}
                  />
                  <DetailRow
                    diff={diff.defaultFlag}
                    title="Default Flag:"
                    value={showProduct && showProduct?.defaultFlag}
                  />
                </>
              )}
              {objectType === 'BOMProduct' && (
                <>
                  <DetailRow
                    diff={diff.partNumber}
                    title="Part Number:"
                    value={showProduct && showProduct?.partNumber}
                  />
                  <DetailRow
                    diff={diff.productNumber}
                    title="Product Number:"
                    value={showProduct && showProduct?.productNumber}
                  />
                  <DetailRow
                    diff={diff.productDescription}
                    title="Product Description:"
                    value={showProduct && showProduct?.productDescription}
                  />
                  <DetailRow
                    diff={diff.productDetails}
                    title="Product Details:"
                    value={showProduct && showProduct?.productDetails}
                  />
                  <DetailRow diff={diff.uom} title="UOM:" value={showProduct && showProduct?.uom} />
                  <DetailRow
                    diff={diff.price}
                    title="Price:"
                    value={`$${toDecimal(showProduct && showProduct?.price)}`}
                  />
                  <DetailRow
                    diff={diff.active}
                    title="Active:"
                    value={showProduct && showProduct?.active ? 'True' : 'False'}
                  />
                  <DetailRow
                    diff={diff.taxable}
                    title="Taxable:"
                    value={showProduct && showProduct?.taxable ? 'True' : 'False'}
                  />
                  <DetailRow
                    diff={diff.comboBox}
                    title="ComboBox:"
                    value={showProduct && showProduct?.comboBox ? 'True' : 'False'}
                  />
                  <DetailRow
                    diff={diff.allowUom}
                    title="AllowUOM:"
                    value={showProduct && showProduct?.allowUom ? 'True' : 'False'}
                  />
                  <DetailRow
                    diff={diff.productUrl}
                    title="Product URL:"
                    value={showProduct && showProduct?.productUrl}
                  />
                  <DetailRow
                    diff={diff.productPictureUrl}
                    title="Product Picture URL:"
                    value={showProduct && showProduct?.productPictureUrl}
                  />
                  <DetailRow
                    diff={diff.productUpc}
                    title="Product UPC:"
                    value={showProduct && showProduct?.productUpc}
                  />
                  <DetailRow
                    diff={diff.productSku}
                    title="Product SKU:"
                    value={showProduct && showProduct?.productSku}
                  />
                  <DetailRow
                    diff={diff.productSoItemType}
                    title="Product SO Item Type:"
                    value={showProduct && showProduct?.productSoItemType}
                  />
                  <DetailRow
                    diff={diff.incomeAccount}
                    title="Income Account:"
                    value={showProduct && showProduct?.incomeAccount}
                  />
                  <DetailRow
                    diff={diff.defaultFlag}
                    title="Default Flag:"
                    value={showProduct && showProduct?.defaultFlag}
                  />
                </>
              )}
            </HForm>
          </div>

          {action === 'edit' && orgProduct && (
            <div className="view-approvals-edit-view">
              {objectType === 'Product' && <ProductPreview product={orgProduct} />}
              {objectType === 'Part' && <PartPreview part={orgProduct} />}
              {objectType === 'BOMProduct' && <ProductPreview product={orgProduct} />}
              <HForm className="edit-view-item-form">
                {objectType === 'Part' && (
                  <>
                    <DetailRow
                      diff={diff.manufacturerId}
                      title="Part Number:"
                      value={orgProduct && orgProduct?.manufacturerId}
                    />
                    <DetailRow
                      diff={diff.manufacturerName}
                      title="Manufacturer:"
                      value={orgProduct && orgProduct?.manufacturerName}
                    />
                    <DetailRow
                      diff={diff.vendor}
                      title="Vendor:"
                      value={orgProduct && orgProduct?.vendor}
                    />
                    <DetailRow
                      diff={diff.vendorPartNumber}
                      title="Vendor Number:"
                      value={orgProduct && orgProduct?.vendorPartNumber}
                    />
                    <DetailRow
                      diff={diff.description}
                      title="Part Description:"
                      value={orgProduct && orgProduct?.description}
                    />
                    <DetailRow
                      diff={diff.details}
                      title="Part Details:"
                      value={orgProduct && orgProduct?.details}
                    />
                    <DetailRow diff={diff.uom} title="UOM:" value={orgProduct && orgProduct?.uom} />
                    <DetailRow
                      diff={diff.cost}
                      title="Price:"
                      value={orgProduct && orgProduct?.cost}
                    />
                    <DetailRow
                      diff={diff.defaultVendor}
                      title="Default Vendor:"
                      value={orgProduct && orgProduct?.defaultVendor ? 'True' : 'False'}
                    />
                    <DetailRow
                      diff={diff.leadTime}
                      title="Lead Time:"
                      value={orgProduct && orgProduct?.leadTime}
                    />
                    <DetailRow
                      diff={diff.partPictureUrl}
                      title="Picture URL:"
                      value={orgProduct && orgProduct?.partPictureUrl}
                    />
                    <DetailRow
                      diff={diff.defaultFlag}
                      title="Default Flag:"
                      value={orgProduct && orgProduct?.defaultFlag}
                    />
                    <div className="view-item-row">
                      <HUOMTable
                        columns={[
                          'UOM',
                          'Per',
                          'Cost',
                          'Weight',
                          'WeightUom',
                          'Length',
                          'Width',
                          'Height',
                          'SizeUom',
                          'CartonType',
                          'CartonCount',
                        ]}
                        data={orgProduct?.UOMs}
                        setData={() => {}}
                        selectMode
                        onSelect={() => {}}
                      />
                    </div>
                  </>
                )}
               {objectType === 'Product' && (
                  <>
                    <DetailRow
                      diff={diff.partNumber}
                      title="Part Number:"
                      value={orgProduct && orgProduct?.partNumber?.id}
                    />
                    <DetailRow
                      diff={diff.productNumber}
                      title="Product Number:"
                      value={orgProduct && orgProduct?.productNumber}
                    />
                    <DetailRow
                      diff={diff.productDescription}
                      title="Product Description:"
                      value={orgProduct && orgProduct?.productDescription}
                    />
                    <DetailRow
                      diff={diff.productDetails}
                      title="Product Details:"
                      value={orgProduct && orgProduct?.productDetails}
                    />
                    <DetailRow diff={diff.uom} title="UOM:" value={orgProduct && orgProduct?.uom} />
                    <DetailRow
                      diff={diff.price}
                      title="Price:"
                      value={`$${toDecimal(orgProduct && orgProduct?.price)}`}
                    />
                    <DetailRow
                      diff={diff.active}
                      title="Active:"
                      value={orgProduct && orgProduct?.active ? 'True' : 'False'}
                    />
                    <DetailRow
                      diff={diff.taxable}
                      title="Taxable:"
                      value={orgProduct && orgProduct?.taxable ? 'True' : 'False'}
                    />
                    <DetailRow
                      diff={diff.comboBox}
                      title="ComboBox:"
                      value={orgProduct && orgProduct?.comboBox ? 'True' : 'False'}
                    />
                    <DetailRow
                      diff={diff.allowUom}
                      title="AllowUOM:"
                      value={orgProduct && orgProduct?.allowUom ? 'True' : 'False'}
                    />
                    <DetailRow
                      diff={diff.productUrl}
                      title="Product URL:"
                      value={orgProduct && orgProduct?.productUrl}
                    />
                    <DetailRow
                      diff={diff.productPictureUrl}
                      title="Product Picture URL:"
                      value={orgProduct && orgProduct?.productPictureUrl}
                    />
                    <DetailRow
                      diff={diff.productUpc}
                      title="Product UPC:"
                      value={orgProduct && orgProduct?.productUpc}
                    />
                    <DetailRow
                      diff={diff.productSku}
                      title="Product SKU:"
                      value={orgProduct && orgProduct?.productSku}
                    />
                    <DetailRow
                      diff={diff.productSoItemType}
                      title="Product SO Item Type:"
                      value={orgProduct && orgProduct?.productSoItemType}
                    />
                    <DetailRow
                      diff={diff.incomeAccount}
                      title="Income Account:"
                      value={orgProduct && orgProduct?.incomeAccount}
                    />
                    <DetailRow
                      diff={diff.weight}
                      title="Weight:"
                      value={orgProduct && orgProduct?.weight}
                    />
                    <DetailRow
                      diff={diff.weightUom}
                      title="Weight UOM:"
                      value={orgProduct && orgProduct?.weightUom}
                    />
                    <DetailRow
                      diff={diff.width}
                      title="Width:"
                      value={orgProduct && orgProduct?.width}
                    />
                    <DetailRow
                      diff={diff.height}
                      title="Height:"
                      value={orgProduct && orgProduct?.height}
                    />
                    <DetailRow
                      diff={diff.length}
                      title="Length:"
                      value={orgProduct && orgProduct?.length}
                    />
                    <DetailRow
                      diff={diff.sizeUom}
                      title="SizeUOM:"
                      value={orgProduct && orgProduct?.sizeUom}
                    />
                    <DetailRow
                      diff={diff.defaultFlag}
                      title="Default Flag:"
                      value={orgProduct && orgProduct?.defaultFlag}
                    />
                    <DetailRow
                      diff={diff.cartonCount}
                      title="Carton Count:"
                      value={orgProduct && orgProduct?.cartonCount}
                    />
                    <DetailRow
                      diff={diff.cartonType}
                      title="Carton Type:"
                      value={orgProduct && orgProduct?.cartonType}
                    />
                  </>
                )} 
                {objectType === 'BOMProduct' && (
                  <>
                    <DetailRow
                      diff={diff.partNumber}
                      title="Part Number:"
                      value={orgProduct && orgProduct?.partNumber}
                    />
                    <DetailRow
                      diff={diff.productNumber}
                      title="Product Number:"
                      value={orgProduct && orgProduct?.productNumber}
                    />
                    <DetailRow
                      diff={diff.productDescription}
                      title="Product Description:"
                      value={orgProduct && orgProduct?.productDescription}
                    />
                    <DetailRow
                      diff={diff.productDetails}
                      title="Product Details:"
                      value={orgProduct && orgProduct?.productDetails}
                    />
                    <DetailRow diff={diff.uom} title="UOM:" value={orgProduct && orgProduct?.uom} />
                    <DetailRow
                      diff={diff.price}
                      title="Price:"
                      value={`$${toDecimal(orgProduct && orgProduct?.price)}`}
                    />
                    <DetailRow
                      diff={diff.active}
                      title="Active:"
                      value={orgProduct && orgProduct?.active ? 'True' : 'False'}
                    />
                    <DetailRow
                      diff={diff.taxable}
                      title="Taxable:"
                      value={orgProduct && orgProduct?.taxable ? 'True' : 'False'}
                    />
                    <DetailRow
                      diff={diff.comboBox}
                      title="ComboBox:"
                      value={orgProduct && orgProduct?.comboBox ? 'True' : 'False'}
                    />
                    <DetailRow
                      diff={diff.allowUom}
                      title="AllowUOM:"
                      value={orgProduct && orgProduct?.allowUom ? 'True' : 'False'}
                    />
                    <DetailRow
                      diff={diff.productUrl}
                      title="Product URL:"
                      value={orgProduct && orgProduct?.productUrl}
                    />
                    <DetailRow
                      diff={diff.productPictureUrl}
                      title="Product Picture URL:"
                      value={orgProduct && orgProduct?.productPictureUrl}
                    />
                    <DetailRow
                      diff={diff.productUpc}
                      title="Product UPC:"
                      value={orgProduct && orgProduct?.productUpc}
                    />
                    <DetailRow
                      diff={diff.productSku}
                      title="Product SKU:"
                      value={orgProduct && orgProduct?.productSku}
                    />
                    <DetailRow
                      diff={diff.productSoItemType}
                      title="Product SO Item Type:"
                      value={orgProduct && orgProduct?.productSoItemType}
                    />
                    <DetailRow
                      diff={diff.incomeAccount}
                      title="Income Account:"
                      value={orgProduct && orgProduct?.incomeAccount}
                    />
                    <DetailRow
                      diff={diff.weight}
                      title="Weight:"
                      value={orgProduct && orgProduct?.weight}
                    />
                    <DetailRow
                      diff={diff.weightUom}
                      title="Weight UOM:"
                      value={orgProduct && orgProduct?.weightUom}
                    />
                    <DetailRow
                      diff={diff.width}
                      title="Width:"
                      value={orgProduct && orgProduct?.width}
                    />
                    <DetailRow
                      diff={diff.height}
                      title="Height:"
                      value={orgProduct && orgProduct?.height}
                    />
                    <DetailRow
                      diff={diff.length}
                      title="Length:"
                      value={orgProduct && orgProduct?.length}
                    />
                    <DetailRow
                      diff={diff.sizeUom}
                      title="SizeUOM:"
                      value={orgProduct && orgProduct?.sizeUom}
                    />
                    <DetailRow
                      diff={diff.defaultFlag}
                      title="Default Flag:"
                      value={orgProduct && orgProduct?.defaultFlag}
                    />
                    <DetailRow
                      diff={diff.cartonCount}
                      title="Carton Count:"
                      value={orgProduct && orgProduct?.cartonCount}
                    />
                    <DetailRow
                      diff={diff.cartonType}
                      title="Carton Type:"
                      value={orgProduct && orgProduct?.cartonType}
                    />
                  </>
                )}
              </HForm>
            </div>
          )}
        </div>
        <div className="view-approvals-filters">
          <EditViewActions
            saveCaption="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Approve&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
            clearCaption=""
            deleteCaption="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Decline&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
            hideClear
            saveAction={onSave}
            deleteAction={onDecline}
          />
          {action === 'edit' && (
            <AddMedia
              caption="Orginal Images"
              pictureUrls={
                (orgProduct && objectType === 'Product' && orgProduct?.productPictureUrl) ||
                (orgProduct && objectType === 'Part' && orgProduct?.partPictureUrl)
              }
              setPictureUrls={() => {}}
              readOnly
            />
          )}
          <AddMedia
            caption={action === 'edit' ? 'New Images' : 'Images'}
            pictureUrls={
              (showProduct && objectType === 'Product' && showProduct?.productPictureUrl) ||
              (showProduct && objectType === 'Part' && showProduct?.partPictureUrl)
            }
            setPictureUrls={() => {}}
            readOnly
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  signedInUser: state.user && state.user.user,
})

ApprovalDetail.propTypes = {
  signedInUser: PropTypes.shape().isRequired,
}

const connectedHeader = connect(mapStateToProps, null)(ApprovalDetail)

export default withRouter(connectedHeader)
