import React from 'react'
import PropTypes from 'prop-types'
import ProductListItem from './ProductListItem'
import './index.css'

const ProductsList = ({ products, viewEditAction, selectedProducts, setSelectedProducts }) => {
  console.log(products)
  return (
    <div className="products-list-container">
      {products &&
        products.length > 0 &&
        products.map((product, index) => (
          <ProductListItem
            key={product.id}
            name={product.name}
            product={product}
            viewEditAction={viewEditAction}
            checked={selectedProducts[index]}
            setChecked={(value) => {
              const productsStatus = [...selectedProducts]
              productsStatus[index] = value
              setSelectedProducts(productsStatus)
            }}
          />
        ))}
      {products && products.length === 0 && (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            textAlign: 'center',
            fontSize: '22px',
          }}
        >
          No Products available.
        </span>
      )}
    </div>
  )
}
ProductsList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})),
  viewEditAction: PropTypes.func,
  selectedProducts: PropTypes.arrayOf(PropTypes.bool),
  setSelectedProducts: PropTypes.func,
}

ProductsList.defaultProps = {
  products: [],
  viewEditAction: () => {},
  selectedProducts: [],
  setSelectedProducts: () => {},
}
export default ProductsList
