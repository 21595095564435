import React from 'react'
import PropTypes from 'prop-types'
import BomsListItem from './BomListItem'
import './index.css'

const BomsList = ({ boms, viewEditAction, selectedProducts, setSelectedProducts }) => {
  return (
    <div className="products-list-container">
      {boms &&
        boms.length > 0 &&
        boms.map((product, index) => (
          <BomsListItem
            key={product.id}
            name={product.name}
            product={product}
            viewEditAction={viewEditAction}
            checked={selectedProducts[index]}
            setChecked={(value) => {
              const bomsStatus = [...selectedProducts]
              bomsStatus[index] = value
              setSelectedProducts(bomsStatus)
            }}
          />
        ))}
    </div>
  )
}
BomsList.propTypes = {
  boms: PropTypes.arrayOf(PropTypes.shape({})),
  viewEditAction: PropTypes.func,
  selectedProducts: PropTypes.arrayOf(PropTypes.bool),
  setSelectedProducts: PropTypes.func,
}

BomsList.defaultProps = {
  boms: [],
  viewEditAction: () => {},
  selectedProducts: [],
  setSelectedProducts: () => {},
}
export default BomsList
