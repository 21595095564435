/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Field, Form, Formik } from 'formik'
import * as yup from 'yup'
import { store } from 'react-notifications-component'
import { REQUIRED_VALIDATION, INVALID_EMAIL } from 'consts/form'
import FormError from 'components/common/Basic/FormError'
import LoadingAnimation from 'components/LoadingAnimation'

import { signinRequest } from 'redux/actions/authActions'
// import { validate, updateUser } from 'api/authApi'
import './index.scss'

const validationSchema = yup.object().shape({
  firstName: yup.string().required(REQUIRED_VALIDATION),
  lastName: yup.string().required(REQUIRED_VALIDATION),
  email: yup
    .string()
    .required(REQUIRED_VALIDATION)
    .email(INVALID_EMAIL),

  password: yup.string(),
  passwordConfirmation: yup.string().when('password', {
    is: (val) => !!(val && val.length > 0),
    then: yup.string().oneOf([yup.ref('password')], 'Both password need to be the same'),
  }),

  companyName: yup.string().required(REQUIRED_VALIDATION),
  city: yup.string().required(REQUIRED_VALIDATION),
  address1: yup.string().required(REQUIRED_VALIDATION),
  address2: yup.string(),
  phone: yup.string().required(REQUIRED_VALIDATION),
  zip: yup.string().required(REQUIRED_VALIDATION),
})

const AccountSettings = ({ user, signinRequest }) => {
  // const userSignedIn = user && user.user && user.user.confirmed
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    zip: '',
    city: '',
    companyName: '',
    phone: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    aboutMe: '',
    defaultDeliveryInstructions: '',
  })

  const fetchData = async () => {
    try {
      // const userInfo = await validate()
      const userInfo = {
        firstName: 'Albert',
        lastName: 'Wong',
        address1: 'Earth',
        address2: 'Best',
        zip: '5323',
        city: 'Dubai',
        companyName: 'Alias',
        phone: '+1 234 567 8901',
        email: 'contact@gmail.com',
        password: '123456',
        passwordConfirmation: '123456',
        aboutMe:
          'I am a sales customer in Dubai, which is based in Dubai International City 44 ...',
        defaultDeliveryInstructions: 'Call me at (...)',
      }
      setInitialValues({
        firstName: (userInfo && userInfo.firstName) || '',
        lastName: (userInfo && userInfo.lastName) || '',
        address1: (userInfo && userInfo.address1) || '',
        address2: (userInfo && userInfo.address2) || '',
        zip: (userInfo && userInfo.zip) || '',
        city: (userInfo && userInfo.city) || '',
        companyName: (userInfo && userInfo.companyName) || '',
        phone: (userInfo && userInfo.phone) || '',
        email: (userInfo && userInfo.email) || '',
        password: (userInfo && userInfo.password) || '',
        passwordConfirmation: (userInfo && userInfo.passwordConfirmation) || '',
        aboutMe: (userInfo && userInfo.aboutMe) || '',
        defaultDeliveryInstructions: (userInfo && userInfo.defaultDeliveryInstructions) || '',
      })
    } catch (e) {
      console.log('---> error', e)
      store.addNotification({
        message: 'Sorry, we are not able to process your request now. Please try again later.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      })
    } finally {
      setLoading(false)
    }
  }

  const updateData = async (form) => {
    try {
      //  const updated = await updateUser({ user: { ...form } })
      const updated = form
      console.log('Updated', updated)
      setInitialValues(updated)
      signinRequest({ user: updated, jwt: user.jwt })
      store.addNotification({
        message: 'Successfully updated!',
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      })
    } catch (e) {
      console.log('---> error', e)
      store.addNotification({
        message: 'Sorry, we are not able to process your request now. Please try again later.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      })
    } finally {
      setLoading(false)
    }
  }

  // useEffect(() => {
  //   if (!userSignedIn) {
  //     history.push('/')
  //   }
  // })

  useEffect(() => {
    setLoading(true)
    fetchData()
  }, [])

  const onSubmit = (form) => {
    setLoading(true)
    updateData(form)
  }

  return (
    <div className="account-setting-container">
      {loading && <LoadingAnimation />}
      {!loading && (
        <div className="container-fluid container-orders page-content">
          <h2 className="setting-page-title mb-4 ml-2">Account Settings</h2>
          <div className="setting-page-body mr-2 ml-2">
            <Formik
              {...{ initialValues, validationSchema }}
              validateOnChange
              validateOnBlur
              onSubmit={onSubmit}
            >
              {({ setFieldValue, values, touched, handleBlur, setFieldTouched }) => (
                <Form className="setting-form pt-2 mr-2 ml-2">
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <label
                          className={classnames({
                            'label-focused': touched.firstName && values.firstName,
                          })}
                        >
                          First name
                        </label>
                        <Field
                          className="form-control"
                          type="text"
                          name="firstName"
                          placeholder=""
                        />
                        <FormError name="firstName" />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <label
                          className={classnames({
                            'label-focused': touched.lastName && values.lastName,
                          })}
                        >
                          Last name
                        </label>
                        <Field className="form-control" type="text" name="lastName" />
                        <FormError name="lastName" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-8">
                      <div className="form-group">
                        <label
                          className={classnames({
                            'label-focused': touched.address1 && values.address1,
                          })}
                        >
                          Delivery Address
                        </label>
                        <Field
                          className="form-control"
                          type="text"
                          name="address1"
                          placeholder="27. Smithwood Dr."
                        />
                        <FormError name="address1" />
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group">
                        <label
                          className={classnames({
                            'label-focused': touched.address2 && values.address2,
                          })}
                        >
                          &nbsp;
                        </label>
                        <Field
                          className="form-control"
                          type="text"
                          name="address2"
                          placeholder="Suite #"
                        />
                        <FormError name="address2" />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-sm-4">
                      <div className="form-group">
                        <label
                          className={classnames({
                            'label-focused': touched.zip && values.zip,
                          })}
                        >
                          Postal code
                        </label>
                        <Field
                          className="form-control"
                          type="text"
                          name="zip"
                          placeholder="M9B4R7"
                        />
                        <FormError name="zip" />
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group">
                        <label
                          className={classnames({
                            'label-focused': touched.city && values.city,
                          })}
                        >
                          City
                        </label>
                        <Field
                          className="form-control"
                          type="text"
                          name="city"
                          placeholder="ETOBICOKE"
                        />
                        <FormError name="city" />
                      </div>
                    </div>

                    <div className="col-12 col-sm-4">
                      <div className="form-group">
                        <label
                          className={classnames({
                            'label-focused': touched.companyName && values.companyName,
                          })}
                        >
                          Company Name
                        </label>
                        <Field className="form-control" type="text" name="companyName" />
                        <FormError name="companyName" />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <label
                          className={classnames({
                            'label-focused': touched.phone && values.phone,
                          })}
                        >
                          Phone Number
                          <span
                            className="small text-primary"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Help info"
                          >
                            (?)
                          </span>
                        </label>
                        <Field className="form-control" type="text" name="phone" />
                        <FormError name="phone" />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <label
                          className={classnames({
                            'label-focused': touched.email && values.email,
                          })}
                        >
                          Email address
                        </label>
                        <Field className="form-control" type="email" name="email" />
                        <FormError name="email" />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <label
                          className={classnames({
                            'label-focused': touched.password && values.password,
                          })}
                        >
                          Password
                        </label>
                        <Field className="form-control" type="password" name="password" />
                        <FormError name="password" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <label
                          className={classnames({
                            'label-focused':
                              touched.passwordConfirmation && values.passwordConfirmation,
                          })}
                        >
                          Confirm Password
                        </label>
                        <Field
                          className="form-control"
                          type="password"
                          name="passwordConfirmation"
                        />
                        <FormError name="passwordConfirmation" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label
                          className={classnames({
                            'label-focused': touched.aboutMe && values.aboutMe,
                          })}
                        >
                          About me
                        </label>
                        <Field
                          className="form-control"
                          name="aboutMe"
                          as="textarea"
                          rows={10}
                          placeholder="Tell us a bit about yourself and company so we can better serve you."
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label
                          className={classnames({
                            'label-focused':
                              touched.defaultDeliveryInstructions &&
                              values.defaultDeliveryInstructions,
                          })}
                        >
                          Default Delivery Instructions
                        </label>
                        <Field
                          className="form-control"
                          as="textarea"
                          name="defaultDeliveryInstructions"
                          placeholder="E.g. Please bring the package to reception on the 3rd floor and call Paula"
                          rows={4}
                        />
                        <FormError name="defaultDeliveryInstructions" />
                      </div>
                    </div>
                  </div>

                  <div className="pq-row">
                    <div className="d-flex justify-content-end mt-3">
                      <button type="submit" className="btn btn-primary settings-btn">
                        SAVE
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  )
}
const mapStateToProps = (state) => ({
  user: state && state.user,
})

const mapDispatchToProps = {
  signinRequest,
}

AccountSettings.propTypes = {
  user: PropTypes.shape({
    user: PropTypes.shape({
      firstName: PropTypes.string,
      confirmed: PropTypes.bool,
    }),
    jwt: PropTypes.string,
  }).isRequired,
  signinRequest: PropTypes.func.isRequired,
}
const connectedHeader = connect(mapStateToProps, mapDispatchToProps)(AccountSettings)

export default connectedHeader
