/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react";
import PropTypes from "prop-types";
import { priceDecimalFormatter } from "utils/formatter";
import { getProductFeatureImage } from "utils/product_util";
import "./index.css";

const MediumProduct = ({ product, viewProduct }) => {
  return (
    <div className="medium-product-show">
      <div className="medium-product-image">
        <img
          src={
            getProductFeatureImage(product.productPictureUrl) ||
            "/assets/img/products/sample.png"
          }
          alt="Missing"
        />
      </div>
      <div className="medium-product-details">
        <div className="medium-product-description">
          {" "}
          {` ${product.productDescription} `}{" "}
        </div>
        <div>
          <span>{`Part Number: `}</span>
          <span className="medium-product-blue">{product.productNumber}</span>
        </div>
        <div className="medium-product-price">
          <span className="medium-product-price-value">
            {priceDecimalFormatter(product.price)}
          </span>
        </div>
        <div className="medium-product-type">
          <a
            role="button"
            onClick={() => {
              viewProduct(product);
            }}
          >
            {product.incomeAccount}{" "}
            <img src="/assets/img/products/link.svg" alt="link" />
          </a>
        </div>
      </div>
    </div>
  );
};

MediumProduct.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    productNumber: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    incomeAccount: PropTypes.string.isRequired,
    productPictureUrl: PropTypes.string,
    productDescription: PropTypes.string,
    partNumber: PropTypes.string
  }),
  viewProduct: PropTypes.func
};

MediumProduct.defaultProps = {
  product: {
    id: 1,
    productNumber: "230605",
    productDescription: "Sunblock, SPF 30, 8oz, 1/each",
    price: 24.99,
    incomeAccount: "Disposable - Sales",
    productPictureUrl: "",
    partNumber: "110115BX"
  },
  viewProduct: () => {}
};
export default MediumProduct;
