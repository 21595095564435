import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { MediumLoadingAnimation } from 'components/LoadingAnimation'
import Caption from 'components/Parts/Caption'
import Filters from 'components/common/Filter/Filters'
import HSingleSelect from 'components/common/Basic/HSingleSelect'
import CustomFilters from 'components/common/Filter/CustomFilters'
import SelectedActions from 'components/common/Filter/SelectedActions'
import PartsList from 'components/Parts/PartsList'
import { decimalFormatter } from 'utils/formatter'
import HButton from 'components/common/Basic/HButton'
import HPagination from 'components/common/Pagination'
import UomSelectionModal from 'components/common/Modal/UomSelectionModal'
import { PAGE_ITEMS } from 'consts/common'
import CustomImportBox from 'components/common/Filter/CustomImport'
import { getParts } from 'api/productApi'
import { store } from 'react-notifications-component'

import {
  setNormalFilter,
  setCustomFilter,
  removeNormalFilter,
  removeCustomFilter,
} from 'redux/actions/filterAction'

import { setImportData } from 'redux/actions/importAction'
import CsvImportModal from 'components/common/Modal/CsvImportModal'

import './index.css'

const ViewPartList = ({
  filter,
  customFilter,
  setNormalFilter,
  setCustomFilter,
  removeNormalFilter,
  removeCustomFilter,
  setImportData,
}) => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [parts, setParts] = useState([])
  const [results, setResults] = useState(0)
  const [pageTitle, setPageTitle] = useState('All Supplier Parts')
  const [pageNumber, setPageNumber] = useState(1)
  const [itemPerPage, setItemPerPage] = useState(100)
  const [totalPageCount, setTotalPageCount] = useState(100)
  const [isModalShown, showModal] = useState(false)
  const [importPart, setImportPart] = useState({})
  const [showImportModal, setImportModal] = useState(false)

  const fetchData = async () => {
    const normal = (filter && filter.part) || {}
    const custom = (customFilter && customFilter.part) || []

    const productFilters = {
      normal,
      custom,
    }
    const parts = await getParts((pageNumber - 1) * itemPerPage, itemPerPage, productFilters)

    if (parts && parts.error) {
      store.addNotification({
        message: 'Sorry, we are not able to process your request now. Please try again later.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      })
    } else {
      console.log(parts)
      setParts(parts.parts)
      setResults(parts.totalCount)
      setTotalPageCount(Math.ceil(parts.totalCount / itemPerPage))
    }
    setLoading(false)
  }

  useEffect(() => {
    // Loading Data
    setLoading(true)
    fetchData()
  }, [pageNumber])

  const onImportAction = (selectedUom, part) => {
    setImportData({
      type: 'Part',
      vendor: part.vendor,
      price: selectedUom.cost,
      uom: selectedUom.uom,
      weight: selectedUom.weight,
      weightUom: selectedUom.weightUom,
      width: selectedUom.width,
      height: selectedUom.height,
      length: selectedUom.length,
      sizeUom: selectedUom.sizeUom,
      cartonType: selectedUom.cartonType,
      cartonCount: selectedUom.cartonCount,
      productDescription: part.description,
      productDetails: part.itemDetails,
      manufacturerId: part.manufacturerId,
      vendorNumber: part.vendorPartNumber,
      manufacturerName: part.manufacturerName,
      productPictureUrl: part.partPictureUrl,
      partNumber: part.id,
      partUom: selectedUom.id,
      linked: { ...part },
      selectedUom,
    })
    history.push('/products/add')
  }

  const onFilterApply = (applyFilter) => {
    if (applyFilter.category === 'Select One' || applyFilter.sortBy === 'Select One') {
      store.addNotification({
        message: "Sorry, you didn't select category or sort option.",
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      })
    } else {
      const updateFilter = { ...filter }
      updateFilter.part = applyFilter
      setNormalFilter(updateFilter)
    }
  }

  const onCustomFilterApply = (applyFilter) => {
    if (applyFilter && applyFilter.length > 0) {
      const updateFilter = { ...customFilter }
      updateFilter.part = applyFilter
      setCustomFilter(updateFilter)
    } else {
      store.addNotification({
        message: "Sorry, you didn't select category or value option.",
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      })
    }
  }

  const onFilterCancel = () => {
    removeNormalFilter()
  }

  const onCustomFilterCancel = () => {
    // removeCustomFilter()
    const updateFilter = { ...customFilter }
    updateFilter.part = []
    setCustomFilter(updateFilter)
  }

  useEffect(() => {
    // Loading Data
    setLoading(true)
    fetchData()

    if (filter && filter.part && filter.part.category) {
      setPageTitle('Filtered Parts')
    }
    if (customFilter && customFilter.part && customFilter.part.length > 0) {
      setPageTitle('Filtered Parts')
    }
  }, [pageNumber, itemPerPage, filter, customFilter])

  return (
    <div className="view-parts">
      {isModalShown && (
        <UomSelectionModal
          onHide={() => {
            showModal(false)
          }}
          headerText="Select UOM Part"
          partUoms={importPart.UOMs}
          onSelect={(item) => {
            onImportAction(item, importPart)
          }}
        />
      )}
      <div className="view-parts-list">
        <div className="view-parts-list-title">
          <Caption
            caption="Supplier Items List"
            addAction={() => {
              history.push('/new-part')
            }}
          />
        </div>
        {loading && (
          <div className="products-list-container">
            <MediumLoadingAnimation />
          </div>
        )}
        {!loading && (
          <PartsList
            parts={parts}
            viewAction={(part) => {
              history.push(`/supplier-part-list/${part.id}`)
            }}
            importAction={(part) => {
              if (part.UOMs && part.UOMs.length > 0) {
                if (part.UOMs.length === 1) {
                  onImportAction(part.UOMs[0], part)
                } else {
                  setImportPart(part)
                  showModal(true)
                }
              } else {
                store.addNotification({
                  message: 'Sorry, UOM list is empty. Try again after adding uoms to the list.',
                  type: 'danger',
                  insert: 'top',
                  container: 'top-right',
                  animationIn: ['animated', 'bounceIn'],
                  animationOut: ['animated', 'fadeOut'],
                  dismiss: {
                    duration: 3000,
                  },
                })
              }
            }}
          />
        )}
        <div className="horizontal-separator" />
        <div className="view-parts-list-footer">
          <div className="view-parts-list-footer-left">
            <span className="view-parts-list-footer-text">
              {`${decimalFormatter(results)} results for  "`}
            </span>
            <span className="view-parts-list-footer-text-bold">{`${pageTitle}"`}</span>
            <HButton
              className="view-parts-list-footer-button"
              onClick={() => {
                setLoading(true)
                fetchData()
              }}
            >
              <img src="/assets/img/common/refresh.png" alt="refresh" />
              Refresh
            </HButton>
            <div className="view-parts-list-footer-select">
              <span>Items Per Page:</span>
              <HSingleSelect
                values={PAGE_ITEMS.map((item) => ({
                  value: item,
                  label: `${item}`,
                }))}
                defaultValue={{ value: 100, label: '100' }}
                name="itemPerPage"
                onChange={(item) => {
                  setItemPerPage(item.value)
                }}
              />
            </div>
          </div>
          <div className="view-parts-list-footer-right">
            <HPagination
              pageNumber={pageNumber}
              totalPageCount={totalPageCount}
              setPageNumber={setPageNumber}
            />
          </div>
        </div>
      </div>
      <div className="view-parts-filters">
        <Filters
          isProduct={false}
          isPart
          filter={filter && filter.part}
          onApply={onFilterApply}
          onCancel={onFilterCancel}
        />
        <CustomFilters
          isPart
          filter={customFilter && customFilter.part}
          onApply={onCustomFilterApply}
          onCancel={onCustomFilterCancel}
        />
        <SelectedActions />
        <CustomImportBox
          caption={'Import Parts'}
          onUploadCallback={() => {
            setImportModal(true)
          }}
        />
      </div>
      <CsvImportModal
        show={showImportModal}
        isPart
        onHide={() => {
          setImportModal(false)
        }}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  filter: state.filter,
  customFilter: state.customFilter,
})

const mapDispatchToProps = {
  setNormalFilter,
  setCustomFilter,
  removeNormalFilter,
  removeCustomFilter,
  setImportData,
}

ViewPartList.propTypes = {
  setNormalFilter: PropTypes.func.isRequired,
  setCustomFilter: PropTypes.func.isRequired,
  removeNormalFilter: PropTypes.func.isRequired,
  removeCustomFilter: PropTypes.func.isRequired,
  filter: PropTypes.shape({}).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  customFilter: PropTypes.any.isRequired,
  setImportData: PropTypes.func.isRequired,
}

const connectedViewParts = connect(mapStateToProps, mapDispatchToProps)(ViewPartList)

export default withRouter(connectedViewParts)
