import React from 'react'
import PropTypes from 'prop-types'
import HButton from 'components/common/Basic/HButton'
import { classNames } from 'utils/helper'
import './index.css'

const Caption = ({
  caption,
  bContainImg,
  primary,
  secondary,
  initial,
  backAction,
  saveAction,
  addAction,
}) => {
  const classes = classNames(
    'users-caption-container',
    (primary && '-primary') || '',
    (secondary && '-secondary') || '',
    (initial && '-initial') || ''
  )
  return (
    <div className={classes}>
      {backAction && (
        <div className="users-caption-container-left">
          <HButton
            className="h-transparent-button"
            onClick={() => {
              backAction()
            }}
          >
            <img src="/assets/img/common/back.svg" alt="back" width="30px" />
          </HButton>
        </div>
      )}

      <div
        className={backAction ? 'users-caption-container-center' : 'users-caption-container-left'}
      >
        {bContainImg && (
          <img src="/assets/img/users/user-solid.svg" alt="user" width="40px" height="30px" />
        )}
        <span> {caption}</span>
      </div>

      <div className="users-caption-container-right">
        {saveAction && (
          <HButton
            className="h-caption-button -primary"
            onClick={() => {
              saveAction()
            }}
          >
            Save
          </HButton>
        )}
        {addAction && (
          <HButton
            className="h-plus-button"
            onClick={() => {
              addAction()
            }}
          >
            +
          </HButton>
        )}
      </div>
    </div>
  )
}

Caption.propTypes = {
  caption: PropTypes.string,
  bContainImg: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  initial: PropTypes.bool,
  backAction: PropTypes.func,
  saveAction: PropTypes.func,
  addAction: PropTypes.func,
}

Caption.defaultProps = {
  caption: '',
  bContainImg: true,
  primary: true,
  secondary: false,
  initial: false,
  backAction: null,
  saveAction: null,
  addAction: null,
}
export default Caption
