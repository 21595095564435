import React from "react";
import PropTypes from "prop-types";
import { classNames } from "utils/helper";
import AnimateHeight from "react-animate-height";

const Collapsible = ({ className, children, expanded, duration }) => {
  const classes = classNames("h-accordion__content", className || "");

  return (
    <AnimateHeight
      duration={duration}
      height={expanded ? "auto" : 0}
      aria-hidden={!expanded}
      className={classes}
    >
      {children}
    </AnimateHeight>
  );
};

Collapsible.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  className: PropTypes.string,
  expanded: PropTypes.bool,
  duration: PropTypes.number,
};

Collapsible.defaultProps = {
  children: "",
  className: null,
  expanded: false,
  duration: 400,
};
export default Collapsible;
