import React from "react";
import PropTypes from "prop-types";
import { classNames } from "utils/helper";
import "./index.css";

const HForm = ({ children, className, ...rest }) => {
  const classes = classNames("h-form", className || "");

  return (
    <form className={classes} {...rest}>
      {children}
    </form>
  );
};
HForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  className: PropTypes.string
};

HForm.defaultProps = {
  children: "",
  className: null
};
export default HForm;
