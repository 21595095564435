export const INITIAL_BOM = {
  defaultVendor: false,
  isBom: true,
  active: true,
  alertNote: false,
  allowUom: false,
  class: '',
  comboBox: false,
  defaultFlag: 'ft',
  height: 0,
  incomeAccount: '',
  expenseAccountName: '',
  length: 0,
  partNumber: null,
  partUom: null,
  price: -1,
  productDescription: '',
  productDetails: '',
  productPictureUrl: '[]',
  productSku: '',
  productSoItemType: 'Sale',
  productUpc: '',
  productUrl: '',
  taxable: false,
  uom: 'ea',
  unitOfMeasureName: 'Lbs',
  weight: 0,
  weightUom: 'kg',
  catLookup: '',
  errors: '[]',
  masterItemId: '',
  priceId: '',
  priceListId: '',
  salesForceFailed: false,
  salesforceLock: false,
  salesforceItemMasterUrl: '',
  salesforcePriceListUrl: '',
  salesforcePriceUrl: '',
  salesforceProductGroupId: '',
  salesforceProductGroupUrl: '',
  salesforceProductId: '',
  salesforceProductUrl: '',
  sizeUom: 'in',
  childs: [],
}

export const BOM_CHILD = {
  bomProductId: -1,
  price: 0,
  notes: '',
  soItemTypeId: '',
  kitItemTypeId: '',
  description: '',
  taxRateName: '',
  bomParentId: 0,
  salesForceFailed: false,
  errors: '',
  bomSalesforceChildId: 0,
  bomSalesforceUrl: '',
}
