import React from 'react'
import PropTypes from 'prop-types'

import { classNames } from 'utils/helper'
import './index.css'

const HButton = ({ children, className, id, placeholder, ...rest }) => {
  const classes = classNames('h-button-container ', className || '')

  return (
    <button type="button" className={classes} {...rest}>
      {children}
    </button>
  )
}
HButton.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
}

HButton.defaultProps = {
  className: '',
  id: '',
  placeholder: '',
}
export default HButton
