/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { store } from 'react-notifications-component'

import { addBom } from 'api/productApi'
import { INITIAL_BOM } from 'consts/bom'

import { isError, bomValidationCheck } from 'utils/formatter'

import LoadingAnimation from 'components/LoadingAnimation'
import Caption from 'components/Products/Caption'
import AddMedia from 'components/common/Filter/AddMedia'
import HSingleSelect from 'components/common/Basic/HSingleSelect'
import HInput from 'components/common/Basic/HInput'
import HTextInput from 'components/common/Basic/HTextInput'
import HForm from 'components/common/Basic/HForm'
import HButton from 'components/common/Basic/HButton'

import EditViewActions from 'components/common/Filter/EditViewActions'
import EditViewSettingItem from 'components/Products/EditViewSettingItem'

import HBomChildsTable from 'components/common/Basic/HBomChildsTable'
import './index.css'

const AddBom = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [newBom, setNewBom] = useState(INITIAL_BOM)
  const [showError, setShowError] = useState(false)
  const [bomChildren, setBomChildren] = useState([])

  const backToList = () => {
    history.push('/boms/view')
  }

  const onSave = () => {
    console.log('Save', newBom)
    if (!bomValidationCheck(newBom)) {
      setShowError(true)
      store.addNotification({
        message: 'Sorry, please complete the product and try again later',
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 2000,
        },
      })
      return
    }
    setLoading(true)
    const fetchData = async () => {
      try {
        const updateBomChildren = bomChildren.map((bom, index) => {
          return {
            price: bom.price,
            bomProductId: null,
            notes: null,
            soItemTypeId: null,
            kitItemTypeId: null,
            description: null,
            taxRateName: null,
            bomParentId: bom.product.id,
            salesForceFailed: null,
            errors: null,
            bomSalesforceChildId: null,
            bomSalesforceURL: null,
          }
        })

        const newProduct = { product: newBom, bomChildren: updateBomChildren }
        const response = await addBom(newProduct)
        console.log(response)
        if (response && response.error) {
          console.log(response.error)
          store.addNotification({
            message: response.error,
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 5000,
            },
          })
        } else if (response) {
          store.addNotification({
            message: `${response.message}. Redirect in 3 seconds. `,
            type: 'success',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 5000,
            },
          })
          setTimeout(() => {
            backToList()
          }, 3000)
        }
      } catch (ev) {
        console.log('AddBom error', ev)
        store.addNotification({
          message: 'Sorry, we are not able to process your request now. Please try again later.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 5000,
          },
        })
      }
      setLoading(false)
    }
    fetchData()
  }

  const onClear = () => {
    console.log('Clear')
    setNewBom(INITIAL_BOM)
  }
  const onDelete = () => {
    console.log('Cancel')
    backToList()
  }

  return (
    <div className="detail-container">
      <div className="add-product">
        {loading && <LoadingAnimation />}
        <div className="add-product-list">
          <div className="add-product-list-title">
            <Caption caption="New Bill of Materials" primary />
          </div>
          <div className="horizontal-separator" />
          <div className="add-product-edit-view">
            <HForm className="add-product-item-form">
              <EditViewSettingItem
                caption="Product Description:"
                errorMessage={
                  showError ? isError((newBom && newBom.productDescription) || '', 'string') : ''
                }
              >
                <HTextInput
                  className="add-product-text-input-style"
                  name="product_description"
                  value={newBom.productDescription}
                  onChange={(ev) => {
                    const updateBom = { ...newBom }
                    updateBom.productDescription = ev.currentTarget.value
                    setNewBom(updateBom)
                  }}
                  error={
                    showError ? isError((newBom && newBom.productDescription) || '', 'string') : ''
                  }
                  rows={3}
                />
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Product Details:"
                clearAction={() => {
                  onClear()
                }}
              >
                <HTextInput
                  className="add-product-text-input-style"
                  name="product_details"
                  value={newBom.productDetails}
                  onChange={(ev) => {
                    const updateBom = { ...newBom }
                    updateBom.productDetails = ev.currentTarget.value
                    setNewBom(updateBom)
                  }}
                  rows={5}
                />
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Price($):"
                errorMessage={showError ? isError(newBom && newBom.price, 'decimal') : ''}
              >
                <HInput
                  className="add-product-input-style"
                  name="price"
                  value={newBom.price}
                  onChange={(ev) => {
                    const updateBom = { ...newBom }
                    updateBom.price = ev.currentTarget.value
                    setNewBom(updateBom)
                  }}
                  error={showError ? isError(newBom && newBom.price, 'decimal') : ''}
                />
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Default Vendor:"
                errorMessage={showError ? isError(newBom && newBom.defaultVendor, 'string') : ''}
              >
                <HSingleSelect
                  name="isDefaultVendor"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                  value={
                    newBom && {
                      value: newBom.defaultVendor,
                      label: newBom.defaultVendor ? 'TRUE' : 'FALSE',
                    }
                  }
                  onChange={(item) => {
                    const updateBom = { ...newBom }
                    updateBom.defaultVendor = item.value
                    setNewBom(updateBom)
                  }}
                  error={showError ? isError(newBom && newBom.defaultVendor, 'string') : ''}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Active:">
                <HSingleSelect
                  name="isActive"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                  defaultValue={
                    newBom && {
                      value: newBom.active,
                      label: newBom.active ? 'TRUE' : 'FALSE',
                    }
                  }
                  onChange={(item) => {
                    const updateBom = { ...newBom }
                    updateBom.active = item.value
                    setNewBom(updateBom)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Taxable:">
                <HSingleSelect
                  name="isTaxable"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                  defaultValue={
                    newBom && {
                      value: newBom.taxable,
                      label: newBom.taxable ? 'TRUE' : 'FALSE',
                    }
                  }
                  onChange={(item) => {
                    const updateBom = { ...newBom }
                    updateBom.taxable = item.value
                    setNewBom(updateBom)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="ComboBox:">
                <HSingleSelect
                  name="isComboBox"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                  defaultValue={
                    newBom && {
                      value: newBom.comboBox,
                      label: newBom.comboBox ? 'TRUE' : 'FALSE',
                    }
                  }
                  onChange={(item) => {
                    const updateBom = { ...newBom }
                    updateBom.comboBox = item.value
                    setNewBom(updateBom)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="AllowSize:">
                <HSingleSelect
                  name="isAllowUOM"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                  defaultValue={
                    newBom && {
                      value: newBom.allowUom,
                      label: newBom.allowUom ? 'TRUE' : 'FALSE',
                    }
                  }
                  onChange={(item) => {
                    const updateBom = { ...newBom }
                    updateBom.allowUom = item.value
                    setNewBom(updateBom)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Product URL:">
                <HInput
                  className="edit-view-input-style"
                  name="product_url"
                  value={newBom.productUrl}
                  onChange={(ev) => {
                    const updateBom = { ...newBom }
                    updateBom.productUrl = ev.currentTarget.value
                    setNewBom(updateBom)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="ProductUPC:">
                <HInput
                  className="add-product-input-style"
                  name="product_upc"
                  value={newBom.productUpc}
                  onChange={(ev) => {
                    const updateBom = { ...newBom }
                    updateBom.productUpc = ev.currentTarget.value
                    setNewBom(updateBom)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="ProductSKU: (Warehouse Entry Only)">
                <HInput
                  className="add-product-input-style"
                  name="product_sku"
                  value={newBom.productSku}
                  onChange={(ev) => {
                    const updateBom = { ...newBom }
                    updateBom.productSku = ev.currentTarget.value
                    setNewBom(updateBom)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Income Account Name:">
                <HInput
                  className="edit-view-input-style"
                  name="income_account"
                  value={newBom.incomeAccount || ''}
                  onChange={(ev) => {
                    const updateBom = { ...newBom }
                    updateBom.incomeAccount = ev.currentTarget.value
                    setNewBom(updateBom)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Expense Account Name:">
                <HInput
                  className="edit-view-input-style"
                  name="expense_account"
                  value={newBom.expenseAccountName || ''}
                  onChange={(ev) => {
                    const updateBom = { ...newBom }
                    updateBom.expenseAccountName = ev.currentTarget.value
                    setNewBom(updateBom)
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="DefaultFlag:">
                <HInput
                  className="add-product-input-style"
                  name="defaultFlag"
                  value={newBom.defaultFlag}
                  onChange={(ev) => {
                    const updateBom = { ...newBom }
                    updateBom.defaultFlag = ev.currentTarget.value
                    setNewBom(updateBom)
                  }}
                />
              </EditViewSettingItem>
              <EditViewSettingItem caption="BOM Children">
                <HBomChildsTable
                  childs={bomChildren}
                  setChilds={(childsValue) => {
                    setBomChildren(childsValue)
                  }}
                />
              </EditViewSettingItem>
              <div className="add-product-last-line">
                <HButton
                  className="h-save-button"
                  onClick={() => {
                    onSave()
                  }}
                >
                  Save
                </HButton>

                <HButton
                  className="h-delete-button"
                  onClick={() => {
                    onDelete()
                  }}
                >
                  Cancel
                </HButton>
              </div>
            </HForm>
          </div>
        </div>
        <div className="add-product-filters">
          <AddMedia
            pictureUrls={newBom.productPictureUrl}
            setPictureUrls={(urls) => {
              const updateBom = { ...newBom }
              updateBom.productPictureUrl = urls
              setNewBom(updateBom)
            }}
          />
        </div>
      </div>
    </div>
  )
}

AddBom.propTypes = {}

export default withRouter(AddBom)
