export const INITIAL_SUPPLIER = {
  name: '',
  serviceName: [],
  serviceFreq: [],
  active: true,
  address: '',
  phoneNumber: '',
  website: '',
  contact: '',
  salesForceId: '',
  siteId: '',
  accountId: '',
  catalogueId: '',
}
