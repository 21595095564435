/* eslint-disable import/no-cycle */
import React from 'react'
import { Route, Switch, withRouter, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

import SignUpModal from 'components/common/Modal/SignUpModal'
import SignInModal from 'components/common/Modal/SignInModal'
import Header from 'components/common/Header/Header'
import Sidebar from 'components/common/Sidebar/Sidebar'
import HomePage from 'pages/Home'
import ForgotPage from 'pages/Forgot'
import ProfileSetting from 'pages/ProfileSetting'
import ViewProducts from 'pages/Products/ViewProducts'
import ViewPartList from 'pages/Parts/PartList'
import ScraperList from 'pages/ScraperList'
import AddProduct from 'pages/Products/AddProduct'
import NewPart from 'pages/Parts/NewPart'
import Dashboard from 'pages/Dashboard'
import ViewUsers from 'pages/UserManagement/Users'
import UserDetail from 'pages/UserManagement/Users/user-detail'
import AddUser from 'pages/UserManagement/AddUser'
import ProductDetail from 'pages/Products/ViewProducts/product-detail'
import PartDetail from 'pages/Parts/PartList/part-detail'
import ViewSuppliers from 'pages/Suppliers/ViewSuppliers'
import SupplierDetail from 'pages/Suppliers/ViewSuppliers/supplier-detail'
import AddSupplier from 'pages/Suppliers/AddSupplier'
import ApproveDeclineProducts from 'pages/Approvals'
import ApprovalDetail from 'pages/Approvals/approval-detail'
import ViewBoms from 'pages/Boms/ViewBoms'
import BomDetail from 'pages/Boms/ViewBoms/bom-detail'
import AddBom from 'pages/Boms/AddBom'
import PageNotFound from 'pages/PageNotFound'
import ScrollToTop from 'components/common/Basic/ScrollToTop'

function App({ user, message }) {
  const { pathname } = useLocation()
  const userSignedIn = user && user.confirmed
  const userAdmin = user && user.confirmed && user.isAdmin

  if (!userSignedIn) {
    console.log('errorMessage app.js', message)
    return (
      <>
        <ReactNotification />
        {pathname !== '/forgot' && pathname !== '/profile' && <Header />}
        <div className="main_body">
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/forgot" component={ForgotPage} />
            <Route exact path="/profile" component={ProfileSetting} />
            <Route component={PageNotFound} />
          </Switch>
        </div>

        <SignInModal errorMessage={message} />
        <SignUpModal />
      </>
    )
  }
  if (userAdmin) {
    return (
      <>
        <ReactNotification />

        {/* <Header />
        <div className="horizontal-separator" /> */}
        <div className="main_body">
          <Sidebar />
          <div className="main-body-panel">
            <Header />
            <Switch>
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/products/view" component={ViewProducts} />
              <Route exact path="/products/view/:id" component={ProductDetail} />
              <Route exact path="/products/add" component={AddProduct} />
              <Route exact path="/boms/view" component={ViewBoms} />
              <Route exact path="/boms/view/:id" component={BomDetail} />
              <Route exact path="/boms/new-bom" component={AddBom} />
              <Route exact path="/supplier-part-list" component={ViewPartList} />
              <Route exact path="/supplier-part-list/:id" component={PartDetail} />
              <Route exact path="/suppliers" component={ViewSuppliers} />
              <Route exact path="/suppliers/:id" component={SupplierDetail} />
              <Route exact path="/new-supplier" component={AddSupplier} />
              <Route exact path="/new-part" component={NewPart} />
              <Route exact path="/competitor-scraper-list" component={ScraperList} />
              <Route exact path="/approvals/view" component={ApproveDeclineProducts} />
              <Route exact path="/approvals/view/:id" component={ApprovalDetail} />
              <Route exact path="/users/view" component={ViewUsers} />
              <Route exact path="/users/view/:id" component={UserDetail} />
              <Route exact path="/users/new" component={AddUser} />
              <Route component={PageNotFound} />
            </Switch>
            <ScrollToTop />
          </div>
        </div>
        <SignInModal errorMessage={message} />
        <SignUpModal />
      </>
    )
  }
  return (
    <>
      <ReactNotification />

      <div className="main_body">
        <Sidebar />
        <div className="main-body-panel">
          <Header />
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/products/view" component={ViewProducts} />
            <Route exact path="/products/view/:id" component={ProductDetail} />
            <Route exact path="/products/add" component={AddProduct} />
            <Route exact path="/boms/view" component={ViewBoms} />
            <Route exact path="/boms/view/:id" component={BomDetail} />
            <Route exact path="/boms/new-bom" component={AddBom} />
            <Route exact path="/supplier-part-list" component={ViewPartList} />
            <Route exact path="/supplier-part-list/:id" component={PartDetail} />
            <Route exact path="/suppliers" component={ViewSuppliers} />
            <Route exact path="/new-part" component={NewPart} />
            <Route exact path="/competitor-scraper-list" component={ScraperList} />
            <Route component={PageNotFound} />
          </Switch>
          <ScrollToTop />
        </div>
      </div>
      <SignInModal errorMessage={message} />
      <SignUpModal />
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.user && state.user.user,
  message: state.user && state.user.message,
})
App.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    confirmed: PropTypes.bool,
    isAdmin: PropTypes.bool,
  }).isRequired,
  message: PropTypes.string,
}

const connectedHeader = connect(mapStateToProps, null)(App)
export default withRouter(connectedHeader)
