import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Spinner from 'react-bootstrap/Spinner'
import { uploadImage, uploadCsvFile } from 'api/productApi'
import HButton from 'components/common/Basic/HButton'
import { store } from 'react-notifications-component'

import './index.css'

const UploadCSV = ({ caption }) => {
  const [csvFiles, setCsvFiles] = useState([])
  const [csvFileUrls, setCsvFileUrls] = useState([])
  const [importingFileIndex, setImportingFileIndex] = useState(-1)
  const [importStates, setImportStates] = useState([])

  const [importLogs, setImportLogs] = useState([])
  const [loading, setLoading] = useState(false)

  const csvImportAction = async (file, index) => {
    if (!file) {
      store.addNotification({
        message: 'Invalid file.',
        type: 'error',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
        },
      })
      return null
    }

    const updatedImportLogs = [...importLogs]
    updatedImportLogs[index].push('...')
    updatedImportLogs[index].push('Import Started...')
    updatedImportLogs[index].push('...')
    const updatedStates = [...importStates]
    updatedStates[index] = 'Loading...'
    setImportStates(updatedStates)
    setImportLogs(updatedImportLogs)
    setImportingFileIndex(index)

    try {
      const log = await uploadCsvFile(file)
      if (!log || (log && log.error) || !log.message) {
        console.log('Uploading CSV Error', log)
        store.addNotification({
          message: `Error: ${log.error}`,
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
          },
        })
        const updatedImportLogs = [...importLogs]
        updatedImportLogs[index].push(log)
        updatedImportLogs[index].push('Import Failed ..')
        const updatedStates = [...importStates]
        updatedStates[index] = 'Import'
        setImportStates(updatedStates)
        setImportLogs(updatedImportLogs)
        setImportingFileIndex(-1)
        return null
      }
      console.log(log.message)
      const updatedImportLogs = [...importLogs]
      updatedImportLogs[index].push(log.message)
      updatedImportLogs[index].push('Import Success')
      updatedImportLogs[index].push('... ')
      const updatedStates = [...importStates]
      updatedStates[index] = 'Success'
      setImportStates(updatedStates)
      setImportLogs(updatedImportLogs)
      setImportingFileIndex(-1)
    } catch (error) {
      console.log('Uploading CSV Error', error)
      store.addNotification({
        message: 'Sorry, we are not able to process your request now, please try again later.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
        },
      })

      const updatedImportLogs = [...importLogs]
      updatedImportLogs[index].push(`${error}`)
      updatedImportLogs[index].push('Import Failed')
      updatedImportLogs[index].push('...')
      const updatedStates = [...importStates]
      updatedStates[index] = 'Import'
      setImportStates(updatedStates)
      setImportLogs(updatedImportLogs)
      setImportingFileIndex(-1)

      return null
    }
  }

  const fileSelectHandler = async (e) => {
    if (e.currentTarget.files[0] === null) {
      return
    }
    setLoading(true)
    const upload = async () => {
      const updatedCsvFiles = [...csvFiles]
      const updatedLogs = [...importLogs]
      const updatedStates = [...importStates]
      const updatedCsvUrls = [...csvFileUrls]

      try {
        const filePath = e.currentTarget.files[0].name
        const fileSize = e.currentTarget.files[0].size
        if (fileSize >= 10 * 1024 * 1024) {
          store.addNotification({
            message: 'Sorry, limit of CSV file is 10MB. Please try again.',
            type: 'warning',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 3000,
            },
          })
          setLoading(false)
          return
        }
        const uploadedFile = await uploadImage(e.currentTarget.files[0])
        if (!uploadedFile || (uploadedFile && uploadedFile.error) || !uploadedFile.url) {
          console.log('Uploading Error', uploadedFile)
          store.addNotification({
            message: 'Sorry, we are not able to process your request now, please try again later.',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 3000,
            },
          })
          setLoading(false)
          return
        }
        updatedCsvUrls.push(uploadedFile.url)
        updatedCsvFiles.push(filePath)
        updatedLogs.push([])
        updatedStates.push('Import')
        setImportLogs(updatedLogs)
        setCsvFiles(updatedCsvFiles)
        setImportStates(updatedStates)
        setCsvFileUrls(updatedCsvUrls)
        setLoading(false)
      } catch (error) {
        console.log('Upload Error', error)
        store.addNotification({
          message: 'Sorry, we are not able to process your request now, please try again later.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
          },
        })
        setLoading(false)
      }
    }
    upload()
  }

  const onStartImport = (file, index) => {
    if (importingFileIndex !== -1) {
      store.addNotification({
        message: 'Another instance is running.',
        type: 'warning',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
        },
      })
      return
    }

    if (importStates[index] === 'Success') {
      store.addNotification({
        message: "It's already imported.",
        type: 'info',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
        },
      })
      return
    }
    console.log(file, csvFileUrls[index])
    csvImportAction(csvFileUrls[index], index)
  }

  return (
    <>
      <div className="upload-csv-files">
        <div className="header-part">
          <span>{caption}</span>
          <HButton className="upload-csv-button" onClick={(ev) => {}}>
            <img
              src="/assets/img/common/file-upload-solid.svg"
              alt="missing"
              width="25px"
              height="25px"
            />
            <input
              type="file"
              name="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={fileSelectHandler}
              onClick={(event) => {
                event.target.value = null
              }}
            />
          </HButton>
        </div>
        <div className="horizontal-separator" />
        <div className="body-part">
          {csvFiles &&
            csvFiles.map((csv, index) => {
              return (
                <div className="csv-file" key={`csv-${index}`}>
                  <div className="csv-file-div">
                    <img
                      src="/assets/img/suppliers/supplier-csv.png"
                      alt="excel"
                      width="30px"
                      height="30px"
                    />
                    <span>{csv}</span>
                    <HButton
                      className={
                        (importStates[index] === 'Import' && 'h-medium-button h-blue-button') ||
                        (importStates[index] === 'Imported' && 'h-medium-button h-green-button') ||
                        'h-medium-button h-grey-button'
                      }
                      onClick={() => {
                        onStartImport(csv, index)
                      }}
                      disabled={index === importingFileIndex}
                    >
                      {importStates[index]}
                    </HButton>
                  </div>
                  {index === importingFileIndex ? (
                    <div className="csv-file-div-loading">
                      <Spinner animation="border" variant="secondary" />
                    </div>
                  ) : (
                    <div className="csv-file-div-log">
                      {importLogs && importLogs[index].length > 0 && (
                        <div className="h-log-div pt-2 pb-2">
                          <div className="h-log-clear-div">
                            <HButton
                              className="h-transparent-button"
                              onClick={() => {
                                const logsList = [...importLogs]
                                logsList[index] = []
                                setImportLogs(logsList)
                              }}
                              type="button"
                            >
                              <img
                                src="/assets/img/suppliers/log-delete.png"
                                width="30px"
                                height="30px"
                                alt="close"
                              />
                            </HButton>
                          </div>
                          {importLogs[index] &&
                            importLogs[index].map((log, ind) => {
                              return (
                                <div className="text-left pl-4" key={`csv-log-${index}-${ind}`}>
                                  {log}
                                </div>
                              )
                            })}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )
            })}
          {loading && (
            <div className="row align-items-center justify-content-center mb-3 mt-3">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
UploadCSV.propTypes = {
  caption: PropTypes.string,
}

UploadCSV.defaultProps = {
  caption: 'Upload CSV',
}
export default UploadCSV
