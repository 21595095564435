import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Spinner from 'react-bootstrap/Spinner'
import { uploadImage } from 'api/productApi'
import HButton from '../Basic/HButton'
import HImage from '../Basic/HImage'
import './index.css'

const AddMedia = ({ caption, pictureUrls, setPictureUrls, readOnly, uniqueMode }) => {
  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (pictureUrls) {
      try {
        const urls = JSON.parse(pictureUrls)
        setImages([...urls])
      } catch (ev) {
        setImages([])
      }
    }
  }, [pictureUrls])

  const fileSelectHandler = async (e) => {
    if (e.currentTarget.files[0] === null) {
      return
    }
    if (uniqueMode) {
      setLoading(true)
      const uploadUnique = async () => {
        const updatedImageList = []
        try {
          const newImage = await uploadImage(e.currentTarget.files[0])
          if (newImage.error) {
            console.log('Image Uploading Error', newImage)
            return
          }
          updatedImageList.push(newImage.url)
          setImages(updatedImageList)
          setPictureUrls(JSON.stringify(updatedImageList))
        } catch (error) {
          console.log('AddMediaError', error)
        }
        setLoading(false)
      }
      uploadUnique()
      return
    }
    const updatedImageList = [...images]
    setLoading(true)
    const upload = async () => {
      try {
        const newImage = await uploadImage(e.currentTarget.files[0])
        if (newImage.error) {
          console.log('Image Uploading Error', newImage)
          return
        }
        updatedImageList.push(newImage.url)
        setImages(updatedImageList)
        setPictureUrls(JSON.stringify(updatedImageList))

        setLoading(false)
      } catch (error) {
        console.log('AddMediaError', error)
      }
    }
    upload()
  }

  const removeImage = (imageIndex) => {
    const updatedImages = [...images]
    updatedImages.splice(imageIndex, 1)
    setImages(updatedImages)
    setPictureUrls(JSON.stringify(updatedImages))
  }

  const setFeatureImage = (imageIndex) => {
    const updatedImages = [...images]
    const featuredImage = updatedImages[0]

    updatedImages.splice(0, 1, updatedImages[imageIndex])
    updatedImages.splice(imageIndex, 1, featuredImage)
    setImages(updatedImages)
    setPictureUrls(JSON.stringify(updatedImages))
  }

  return (
    <>
      <div className="add-media-product-images">
        <div className="header-part">
          {caption}
          {!readOnly && (
            <HButton
              className="rounded-button"
              onClick={(ev) => {
                console.log('AddImage clicked')
              }}
            >
              +
              <input
                type="file"
                name="file"
                accept="image/png, image/jpeg, image/bmp"
                onChange={fileSelectHandler}
                onClick={(event) => {
                  event.target.value = null
                }}
              />
            </HButton>
          )}
        </div>
        <div className="horizontal-separator" />
        <div className="body-part">
          {loading && (
            <div
              className="row align-items-center justify-content-center mb-3 mt-3
          "
            >
              <Spinner animation="border" variant="primary" />
            </div>
          )}
          {images &&
            images.length > 0 &&
            images.map((image, index) => {
              if (index === 0) {
                return (
                  <div key={image}>
                    {!uniqueMode && <div className="body-part-featured-text">Featured Image</div>}
                    <div className="body-part-featured-image">
                      <HImage
                        src={image}
                        alt="missing"
                        width="336px"
                        height="156px"
                        // onEdit={() => {
                        //   console.log("edit");
                        // }}
                        onRemove={() => {
                          removeImage(0)
                        }}
                        readOnly={readOnly}
                      />
                    </div>
                  </div>
                )
              }
              if (index % 2 === 1) {
                return (
                  <div key={`image-${index - 1} / 2`} className="body-part-row">
                    <div className="body-part-image">
                      <HImage
                        src={image}
                        alt="missing"
                        width="156px"
                        height="156px"
                        // onEdit={() => {
                        //   console.log("edit");
                        // }}
                        onRemove={() => {
                          removeImage(index)
                        }}
                        onPrimary={() => {
                          setFeatureImage(index)
                        }}
                        readOnly={readOnly}
                      />
                    </div>
                    <div className="body-part-image">
                      {index + 1 < images.length && (
                        <HImage
                          src={images[index + 1]}
                          alt="missing"
                          width="156px"
                          height="156px"
                          // onEdit={() => {
                          //   console.log("edit");
                          // }}
                          onRemove={() => {
                            removeImage(index + 1)
                          }}
                          onPrimary={() => {
                            setFeatureImage(index + 1)
                          }}
                          readOnly={readOnly}
                        />
                      )}
                    </div>
                  </div>
                )
              }
              return null
            })}
          {(!images || images.length === 0) && (
            <p>No images found. Click the + above to add new images.</p>
          )}
        </div>
      </div>
    </>
  )
}
AddMedia.propTypes = {
  caption: PropTypes.string,
  pictureUrls: PropTypes.string,
  setPictureUrls: PropTypes.func,
  readOnly: PropTypes.bool,
  uniqueMode: PropTypes.bool,
}

AddMedia.defaultProps = {
  caption: 'Images',
  pictureUrls: '[]',
  setPictureUrls: (sz) => {
    console.log(sz)
  },
  readOnly: false,
  uniqueMode: false,
}
export default AddMedia
