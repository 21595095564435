import _ from 'lodash'
import camelCase from 'lodash/camelCase'
import snakeCase from 'lodash/snakeCase'
import startCase from 'lodash/startCase'

// eslint-disable-next-line
export const objectKeysToCamelCase = (snake_case_object) => {
  // for the array which contains object keys as well
  const camelCaseObject = {}
  let newValue
  _.forEach(snake_case_object, (value, key) => {
    if (_.isPlainObject(value) || _.isArray(value)) {
      // checks that a value is a plain object or an array - for recursive key conversion
      newValue = objectKeysToCamelCase(value) // recursively update keys of any values that are also objects
    }
    camelCaseObject[_.camelCase(key)] = newValue
  })
  return camelCaseObject
}

export const objectKeysToSnakeCase = (camelCaseObject) => {
  // eslint-disable-next-line
  const snake_case_object = {}
  let newValue
  _.forEach(camelCaseObject, (value, key) => {
    if (_.isPlainObject(value) || _.isArray(value)) {
      // checks that a value is a plain object or
      // an array for recursive key conversion
      newValue = objectKeysToSnakeCase(value) // recursively update keys of any values that are also objects
    }
    snake_case_object[_.snakeCase(key)] = newValue
  })
  // eslint-disable-next-line
  return snake_case_object
}

function camelCaseWithException(key) {
  if (key === 'UOMs') {
    return key
  }
  if (key === 'BOMProductId') {
    return 'bomProductId'
  }
  return camelCase(key)
}

function snakeCaseWithException(key) {
  if (key === 'address1') {
    return key
  }

  return snakeCase(key)
}

export const toCamelKeys = (obj) => {
  // for regular use
  if (Array.isArray(obj)) {
    return obj.map((v) => toCamelKeys(v))
  }
  if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCaseWithException(key)]: toCamelKeys(obj[key]),
      }),
      {}
    )
  }
  return obj
}

function pascalCaseWithException(key) {
  if (key === 'id' || key === 'createdAt' || key === 'updatedAt') {
    return key
  }
  if (key === 'allowUom') return 'AllowUOM'
  if (key === 'productSku') return 'ProductSKU'
  if (key === 'productSoItemType') return 'ProductSOItemType'
  if (key === 'productUpc') return 'ProductUPC'
  if (key === 'productUrl') return 'ProductURL'
  if (key === 'uom') return 'UOM'
  if (key === 'weightUom') return 'WeightUOM'
  if (key === 'masterItemId') return 'MasterItemID'
  if (key === 'salesforcePriceUrl') return 'SalesforcePriceURL'
  if (key === 'salesforceProductGroupUrl') return 'SalesforceProductGroupURL'
  if (key === 'salesforceProductUrl') return 'SalesforceProductURL'
  if (key === 'sizeUom') return 'SizeUOM'
  if (key === 'UOMs') return 'UOMs'
  if (key === 'partUom') return 'PartUOM'
  if (key === 'isBom') return 'IsBOM'
  if (key === 'bomParentId') return 'BOMParentID'
  if (key === 'bomProductId') return 'BOMProductId'
  return startCase(camelCase(key)).replace(/ /g, '')
}

export const toSnakeKeys = (obj) => {
  // for regular use
  if (Array.isArray(obj)) {
    return obj.map((v) => toSnakeKeys(v))
  }
  if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [snakeCaseWithException(key)]: toSnakeKeys(obj[key]),
      }),
      {}
    )
  }
  return obj
}

export const toPascalKeys = (obj) => {
  if (obj === undefined || obj === null) {
    return obj
  }
  if (Array.isArray(obj)) {
    return obj.map((v) => toPascalKeys(v))
  }
  if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [pascalCaseWithException(key)]: toPascalKeys(obj[key]),
      }),
      {}
    )
  }
  return obj
}
