import { fetchAPI } from './apiUtils'
import { SEARCH_TOP_BAR } from '../consts/url'

export const getTopBarSearchResult = (keyword) => {
  const formData = {
    keyword,
  }

  return fetchAPI(SEARCH_TOP_BAR, 'POST', formData)
}
