import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import HSingleSelect from 'components/common/Basic/HSingleSelect'
import FILTERS, {
  PRODUCT_FILTERS_CATERGORIES,
  PART_FILTERS_CATEGORIES,
  SUPPLIER_FILTERS_CATERGORIES,
  BOM_FILTERS_CATEGORIES,
  PRODUCT_SELECT_CATEGORIES,
  PART_SELECT_CATEGORIES,
  SUPPLIER_SELECT_CATEGORIES,
  BOM_SELECT_CATEGORIES,
} from 'consts/filters'
import HInput from '../Basic/HInput'
import HDropButton from '../Basic/HDropButton'
import HButton from '../Basic/HButton'
import Collapsible from '../Basic/Collapsible'
import { getUomList, getActiveSuppliers } from 'api/productApi'
import './index.css'

const CustomFilters = ({
  isProduct,
  isPart,
  isScraper,
  isBom,
  isSupplier,
  onApply,
  onCancel,
  filter,
}) => {
  const [filters, setFilters] = useState([
    {
      category: '',
      operator: 'eq',
      value: '',
      label: '',
    },
  ])
  const [isExpanded, setExpand] = useState(filter.length > 0)
  const [categoryValues, setCategoryValues] = useState({})

  const updateProductOptions = async () => {
    const uomList = await getUomList()
    let uomOptions = []
    if (uomList) {
      uomOptions = uomList.map((item) => {
        return {
          value: item && item.name,
          label: item && item.name,
        }
      })
    }
    setCategoryValues({ ...PRODUCT_SELECT_CATEGORIES, UOM: [...uomOptions] })
  }

  const updatePartOptions = async () => {
    const { manufacturers, vendors } = await getActiveSuppliers()
    let manufacturersOptions = []
    if (manufacturers) {
      manufacturersOptions = manufacturers.map((item) => {
        return {
          value: item,
          label: item,
        }
      })
    }
    let vendorsOptions = []
    if (vendors) {
      vendorsOptions = vendors.map((item) => {
        return {
          value: item,
          label: item,
        }
      })
    }
    const uomList = await getUomList()
    let uomOptions = []
    if (uomList) {
      uomOptions = uomList.map((item) => {
        return {
          value: item && item.name,
          label: item && item.name,
        }
      })
    }
    setCategoryValues({
      ...PART_SELECT_CATEGORIES,
      ManufacturerName: [...manufacturersOptions],
      Vendor: [...vendorsOptions],
      UOM: [...uomOptions],
    })
  }

  useEffect(() => {
    if (isProduct) {
      updateProductOptions()
    }
    if (isPart) {
      updatePartOptions()
    }
    if (isSupplier) {
      setCategoryValues(SUPPLIER_SELECT_CATEGORIES)
    }
    if (isBom) {
      setCategoryValues(BOM_SELECT_CATEGORIES)
    }
  }, [isProduct, isPart, isSupplier, isBom])

  useEffect(() => {
    console.log(filter)

    if (filter.length > 0) {
      const temporary = []
      filter.map((item) => {
        temporary.push({
          category: item.category,
          operator: item.operator,
          value: item.value,
          label: item.label,
        })
      })
      if (temporary.length > 0) {
        setFilters(temporary)
      }
    }
  }, [filter])

  const applyFilters = () => {
    const filterApply = []
    if (filters.length === 0) {
      onCancel()
      return
    }
    filters.map((item) => {
      if (item.category && item.value) {
        filterApply.push(item)
      }
    })
    onApply(filterApply)
  }
  const setCategory = (index, value) => {
    console.log(index, value)
    const filtersTemporary = [...filters]
    filtersTemporary[index].category = value
    if (value && categoryValues[value]) {
      filtersTemporary[index].value = categoryValues[value][0].value
      filtersTemporary[index].label = categoryValues[value][0].label
    }
    setFilters(filtersTemporary)
  }

  const setValue = (index, value, label) => {
    const filtersTemporary = [...filters]
    filtersTemporary[index].value = value
    filtersTemporary[index].label = label
    setFilters(filtersTemporary)
  }

  const setOperator = (index, value) => {
    const filtersTemporary = [...filters]
    filtersTemporary[index].operator = value
    setFilters(filtersTemporary)
  }

  const removeFilter = (index) => {
    const filtersTemporary = [...filters]
    filtersTemporary.splice(index, 1)

    if (filtersTemporary.length === 0) {
      onCancel()
      setFilters([
        {
          category: '',
          operator: 'eq',
          value: '',
          label: '',
        },
      ])
    } else {
      setFilters(filtersTemporary)
      onApply(filtersTemporary)
    }
  }

  return (
    <div className="filters-container">
      <div className="filters-container-caption">
        <span>Filters</span>
        <button
          className="filters-container-expand"
          onClick={() => {
            setExpand(!isExpanded)
          }}
          type="button"
        >
          {isExpanded ? (
            <img src="/assets/img/common/close.svg" alt="close" />
          ) : (
            <img src="/assets/img/common/expand.svg" alt="expand" />
          )}
        </button>
      </div>
      <Collapsible expanded={isExpanded} duration={300}>
        <div className="filters-container-seperator" />
        <div className="filters-container-body">
          {filters.length > 0 &&
            filters.map((filter, index) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`filter-${index}`} className="filters-container-row">
                  <div className="filters-container-row-custom">
                    <div className="filters-container-row-custom-filter">
                      <HSingleSelect
                        values={
                          (isProduct &&
                            PRODUCT_FILTERS_CATERGORIES.map((category) => ({
                              value: category,
                              label: category,
                            }))) ||
                          (isPart &&
                            PART_FILTERS_CATEGORIES.map((category) => ({
                              value: category,
                              label: category,
                            }))) ||
                          (isSupplier &&
                            SUPPLIER_FILTERS_CATERGORIES.map((category) => ({
                              value: category,
                              label: category,
                            }))) ||
                          (isBom &&
                            BOM_FILTERS_CATEGORIES.map((category) => ({
                              value: category,
                              label: category,
                            }))) ||
                          FILTERS.CATEGORIES.map((category) => ({
                            value: category,
                            label: category,
                          }))
                        }
                        placeholder="Select One"
                        name="Categories"
                        isSearchable={false}
                        onChange={(ev) => {
                          setCategory(index, ev.value)
                        }}
                        value={{
                          value: filter.category,
                          label: filter.category,
                        }}
                      />
                    </div>
                    <div className="filters-container-row-custom-operator">
                      <HDropButton
                        placeholder="Equal"
                        values={FILTERS.OPERATORS}
                        defaultValue={{ value: 'eq', label: 'Equals' }}
                        onChange={(ev) => {
                          setOperator(index, ev.value)
                        }}
                        isSearchable={false}
                      />
                    </div>
                    <div className="filters-container-row-custom-value">
                      {filter.category && categoryValues[filter.category] ? (
                        <HDropButton
                          placeholder=" "
                          values={categoryValues[filter.category]}
                          defaultValue={categoryValues[filter.category][0]}
                          onChange={(ev) => {
                            setValue(index, ev.value, ev.label)
                          }}
                          isSearchable
                          value={{
                            value: filter && filter.value,
                            label: filter && filter.label,
                          }}
                        />
                      ) : (
                        <HInput
                          placeholder="Input Value..."
                          inputType="mini"
                          name={`custom-filter-right-${index}`}
                          type="mini"
                          onChange={(ev) => {
                            setValue(index, ev.target.value)
                          }}
                          value={filter && filter.value}
                        />
                      )}
                    </div>
                    <div className="filters-container-row-custom-close">
                      <HButton
                        className="h-mini-button h-close-button"
                        onClick={(ev) => {
                          removeFilter(index)
                        }}
                      />
                    </div>
                  </div>
                </div>
              )
            })}
          <div className="filters-container-row">
            <div className="filters-container-row-left">
              <HButton
                className="h-medium-button h-green-button mr-3"
                onClick={() => {
                  const temporaryFilters = [...filters]
                  temporaryFilters.push({
                    category: '',
                    operator: 'eq',
                    value: '',
                  })
                  setFilters(temporaryFilters)
                }}
              >
                Add Filter
              </HButton>
            </div>

            <div className="filters-container-row-right">
              <HButton className="h-medium-button h-green-button mr-2" onClick={applyFilters}>
                Apply
              </HButton>
              <HButton
                className="h-medium-button h-pink-button"
                onClick={() => {
                  setFilters([
                    {
                      category: '',
                      operator: 'eq',
                      value: '',
                      label: '',
                    },
                  ])
                  onCancel()
                  setExpand(false)
                }}
              >
                Cancel
              </HButton>
            </div>
          </div>
        </div>
      </Collapsible>
    </div>
  )
}
CustomFilters.propTypes = {
  isProduct: PropTypes.bool,
  isPart: PropTypes.bool,
  isScraper: PropTypes.bool,
  isSupplier: PropTypes.bool,
  isBom: PropTypes.bool,
  onApply: PropTypes.func,
  onCancel: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  filter: PropTypes.any,
}

CustomFilters.defaultProps = {
  isProduct: false,
  isPart: false,
  isScraper: false,
  isSupplier: false,
  isBom: false,
  onApply: () => {},
  onCancel: () => {},
  filter: [],
}
export default CustomFilters
