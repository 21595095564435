/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react'
import { withRouter, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import LoadingAnimation from 'components/LoadingAnimation'
import Caption from 'components/Products/Caption'
import AddMedia from 'components/common/Filter/AddMedia'
import HSingleSelect from 'components/common/Basic/HSingleSelect'
import HInput from 'components/common/Basic/HInput'
import HTextInput from 'components/common/Basic/HTextInput'
import HSearchPartInput from 'components/common/Basic/HSearchPartInput'
import HButton from 'components/common/Basic/HButton'
import PRODUCTS, { INITIAL_PRODUCT } from 'consts/products'
import { isError, productValidationCheck } from 'utils/formatter'
import HForm from 'components/common/Basic/HForm'
import ProductSalesforceItems from 'components/common/Filter/SalesforceItems'
import SuggestedCompetitorsProducts from 'components/common/Filter/SuggestedCompetitorsProducts'
import SimilarProducts from 'components/common/Filter/SimilarProducts'
import DeleteWarningModal from 'components/common/Modal/DeleteWarningModal'
import EditViewSettingItem from 'components/Products/EditViewSettingItem'
import ProductPreview from 'components/Products/ProductPreview'
import { store } from 'react-notifications-component'
import { getProduct, getPart, updateProduct, deleteProduct, getUomList } from 'api/productApi'
import { PartPreviewSmall } from 'components/Parts/PartPreview'
import PartUomView from 'components/Parts/PartUom'
import './index.css'

const ProductDetail = ({ history }) => {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [showProduct, setShowProduct] = useState({})
  const [orgProduct, setOrgProduct] = useState({})
  const [linkedPart, setLinkedPart] = useState(null)
  const [competitors, setCompetitors] = useState(null)
  const [similarProducts, setSimilarProducts] = useState(null)
  const [globalUoms, setGlobalUoms] = useState([])
  const [deleteWarning, showDeleteWarning] = useState(false)
  const [undoWarning, showUndoWarning] = useState(false)
  const [changes, setChanges] = useState({})
  const [productUoms, setProductUoms] = useState([])

  const backToList = () => {
    history.push('/products/view')
  }

  useEffect(() => {
    setLoading(true)
    const getData = async () => {}
    getData()
  }, [])

  useEffect(() => {
    if (!id) {
      backToList()
    }
    setLoading(true)
    const getData = async () => {
      try {
        const {
          product: curProduct,
          relatedCompetitorProducts: competitorProducts,
          similarManufacturerParts: similar,
        } = await getProduct(id)
        if (curProduct && curProduct.error) {
          console.log(curProduct.error)
          store.addNotification({
            message: 'Sorry, we are not able to process your request now, please try again later.',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 5000,
            },
          })
        } else {
          setShowProduct({ ...INITIAL_PRODUCT, ...curProduct })
          setCompetitors(competitorProducts)
          setSimilarProducts(similar)
          if (curProduct && curProduct.partNumber >= 0) {
            const tempPart = await getPart(curProduct.partNumber)
            if (tempPart && tempPart.error) {
              store.addNotification({
                message: `Part Not Found: ${curProduct.partNumber}`,
                type: 'danger',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'bounceIn'],
                animationOut: ['animated', 'fadeOut'],
                dismiss: {
                  duration: 2000,
                },
              })
              setLinkedPart(null)
            } else if (tempPart && tempPart.UOMs) {
              tempPart.UOMs.forEach((uom) => {
                if (uom.id === curProduct.partUom) {
                  tempPart.uom = uom
                }
              })
              setLinkedPart(tempPart)
              setProductUoms(
                tempPart.UOMs.map((uom) => {
                  return uom && uom.uom
                })
              )
            }
          } else {
            // store.addNotification({
            //   message: `Invalid Part Number. ${curProduct.partNumber}`,
            //   type: 'danger',
            //   insert: 'top',
            //   container: 'top-right',
            //   animationIn: ['animated', 'bounceIn'],
            //   animationOut: ['animated', 'fadeOut'],
            //   dismiss: {
            //     duration: 2000,
            //   },
            // })
            setLinkedPart(null)
          }
          setOrgProduct(curProduct)
          setLoading(false)
        }
      } catch (error) {
        console.log('ViewProduct error', error)
        store.addNotification({
          message: 'Sorry, we are not able to process your request now. Please try again later.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 4000,
          },
        })
      }
      try {
        const uoms = await getUomList()
        if (uoms) {
          setGlobalUoms(
            uoms.map((item) => {
              return item && item.name
            })
          )
        } else {
          setGlobalUoms([])
        }
      } catch (error) {
        console.log('GetUOMs error', error)
        store.addNotification({
          message: 'Sorry, we are not able to process your request now. Please try again later.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 4000,
          },
        })
      }
    }
    getData()
  }, [id])

  const onSave = () => {
    if (!productValidationCheck(showProduct)) {
      console.log('onSave productValidationCheck failed')
      store.addNotification({
        message: 'Complete Product and try again.',
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 2000,
        },
      })
      return
    }
    if (showProduct === orgProduct) {
      console.log('Save --> no changes')
      store.addNotification({
        message: 'Sorry, no changes were detected. Please try again after changing the details.',
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      })
    } else {
      console.log('Save', showProduct, orgProduct)

      setLoading(true)
      const editProduct = async () => {
        const response = await updateProduct(orgProduct, showProduct)
        console.log(response)
        if (response && response.error) {
          console.log(response.error.message)
          store.addNotification({
            message: 'Sorry, we are not able to process your request now. please try again later.',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 4000,
            },
          })
        } else if (response) {
          // eslint-disable-next-line no-alert
          console.log(response)
          store.addNotification({
            message: `${response.message}`,
            type: 'success',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 5000,
            },
          })
        }
        setLoading(false)
      }
      editProduct()
    }
  }

  const onClear = () => {
    setShowProduct({
      ...INITIAL_PRODUCT,
      id: showProduct.id,
      productNumber: showProduct.productNumber,
      partNumber: showProduct.partNumber,
      partUom: showProduct.partUom,
      productPictureUrl: showProduct.productPictureUrl,
    })
  }

  const logChange = (category) => {
    const tempChanges = { ...changes }
    tempChanges[category] = true
    setChanges(tempChanges)
  }

  const onUndo = () => {
    setShowProduct(orgProduct)
    setChanges({})
  }

  const onDelete = () => {
    setLoading(true)
    const onDeleteAction = async () => {
      const response = await deleteProduct(showProduct.id)
      console.log(response)
      if (response && response.error) {
        console.log(response.error.message)
        store.addNotification({
          message: 'Sorry, we are not able to process your request now. Please try again later.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 4000,
          },
        })
      } else if (response) {
        store.addNotification({
          message: `${response.message}. Redirect in 3 seconds. `,
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 5000,
          },
        })
        setTimeout(() => {
          backToList()
        }, 3000)
      }
      setLoading(false)
    }
    onDeleteAction()
  }
  const onViewEditProduct = (product) => {
    history.push(`/products/view/${product.id}`)
  }

  const onViewCompetitor = (competitor) => {
    if (competitor && competitor.productUrl) {
      window.open(competitor.productUrl)
    }
  }

  const onViewSimilarProduct = (part) => {
    if (part && part.id) {
      history.push(`/supplier-part-list/${part.id}`)
    }
  }
  return (
    <div className="detail-container">
      <div className="product-detail">
        {deleteWarning && (
          <DeleteWarningModal
            onHide={() => {
              showDeleteWarning(false)
            }}
            content="Are you sure you want to delete this product?"
            okAction={() => {
              onDelete()
              showDeleteWarning(false)
            }}
            cancelAction={() => {
              showDeleteWarning(false)
            }}
          />
        )}
        {undoWarning && (
          <DeleteWarningModal
            onHide={() => {
              showUndoWarning(false)
            }}
            content="Are you sure you want to revert your changes?"
            okAction={() => {
              onUndo()
              showUndoWarning(false)
            }}
            okCaption="Undo"
            cancelAction={() => {
              showUndoWarning(false)
            }}
          />
        )}
        {loading && <LoadingAnimation />}
        <div className="product-detail-list">
          <div className="product-detail-list-title">
            <Caption
              caption="View / Edit Product"
              backAction={() => {
                history.push('/products/view')
              }}
            />
          </div>
          <div className="horizontal-separator" />
          <div className="product-detail-edit-view">
            <ProductPreview product={showProduct} />
            <HForm className="edit-view-item-form">
              {linkedPart === null && (
                <EditViewSettingItem caption="Select Supplier-Item:">
                  <HSearchPartInput
                    setSelectedPart={(part) => {
                      const updateProduct = { ...showProduct }
                      updateProduct.partNumber = part.id
                      updateProduct.partUom = part.uom.id
                      updateProduct.productPictureUrl = part.partPictureUrl
                      updateProduct.uom = part.UOMs.length > 0 && part.UOMs[0].uom
                      setShowProduct(updateProduct)
                      setLinkedPart(part)
                      setProductUoms(
                        part.UOMs.map((uom) => {
                          return uom && uom.uom
                        })
                      )
                      logChange('partNumber')
                      logChange('partUom')
                      logChange('productPictureUrl')
                    }}
                  />
                </EditViewSettingItem>
              )}
              <EditViewSettingItem
                caption="Linked Supplier Item"
                bordered
                errorMessage={isError(
                  (showProduct && showProduct?.partNumber?.vendorPartNumber) || '',
                  'number'
                )}
                deleteAction={
                  linkedPart
                    ? (ev) => {
                        ev.stopPropagation()
                        setLinkedPart(null)
                        setProductUoms([])
                      }
                    : null
                }
                link={
                  showProduct?.partNumber?.id &&
                  `/supplier-part-list/${showProduct?.partNumber?.id}`
                }
              >
                {showProduct?.partNumber && <PartPreviewSmall part={showProduct?.partNumber} />}
                {showProduct?.partNumber?.UOMs && (
                  <div>
                    {showProduct.partNumber.UOMs.map((uom) => (
                        <PartUomView uom={uom} />
                    ))}
                  </div>
                )}
                {!showProduct?.partNumber && (
                  <div
                    style={{
                      display: 'flex',
                      height: '125px',
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: '#000000',
                      fontSize: '16px',
                    }}
                  >
                    No Linked Item
                  </div>
                )}
              </EditViewSettingItem>
              <EditViewSettingItem
                caption="Product Number:"
                errorMessage={isError((showProduct && showProduct.productNumber) || '', 'number')}
              >
                <HInput
                  className="edit-view-input-style"
                  name="product_number"
                  value={showProduct.productNumber}
                  onChange={(ev) => {
                    const newProduct = { ...showProduct }
                    newProduct.productNumber = ev.currentTarget.value
                    setShowProduct(newProduct)
                    logChange('productNumber')
                  }}
                  error={isError((showProduct && showProduct.productNumber) || '', 'string')}
                  uppercase
                  readOnly
                />{' '}
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Product Description:"
                errorMessage={isError(
                  (showProduct && showProduct.productDescription) || '',
                  'string'
                )}
                clearAction={() => {
                  onClear()
                }}
              >
                <HTextInput
                  className="edit-view-text-input-style"
                  name="product_description"
                  value={showProduct.productDescription}
                  onChange={(ev) => {
                    const newProduct = { ...showProduct }
                    newProduct.productDescription = ev.currentTarget.value
                    setShowProduct(newProduct)
                    logChange('productDescription')
                  }}
                  error={isError((showProduct && showProduct.productDescription) || '', 'string')}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Product Details:(Optional)">
                <HTextInput
                  className="edit-view-text-input-style"
                  name="product_details"
                  value={showProduct.productDetails}
                  onChange={(ev) => {
                    const newProduct = { ...showProduct }
                    newProduct.productDetails = ev.currentTarget.value
                    setShowProduct(newProduct)
                    logChange('productDetails')
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Cost of Goods:">
                <HInput
                  className="edit-view-input-style"
                  name="product_cogs"
                  value={showProduct.cogs}
                  onChange={(ev) => {
                    const newProduct = { ...showProduct }
                    newProduct.cogs = ev.currentTarget.value
                    setShowProduct(newProduct)
                    logChange('cogs')
                  }}
                  readOnly
                />{' '}
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Size:"
                errorMessage={isError((showProduct && showProduct.uom) || '', 'string')}
              >
                <HSingleSelect
                  name="uom"
                  secondary
                  values={
                    productUoms.length
                      ? productUoms.map((type) => ({
                          value: type,
                          label: type,
                        }))
                      : globalUoms.map((type) => ({
                          value: type,
                          label: type,
                        }))
                  }
                  value={
                    showProduct && {
                      value: showProduct.uom,
                      label: showProduct.uom,
                    }
                  }
                  onChange={(ev) => {
                    const newProduct = { ...showProduct }
                    newProduct.uom = ev.value
                    setShowProduct(newProduct)
                    logChange('uom')
                  }}
                  isSearchable={false}
                />
              </EditViewSettingItem>

              <EditViewSettingItem
                caption="Price($):"
                errorMessage={isError(showProduct && showProduct.price, 'decimal')}
              >
                <HInput
                  className="edit-view-input-style"
                  name="price"
                  value={showProduct.price}
                  onChange={(ev) => {
                    const newProduct = { ...showProduct }
                    newProduct.price = ev.currentTarget.value
                    setShowProduct(newProduct)
                    logChange('price')
                  }}
                  error={isError(showProduct && showProduct.price, 'decimal')}
                />
              </EditViewSettingItem>
              <EditViewSettingItem caption="Default Vendor:">
                <HSingleSelect
                  name="isDefaultVendor"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                  defaultValue={
                    showProduct && {
                      value: showProduct.defaultVendor,
                      label: showProduct.defaultVendor ? 'TRUE' : 'FALSE',
                    }
                  }
                  onChange={(item) => {
                    const updateProduct = { ...showProduct }
                    updateProduct.defaultVendor = item.value
                    setShowProduct(updateProduct)
                    logChange('defaultVendor')
                  }}
                />
              </EditViewSettingItem>
              <EditViewSettingItem caption="Active:">
                <HSingleSelect
                  name="isActive"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                  value={
                    showProduct && {
                      value: showProduct && showProduct.active,
                      label: showProduct && showProduct.active ? 'TRUE' : 'FALSE',
                    }
                  }
                  onChange={(ev) => {
                    const newProduct = { ...showProduct }
                    newProduct.active = ev.value
                    setShowProduct(newProduct)
                    logChange('active')
                  }}
                  isSearchable={false}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Taxable:">
                <HSingleSelect
                  name="isTaxable"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                  value={
                    showProduct && {
                      value: showProduct.taxable,
                      label: showProduct.taxable ? 'TRUE' : 'FALSE',
                    }
                  }
                  onChange={(ev) => {
                    const newProduct = { ...showProduct }
                    newProduct.taxable = ev.value
                    setShowProduct(newProduct)
                    logChange('taxable')
                  }}
                  isSearchable={false}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="ComboBox:">
                <HSingleSelect
                  name="isComboBox"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                  value={
                    showProduct && {
                      value: showProduct.comboBox,
                      label: showProduct.comboBox ? 'TRUE' : 'FALSE',
                    }
                  }
                  onChange={(ev) => {
                    const newProduct = { ...showProduct }
                    newProduct.comboBox = ev.value
                    setShowProduct(newProduct)
                    logChange('comboBox')
                  }}
                  isSearchable={false}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="AllowSize:">
                <HSingleSelect
                  name="isAllowSize"
                  secondary
                  values={[
                    { value: true, label: 'TRUE' },
                    { value: false, label: 'FALSE' },
                  ]}
                  value={
                    showProduct && {
                      value: showProduct.allowUom,
                      label: showProduct.allowUom ? 'TRUE' : 'FALSE',
                    }
                  }
                  onChange={(ev) => {
                    const newProduct = { ...showProduct }
                    newProduct.allowUom = ev.value
                    setShowProduct(newProduct)
                    logChange('allowUom')
                  }}
                  isSearchable={false}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Product URL:">
                <HInput
                  className="edit-view-input-style"
                  name="product_url"
                  value={showProduct.productUrl}
                  onChange={(ev) => {
                    const newProduct = { ...showProduct }
                    newProduct.productUrl = ev.currentTarget.value
                    setShowProduct(newProduct)
                    logChange('productUrl')
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="ProductUPC:">
                <HInput
                  className="edit-view-input-style"
                  name="product_upc"
                  value={showProduct.productUpc}
                  onChange={(ev) => {
                    const newProduct = { ...showProduct }
                    newProduct.productUpc = ev.currentTarget.value
                    setShowProduct(newProduct)
                    logChange('productUpc')
                  }}
                  inputType="number"
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="ProductSKU: (Warehouse Entry Only)">
                <HInput
                  className="edit-view-input-style"
                  name="product_sku"
                  value={showProduct.productSku}
                  onChange={(ev) => {
                    const newProduct = { ...showProduct }
                    newProduct.productSku = ev.currentTarget.value
                    setShowProduct(newProduct)
                    logChange('productSku')
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Product SO Item type:">
                <HSingleSelect
                  name="product_so_item_type"
                  secondary
                  values={PRODUCTS.PRODUCT_SO_TYPES.map((type) => ({
                    value: type,
                    label: type,
                  }))}
                  value={
                    showProduct && {
                      value: showProduct.productSoItemType,
                      label: showProduct.productSoItemType,
                    }
                  }
                  onChange={(ev) => {
                    const newProduct = { ...showProduct }
                    newProduct.productSoItemType = ev.value
                    setShowProduct(newProduct)
                    logChange('productSoItemType')
                  }}
                  isSearchable={false}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Income Account Name:">
                <HInput
                  className="edit-view-input-style"
                  name="income_account"
                  value={showProduct.incomeAccount || ''}
                  onChange={(ev) => {
                    const newProduct = { ...showProduct }
                    newProduct.incomeAccount = ev.currentTarget.value
                    setShowProduct(newProduct)
                    logChange('incomeAccount')
                  }}
                />
              </EditViewSettingItem>
              <EditViewSettingItem caption="Expense Account Name:">
                <HInput
                  className="edit-view-input-style"
                  name="expense_account"
                  value={showProduct.expenseAccountName || ''}
                  onChange={(ev) => {
                    const updateProduct = { ...showProduct }
                    updateProduct.expenseAccountName = ev.currentTarget.value
                    setShowProduct(updateProduct)
                    logChange('expenseAccountName')
                  }}
                />
              </EditViewSettingItem>
              <EditViewSettingItem caption="Weight:">
                <HInput
                  className="edit-view-input-style"
                  name="weight"
                  value={showProduct.weight}
                  onChange={(ev) => {
                    const newProduct = { ...showProduct }
                    newProduct.weight = ev.currentTarget.value
                    setShowProduct(newProduct)
                    logChange('weight')
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="WeightUnit:">
                <HSingleSelect
                  name="weightUnit"
                  secondary
                  values={PRODUCTS.WEIGHT_UOM.map((uom) => ({
                    value: uom,
                    label: uom,
                  }))}
                  value={
                    showProduct && {
                      value: showProduct.weightUom,
                      label: showProduct.weightUom,
                    }
                  }
                  onChange={(ev) => {
                    const newProduct = { ...showProduct }
                    newProduct.weightUom = ev.value
                    setShowProduct(newProduct)
                    logChange('weightUom')
                  }}
                  isSearchable={false}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Width:">
                <HInput
                  className="edit-view-input-style"
                  name="width"
                  value={showProduct.width}
                  onChange={(ev) => {
                    const newProduct = { ...showProduct }
                    newProduct.width = ev.currentTarget.value
                    setShowProduct(newProduct)
                    logChange('width')
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Height:">
                <HInput
                  className="edit-view-input-style"
                  name="height"
                  value={showProduct.height}
                  onChange={(ev) => {
                    const newProduct = { ...showProduct }
                    newProduct.height = ev.currentTarget.value
                    setShowProduct(newProduct)
                    logChange('height')
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Length:">
                <HInput
                  className="edit-view-input-style"
                  name="Length"
                  value={showProduct.length}
                  onChange={(ev) => {
                    const newProduct = { ...showProduct }
                    newProduct.length = ev.currentTarget.value
                    setShowProduct(newProduct)
                    logChange('length')
                  }}
                />
              </EditViewSettingItem>
              <EditViewSettingItem caption="SizeUnit:">
                <HSingleSelect
                  name="sizeUnit"
                  secondary
                  values={PRODUCTS.SIZE_UOM.map((uom) => ({
                    value: uom,
                    label: uom,
                  }))}
                  value={{
                    value: showProduct.sizeUom || 'In',
                    label: showProduct.sizeUom || 'In',
                  }}
                  onChange={(ev) => {
                    const newProduct = { ...showProduct }
                    newProduct.sizeUom = ev.value
                    setShowProduct(newProduct)
                    logChange('sizeUom')
                  }}
                  isSearchable={false}
                />
              </EditViewSettingItem>
              <EditViewSettingItem caption="DefaultFlag:(Optional)">
                <HInput
                  className="edit-view-input-style"
                  name="defaultFlag"
                  value={showProduct.defaultFlag}
                  onChange={(ev) => {
                    const newProduct = { ...showProduct }
                    newProduct.defaultFlag = ev.currentTarget.value
                    setShowProduct(newProduct)
                    logChange('defaultFlag')
                  }}
                />
              </EditViewSettingItem>
              <EditViewSettingItem caption="Carton Count:">
                <HInput
                  className="edit-view-input-style"
                  name="carton_count"
                  type="number"
                  value={showProduct.cartonCount || ''}
                  onChange={(ev) => {
                    const newProduct = { ...showProduct }
                    newProduct.cartonCount = ev.currentTarget.value
                    setShowProduct(newProduct)
                    logChange('cartonCount')
                  }}
                />
              </EditViewSettingItem>

              <EditViewSettingItem caption="Carton Type:">
                <HInput
                  className="edit-view-input-style"
                  name="carton_type"
                  value={showProduct.cartonType || ''}
                  onChange={(ev) => {
                    const newProduct = { ...showProduct }
                    newProduct.cartonType = ev.currentTarget.value
                    setShowProduct(newProduct)
                    logChange('cartonType')
                  }}
                />
              </EditViewSettingItem>
              <div className="product-detail-last-line">
                <HButton
                  className="h-delete-button"
                  onClick={() => {
                    showDeleteWarning(true)
                  }}
                >
                  Delete Product
                </HButton>
                <div className="product-detail-last-line-right">
                  <HButton
                    className="h-undo-button"
                    onClick={() => {
                      showUndoWarning(true)
                    }}
                    disabled={Object.keys(changes).length === 0}
                  >
                    Undo changes({Object.keys(changes).length})
                  </HButton>
                  <HButton
                    className="h-save-button"
                    onClick={() => {
                      onSave()
                    }}
                  >
                    Save
                  </HButton>
                </div>
              </div>
            </HForm>
          </div>
        </div>
        <div className="product-detail-filters">
          <AddMedia
            pictureUrls={showProduct.productPictureUrl}
            setPictureUrls={(urls) => {
              const updateProduct = { ...showProduct }
              updateProduct.productPictureUrl = urls
              setShowProduct(updateProduct)
              logChange('productPictureUrl')
            }}
          />
          <SimilarProducts
            caption={`Alternative Suppliers for ${showProduct && showProduct.manufacturerId}`}
            secondary
            products={similarProducts}
            viewProduct={onViewSimilarProduct}
            manufacturerNum={showProduct && showProduct.manufacturerId}
          />
          <SuggestedCompetitorsProducts
            competitors={competitors}
            viewCompetitor={onViewCompetitor}
          />
          <ProductSalesforceItems salesforceInfo={showProduct && showProduct.productSalesforce} />
        </div>
      </div>
    </div>
  )
}

ProductDetail.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default withRouter(ProductDetail)
