import React from 'react'
import PropTypes from 'prop-types'
import { classNames } from 'utils/helper'
import Select from 'react-select'
import './index.css'

const HSingleSelect = ({
  className,
  name,
  placeholder,
  secondary,
  miniMode,
  values,
  error,
  ...rest
}) => {
  const classes = classNames('h-single-select', className || '')
  const customStyle = {
    control: (styles) => ({
      ...styles,
      minHeight: '20px',
      boxSizing: 'border-box',
      borderColor: '#5371f7',
      borderRadius: '5px',
      backgroundColor: '#ffffff',
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: '0px 8px',
      ':hover': {
        color: '#ffffff',
      },
    }),
    input: (styles) => ({
      ...styles,
      minHeight: '18px',
      height: '18px',
      paddingTop: 0,
      paddingBottom: 0,
      margin: 0,
    }),
    menu: (styles) => ({
      ...styles,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: () => ({
      backgroundColor: '#0c40a5',
      borderRadius: '5px',
      display: 'block',
      height: '18px',
      width: '18px',
    }),
    dropdownIndicator: () => ({
      color: 'white',
      width: '18px',
      height: '18px',
      minHeight: '18px',
      display: 'block',
      cursor: 'pointer',
      ':hover': {
        color: '#9F9F9F',
      },
      ' svg': {
        width: '18px',
        height: '18px',
      },
    }),
  }

  const secondaryStyle = {
    control: (styles) => ({
      ...styles,
      boxSizing: 'unset',
      borderRadius: '5px',
      backgroundColor: '#ffffff',
      minHeight: '20px',
      height: '30px',
      borderColor: error ? '#ff0303' : '#b4b4b4',
    }),

    menu: (styles) => ({
      ...styles,
    }),
    input: (styles) => ({
      ...styles,
      margin: '0px',
      padding: '0px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: () => ({
      alignItems: 'center',
      backgroundColor: '#0c40a5',
      borderRadius: '5px',
      display: 'block',
      width: '31px',
      height: '32px',
      marginTop: '-1px',
    }),
    dropdownIndicator: () => ({
      alignItems: 'center',
      color: 'white',
      width: '30px',
      display: 'flex',
      height: '31px',
      cursor: 'pointer',
      justifyContent: 'center',
      ':hover': {
        color: '#9F9F9F',
      },
    }),
    clearIndicator: () => ({
      display: 'none',
    }),
  }

  const miniStyle = {
    control: (styles) => ({
      ...styles,
      boxSizing: 'border-box',
      borderRadius: '5px',
      borderColor: '#5371f7',
      backgroundColor: '#ffffff',
      minHeight: '20px',
      height: '20px',
    }),

    menu: (styles) => ({
      ...styles,
      position: 'relative',
      marginTop: '1px',
      minWidth: '100px',
      paddingLeft: '1px',
      zIndex: 999,
    }),
    input: (styles) => ({
      ...styles,
      margin: '0px',
      padding: '0px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: () => ({
      alignItems: 'center',
      backgroundColor: '#0c40a5',
      borderRadius: '5px',
      display: 'none',
      width: '15px',
      height: '15px',
      marginTop: '-1px',
    }),
    dropdownIndicator: () => ({
      alignItems: 'center',
      color: 'white',
      width: '10px',
      display: 'none',
      height: '10px',
      cursor: 'pointer',
      justifyContent: 'center',
      ':hover': {
        color: '#9F9F9F',
      },
    }),
    clearIndicator: () => ({
      display: 'none',
    }),
  }

  return (
    <Select
      className={classes}
      {...rest}
      {...{ name, placeholder }}
      styles={secondary ? secondaryStyle : miniMode ? miniStyle : customStyle}
      options={values}
      instanceId={name}
    />
  )
}
HSingleSelect.propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  inputType: PropTypes.string,
  secondary: PropTypes.bool,
  miniMode: PropTypes.bool,
  error: PropTypes.string,
}

HSingleSelect.defaultProps = {
  className: '',
  id: '',
  placeholder: '',
  name: '',
  inputType: 'normal',
  values: [{}],
  secondary: false,
  miniMode: false,
  error: '',
}
export default HSingleSelect
