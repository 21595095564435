import React from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage } from 'formik'

const FormError = ({ name }) => (
  <ErrorMessage name={name}>
    {(msg) => (
      <div className="alert-invalid-input">
        <span>*{msg}</span>
      </div>
    )}
  </ErrorMessage>
)

FormError.propTypes = {
  name: PropTypes.string.isRequired,
}

export default FormError
