/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react";
import PropTypes from "prop-types";
import { capitalizeText } from "utils/formatter";

import "./index.css";

const UserPreview = ({ user }) => {
  return (
    <div className="user-preview">
      <div className="user-preview-image">
        <img
          className="user-preview-image-body"
          src={user.userImage || "/assets/img/users/user-blue.svg"}
          alt=""
          onError={(ev) => {
            // eslint-disable-next-line no-param-reassign
            ev.target.onerror = null;
            // eslint-disable-next-line no-param-reassign
            ev.target.src = "/assets/img/users/user-blue.svg";
          }}
        />
      </div>
      <div className="user-preview-details">
        <div className="user-preview-name">
          <span>{capitalizeText(user.username)}</span>
          {user.isAdmin && (
            <div className="users-preview-name-badge">
              <img
                src="/assets/img/users/admin_badge.svg"
                alt="admin"
                width="25px"
              />
            </div>
          )}
        </div>

        <div className="user-preview-email"> {` ${user.email} `} </div>
      </div>
    </div>
  );
};

UserPreview.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    email: PropTypes.string,
    isAdmin: PropTypes.bool,
    userImage: PropTypes.string,
  }),
};

UserPreview.defaultProps = {
  user: {
    id: 1,
    username: "Andres Marques",
    email: "Andres@admin.com",
    isAdmin: true,
    userImage: "",
  },
};
export default UserPreview;
