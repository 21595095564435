/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react'
import PropTypes from 'prop-types'
import { priceDecimalFormatter } from 'utils/formatter'
import { getProductFeatureImage } from 'utils/product_util'
import './index.css'

const ProductPreview = ({ product }) => {
  console.log(product,"product")
  return (
    <div className="large-product-show">
      <div className="large-product-image">
        <div className="large-product-image-header">Featured Image</div>
        <div className="large-product-image-body">
          <img
            src={
              getProductFeatureImage(product && product?.ProductPictureUrl || product?.productPictureUrl) ||
              '/assets/img/products/featured.svg'
            }
            alt=""
            onError={(ev) => {
              // eslint-disable-next-line no-param-reassign
              ev.target.onerror = null
              // eslint-disable-next-line no-param-reassign
              ev.target.src = '/assets/img/products/featured.svg'
            }}
          />
        </div>
      </div>
      <div className="large-product-details">
        <div className="large-product-number">
          <span>{product && product?.ProductDescription || product?.productDescription}</span>
        </div>

        <div className="large-product-description">
          <p>{product && product?.ProductNumber || product?.productNumber}</p>
        </div>
        <div className="large-product-price-type">
          <div className="large-product-price">
            {priceDecimalFormatter(product && product?.Price || product?.price)}
          </div>
          <div className="large-product-type">{product && product?.IncomeAccount || product?.incomeAccount}</div>
        </div>
        <div className="large-product-details-text">
          <p>{product && product?.ProductDetails || product?.productDetails}</p>
        </div>
        <div className="large-product-updated-time">
          Last updated:{' '}
          {product && product?.updatedAt
            ? new Date(product?.updatedAt).toLocaleString()
            : 'Not updated yet'}
        </div>
      </div>
    </div>
  )
}

const ProductPreviewSmall = ({ product, link }) => {
  return (
    <div className="small-product-show">
      <div className="small-product-image">
        <div className="small-product-image-body">
          <img
            src={
              getProductFeatureImage(product && product?.ProductPictureUrl || product?.productPictureUrl) ||
              '/assets/img/products/featured.svg'
            }
            alt=""
            onError={(ev) => {
              // eslint-disable-next-line no-param-reassign
              ev.target.onerror = null
              // eslint-disable-next-line no-param-reassign
              ev.target.src = '/assets/img/products/featured.svg'
            }}
          />
        </div>
      </div>
      <div className="small-product-details">
        <div className="small-product-number">
          <a
            href={link}
            onClick={(ev) => {
              ev.stopPropagation()
            }}
          >
            {product && product?.ProductNumber || product?.productNumber}
          </a>
        </div>

        <div className="small-product-description">
          <span>{product && product?.ProductDescription || product?.productDescription}</span>
        </div>
        <div className="small-product-price-type">
          <div className="small-product-price">
            {priceDecimalFormatter(product && product?.Price || product?.price)}
          </div>
          <div className="small-product-type">{product && product?.uom && product?.uom.uom}</div>
        </div>
        <div className="small-product-details-text">
          <p>{product && product?.ProductDetails}</p>
        </div>
      </div>
    </div>
  )
}

ProductPreview.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number,
    productNumber: PropTypes.string,
    price: PropTypes.number,
    incomeAccount: PropTypes.string,
    productPictureUrl: PropTypes.string,
    productDescription: PropTypes.string,
    productDetails: PropTypes.string,
    partNumber: PropTypes.string,
    details: PropTypes.string,
  }),
}

ProductPreview.defaultProps = {
  product: {
    id: 1,
    productNumber: '230605',
    productDescription: 'Sunblock, SPF 30, 8oz, 1/each',
    price: 24.99,
    incomeAccount: 'Disposable - Sales',
    productPictureUrl: '[]',
    partNumber: '110115BX',
    productDetails:
      'Morbi condimentum sollicitudin elit, nec porttitor ex tincidunt non. Vivamus aliquam pellentesque nulla. Nullam aliquet pharetra finibus. Vivamus vestibulum ipsum nisl, et dapibus enim ultrices vel. Donec commodo, dui feugiat tincidunt tempus, risus lectus scelerisque neque, et maximus sem ex ullamcorper arcu. Nullam maximus risus enim, sit amet feugiat risus dapibus vitae. Mauris non consectetur leo.',
  },
}

ProductPreviewSmall.propTypes = {
  product: PropTypes.shape(),
  link: PropTypes.string,
}

ProductPreviewSmall.defaultProps = {
  product: {},
  link: '',
}

export { ProductPreviewSmall }
export default ProductPreview
