const PRODUCTS = {
  SUPPLIERS: ['Supplier 1', 'Supplier 2', 'Supplier 3'],
  WEIGHT_UOM: ['Lbs', 'Kg', 't'],
  SIZE_UOM: ['in', 'cm', 'km'],
  PRODUCT_SO_TYPES: ['Sale', 'Other'],
  UOM: ['EA', 'BOX', 'DOZEN'],
}

export const NEW_PRODUCT_INFO = {
  MANUFACTURER: ['Manufacturer1', 'Manufacturere2', 'Manufacturer3'],
  VENDOR: ['Vendor1', 'Vendor2', 'Vendor3'],
  INCOME_ACCOUNTS: ['Cash1', 'Cash2', 'Cash3'],
  EXPENSE_ACCOUNTS: ['Cost of Goods Sold', 'Cost of Goods Bought'],
}

export const INITIAL_PRODUCT = {
  defaultVendor: false,
  isBom: false,
  active: true,
  alertNote: false,
  allowUom: false,
  class: '',
  comboBox: false,
  defaultFlag: 'ft',
  height: 0,
  width:0,
  incomeAccount: '',
  expenseAccountName: '',
  length: 0,
  partNumber: '',
  partUom: {},
  price: 0,
  productDescription: '',
  productDetails: '',
  productPictureUrl: '[]',
  productSku: '',
  productSoItemType: 'Sale',
  productUpc: '',
  productUrl: '',
  taxable: false,
  uom: 'ea',
  unitOfMeasureName: 'Lbs',
  weight: 0,
  weightUom: 'kg',
  catLookup: '',
  errors: '[]',
  masterItemId: '',
  priceId: '',
  priceListId: '',
  salesForceFailed: false,
  salesforceLock: false,
  salesforceItemMasterUrl: '',
  salesforcePriceListUrl: '',
  salesforcePriceUrl: '',
  salesforceProductGroupId: '',
  salesforceProductGroupUrl: '',
  salesforceProductId: '',
  salesforceProductUrl: '',
  sizeUom: 'in',
}
export default PRODUCTS
