import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import LoadingAnimation from "../../components/LoadingAnimation";
import "./index.css";

const HomePage = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Loading Data
    const fetchData = async () => {
      setLoading(false);
    };
    fetchData();
  }, []);

  if (loading) {
    return <LoadingAnimation />;
  }

  return <div className="home_body_part"></div>;
};

export default withRouter(HomePage);
