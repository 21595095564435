import React from "react";
import "./index.scss";

const LoadingAnimation = () => (
  <div className="loading-animation-container">
    <svg
      className="spinner"
      width="65px"
      height="65px"
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="path"
        fill="none"
        strokeWidth="6"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
      />
    </svg>
  </div>
);

export const MiniLoadingAnimation = () => (
  <div className="loading-mini-animation-container">
    <svg
      className="spinner"
      width="32px"
      height="32px"
      viewBox="0 0 35 35"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="path"
        fill="none"
        strokeWidth="3"
        strokeLinecap="round"
        cx="16"
        cy="16"
        r="15"
      />
    </svg>
  </div>
);

export const MediumLoadingAnimation = () => (
  <div className="loading-medium-animation-container">
    <svg className="spinner" width="65px" height="65px" viewBox="0 0 65 65">
      <circle
        className="path"
        fill="none"
        strokeWidth="3"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
      />
    </svg>
  </div>
);
export default LoadingAnimation;
