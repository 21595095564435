/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { getShortString } from 'utils/formatter'
import { getProductFeatureImage } from 'utils/product_util'
import { classNames } from 'utils/helper'
import './index.css'

const PartPreview = ({ part }) => {
  return (
    <div className="large-part-show">
      <div className="large-part-image">
        <div className="large-part-image-header">Featured Image</div>
        <div className="large-part-image-body">
          <img
            src={
              getProductFeatureImage((part && part?.PartPictureUrl) || part?.partPictureUrl) ||
              '/assets/img/parts/featured.svg'
            }
            alt=""
            onError={(ev) => {
              // eslint-disable-next-line no-param-reassign
              ev.target.onerror = null
              // eslint-disable-next-line no-param-reassign
              ev.target.src = '/assets/img/parts/featured.svg'
            }}
          />
        </div>
      </div>
      <div className="large-part-details">
        <div className="large-part-number">
          <span>{(part && part?.Description) || part?.description}</span>
        </div>

        <div className="large-part-description">
          <p>{(part && part?.VendorPartNumber) || part?.vendorPartNumber}</p>
        </div>
        <div className="large-part-price-type">
          <div className="large-part-type">{(part && part?.Vendor) || part?.vendor}</div>
        </div>
        <div className="large-part-details-text">
          <p>{(part && part?.ItemDetails) || part?.itemDetails}</p>
        </div>
        <div className="large-part-update-time">
          Last updated: {part && new Date(part?.updatedAt).toLocaleString()}
        </div>
      </div>
    </div>
  )
}

const PartPreviewSmall = ({ part, link }) => {
  const history = useHistory()
  const classes = classNames('small-part-show', (link && '-hover') || '')

  return (
    <div
      className="small-part-show"
      onClick={() => {
        if (link) {
          history.push(link)
        }
      }}
    >
      <div className="small-part-image">
        <div className="small-part-image-body">
          <img
            src={
              getProductFeatureImage(part && part?.PartPictureUrl || part?.partPictureUrl) ||
              '/assets/img/parts/featured.svg'
            }
            alt=""
            onError={(ev) => {
              // eslint-disable-next-line no-param-reassign
              ev.target.onerror = null
              // eslint-disable-next-line no-param-reassign
              ev.target.src = '/assets/img/parts/featured.svg'
            }}
          />
        </div>
      </div>
      <div className="small-part-details">
        <div className="small-part-number">
          <a href={`${link}`}>
            {part && getShortString(part?.Description || part?.description || part?.VendorPartNumber || part?.vendorPartNumber)}
          </a>
        </div>

        <div className="small-part-description">
          <span>{part && part?.ManufacturerName || part?.manufacturerName}</span>
        </div>
        <div className="small-part-price-type">
          <div className="small-part-price">{part && part?.Vendor || part?.vendor}</div>
          <div className="small-part-type">{part && part?.VendorPartNumber || part?.vendorPartNumber}</div>
        </div>
      </div>
    </div>
  )
}

PartPreview.propTypes = {
  part: PropTypes.shape(),
}

PartPreview.defaultProps = {
  part: {},
}

PartPreviewSmall.propTypes = {
  part: PropTypes.shape(),
  link: PropTypes.string,
}

PartPreviewSmall.defaultProps = {
  part: {},
  link: '',
}

export { PartPreviewSmall }
export default PartPreview
