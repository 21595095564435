import React from 'react'
import PropTypes from 'prop-types'
import { classNames } from 'utils/helper'
import HButton from 'components/common/Basic/HButton'
import './index.css'

const Caption = ({ caption, bContainImg, primary, secondary, initial, tertiary, backAction }) => {
  const classes = classNames(
    'approval-caption-container',
    (primary && '-primary') || '',
    (secondary && '-secondary') || '',
    (initial && '-initial') || '',
    (tertiary && '-tertiary') || ''
  )
  return (
    <div className={classes}>
      {backAction && (
        <div className="approval-caption-container-left">
          <HButton
            className="h-transparent-button"
            onClick={() => {
              backAction()
            }}
          >
            <img src="/assets/img/common/back.svg" alt="back" width="30px" />
          </HButton>
        </div>
      )}
      <div
        className={
          backAction ? 'approval-caption-container-center' : 'approval-caption-container-left'
        }
      >
        {bContainImg && <span className="h-icon -sz-30 -approval-caption-box mr-1" />}
        <span> {caption}</span>
      </div>
      <div className="approval-caption-container-right" />
    </div>
  )
}

Caption.propTypes = {
  caption: PropTypes.string,
  bContainImg: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  initial: PropTypes.bool,
  backAction: PropTypes.func,
}

Caption.defaultProps = {
  caption: '',
  bContainImg: true,
  primary: true,
  secondary: false,
  tertiary: false,
  initial: false,
  backAction: null,
}
export default Caption
