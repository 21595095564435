/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { getFormattedTime } from 'utils/formatter'
import { getNotifications, setNotificationRead } from 'api/productApi'
import { getCommonData } from 'api/productApi'
import Spinner from 'react-bootstrap/Spinner'

import Dropdown from 'react-bootstrap/Dropdown'

import './NotiBell.scss'

const NotiBell = () => {
  const history = useHistory()
  const { pathname } = useLocation()
  const [msgs, setMsgs] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0)

  useEffect(() => {
    loadMsgs()
  }, [pathname])

  const loadMsgs = async () => {
    setLoading(true)
    try {
      setLoading(true)
      // const notifications = await getNotifications();
      // setMsgs(notifications);
      const datas = await getNotifications(0, 10)
      console.log('datas', datas)
      setTotalCount(datas.totalCount)
      setMsgs(datas.activities)
    } catch (error) {
      setMsgs([])
    }
    setLoading(false)
  }

  const removeNotification = async (index) => {
    const msgId = msgs[index] && msgs[index].id
    const updatedMsgs = [...msgs]
    updatedMsgs.splice(index, 1)
    setMsgs(updatedMsgs)
    setTotalCount(totalCount - 1)
    const data = await setNotificationRead(msgId)
    console.log(data)
  }

  const removeAllNotifications = async () => {
    setLoading(true)
    msgs.forEach(async (msg) => {
      await setNotificationRead(msg.id)
    })
    loadMsgs()
    setLoading(false)
  }

  return (
    <div className="top-nav-noti-container">
      <ul>
        <Dropdown
          onToggle={() => {
            if (!(msgs && msgs.length > 0)) {
              loadMsgs()
            }
          }}
        >
          <Dropdown.Toggle variant="outline-light">
            <img
              className={
                msgs.length > 0 ? 'top-nav-noti-bell-active' : 'top-nav-noti-bell-non-active'
              }
              src="/assets/img/header/notification.svg"
              alt="notification"
            />
            <div
              className={
                msgs.length > 0
                  ? 'top-nav-noti-bell-mark-active'
                  : 'top-nav-noti-bell-mark-disabled'
              }
            >
              {totalCount > 0 ? totalCount : ''}
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu alignRight>
            {!isLoading && msgs && msgs.length > 0 && (
              <>
                {msgs &&
                  msgs.map((item, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        onClick={() => {
                          removeNotification(index)
                          if (item.objectType === 'Product') {
                            history.push(`/products/view/${item.objectId}`)
                          } else if (item.objectType === 'BOMProduct') {
                            history.push(`/boms/view/${item.objectId}`)
                          } else if (item.objectType === 'Part') {
                            history.push(`/supplier-part-list/${item.objectId}`)
                          } else {
                            history.push(`/activities/${item.id}`)
                          }
                        }}
                      >
                        <div className="dashboard-item pl-0 pr-0 ml-0 mr-0">
                          <img
                            className="dashboard-item-image dashboard-user-image"
                            src={
                              (item && item.user && item.user.userImage) ||
                              '/assets/img/users/user-blue.svg'
                            }
                            alt="pic"
                            onError={(ev) => {
                              // eslint-disable-next-line no-param-reassign
                              ev.target.onerror = null
                              // eslint-disable-next-line no-param-reassign
                              ev.target.src = '/assets/img/users/user-blue.svg'
                            }}
                          />
                          <div className="dashboard-item-info">
                            <div className="dashboard-item-info-title">
                              <span className="dashboard-user-name">
                                <a
                                  href={`/users/view/${item && item.user && item.user.id}`}
                                  onClick={(ev) => {
                                    ev.stopPropagation()
                                  }}
                                >{`${item && item.user && item.user.username} `}</a>
                              </span>
                              <span className="dashboard-user-action">
                                {item.action === 'edit' && 'modified a product'}
                                {item.action === 'add' && 'added new product'}
                                {item.action === 'delete' && 'deleted product'}
                              </span>
                            </div>
                            <div className="dashboard-item-info-description">
                              <span className="dashboard-action-time">
                                {getFormattedTime(item)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Dropdown.Item>
                    )
                  })}
              </>
            )}
            {isLoading && (
              <Dropdown.Item>
                <div className="row align-items-center justify-content-center">
                  <Spinner className="mr-3" animation="border" variant="primary" /> Loading...
                </div>
              </Dropdown.Item>
            )}
            {msgs.length > 0 && (
              <Dropdown.Item
                onClick={() => {
                  removeAllNotifications()
                }}
              >
                <div className="row align-items-center justify-content-center">
                  <span>Mark as read</span>
                </div>
              </Dropdown.Item>
            )}
            {msgs.length === 0 && (
              <Dropdown.Item disabled>
                <div className="row align-items-center justify-content-center">
                  <span>No new messages</span>
                </div>
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </ul>
    </div>
  )
}

export default NotiBell
