import sumBy from 'lodash/sumBy'

export const splitItems = (productItems) => {
  if (productItems && productItems.length > 0) {
    const categories = []
    let currentCategory = []
    for (let i = 0; i < productItems.length; i += 1) {
      if (
        i === 0 ||
        productItems[i].productMenuCategoryName === productItems[i - 1].productMenuCategoryName
      ) {
        // add new categories
        currentCategory.push(productItems[i])
      } else {
        categories.push(currentCategory)
        currentCategory = [productItems[i]]
      }
    }

    if (currentCategory.length > 0) {
      categories.push(currentCategory)
    }

    return categories
  }

  return []
}

export const getProductItemQuantity = (productItem) => {
  // productItem change form data
  const { menuItems, menuItemsMapping, quantity } = productItem
  if (menuItems && menuItems.length > 0) {
    // get menu mappings
    return sumBy(Object.values(menuItemsMapping), (mapping) => parseInt(mapping, 10)) || 0
  }

  return quantity
}

export const getProductItemTotalPrice = (productItem) => {
  // productItem change form data
  const { menuItems, menuItemsMapping, quantity, priceCents } = productItem
  if (menuItems && menuItems.length > 0) {
    // get menu mappings
    return (
      sumBy(
        menuItems,
        (menuItem) => (parseInt(menuItemsMapping[menuItem.id], 10) || 0) * menuItem.priceCents
      ) || 0
    )
  }

  return priceCents * quantity
}

export const getProductSubTotalPrice = (productItems) => {
  return sumBy(productItems, (productItem) => getProductItemTotalPrice(productItem))
}

export const getProductFeatureImage = (images) => {
  try {
    const imgArray = JSON.parse(images)
    return [...imgArray][0]
  } catch (ev) {
    return null
  }
}

export const filterText = (text, length = 30) => {
  if (text && text.length > length) {
    return text.slice(0, length) + '...'
  }
  return text
}
