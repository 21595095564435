import React from 'react'
import PropTypes from 'prop-types'
import PartsListitem from './PartsListItem'
import './index.css'

const PartsList = ({ parts, viewAction, importAction }) => {
  return (
    <div className="parts-list-container">
      {parts &&
        parts.length > 0 &&
        parts.map((part) => (
          <PartsListitem
            key={part.id}
            name={part.name}
            part={part}
            viewAction={viewAction}
            importAction={importAction}
          />
        ))}
      {parts && parts.length === 0 && (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            textAlign: 'center',
            fontSize: '22px',
          }}
        >
          No Parts available.
        </span>
      )}
    </div>
  )
}
PartsList.propTypes = {
  parts: PropTypes.arrayOf(PropTypes.shape({})),
  viewAction: PropTypes.func,
  importAction: PropTypes.func,
}

PartsList.defaultProps = {
  parts: [],
  viewAction: () => {},
  importAction: () => {},
}
export default PartsList
