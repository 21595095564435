import React, { useEffect, useState } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { MediumLoadingAnimation } from 'components/LoadingAnimation'
import Caption from 'components/Users/Caption'
import AlertModal from 'components/common/Modal/AlertModal'
import HSingleSelect from 'components/common/Basic/HSingleSelect'
import SelectedActions from 'components/common/Filter/SelectedActions'
import UsersList from 'components/Users/UsersList'
import { decimalFormatter } from 'utils/formatter'
import HButton from 'components/common/Basic/HButton'
import HPagination from 'components/common/Pagination'
import { getUsers } from 'api/userApi'
import { PAGE_ITEMS } from 'consts/common'
import './index.css'

const ViewUsers = ({ user }) => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState([])
  const [results, setResults] = useState(0)
  const [pageTitle, setPageTitle] = useState('All Users')
  const [pageNumber, setPageNumber] = useState(1)
  const [itemPerPage, setItemPerPage] = useState(100)
  const [totalPageCount, setTotalPageCount] = useState(100)

  const [alertShown, setShowAlert] = useState(false)
  const [alertHeaderText, setAlertHeaderText] = useState('')
  const [alertBodyText, setAlertBodyText] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const fetchData = async () => {
    const users = await getUsers((pageNumber - 1) * itemPerPage, itemPerPage)
    if (users && users.error) {
      console.log(users)
      setAlertHeaderText('GET Users failed')
      setAlertBodyText('Error:', users.error.message)
      setShowAlert(true)
    } else {
      console.log(users)
      setUsers(users.users)
      setResults(users.totalCount)
      setTotalPageCount(Math.ceil(users.totalCount / itemPerPage))
    }
    setLoading(false)
  }

  useEffect(() => {
    if (user && user.confirmed && user.isAdmin) {
      setErrorMessage('')
    } else {
      setAlertHeaderText('AdminRole is required')
      setAlertBodyText('You have no right to manage Users, contact Site Administrator')
      setShowAlert(true)
      setErrorMessage('You have no right to manage Users, contact Site Administrator')
    }
  }, [])

  useEffect(() => {
    // Loading Data
    if (user && user.confirmed && user.isAdmin) {
      setLoading(true)
      fetchData()
    }
  }, [pageNumber, itemPerPage])

  const onViewEditProduct = (user) => {
    history.push(`/users/view/${user.id}`)
  }

  return (
    <div className="view-users">
      {alertShown && (
        <AlertModal
          onHide={() => {
            setShowAlert(false)
          }}
          headerText={alertHeaderText}
          bodyText={alertBodyText}
          actionLabels={['Okay']}
          actions={[
            () => {
              setShowAlert(false)
            },
          ]}
        />
      )}
      {errorMessage && <div className="medium-size-text">{errorMessage}</div>}
      {errorMessage === '' && (
        <>
          <div className="view-users-list">
            <div className="view-users-list-title">
              <Caption
                caption={pageTitle}
                addAction={() => {
                  history.push('/users/new')
                }}
                primary
              />
            </div>
            {loading && (
              <div className="users-list-container">
                <MediumLoadingAnimation />
              </div>
            )}
            {!loading && <UsersList users={users} viewEditAction={onViewEditProduct} />}
            <div className="horizontal-separator" />
            <div className="view-users-list-footer">
              <div className="view-users-list-footer-left">
                <span className="view-users-list-footer-text">
                  {`${decimalFormatter(results)} results for  "`}
                </span>
                <span className="view-users-list-footer-text-bold">{`${pageTitle}"`}</span>
                <HButton
                  className="view-users-list-footer-button"
                  onClick={() => {
                    if (user && user.confirmed && user.isAdmin) {
                      setLoading(true)
                      fetchData()
                    }
                  }}
                >
                  <img src="/assets/img/common/refresh.png" alt="refresh" />
                  Refresh
                </HButton>
                <div className="view-users-list-footer-select">
                  <span>Items Per Page:</span>
                  <HSingleSelect
                    values={PAGE_ITEMS.map((item) => ({
                      value: item,
                      label: `${item}`,
                    }))}
                    defaultValue={{ value: 100, label: '100' }}
                    name="itemPerPage"
                    onChange={(item) => {
                      setItemPerPage(item.value)
                    }}
                    isSearchable={false}
                  />
                </div>
              </div>
              <div className="view-users-list-footer-right">
                <HPagination
                  pageNumber={pageNumber}
                  totalPageCount={totalPageCount}
                  setPageNumber={setPageNumber}
                />
              </div>
            </div>
          </div>
          <div className="view-users-filters">
            <SelectedActions />
          </div>
        </>
      )}
    </div>
  )
}
const mapStateToProps = (state) => ({
  user: state.user && state.user.user,
})

ViewUsers.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    isAdmin: PropTypes.bool,
    confirmed: PropTypes.bool,
  }).isRequired,
}

const connectedHeader = connect(mapStateToProps, null)(ViewUsers)
export default withRouter(connectedHeader)
