/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import BomChildModal from 'components/common/Modal/BomChildModal'
import { ProductPreviewSmall } from 'components/Products/ProductPreview'
import HButton from './HButton'
import { store } from 'react-notifications-component'
import './index.css'
import { FastField } from 'formik'

const HBomChildsTable = ({ childs, setChilds, readOnly }) => {
  const [showModal, setShowModal] = useState(false)
  const [selectedId, setSelectedId] = useState(-1)
  return (
    <>
      {showModal && (
        <BomChildModal
          onHide={() => {
            setShowModal(false)
          }}
          bomChild={selectedId !== -1 ? childs[selectedId] : {}}
          okAction={(product, price, quantity) => {
            const newChild = { product, price, quantity }
            if (selectedId === -1) {
              const childsList = [...childs]
              childsList.push(newChild)
              setChilds(childsList)
            } else {
              const childsList = [...childs]
              childsList[selectedId] = newChild
              setChilds(childsList)
            }
            setShowModal(false)
          }}
          cancelAction={() => {
            setSelectedId(-1)
            setShowModal(false)
          }}
        />
      )}
      <div className="table-responsive">
        <table className="table table-hover table-bordered bom-table">
          <thead>
            <tr className="h-table-header">
              <th>Product</th>
              <th style={{ width: '100px' }}>Quantity</th>
              <th style={{ width: '100px' }}>Price</th>
              <th align="center" style={{ width: '30px' }}></th>
            </tr>
          </thead>
          <tbody>
            {!readOnly &&
              childs &&
              childs.map((item, index) => {
                return (
                  <tr
                    key={`child-index-${index}`}
                    className="table-row-hover-blue cursor-pointer"
                    onClick={() => {
                      setSelectedId(index)
                      setShowModal(true)
                    }}
                  >
                    <td>
                      <ProductPreviewSmall
                        product={item?.product || item?.bomParentId}
                        link={
                          item?.isBom ? `/boms/view/${item?._id}` : `/products/view/${item?._id}`
                        }
                      />
                    </td>
                    <td>{(item && item?.quantity) || '1'}</td>
                    <td>${(item && item?.price) || '0'}</td>
                    <td>
                      {!readOnly && (
                        <HButton
                          className="h-blue-button rounded-button"
                          onClick={(ev) => {
                            ev.stopPropagation()
                            const tempData = [...childs]
                            tempData.splice(index, 1)
                            setChilds(tempData)
                            store.addNotification({
                              message: 'Info: BOM deleted',
                              type: 'info',
                              insert: 'bottom',
                              container: 'bottom-left',
                              animationIn: ['animated', 'fadeIn'],
                              animationOut: ['animated', 'fadeOut'],
                              dismiss: {
                                duration: 1000,
                              },
                            })
                          }}
                        >
                          -
                        </HButton>
                      )}
                    </td>
                  </tr>
                )
              })}
            {readOnly &&
              childs &&
              childs.map((item, index) => {
                return (
                  <tr key={`child-index-${index}`} className="table-row-hover-blue cursor-pointer">
                    <td>{item && item?.bomParentId}</td>
                    <td>{(item && item?.quantity) || '1'}</td>
                    <td>${(item && item?.price) || '0'}</td>
                    <td>
                      {!readOnly && (
                        <HButton
                          className="h-blue-button rounded-button"
                          onClick={(ev) => {
                            ev.stopPropagation()
                            const tempData = [...childs]
                            tempData.splice(index, 1)
                            setChilds(tempData)
                            store.addNotification({
                              message: 'Info: BOM deleted',
                              type: 'info',
                              insert: 'bottom',
                              container: 'bottom-left',
                              animationIn: ['animated', 'fadeIn'],
                              animationOut: ['animated', 'fadeOut'],
                              dismiss: {
                                duration: 1000,
                              },
                            })
                          }}
                        >
                          -
                        </HButton>
                      )}
                    </td>
                  </tr>
                )
              })}
            {childs && childs.length === 0 && (
              <tr>
                <td colSpan={10} style={{ textAlign: 'center', fontSize: '18px' }}>
                  No Products - click the + icon to add a Product for this BOM.
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="h-child-table-add-row">
          {!readOnly && (
            <HButton
              className="rounded-button"
              onClick={() => {
                setSelectedId(-1)
                setShowModal(true)
              }}
            >
              +
            </HButton>
          )}
        </div>
      </div>
    </>
  )
}
HBomChildsTable.propTypes = {
  childs: PropTypes.arrayOf(PropTypes.shape({})),
  setChilds: PropTypes.func,
  onSelect: PropTypes.func,
  readOnly: PropTypes.bool,
}

HBomChildsTable.defaultProps = {
  childs: [],
  setChilds: () => {},
  onSelect: () => {},
  readOnly: false,
}
export default HBomChildsTable
