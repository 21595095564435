// deprecated - as we don't need to save Products data inside redux
import * as types from "./actionTypes";

export function filterSettingSuccess(filter) {
  return { type: types.FILTER_SETTING_SUCCESS, filter };
}

export function customFilterSettingSuccess(filter) {
  return { type: types.CUSTOM_FILTER_SETTING_SUCCESS, filter };
}

export function filterRemoveSuccess() {
  return { type: types.FILTER_REMOVE_SUCCESS };
}

export function customFilterRemoveSuccess() {
  return { type: types.CUSTOM_FILTER_REMOVE_SUCCESS };
}

export function setNormalFilter(filter) {
  console.log("NormalFilter_Action", filter);
  return async dispatch => {
    dispatch(filterSettingSuccess(filter));
  };
}

export function setCustomFilter(filter) {
  console.log("CustomFilter_Action", filter);
  return async dispatch => {
    dispatch(customFilterSettingSuccess({ ...filter }));
  };
}

export function removeNormalFilter() {
  return async dispatch => {
    dispatch(filterRemoveSuccess());
  };
}

export function removeCustomFilter() {
  return async dispatch => {
    dispatch(customFilterRemoveSuccess());
  };
}
