/* eslint-disable import/no-cycle */
import { toCamelKeys } from 'utils/convert.util'
import { store } from 'index'
import { signoutRequest } from 'redux/actions/authActions'

export async function handleResponse(response) {
  const jsonData = response.json()
  if (response.ok) return jsonData
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message,
    // so parse as text instead of json.
    const error = await response.text()
    throw new Error(error)
  }
  throw new Error('Network response was not ok.')
}

export async function handleTokenResponse(response) {
  if (response.ok) return response.text()
  throw new Error('Token response was not ok.')
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
  // eslint-disable-next-line no-console
  console.error(`API call failed. ${error}`)
  throw error
}

export function getHeaderInfo(isFormData = false) {
  // User is set on User object in Redux store
  // const userInfo = store.getState().user
  const userInfo = store.getState().user.user
  const token = userInfo.authToken
  if (isFormData) {
    const headerInfo = {
      Authorization: `${token}`,
    }
    return headerInfo
  }
  const headerInfo = {
    'Content-Type': 'application/json',
    Accept: 'application/vnd.api+json',
    Authorization: `${token}`,
  }

  return headerInfo
}

export function setHeaderInfo(newToken) {
  localStorage.setItem('authToken', newToken)
}

function parseJSON(response) {
  console.log(response)
  if (!response) {
    return null
  }
  if (response.status === 204 || response.status === 205) {
    return null
  }
  return response.json()
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  }

  if (response.status === 401) {
    console.log('Your session is disconnected, please try again.')
    store.dispatch(signoutRequest('Your session is disconnected, please try again.'))
    return response
  }
  if (response.status === 422) {
    console.log('Session Expired')
    store.dispatch(signoutRequest('Session Expired. Please sign in again.'))
    return response
  }

  if (response.status === 402) {
    console.log('Product Error')
    return response
  }

  if (response.status === 403) {
    console.log('Part Error')
    return response
  }

  if (response.status === 404) {
    console.log('Scraper Error')
    return response
  }

  if (response.status === 405) {
    console.log('User Error')
    return response
  }

  if (response.status === 410) {
    console.log('Role Error')
    const error = new Error('You have no right to manage Users')
    error.response = response
    throw error
  } else {
    const error = new Error(response.statusText)
    error.response = response
    throw error
  }
}

export function fetchAPI(url, method = 'GET', payload = null, isFormData = false) {
  const requestUrl = `${process.env.REACT_APP_API_URL}/${url}`
  const options = { method }
  const headers = getHeaderInfo(isFormData)

  // maybe we need to set global API loading status const store = getStore();
  // store.dispatch(setAPILoading(true));
  const newPayload = { ...payload }

  if (isFormData) {
    options.body = payload

    return fetch(requestUrl, {
      ...options,
      headers,
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        console.log(resp)
        return toCamelKeys(resp)
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response)
          if (err.response.status === 410) {
            return { error: 'You have no right to manage Users' }
          }
          if (err.response.status === 400) {
            throw err
          }
          return { error: err.response.statusText || 'Error' }
        }
        console.log(err)
        throw err
      })
  }

  if (newPayload) {
    options.body = JSON.stringify(newPayload)
    console.log('fetchAPI', url, options.body)
  }
  return fetch(requestUrl, {
    ...options,
    headers,
  })
    .then(checkStatus)
    .then(parseJSON)
    .then((resp) => {
      return toCamelKeys(resp)
    })
    .catch((err) => {
      if (err.response) {
        console.log(err.response)
        if (err.response.status === 410) {
          return { error: 'You have no right to manage Users' }
        }
        if (err.response.status === 400) {
          throw err
        }
        return { error: err.response.statusText || 'Error' }
      }
      console.log(err)
      throw err
    })
}
