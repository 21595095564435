import React from 'react'
import PropTypes from 'prop-types'
import HCheckBox from 'components/common/Basic/HCheckBox'
import { toArrayFromJSON, toJSONFromArray } from 'utils/formatter'
import './index.css'

const SuppliersListItem = ({ supplier, viewEditAction, checked, setChecked, syncAction }) => {
  if (supplier.isVendor) {
    supplier.type = 'Vendor'
  }
  if (supplier.isManufacturer) {
    supplier.type = 'Manufacturer'
  }
  if (supplier.isManufacturer && supplier.isVendor) {
    supplier.type = 'Vendor/Manufacturer'
  }
  return (
    <div
      className={checked ? 'suppliers-list-item-checked' : 'suppliers-list-item'}
      onClick={() => {
        setChecked(!checked)
      }}
      onDoubleClick={() => {
        viewEditAction(supplier)
      }}
    >
      <div className="suppliers-list-item-info">
        <div className="suppliers-list-item-info-checkbox">
          <HCheckBox
            name={`${supplier.id}`}
            onChange={() => {
              setChecked(checked)
            }}
            checked={checked}
          />
        </div>
        <div className="suppliers-list-item-info-pic">
          <img
            src={supplier.logoUrl || '/assets/img/suppliers/supplier-image.png'}
            alt=""
            onError={(ev) => {
              // eslint-disable-next-line no-param-reassign
              ev.target.onerror = null
              // eslint-disable-next-line no-param-reassign
              ev.target.src = '/assets/img/suppliers/supplier-image.png'
            }}
          />
        </div>
        <div className="suppliers-list-item-info-badge">
          <img
            src={
              supplier.active
                ? '/assets/img/suppliers/active-true.png'
                : '/assets/img/suppliers/active-false.png'
            }
            alt="admin"
            width="25px"
          />
        </div>
        <div className="suppliers-list-item-info-subinfo">
          <div className="suppliers-list-item-info-title">{supplier.name}</div>
          <div className="suppliers-list-item-info-description">{supplier.type}</div>
        </div>
        <div className="suppliers-list-item-info-price-type">
          <div className="suppliers-list-item-info-price">
            {toArrayFromJSON(supplier.serviceName).map((item) => {
              return `${item}, `
            })}
          </div>
          <div className="suppliers-list-item-info-type">{}</div>
        </div>
      </div>
      <div className="suppliers-list-item-action">
        <a
          role="button"
          onClick={(ev) => {
            ev.stopPropagation()
            syncAction(supplier.serviceName)
          }}
          tabIndex={0}
        >
          Synchronize
        </a>
      </div>
    </div>
  )
}
SuppliersListItem.propTypes = {
  supplier: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    serviceName: PropTypes.string,
    active: PropTypes.bool,
    address: PropTypes.string,
    phoneNumber: PropTypes.string,
    contact: PropTypes.string,
    salesForceId: PropTypes.string,
    logoUrl: PropTypes.string,
  }),
  viewEditAction: PropTypes.func,
  checked: PropTypes.bool,
  setChecked: PropTypes.func,
  syncAction: PropTypes.func,
}

SuppliersListItem.defaultProps = {
  supplier: null,
  viewEditAction: () => {},
  checked: false,
  setChecked: () => {},
  syncAction: () => {},
}
export default SuppliersListItem
