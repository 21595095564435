/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import HUOMTable from 'components/common/Basic/HUOMTable'
import './index.css'

const UomSelectionModal = ({ onHide, headerText, partUoms, onSelect }) => {
  return (
    <Modal size="lg" show onHide={onHide}>
      <Modal.Header>
        <div className="px-2 mb-2">
          <h2>{headerText}</h2>
        </div>
      </Modal.Header>
      <Modal.Body className="modal--quick-view">
        <HUOMTable
          columns={[
            'UOM',
            'Per',
            'Cost',
            'Weight',
            'WeightUom',
            'Width',
            'Height',
            'Length',
            'SizeUom',
          ]}
          data={partUoms}
          setData={() => {}}
          selectMode
          onSelect={onSelect}
        />
      </Modal.Body>
    </Modal>
  )
}

UomSelectionModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  headerText: PropTypes.string,
  partUoms: PropTypes.any,
  onSelect: PropTypes.func.isRequired,
}
UomSelectionModal.defaultProps = {
  headerText: 'Success',
  partUoms: null,
}
export default UomSelectionModal
