/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import HCheckBox from 'components/common/Basic/HCheckBox'
import { priceDecimalFormatter, getShortString } from 'utils/formatter'
import { getProductFeatureImage } from 'utils/product_util'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import './index.css'

const BomsListItem = ({ product, viewEditAction, checked, setChecked }) => {
  const [dateText, setDateText] = useState('')

  useEffect(() => {
    const dateDistance = formatDistanceToNow(new Date(product && product.updatedAt))
    setDateText(`Updated ${dateDistance} ago`)
  }, [])

  return (
    <div
      className={checked ? 'products-list-item-checked' : 'products-list-item'}
      onClick={() => {
        setChecked(!checked)
      }}
      onDoubleClick={() => {
        viewEditAction(product)
      }}
    >
      <div className="products-list-item-info">
        <div className="products-list-item-info-checkbox">
          <HCheckBox
            name={product && product.productNumber}
            onChange={() => {
              setChecked(checked)
            }}
            checked={checked}
          />
        </div>
        <div className="products-list-item-info-pic">
          <img
            className="products-list-item-info-pic"
            src={
              getProductFeatureImage(product.productPictureUrl) ||
              '/assets/img/products/featured.svg'
            }
            alt="123"
            onError={(ev) => {
              // eslint-disable-next-line no-param-reassign
              ev.target.onerror = null
              // eslint-disable-next-line no-param-reassign
              ev.target.src = '/assets/img/products/featured.svg'
            }}
          />
        </div>
        <div className="products-list-item-info-subinfo">
          <div className="products-list-item-info-title">
            {(product && getShortString(product.productDescription, 80)) || ''}
          </div>
          <div className="products-list-item-info-description">
            {(product && getShortString(product.productNumber)) || ''}
          </div>
          <div className="products-list-item-info-price-type">
            <div className="products-list-item-info-price">
              {priceDecimalFormatter(product && product.price)}
            </div>
            <div className="products-list-item-info-type">
              {(product && getShortString(product.incomeAccount, 10)) || ''}
            </div>
          </div>
        </div>
      </div>
      <div className="products-list-item-action">
        {/* <div className="products-list-item-date">{dateText}</div> */}
        <div className="products-list-item-date">{}</div>
        <Link to={`/products/view/${product.id}`}>EDIT / VIEW</Link>
      </div>
    </div>
  )
}
BomsListItem.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number,
    productNumber: PropTypes.string,
    price: PropTypes.number,
    incomeAccount: PropTypes.string,
    productPictureUrl: PropTypes.string,
    productDescription: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
  viewEditAction: PropTypes.func,
  checked: PropTypes.bool,
  setChecked: PropTypes.func,
}

BomsListItem.defaultProps = {
  product: null,
  viewEditAction: () => {},
  checked: false,
  setChecked: () => {},
}
export default BomsListItem
