/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { classNames } from 'utils/helper'

import { getProductFeatureImage } from 'utils/product_util'
import { priceDecimalFormatter } from 'utils/formatter'

import throttle from 'lodash/throttle'
import Spinner from 'react-bootstrap/Spinner'
import { store } from 'react-notifications-component'
import { getProductsByQuery } from 'api/productApi'
import onClickOutside from 'react-onclickoutside'
import HInput from './HInput'

import './index.css'

function HSearchProductInput({
  initialProductCost,
  initialProductQuantity,
  setSelectedProduct,
  setSelectedProductCost,
  setSelectedProductQuantity,
}) {
  const [searchProductQuery, setSearchProductQuery] = useState('')
  const [searchProductResult, setSearchProductResult] = useState([])
  const [searchFocused, setSearchFocused] = useState(false)
  const [searchLoading, setSearchLoading] = useState(false)
  const [productCost, setProductCost] = useState(initialProductCost)
  const [productQuantity, setProductQuantity] = useState(initialProductQuantity)

  const searchProduct = throttle(async (searchProductQuery) => {
    if (searchProductQuery && searchProductQuery.length > 0) {
      try {
        const results = await getProductsByQuery(searchProductQuery)
        console.log('getProductsByQuery --> ', results)
        if (results && results.error) {
          console.log('error', results.error)
          store.addNotification({
            message: 'Sorry, we are not able to process your request now. Please try again later.',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 4000,
            },
          })
        }
        if (results && results.products) {
          setSearchProductResult(results.products)
        }
      } catch (error) {
        console.log('Search error', error)
        store.addNotification({
          message: 'Sorry, we are not able to process your request now. Please try again later.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 4000,
          },
        })
      }
    } else {
      setSearchProductResult([])
    }
    setSearchLoading(false)
  }, 1000)

  useEffect(() => {
    if (searchProductQuery.length >= 3) {
      setSearchLoading(true)
      searchProduct(searchProductQuery)
    }
  }, [searchProductQuery])

  HSearchProductInput.handleClickOutside = () => setSearchFocused(false)

  return (
    <div className="search-product-input-div">
      <div className="search-product-input-line">
        <HInput
          className="search-product-input flex-2"
          searchBox
          value={searchProductQuery}
          onChange={(ev) => {
            setSearchProductQuery(ev.currentTarget.value)
          }}
          onFocus={() => {
            setSearchFocused(true)
          }}
        />
        <HInput
          className="ml-1 mr-1 search-product-cost"
          placeholder="Quantity"
          value={productQuantity}
          onChange={(ev) => {
            setProductQuantity(ev.currentTarget.value)
            setSelectedProductQuantity(ev.currentTarget.value)
          }}
        />
        <HInput
          className="ml-1 mr-1 search-product-cost"
          placeholder="Price"
          value={productCost}
          onChange={(ev) => {
            setProductCost(ev.currentTarget.value)
            setSelectedProductCost(ev.currentTarget.value)
          }}
        />
        <span className="search-product-dollar">$</span>
      </div>
      {searchProductResult && searchProductResult.length > 0 && (
        <div
          className={
            !searchFocused ? 'search-result-container hide' : 'search-result-container show'
          }
        >
          {searchLoading && (
            <div className="pt-3 pb-3 row align-items-center justify-content-center">
              <Spinner className="mr-3" animation="border" variant="primary" /> Loading...
            </div>
          )}
          {!searchLoading && (
            <ul>
              {searchProductResult &&
                searchProductResult.map((product, index) => {
                  return (
                    <li
                      className="suggestion-item"
                      key={index}
                      onClick={() => {
                        setSelectedProduct(product)
                        setProductCost(product.price)
                        setSelectedProductCost(product.price)
                        setSearchFocused(false)
                      }}
                    >
                      <div className="suggestions-item-div">
                        <div className="suggestion-item-img">
                          <img
                            className="suggestion-item-img-pic"
                            src={
                              getProductFeatureImage(product && product.productPictureUrl) ||
                              '/assets/img/products/featured.svg'
                            }
                            alt=""
                            onError={(ev) => {
                              // eslint-disable-next-line no-param-reassign
                              ev.target.onerror = null
                              // eslint-disable-next-line no-param-reassign
                              ev.target.src = '/assets/img/products/featured.svg'
                            }}
                          />
                        </div>
                        <div className="suggestion-item-details">
                          <div className="suggestion-item-number">
                            <span>{product.productNumber}</span>
                          </div>
                          <div className="suggestion-item-description">
                            <span>{product.description}</span>
                          </div>
                          <div className="suggestion-item-sub-details">
                            <div className="suggestion-item-price">
                              <span>{priceDecimalFormatter(product && product.price)}</span>
                            </div>
                            <div className="suggestion-item-uom">
                              <span>{product.uom}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                })}
            </ul>
          )}
        </div>
      )}
      {searchProductResult && searchProductResult.length === 0 && (
        <div
          className={
            !searchFocused ? 'search-result-container hide' : 'search-result-container show'
          }
          tabIndex={0}
        >
          <div className="pt-3 pb-3 row align-items-center justify-content-center">
            {searchLoading && (
              <>
                <Spinner className="mr-3" animation="border" variant="primary" /> Loading...
              </>
            )}
            {!searchLoading && `No Results for "${searchProductQuery}"`}
          </div>
        </div>
      )}
    </div>
  )
}

HSearchProductInput.propTypes = {
  initialProductCost: PropTypes.string,
  initialProductQuantity: PropTypes.string,
  setSelectedProduct: PropTypes.func,
  setSelectedProductCost: PropTypes.func,
  setSelectedProductQuantity: PropTypes.func,
}

HSearchProductInput.defaultProps = {
  initialProductCost: '0',
  initialProductQuantity: '1',
  setSelectedProduct: () => {},
  setSelectedProductCost: () => {},
  setSelectedProductQuantity: () => {},
}

const clickOutsideConfig = {
  handleClickOutside: () => HSearchProductInput.handleClickOutside,
}

export default onClickOutside(HSearchProductInput, clickOutsideConfig)
